import Ellipsis from '../../node_modules/ellipsis.js/ellipsis';

/**
 * Ellipsis wrapper helper
 * @param {string,HTMLElement} node - dom selector or element
 * @param {object} config - ellipsis options
 * @return {object}
 */
export const clamp = (node, config = {}) => {
	const ellipsis = Ellipsis(config);
	const element =
		typeof node === 'string' ? document.getElementsByClassName(node) : node;
	element && ellipsis.add(element);
};

/**
 * returns a config object for clamp
 * @param config
 * @param isMobile
 */
export const getClampConfig = (config, isMobile) => {
	const retConfig = {};
	if (typeof config === 'number') {
		retConfig.lines = config;
	} else if (typeof config === 'object' && config !== null) {
		const { mobile, desktop } = config;
		retConfig.lines = isMobile ? mobile : desktop;
	}
	return retConfig;
};
