import APIClient from '../APIClient';
import { buildUrl } from 'utils/globalUtils';

const basePath = '/my';

export const getTopics = (params = {}) => {
	return APIClient.get(buildUrl(`${basePath}/topics`, params));
}

export const getUnreadNotificationCount = () => {
    return APIClient.get(buildUrl(`${basePath}/notifications/unacknowledged_exists`, {}));
}

export const getNotifications = (page) => {
    return APIClient.get(buildUrl(`${basePath}/notifications`, { page }));
}