export const fsDesktop = 'fs-d';
export const fsHD = 'fs-hd';
export const fsMobile = 'fs-m';
export const fsTablet = 'fs-t';

export const fsRoot = 'fs-root';

export const fsPage = 'fs-page';
export const fsPageBanner = `${fsPage}--banner`;
export const fsPageContainerFooter = `${fsPage}--container-footer`;
export const fsPageError = `${fsPage}--error`;
export const fsPageLoading = `${fsPage}--loading`;
export const fsPageFooter = `${fsPage}--footer`;
export const fsPageFooterFixed = `${fsPageFooter}-fixed`;
export const fsPageSidebarLeft = `${fsPage}--sidebar-left`;
export const fsPageSidebarRight = `${fsPage}--sidebar-right`;

export const fsPageFooterPush = `${fsPage}__footer-push`;

export const fsContainer = 'fs-container';
export const fsContainerBanner = `${fsContainer}--banner`;
export const fsContainerError = `${fsContainer}--error`;
export const fsContainerLoading = `${fsContainer}--loading`;

export const fsContainerCenter = `${fsContainer}__center`;
export const fsContainerContent = `${fsContainer}__content`;
// temporary for calling element with group banner / will call group banner directly once component class refactored
export const fsContainerHeader = `${fsContainer}__header`;

// temporary for calling element with group banner / will call group banner directly once component class refactored
export const fsContent = 'fs-content';
export const fsContentFull = `${fsContent}__full`;

export const fsContainerContentFooter = `${fsContainerContent}--footer`;
export const fsContainerFooterPush = 'fs-container__footer-push';
export const fsContainerFooter = 'fs-container--footer';

export const fsContainerAdmin = 'fs-container--admin';
export const fsContainerDetail = 'fs-container--detail';
export const fsContainerList = 'fs-container--list';

export const fsContentError = 'fs-content--error';
export const fsContentLoading = 'fs-content--loading';

// export const fsNavigation = 'fs-navigation';
export const fsHeader = 'wrapper__header';
export const fsSubNavOpen = 'fs-navigation--sub-open';

export const fsFooter = 'fs-footer-refactor';
export const fsFooterFixed = `${fsFooter}--fixed`;
export const fsFooterContent = `${fsFooter}__content`;

export const fsSide = 'fs-sidebar';
export const fsSideInline = 'fs-sidebar--inline';
export const fsSideLeft = 'fs-sidebar--left';
export const fsSideRight = 'fs-sidebar--right';

export const fsImage = 'fs-image';
export const fsDateBox = 'fs-date-box';

export const cnPageForm = 'page-form';