import React from 'react';

class TermsOfUse extends React.Component {

	render() {
		return (
			<div className="modal--terms">
				<section>
					<p className="large"><strong>TERMS OF USE</strong></p>

					<p>Last Revised June 3, 2014</p>

					<p>These terms and conditions of use ("Terms") govern your use of Full Sail's ("Full Sail" is used to refer to Full Sail, Inc., Full Sail, LLC, and Full Sail University) website (the "Site"). You must to comply with the Terms if you want to visit or use the Site. By using the Site, you automatically agree to the Terms, and the Terms are an agreement between you and Full Sail.</p>

					<p>If you do not agree to be bound by the Terms, you are not allowed to visit the Site. Please also review our Privacy Policy for a description of Full Sail's privacy practices and policies. We encourage you to read the Privacy Policy, and to use it to help make informed decisions.</p>

					<p>These Terms are written in English. Full Sail does not guarantee the accuracy of any translated versions of the Terms. To the extent any translated version of the Terms conflicts with the English version, the English version controls.</p>

					<p><strong>Full Sail Owns the Site.</strong> Other than User Submissions (as defined below), the Site and any materials made available for download or viewing are the property of Full Sail. Full Sail is protected by United States and international intellectual property, copyright, and trademark laws. The contents of the Full Sail website, including without limitation the files, documents, text, photographs, images, audio, and video, and any materials accessed through or made available for use or download through the Site may not be copied, distributed, modified, reproduced, published or used, in whole or in part, except for purposes authorized or approved by Full Sail. You may not frame or utilize framing techniques to enclose or link to, any name, trademarks, service marks, logo, or other proprietary information (such as images, text, page layout, or form) from the Site without Full Sail's express written consent. You also understand and acknowledge that Full Sail personnel may access stored communications and other electronically stored information that you provide or are provided utilizing the Site, and you understand you have no expectation of privacy in communications made on the Site.</p>

					<p><strong>You Must Abide By Full Sail's Policies When Using the Site.</strong> You must abide by Full Sail's Code of Conduct, Standards of Behavior, and Intellectual Property Policy, as established in the applicable Full Sail Student Manual or Student Handbook.</p>

					<p><strong>You Grant Full Sail a License to Use What You Post.</strong> You can voluntarily post or display a variety of comments, information, images, files, links and other materials using the Site (“User Submissions”), and you grant Full Sail and its assigns a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any of your User Submissions that you post on or in connection with the Site (“User Submission License”). We may use your User Submissions without any obligation to compensate you for them. Full Sail reserves the right to, in its sole discretion, refuse to accept, post, display, or transmit any User Submission. You represent and warrant that you have all the rights, power, and authority necessary to post your User Submissions. You agree that Full Sail has no responsibility or liability for the deletion of, or the failure to store or to transmit, any User Submissions or anything else on the Site. You agree that you will use the Site in compliance with all applicable local, state, national, and international laws, rules and regulations, including any laws regarding the transmission of technical data exported from your country of residence and all United States export control laws.</p>

					<p><strong>When Using the Site:</strong></p>

					<ol>
						<li>No harassing or offensive User Submissions. Unlawful, harassing, defamatory, abusive, threatening, harmful, obscene, profane, sexually oriented, racially offensive, or otherwise objectionable comments are not ok. Anything facilitating or encouraging violations of these Terms is also unacceptable.</li>
						<li>No spamming or flooding. Do not post the same User Submission more than once.</li>
						<li>Do not use automated means, such as bots, to access the Site or collect user content or information.</li>
						<li>Do not use the Site, or User Submissions, to engage in or promote any unlawful activities, or for any commercial purposes.</li>
						<li>Do not submit or upload anything that violates the copyright or intellectual property rights of any person or entity.</li>
						<li>Do not post or transmit a virus or any other harmful component.</li>
						<li>Do not impersonate others. Adopting an inappropriate user name (one that is vulgar or offensive) is also unacceptable.</li>
						<li>Do not solicit login information or access someone else's account on the Site.</li>
						<li>Only include suitable URLs in your User Submissions- unsuitable URLs may be removed. Unsuitable sites would include those with racist material, pornographic or sexually explicit material, potentially defamatory material, anything which encourages illegal activities, material which infringes copyright, sites which purely plug or promote commercial products or services without containing material which enhances the subject matter, or sites which may offend our users.</li>
					</ol>

					<p><strong>Full Sail is Not Responsible for Copyright Infringement by Others.</strong> We disclaim any responsibility or liability for copyrighted materials posted on the Site. If you believe that your work has been copied in a manner that constitutes copyright infringement, please let us know - follow the procedures set forth in our copyright policy.</p>

					<p><strong>Full Sail Has No Control Over Third Party Content.</strong> By using the Site, you may be exposed to content provided by third parties, or to information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Except for certain limited circumstances, such as where legally required, we generally do not review or filter such content. You agree and acknowledge that we are not responsible for other third party content or information or for any damage of any kind incurred as result of your reliance thereon. The Site may also include links to third party web sites You are responsible for evaluating whether you want to access or use a third party site, and Full Sail does not control or endorse any third party website. You should review any applicable terms or privacy policy of a third party site before using it or sharing any information with it, because the third party site may use your information in ways Full Sail would not. Full Sail is not responsible for and does not endorse any features, content, advertising, products or other materials on or available from third party sites. Full Sail also does not screen, audit, or endorse such third party sites. Accordingly, if you decide to use third party sites, you acknowledge you do so at your own risk.</p>

					<p><strong>Do Not Give Out Your Username or Password.</strong> You may use a specific username and password to access portions of the Site. Only access the Site using your username and password. You agree to protect the confidentiality of your username and password, and not to share or disclose your username or password to anyone else. You agree that you are responsible for all transactions that occur under your username.</p>

					<p><strong>Full Sail Can Terminate Your Access to the Site, and to Your User Account.</strong> Full Sail may, in its sole discretion, at any time and for any reason, terminate your access to the Site at any time with or without cause, terminate this agreement (meaning the Terms of Use - remember that these Terms of Use are an agreement between you and Full Sail), and suspend or delete your username, password and account. Other than any obligations specifically imposed by Federal or state law, Full Sail has no obligation to store any content on the Site, including but not limited to User Submissions, stored communications, or other electronically stored information, and Full Sail regularly overwrites and deletes data associated with the Site and your use of the Site as part of the routine, good-faith operation of Full Sail's electronic information systems. To the extent you wish to preserve any such electronically stored information, you are solely responsible for the preservation of such electronically stored information. Full Sail may also block your access to the Site. If Full Sail decides to take any of the above-listed actions, your account may be disabled and you may not be granted access to your account or any files or other content contained in your account. Full Sail reserves the right to refuse access or service to anyone at any time without notice for any reason.</p>

					<p><strong>You Agree to Indemnify Full Sail for Damage That You Cause.</strong> You agree to defend, indemnify, and hold Full Sail harmless from and against all third party claims, damages, and expenses (including reasonable attorneys' fees) against or incurred by Full Sail arising out of your use of the Site, your access to the Site, or any breach of these Terms of Use.</p>

					<p><strong>You Cannot Use The Site if You Are Under Thirteen Years of Age.</strong> You must be at least thirteen years of age to use the Site, and Full Sail forbids anyone under thirteen years of age from using the Site.</p>


					<p><strong>Privacy on the Site – You Agree to the Terms in the Privacy Policy.</strong> As a condition of using the Site, you agree to the terms of the Privacy Policy, which may be updated from time to time, as expressed in the most recent version that exists at the time of your use. You agree that Full Sail may access or disclose your personal information, including the content of your communications, if Full Sail is required to do so in order to comply with any valid legal process or governmental request (such as a search warrant, subpoena, statute, or court order), or as otherwise provided in the Terms and the Privacy Policy. Personal information collected by Full Sail may be stored and processed in the United States or any other country. By using the Site, you expressly consent to any such transfer of information outside of your country. You also understand and acknowledge that Full Sail personnel may access stored communications and other electronically stored information that you provide or are provided utilizing the Site, and you understand you have no expectation of privacy in communications made on the Site.</p>

					<p><strong>Disclaimer of Warranties.</strong></p>

					<p>FULL SAIL DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE CORRECTED. THE COMPANY, INCLUDING ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY COMPANY-RELATED SERVICE, IS PROVIDED "AS IS," WITH ALL FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. THE COMPANY SPECIFICALLY DISCLAIMS ALL LIABILITY FOR ERRORS OR OMISSIONS IN, OR THE MISUSE OR MISINTERPRETATION OF, ANY INFORMATION OBTAINED THROUGH THE SITE.</p>

					<p>YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE. FULL SAIL DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA BACK UP AND SECURITY. YOU AGREE THAT FULL SAIL SHALL NOT BE LIABLE FOR ANY COST OR DAMAGE ARISING DIRECTLY OF INDIRECTLY FROM ANY SUCH CODE. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE AND OF THE INTERNET.</p>

					<p><strong>Limitation of Liability Regarding Use of the Site.</strong></p>

					<p>FULL SAIL IS NOT RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, SITE-RELATED SERVICES, CONTENT OR INFORMATION CONTAINED WITHIN THE SITE, OR ANY HYPERLINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES, OR HYPERLINKED WEB SITES IS TO STOP USING THE SITE AND ANY ASSOCIATED SERVICES.</p>
				</section>

				<section>
					<p className="large"><strong>COPYRIGHT POLICY</strong></p>

					<p>Last Revised June 3, 2014</p>

					<p>As set forth in Full Sail's ("Full Sail" is used to refer to Full Sail, Inc., Full Sail, LLC, and Full Sail University) Terms of Use [see above], Full Sail respects the intellectual property rights of others.Our Terms of Use prohibit users from submitting or uploading content that violates the copyright or intellectual property rights of any person or entity.This Copyright Policy is written in English.Full Sail does not guarantee the accuracy of any translated versions of this Copyright Policy.To the extent any translated version of this Copyright Policy conflicts with the English version, the English version controls.</p>

					<p>Full Sail provides a process for submission of complaints concerning copyright infringement, as outlined below.</p>

					<p>In accordance with our Terms of Use and the below-described procedures, and regardless of whether Full Sail may be liable for any alleged violation of rights or posting of inaccurate or unlawful content, we may remove or disable access to specified content appearing on Full Sail's website upon receipt of a verified notice asserting that the content infringes intellectual property rights, is inaccurate, or is otherwise unlawful. As mentioned in the Terms of Use, Full Sail reserves the right to remove any content posted to Full Sail's website for any reason.</p>

					<p>Whether or not we disable access to or remove content, we may make a good faith attempt to forward the written notification, including the complainant’s contact information, to the user who posted the content and to take any other steps to notify the user that Full Sail received a notice of an alleged violation of intellectual property rights or other content violation.</p>

					<p>The user may then refute the claim by submitting a counter-notice as permitted in these procedures. We may also provide any submitted counter-notice to the complainant with the user’s personal contact information. In appropriate circumstances and in our discretion, we may disable or delete the accounts of users, or groups, who infringe the intellectual property rights of others.</p>

					<p>Please note that any notice or counter-notice you submit must be truthful and must be submitted under penalty of perjury. A false notice or counter-notice may give rise to your personal liability in a lawsuit. You should therefore seek the advice of legal counsel before submitting a notice or a counter-notice to Full Sail.</p>

					<p>Pursuant to the Digital Millennium Copyright Act (17 U.S.C. § 512), Full Sail has specific procedures for receiving written notification of claimed infringements. Full Sail has also designated an agent to receive notices of claimed copyright infringement.</p>

					<p>If you believe in good faith that your copyright has been infringed, you may complete and submit a Notice of Copyright Infringement form, or otherwise provide a written Reporting Copyright Infringement communication which contains:</p>

					<ol>
						<li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
						<li>A description of the copyrighted work that you claim has been infringed;</li>
						<li>A description specifying the location on Full Sail's website of the material that you claim is infringing;</li>
						<li>Your telephone number, physical mailing address, and email address;</li>
						<li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</li>
						<li>A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
					</ol>

					<p>The fastest and easiest way to send Full Sail's Copyright Agent, Adam C. Losey, a claim of copyright infringement by sending an email to <a href="mailto:alosey@foley.com">alosey@foley.com</a>.You may also contact Full Sail's Copyright Agent by mailing your claim to Adam C. Losey, Foley & Lardner LLP, 90 Park Avenue, New York, NY 1006-1314, and you can reach Full Sail's Copyright Agent at 212-338-3599.</p>

					<p>If you believe that a notice of copyright infringement has been improperly submitted against you, you may submit a Counter-Notice, pursuant to Sections 512(g)(2) and (3) of the Digital Millennium Copyright Act. You may complete the Counter-Notice Regarding Claim of Copyright Infringement form, or otherwise provide a written communication which contains:</p>

					<ol>
						<li>Your physical or electronic signature;</li>
						<li>Identification of the material removed or to which access has been disabled;</li>
						<li>A statement under penalty of perjury that you have a good faith belief that removal or disablement of the material was a mistake or that the material was misidentified;</li>
						<li>Your name, telephone number, email address and a statement that you consent to the jurisdiction of the Federal District court (i) in the judicial district where your address is located if the address is in the United States, or (ii) located in the Middle District of Florida, if your address is located outside the United States, and that you will accept service of process from the Complainant submitting the notice or his/her authorized agent.</li>
					</ol>

					<p>Please submit any Counter-Notice to Full Sail's Copyright Agent at the address specified above.</p>
				</section>

				<section>
					<p className="large"><strong>PRIVACY POLICY</strong></p>

					<p>Last Revised June 3, 2014</p>

					<p>Full Sail ("Full Sail" is used to refer to Full Sail, Inc., Full Sail, LLC, and Full Sail University) is committed to making your experience online satisfying and safe. Your privacy on the Internet is of utmost important to us.</p>

					<p>We provide this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure of information, including personally identifiable information, that we receive from users of our websites (collectively, the "Sites"), through our applications for mobile devices ("Mobile Apps"), and through various offline information collection methods including without limitation the completion of lead forms sourced by third-party data aggregators. This Privacy Policy applies only to information that you provide to us through the Sites, the Mobile Apps or any of our offline information collection methods.</p>

					<p>As used in this policy, the terms "using" and "processing" information include using cookies on a computer, subjecting the information to statistical or other analysis and using or handling information in any way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and transferring information within our organization or among our affiliates within the United States or internationally.</p>

					<p>This Privacy Policy is written in English. Full Sail does not guarantee the accuracy of any translated versions of this Privacy Policy. To the extent any translated version of this Privacy Policy conflicts with the English version, the English version controls.</p>

					<p><strong>Information Collection and Use</strong></p>

					<p><strong>Personally Identifiable Information.</strong> When you use the Sites and Mobile Apps, we may ask you to provide us with Personally Identifiable Information. As used in this Privacy Policy, "Personally Identifiable Information" means any information that can be used to contact or identify you such as your name, home address, email address, telephone number or payment card number, social security number, and official, or government-issued, ID information. We collect Personally Identifiable Information for a variety of reasons. For example, certain features of the Sites and Mobile Apps require that you have a user account with a login and password: Full Sail students need a user account to access online courses; alums need a user account to access the online alumni network website; and shoppers may create a user account to order merchandise from Full Sail's online store. Full Sail also collects information that users provide through optional, voluntary submissions. These voluntary submissions include making an online request that Full Sail send you additional information about its programs, via email or regular mail, and submitting an application to Full Sail degree programs online.</p>

					<p>We also collect the other information that you provide as part of use of the Sites and Mobile Apps (e.g., without limitation, zip code (on its own), city and state, age and individual preferences) ("Non-Identifying Information").</p>

					<p>We use your Personally Identifiable Information (in some cases, in conjunction with your Non-Identifying Information) mainly to administer and provide the Sites and Mobile Apps and to administer your inquiries.</p>

					<p>Certain Non-Identifying Information, which would be considered a part of your Personally Identifiable Information if it were combined with other identifiers such as your zip code and street address in a way that enables you to be identified, is considered to be Non-Identifying Information when it is taken alone or combined only with other non-identifying information. We may combine your Personally Identifiable Information with Non-Identifying Information and aggregate it with information collected from other users of the Sites and Mobile Apps to attempt to provide you with a better experience, to improve the quality and value of the Sites and Mobile Apps, and to analyze and understand how our Sites and Mobile Apps are used.</p>

					<p><strong>Unsubscribe Information.</strong> You may opt out of email communications from Full Sail University at any time by clicking on the "unsubscribe" link found at the bottom of every email. Requests are processed as they are received; however, some email communications are planned in advance and may continue to be sent for a few days following your unsubscribe request.</p>

					<p><strong>Usage Tracking Information.</strong> Full Sail collects aggregated information regarding user traffic patterns mainly by tallying page views throughout the Sites and Mobile Apps ("Usage Tracking Information"). Full Sail does break down overall Usage Tracking Information according to a user's domain name, browser type, IP address and MIME type by reading this information from the browser string (information contained in every user's browser). Usage Tracking Information may also include the pages you were visiting before you came to our Sites or Mobile Apps, the time spent on those pages, search terms that you enter into our Search function, access times and dates, and other statistics. In addition to submitted content, user acknowledges that all activity may be monitored and recorded. This includes but is not limited to login and logout timestamps, page clicks and viewing history. We use Usage Tracking Information to determine which areas of our Sites and Mobile Apps that users visit based on traffic to those areas. We do not track what individual users read, but rather how well each page performs overall. This helps us continue to build better Sites and Mobile Apps for you.</p>

					<p><strong>How We Respond to Web Browser "Do Not Track" Signals or Other Mechanisms.</strong> Full Sail’s learning management system does not listen for or respond to Do Not Track signals.</p>

					<p><strong>Pixel Tags.</strong> Full Sail works with multiple marketing partners to display retargeted ads to users of our Sites, on our behalf, across the Internet. They collect non-Personally Identifiable Information about your visits to our Sites and your interaction with our products and services. We collect non-Personally Identifiable Information through the use of pixel tags, "clear GIFs," and javascript-based tracking means, which are industry standard technologies that most major websites use. We collect no Personally Identifiable Information during this process.</p>

					<p><strong>Cookies.</strong> We may place a text file called a "cookie" in the browser files of your computer. The cookie itself contains no Personally Identifiable Information although it will enable us to relate your use of the Sites to information that you have specifically and knowingly provided. But the only information a cookie can convey to us is information you supply yourself. A cookie cannot read data off your hard disk or read cookie files created by other sites. Full Sail uses cookies to track user traffic patterns (as described above). Our advertising system delivers a one-time cookie to better track ad impressions and click rates.</p>

					<p>You can refuse cookies by turning them off in your browser. If you have set your browser to warn you before accepting cookies, you will receive the warning message with each cookie. You do not need to have cookies turned on to use the Sites. Nevertheless, there may be certain portions of the Sites that can only be used if cookies are turned on. For example, cookies are necessary for a user to access portions of the Sites that require a valid user account.</p>

					<p><strong>Full Sail May Access Stored Communications and Electronically Stored Information on the Site.</strong> In using the Sites, you may use certain internal email or messaging functions that involve the creation, transmission, or receipt of stored communications or electronically stored information. Full Sail personnel may access stored communications and other electronically stored information that you provide or are provided utilizing the Sites, and you understand you have no expectation of privacy in communications made on the Sites.</p>

					<p><strong>Phishing</strong></p>

					<p>Identity theft and the practice currently known as "phishing" are of great concern to Full Sail. Safeguarding information to help protect you from identity theft is a top priority. We do not and will not, at any time, request your credit card information, your login information, or national identification numbers in a non-secure or unsolicited email or telephone communication. For more information about phishing, visit the Federal Trade Commission's website.</p>

					<p><strong>Full Sail Limits Information Shared with Third Parties</strong></p>

					<p>Full Sail does not share Personally Identifiable Information with third-party ad servers or ad networks to serve third-party advertisements on the Sites or Mobile Apps.</p>

					<p><strong>Aggregate Information and Non-Identifying Information.</strong> We may share aggregated information that includes your Personally Identifiable Information, Non-Identifying Information and Usage Tracking Information with third parties for industry analysis and demographic profiling and to deliver targeted advertising about other products and services.</p>

					<p><strong>Service Providers.</strong> We may engage certain trusted third party companies and individuals to facilitate the Sites and Mobile Apps on our behalf, to perform Site and Mobile Apps-related services (e.g., without limitation, hosting and maintenance services, customer relationship services, web analytics, payment processing and database storage and management services). Full Sail also shares information about prospective students with third-party services that Full Sail uses to evaluate potential applicants. We will share your Personally Identifiable Information with these third parties, but only to the extent necessary to perform these functions and provide such services, and only if such third parties are obligated not to use or disclose your Personally Identifiable Information for any other purpose.</p>

					<p><strong>Affiliated Schools.</strong> If you contact us to request information regarding our courses or degree programs, we may share your Personally Identifiable Information with our affiliated schools, the Los Angeles Film School and Rocky Mountain College of Art and Design. Our affiliated schools may use your Personally Identifiable Information to contact you to provide information regarding their courses and degree programs.</p>

					<p><strong>Compliance with Laws and Law Enforcement.</strong> Full Sail cooperates with government and law enforcement officials and private parties to enforce and to comply with applicable law or valid legal process. Full Sail may disclose information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), to protect the property and rights of Full Sail or a third party, to protect the safety of the public or any person or to prevent or stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable activity.</p>

					<p><strong>Business Transfers.</strong> Full Sail may sell, transfer or otherwise share some or all of its assets, including your Personally Identifiable Information, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy.</p>

					<p><strong>Changes to Privacy Policy</strong></p>

					<p>If we decide to make any material changes to our Privacy Policy, we will notify you by posting the new Privacy Policy on the Sites or via the Mobile Apps. You are advised to consult this policy regularly for any changes.</p>

					<p><strong>Updating Your Privacy Preferences and Information</strong></p>

					<p>To update or correct your information and privacy preferences (such as whether you wish to receive promotional communications), you may write Full Sail at:</p>

					<div>
						<p>Full Sail University</p>
						<p>Privacy Policy Information</p>
						<p>3300 University Boulevard</p>
						<p>Winter Park, FL 32792</p>
						<p>website@fullsail.com</p>
					</div>

					<p>You can use the same contact information above to request information under California Civil Code Section 1798.83.</p>

					<p><strong>Security</strong></p>

					<p>We do not take measures to protect non-sensitive Personally Identifiable Information. Full Sail is very concerned with safeguarding your information. The security of your Personally Identifiable Information is important to us. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. When you enter sensitive information (such as credit card number or social security number) on our registration or order forms, we encrypt that information using secure socket layer technology (SSL). In some cases, we also encrypt sensitive information where it is stored on our systems.</p>

					<p><strong>International Transfer</strong></p>

					<p>Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as where you live. If you are located outside the United States and choose to provide information to us, we may transfer your Personally Identifiable Information to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

					<p><strong>Links to Other Sites</strong></p>

					<p>The Sites may include links to other websites maintained or controlled by third parties unrelated to Full Sail. If you to click on such a link, you will be directed to that third party's website. The fact that we link to a website is not an endorsement or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit Personally Identifiable Information from you. Other websites follow different rules regarding the use or disclosure of Personally Identifiable Information. We advise you to review the individual privacy policies of each external site that you use.</p>

					<p><strong>Our Policy Toward Children</strong></p>

					<p>The Sites and Mobile Apps are not directed to children under 13. We do not knowingly collect Personally Identifiable Information from children. If a parent or guardian becomes aware that his or her child has provided us with Personally Identifiable Information without their consent, he or she should contact us at website@fullsail.com. If we become aware that a child under the age of 13 and has provided us with Personally Identifiable Information without verifiable parental consent, we will delete such information from our files.</p>
				</section>
			</div>
		)
	}
}

export default (TermsOfUse);