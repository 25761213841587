import React, { createElement, Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import WithResize from 'components/HOC/WithResize';
import { ToastContainer } from 'react-toastify';
import { fsDesktop, fsHD, fsMobile, fsPage, fsRoot, fsTablet } from '../helpers/layoutClassNameList';
import { LayoutContext } from 'components/layout/LayoutProvider';
import { Header } from 'components/layout';
import { prefixer } from 'components/layout/helpers/prefixer';

const hasValidSubNavigation = subNavigation => {
	return Boolean(subNavigation?.title?.length && subNavigation?.children?.length);
}

function Root(props) {
	const { children, isHD, isTablet, isDesktop, isMobile, disableNavigation = false  } = props;

	const { action, state } = useContext(LayoutContext);
	const { setIsMobile } = action;
	const { subNavigation, panel, panelActive, navigationActive, pageLoading, pageModifier } = state;

	const [rootInlineStyles] = useState({});

	const rootStyles = [
		fsRoot,
		{
			[fsHD]: isHD,
			[fsDesktop]: isHD || isDesktop,
			[fsTablet]: isTablet,
			[fsMobile]: isTablet || isMobile
		}
	];

	const pageStyles = [
		fsPage,
		...prefixer(fsPage, pageModifier)
	];

	// Update layout provider with isMobile flag
	useEffect(() => {
		setIsMobile(isMobile);
	}, [isMobile, setIsMobile]);

	return (
			<Fragment>
				<ToastContainer />

				<div className={cn(rootStyles)} style={rootInlineStyles}>
					<div className={cn(pageStyles)}>
						{!pageLoading && navigationActive && !disableNavigation && (
							<Header subnavigationTitle={subNavigation.title}>
								{hasValidSubNavigation(subNavigation) && (
									createElement(
										subNavigation.component,
										subNavigation.props,
										subNavigation.children
									)
								)}
							</Header>
						)}

						{!pageLoading && panelActive && panel.component && (
							createElement(
								panel.component,
								panel.props,
								panel.children
							)
						)}

						{children}
					</div>
				</div>
			</Fragment>
	);
}

Root.propTypes = {
	publicWrapper: PropTypes.bool
};

export default WithResize(Root);
