import * as type from './types';
import { useCallback } from 'react';

export const useLayoutActions = (dispatch) => ({

	loadSubNavigation: useCallback((config) => {
		dispatch({ type: type.LOAD_SUB_NAVIGATION, payload: config });
	}, [dispatch]),

	loadPanel: useCallback((config) => {
		dispatch({ type: type.LOAD_PANEL, payload: config });
	}, [dispatch]),

	loadFooter: useCallback((config) => {
		dispatch({ type: type.LOAD_FOOTER, payload: config });
	}, [dispatch]),

	setNavigationRef: useCallback((ref) => {
		dispatch({ type: type.SET_NAVIGATION_REF, payload: ref });
	}, [dispatch]),

	setFooterRef: useCallback((ref) => {
		dispatch({ type: type.SET_FOOTER_REF, payload: ref });
	}, [dispatch]),

	setFooterActive: useCallback((active) => {
		dispatch({ type: type.SET_FOOTER_ACTIVE, payload: active });
	}, [dispatch]),

	setNavigationActive: useCallback((active) => {
		dispatch({ type: type.SET_NAVIGATION_ACTIVE, payload: active });
	}, [dispatch]),

	setAdminActive: useCallback((active) => {
		dispatch({ type: type.SET_ADMIN_ACTIVE, payload: active });
	}, [dispatch]),

	setPanelActive: useCallback((active) => {
		dispatch({ type: type.SET_PANEL_ACTIVE, payload: active });
	}, [dispatch]),

	setPageModifier: useCallback((modifier) => {
		dispatch({ type: type.SET_PAGE_MODIFIER, payload: modifier });
	}, [dispatch]),

	setIsMobile: useCallback((bool) => {
		dispatch({ type: type.SET_IS_MOBILE, payload: bool });
	}, [dispatch]),

	resetPageModifier: useCallback(() => {
		dispatch({ type: type.RESET_PAGE_MODIFIER });
	}, [dispatch]),

	removePageModifier: useCallback((modifier) => {
		dispatch({ type: type.REMOVE_PAGE_MODIFIER, payload: modifier })
	}, [dispatch]),

	setPageLoading: useCallback((isLoading) => {
		dispatch({ type: type.SET_PAGE_LOADING, payload: isLoading })
	}, [dispatch]),

	resetPanel: useCallback(() => {
		dispatch({ type: type.RESET_PANEL })
	}, [dispatch]),

	resetSubNavigation: useCallback(() => {
		dispatch({ type: type.RESET_SUB_NAVIGATION })
	}, [dispatch]),

	resetCalcHeight: useCallback(() => {
		dispatch({ type: type.RESET_REF_HEIGHT_CALCULATION });
	}, [dispatch]),

	// used to set for calc height
	setRefForCalcHeight: useCallback((ref, name) => {
		dispatch({ type: type.SET_REF_HEIGHT_CALCULATION, payload: { ref, name } })
	}, [dispatch])
});




