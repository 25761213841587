import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const newsProcessing = () => ({
	type: ActionTypes.MY_NEWS_PROCESSING
});

const newsSuccess = data => ({
	type: ActionTypes.MY_NEWS_SUCCESS,
	data
});

const newsFailure = error => ({
	type: ActionTypes.MY_NEWS_FAILURE,
	error
});


export const fetchMyNewsArticles = () => {
	return (dispatch) => {
		dispatch(newsProcessing());

		return APIClient.get(
			`/my/news_articles`
		).then(
			({ data }) => dispatch(newsSuccess(data.content)),
			(error) => dispatch(newsFailure(error))
		);
	};
};

export const fetchPublishedMyNewsArticles = () => {
	return (dispatch) => {
		dispatch(newsProcessing());

		return APIClient.get(
			`/my/news_articles?isPublished=true`
		).then(
			({ data }) => {
				return dispatch(newsSuccess(data.content))
			},
			(error) => dispatch(newsFailure(error))
		);
	};
};