export const LOAD_SUB_NAVIGATION = 'LOAD_SUB_NAVIGATION';
export const LOAD_PANEL = 'LOAD_PANEL';
export const LOAD_FOOTER = 'LOAD_FOOTER';
export const RESET_PANEL = 'RESET_PANEL';
export const RESET_SUB_NAVIGATION = 'RESET_SUB_NAVIGATION';

export const SET_NAVIGATION_REF = 'SET_NAVIGATION_REF';
export const SET_FOOTER_REF = 'SET_FOOTER_REF';

export const SET_FOOTER_ACTIVE = 'SET_FOOTER_ACTIVE';
export const SET_ADMIN_ACTIVE = 'SET_ADMIN_ACTIVE';

export const SET_PAGE_MODIFIER = 'SET_PAGE_MODIFIER';
export const RESET_PAGE_MODIFIER = 'RESET_PAGE_MODIFIER';
export const REMOVE_PAGE_MODIFIER = 'REMOVE_PAGE_MODIFIER';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

export const SET_NAVIGATION_ACTIVE = 'SET_NAVIGATION_ACTIVE';
export const SET_PANEL_ACTIVE = 'SET_PANEL_ACTIVE';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';

export const CALC_CONTAINER_HEIGHT = 'CALC_CONTAINER_HEIGHT';
export const SET_REF_HEIGHT_CALCULATION = 'SET_REF_HEIGHT_CALCULATION';
export const RESET_REF_HEIGHT_CALCULATION = 'RESET_REF_HEIGHT_CALCULATION';


