import React from 'react';
import cn from 'classnames';
import { CardGroupContext } from './CardGroupContext';
import WithResize from 'components/HOC/WithResize';
import PropTypes from 'prop-types';
import { fsCardGroup } from './helpers/cardClassNameList';

// card group modifiers
const modifiers = ['center'];
const gridStyleModifiers = ['grid', 'scroll', 'notification'];
const groupChildModifiers = ['top', 'slim', 'list', 'notification'];

class CardGroup extends React.Component {
	static propTypes = {
		modifier: PropTypes.oneOfType([
			PropTypes.oneOf(modifiers.concat(gridStyleModifiers)),
			PropTypes.arrayOf(PropTypes.oneOf(modifiers.concat(gridStyleModifiers)))
		]),
		gutter: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.shape({
				mobile: PropTypes.number,
				desktop: PropTypes.number
			})
		]),
		useBreakpoint: PropTypes.number
	};

	static defaultProps = {
		gutter: 10,
		useBreakpoint: 0
	};

	state = {
		childRef: null,
		childModifiers: [],
		modifiers: {},
		containerInlineStyles: {},
		groupInlineStyles: {},
		isMobile: undefined,
		containerWidth: null,
		cardWidth: null
	};

	childData = ({ ref: childRef, modifiers = {} }) => {
		const childModifiers = Object.keys(
			modifiers ? modifiers : this.state.childModifiers
		)
			.map(className => className.split('--')[1])
			.map(modifier => modifier.split('-')[modifier.split('-').length - 1])
			.filter(childModifier => groupChildModifiers.includes(childModifier));
		this.setState({ childRef, childModifiers });
	};

	contains = (target, source, some = false) => {
		const methodName = some ? 'some' : 'every';
		return target[methodName](
			item => source.includes(item) && target.length === source.length
		);
	};

	/**
	 * Checks if the modifier is valid and merges it into the modifier state
	 * @param modifier
	 * @param addModifier
	 */
	mergeModifierToState = (modifier, addModifier = true) => {
		if (modifier) {
			this.setState({
				modifiers: Object.assign(this.state.modifiers, {
					[`${fsCardGroup}--${modifier}`]: addModifier
				})
			});
		}
	};

	normalizeModifier = modifier =>
		Array.isArray(modifier) ? modifier : [modifier];

	sanitizeFalsy = target =>
		Object.keys(target).reduce((accumulator, current) => {
			if (target[current]) accumulator[current] = target[current];
			return accumulator;
		}, {});

	toggleModifiers = ({ mobile, desktop, breakpoint }) => {
		this.mergeModifierToState(this.isMobile(breakpoint) ? mobile : desktop);
		this.mergeModifierToState(
			!this.isMobile(breakpoint) ? mobile : desktop,
			false
		);
	};

	isMobile = (breakpoint, usePageWidth = false) =>
		usePageWidth
			? this.props.windowInnerWidth
			: this.cardGroupRef.current.getBoundingClientRect().width < breakpoint;

	cardRuleKeys = {
		gridList: 'gridList',
		gridTopSlim: 'gridTopSlim',
		gridTop: 'gridTop',
		gridScrollTop: 'gridScrollTop',
		gridScroll: 'gridScroll',
		grid: 'grid'
	};

	getCardRule = prop => {
		const modifiers = this.normalizeModifier(this.props.modifier).filter(item =>
			gridStyleModifiers.includes(item)
		);
		const {
			gridList,
			grid,
			gridScroll,
			gridScrollTop,
			gridTop,
			gridTopSlim
		} = this.cardRuleKeys;

		const rules = {
			[gridList]:
				this.contains(['grid'], modifiers) &&
				this.contains(['list'], this.state.childModifiers),
			[gridTopSlim]:
				this.contains(['grid'], modifiers) &&
				this.contains(['top', 'slim'], this.state.childModifiers),
			[gridTop]:
				this.contains(['grid'], modifiers) &&
				this.contains(['top'], this.state.childModifiers),
			[gridScrollTop]:
				this.contains(['grid', 'scroll'], modifiers) &&
				this.contains(['top'], this.state.childModifiers),
			[gridScroll]: this.contains(['grid', 'scroll'], modifiers),
			[grid]: this.contains(['grid'], modifiers)
		};

		return rules[prop];
	};

	cardGroupRef = React.createRef();

	componentDidMount = () => {
		// track all changes for WithResize HOC. component will rerender at the end of a resize event
		this.props.trackAll(true);

		this.setState({
			containerWidth: this.cardGroupRef.current.getBoundingClientRect().width
		});

		this.props.modifier && Array.isArray(this.props.modifier)
			? this.props.modifier.forEach(modifier =>
					this.mergeModifierToState(modifier)
			  )
			: this.mergeModifierToState(this.props.modifier);
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.windowInnerWidth !== this.props.windowInnerWidth) {
			this.setState({
				containerWidth: this.cardGroupRef.current.getBoundingClientRect().width
			});
		}

		if (
			prevProps.isMobile !== this.props.isMobile ||
			prevState.childModifiers !== this.state.childModifiers ||
			prevState.containerWidth !== this.state.containerWidth ||
			prevProps.windowInnerWidth !== this.props.windowInnerWidth
		) {
			const {
				gridList,
				grid,
				gridScroll,
				gridScrollTop,
				gridTop,
				gridTopSlim
			} = this.cardRuleKeys;

			// Card grid top slim or grid top
			if (
				this.getCardRule(gridTopSlim) ||
				this.getCardRule(gridTop) ||
				this.getCardRule(gridList)
			) {
				let breakpoint;
				if (this.getCardRule(gridTopSlim)) {
					breakpoint = 500;
				} else if (this.getCardRule(gridTop) || this.getCardRule(gridList)) {
					breakpoint = 676;
				}
				const minimumCards = 2;
				const gridGap = 28;
				const calcWidth = (breakpoint - gridGap) / minimumCards;
				const isMobile = this.isMobile(breakpoint);
				this.mergeModifierToState('flex', false);
				this.toggleModifiers({ mobile: '', desktop: 'grid', breakpoint });
				this.toggleModifiers({ mobile: 'm', desktop: 'd', breakpoint });

				this.setState({
					isMobile,
					containerInlineStyles: isMobile
						? { width: '100%' }
						: {
								gridGap,
								gridTemplateColumns: `repeat(auto-fit, ${calcWidth}px)`
						  }
				});
			}

			// Card grid scroll or grid scroll top
			else if (
				this.getCardRule(gridScrollTop) ||
				this.getCardRule(gridScroll)
			) {
				let breakpoint;
				if (this.getCardRule(gridScrollTop)) {
					breakpoint = 1028;
				} else if (this.getCardRule(gridScroll)) {
					breakpoint = 676;
				}

				const isMobile = this.isMobile(breakpoint);
				this.mergeModifierToState('grid', false);
				this.toggleModifiers({ mobile: 'scroll', desktop: 'flex', breakpoint });
				this.toggleModifiers({ mobile: 'm', desktop: 'd', breakpoint });
				this.setState({
					isMobile,
					cardWidth:
						this.state.cardWidth ||
						(this.state.childRef &&
							this.state.childRef.getBoundingClientRect().width),
					groupInlineStyles: isMobile
						? {
								marginLeft: `-${this.props.gutter}px`,
								width: `calc(100% + ${this.props.gutter * 2}px)`
						  }
						: {},
					containerInlineStyles: isMobile
						? { paddingLeft: `${this.props.gutter}px` }
						: {}
				});
			}

			// Card Grid
			else if (this.getCardRule(grid)) {
				const breakpoint = 676;
				this.mergeModifierToState('grid', false);
				this.toggleModifiers({ mobile: '', desktop: 'flex', breakpoint });
				this.toggleModifiers({ mobile: 'm', desktop: 'd', breakpoint });
				this.setState({ isMobile: this.isMobile(breakpoint) });
			}

			// Default card
			else {
				const breakpoint = this.props.useBreakpoint || 1028;
				const isMobile = this.isMobile(breakpoint);
				this.toggleModifiers({ mobile: 'm', desktop: 'd', breakpoint });

				this.setState({
					isMobile,
					containerInlineStyles: isMobile ? { width: '100%' } : {}
				});
			}
		}
	}

	render() {
		const { gridScroll, gridScrollTop } = this.cardRuleKeys;
		const cardGroupStyles = [fsCardGroup, this.state.modifiers];

		const minimumCardLimit = 3;
		let filteredChildren = null;
		if (
			React.Children.count(this.props.children) >= minimumCardLimit &&
			(this.getCardRule(gridScroll) || this.getCardRule(gridScrollTop))
		) {
			const canFit =
				!!this.state.cardWidth &&
				Math.floor(
					this.state.containerWidth / (this.state.cardWidth + this.props.gutter)
				);
			filteredChildren =
				!!canFit &&
				this.props.children.slice(
					0,
					canFit >= minimumCardLimit ? canFit : minimumCardLimit
				);
		}

		return (
			<CardGroupContext.Provider
				value={{
					hasGroup: true,
					childData: this.childData,
					isMobile:
						typeof this.state.isMobile !== 'undefined'
							? this.state.isMobile
							: this.props.isMobile
				}}
			>
				<div
					ref={this.cardGroupRef}
					className={cn(cardGroupStyles)}
					style={this.state.groupInlineStyles}
				>
					<div
						className={cn('fs-card__group-container')}
						style={this.state.containerInlineStyles}
					>
						{filteredChildren || this.props.children}

						{/* Hack: add div for right gutter since we're not able to set pseudo :before or :after for inline styles */}
						{this.state.isMobile &&
							this.getCardRule(this.cardRuleKeys.gridScroll) && (
								<div style={{ flex: `0 0 ${this.props.gutter}px` }} />
							)}
					</div>
				</div>
			</CardGroupContext.Provider>
		);
	}
}

export default WithResize(CardGroup);
