import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  group: {},
  groupProcessing: false,
  groupError: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_APP_GROUP_PROCESSING:
      return state.merge({
        groupProcessing: true
      });
    case ActionTypes.LOAD_APP_GROUP_SUCCESS:
      return state.merge({
        groupProcessing: false,
        group: action.data
      });
    case ActionTypes.LOAD_APP_GROUP_FAILURE:
      return state.merge({
        error: action.error,
        groupProcessing: false
      });
    default:
      return state;
  }
}
