import { fromJS, List } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as TopicAPI from '../../api/topics/TopicsAPI';
import * as AdminAPI from '../../api/admin/AdminTopicsAPI';
import { createLoaderSelector } from './loader';
import { normalizeComments } from '../utils/normalizeUtils';
import { makeActionCreator } from '../utils/reducerUtils';
import { createErrorSelector } from './error';
import { growl, types as growlTypes, getErrorMessage } from 'utils/errorUtils';
import get from 'lodash/get';
import {
	addReplies,
	addComments,
	updateCommentReplies,
	updateComments,

	updateReactionProfileId,
	findParentId,
	commentChunks,
	getRepliesWithRootParent
} from '../utils/commentingUtils';


/**
 * Comment entity structure
 * @example
 * {
 *   entities: {
 *     commenting: {
 *       comments: { byId: {}, allIds: [] },
 *       replies: { byId: {}, allIds: [] }
 *     }
 *   }
 * }
 */

// Types

// comment data management types
const ADD_COMMENTS = 'ADD_COMMENTS';
const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
const BATCH_UPDATE_COMMENT = 'BATCH_UPDATE_COMMENT';

// reply data management types
const ADD_REPLIES = 'ADD_REPLIES';
const BATCH_UPDATE_REPLY = 'BATCH_UPDATE_REPLY';

// loader tracking types
const CREATE_COMMENT = 'CREATE_COMMENT';
const CREATE_COMMENT_PROCESSING = 'CREATE_COMMENT_PROCESSING';
const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE';

const REPLY_COMMENT = 'REPLY_COMMENT';
const REPLY_COMMENT_PROCESSING = 'REPLY_COMMENT_PROCESSING';
const REPLY_COMMENT_SUCCESS = 'REPLY_COMMENT_SUCCESS';
const REPLY_COMMENT_FAILURE = 'REPLY_COMMENT_FAILURE';

const FETCH_COMMENTS = 'FETCH_COMMENTS';
const FETCH_COMMENTS_PROCESSING = 'FETCH_COMMENTS_PROCESSING';
const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';

const CREATE_REACTION = 'CREATE_REACTION';
const CREATE_REACTION_PROCESSING = 'CREATE_REACTION_PROCESSING';
const CREATE_REACTION_SUCCESS = 'CREATE_REACTION_SUCCESS';
const CREATE_REACTION_FAILURE = 'CREATE_REACTION_FAILURE';

const DELETE_REACTION = 'DELETE_REACTION';
const DELETE_REACTION_PROCESSING = 'DELETE_REACTION_PROCESSING';
const DELETE_REACTION_SUCCESS = 'DELETE_REACTION_SUCCESS';
const DELETE_REACTION_FAILURE = 'DELETE_REACTION_FAILURE';

const FLAG_COMMENT = 'FLAG_COMMENT';
const FLAG_COMMENT_PROCESSING = 'FLAG_COMMENT_PROCESSING';
const FLAG_COMMENT_SUCCESS = 'FLAG_COMMENT_SUCCESS';
const FLAG_COMMENT_FAILURE = 'FLAG_COMMENT_FAILURE';

const UPDATE_FLAGGED_COMMENT = 'UPDATE_FLAGGED_COMMENT';
const UPDATE_FLAGGED_COMMENT_PROCESSING = 'UPDATE_FLAGGED_COMMENT_PROCESSING';
const UPDATE_FLAGGED_COMMENT_SUCCESS = 'UPDATE_FLAGGED_COMMENT_SUCCESS';
const UPDATE_FLAGGED_COMMENT_FAILURE = 'UPDATE_FLAGGED_COMMENT_FAILURE';

const HIDE_COMMENT = 'HIDE_COMMENT';
const HIDE_COMMENT_PROCESSING = 'HIDE_COMMENT_PROCESSING';
const HIDE_COMMENT_SUCCESS = 'HIDE_COMMENT_SUCCESS';
const HIDE_COMMENT_FAILURE = 'HIDE_COMMENT_FAILURE';

const DELETE_COMMENT = 'DELETE_COMMENT';
const DELETE_COMMENT_PROCESSING = 'DELETE_COMMENT_PROCESSING';
const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';

const EDIT_COMMENT = 'EDIT_COMMENT';
const EDIT_COMMENT_PROCESSING = 'EDIT_COMMENT_PROCESSING';
const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
const EDIT_COMMENT_FAILURE = 'EDIT_COMMENT_FAILURE';

const RESET_COMMENTS = 'RESET_COMMENTS';


const entityState = fromJS({
	'byId': {},
	'allIds': []
});

function commentsReducer(state = entityState, action) {
	switch (action.type) {
		case BATCH_UPDATE_COMMENT:
			return state.mergeDeep(fromJS(action.payload));
		case UPDATE_COMMENTS:
			return updateComments(state, action.payload);
		case ADD_COMMENTS:
			return addComments(state, action.payload)
		case ADD_REPLIES:
			return updateCommentReplies(state, action.payload)
		case RESET_COMMENTS:
			return entityState;
		default:
			return state;
	}
}


function repliesReducer(state = entityState, action) {
	switch (action.type) {
		case BATCH_UPDATE_REPLY:
			return state.mergeDeep(fromJS(action.payload));
		case UPDATE_COMMENTS:
			return updateComments(state, action.payload);
		case ADD_REPLIES:
			return addReplies(state, action.payload);
		case RESET_COMMENTS:
			return entityState;
		default:
			return state;
	}
}


export const commentingActions = {
	// actions creators
	createActions: makeActionCreator(ADD_COMMENTS, 'payload'),
	updateComment: makeActionCreator(UPDATE_COMMENTS, 'payload'),
	batchUpdateComments: makeActionCreator(BATCH_UPDATE_COMMENT, 'payload'),

	addReplies: makeActionCreator(ADD_REPLIES, 'payload'),
	addComments: makeActionCreator(ADD_COMMENTS, 'payload'),
	batchUpdateReplies: makeActionCreator(BATCH_UPDATE_REPLY, 'payload'),

	fetchCommentsProcessing: makeActionCreator(FETCH_COMMENTS_PROCESSING),
	fetchCommentsSuccess: makeActionCreator(FETCH_COMMENTS_SUCCESS),
	fetchCommentsFailure: makeActionCreator(FETCH_COMMENTS_FAILURE, 'payload'),

	createCommentProcessing: makeActionCreator(CREATE_COMMENT_PROCESSING),
	createCommentSuccess: makeActionCreator(CREATE_COMMENT_SUCCESS),
	createCommentFailure: makeActionCreator(CREATE_COMMENT_FAILURE, 'payload'),

	replyCommentProcessing: makeActionCreator(REPLY_COMMENT_PROCESSING),
	replyCommentSuccess: makeActionCreator(REPLY_COMMENT_SUCCESS),
	replyCommentFailure: makeActionCreator(REPLY_COMMENT_FAILURE, 'payload'),

	createReactionProcessing: makeActionCreator(CREATE_REACTION_PROCESSING),
	createReactionSuccess: makeActionCreator(CREATE_REACTION_SUCCESS),
	createReactionFailure: makeActionCreator(CREATE_REACTION_FAILURE, 'payload'),

	deleteReactionProcessing: makeActionCreator(DELETE_REACTION_PROCESSING),
	deleteReactionSuccess: makeActionCreator(DELETE_REACTION_SUCCESS),
	deleteReactionFailure: makeActionCreator(DELETE_REACTION_FAILURE, 'payload'),

	flagCommentProcessing: makeActionCreator(FLAG_COMMENT_PROCESSING),
	flagCommentSuccess: makeActionCreator(FLAG_COMMENT_SUCCESS),
	flagCommentFailure: makeActionCreator(FLAG_COMMENT_FAILURE, 'payload'),

	updateFlaggedCommentProcessing: makeActionCreator(UPDATE_FLAGGED_COMMENT_PROCESSING),
	updateFlaggedCommentSuccess: makeActionCreator(UPDATE_FLAGGED_COMMENT_SUCCESS),
	updateFlaggedCommentFailure: makeActionCreator(UPDATE_FLAGGED_COMMENT_FAILURE, 'payload'),

	hideCommentProcessing: makeActionCreator(HIDE_COMMENT_PROCESSING),
	hideCommentSuccess: makeActionCreator(HIDE_COMMENT_SUCCESS),
	hideCommentFailure: makeActionCreator(HIDE_COMMENT_FAILURE, 'payload'),

	deleteCommentProcessing: makeActionCreator(DELETE_COMMENT_PROCESSING),
	deleteCommentSuccess: makeActionCreator(DELETE_COMMENT_SUCCESS),
	deleteCommentFailure: makeActionCreator(DELETE_COMMENT_FAILURE, 'payload'),

	editCommentProcessing: makeActionCreator(EDIT_COMMENT_PROCESSING),
	editCommentSuccess: makeActionCreator(EDIT_COMMENT_SUCCESS),
	editCommentFailure: makeActionCreator(EDIT_COMMENT_FAILURE, 'payload'),

	resetComments: makeActionCreator(RESET_COMMENTS),

	// actions with side effects
	updateComments(data) {
		return dispatch => {
			dispatch(commentingActions.updateComment(data));
		}
	},
	newComments(data) {
		return (dispatch, getState) => {
			const state = getState();
			const { comments, replies } = commentChunks(data);
			const commentsById = commentingSelectors.getCommentsById(state);
			const repliesById = commentingSelectors.getRepliesById(state);
			dispatch(commentingActions.createActions(comments));
			dispatch(commentingActions.addReplies(
				getRepliesWithRootParent(replies, commentsById, repliesById)
			));
		}
	},
	createComment(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.createCommentProcessing());
			return TopicAPI.createComment(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.addComments([data]));
					dispatch(commentingActions.createCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.createCommentFailure(error));
					const message = get(error, 'response.data.message') || error.message;
					growl(growlTypes.error, message);
				});
		}
	},

	replyComment(params, onDone) {
		return (dispatch, getState) => {
			dispatch(commentingActions.replyCommentProcessing());
			return TopicAPI.createComment(params)
				.then(response => response.data)
				.then(data => {
					const state = getState();
					const commentsById = commentingSelectors.getCommentsById(state);
					const repliesById = commentingSelectors.getRepliesById(state);
					const parentId = params.payload.replyToCommentId;
					const replies = getRepliesWithRootParent([{ ...data, parentId }], commentsById, repliesById);
					dispatch(commentingActions.addReplies(replies));
					dispatch(commentingActions.replyCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.replyCommentFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		}
	},

	getComments(params) {
		return dispatch => {
			dispatch(commentingActions.fetchCommentsProcessing());
			return TopicAPI.getAllComments(params)
				.then(response => response.data)
				.then(data => {
					const { replies, comments } = normalizeComments(data.content);
					dispatch(commentingActions.batchUpdateComments(comments));
					dispatch(commentingActions.batchUpdateReplies(replies));
					dispatch(commentingActions.fetchCommentsSuccess());
				}, error => {
					dispatch(commentingActions.fetchCommentsFailure(error));
					const message = get(error, 'response.data.message') || error.message;
					growl(growlTypes.error, message);
				});
		}
	},

	createReaction(params, userId = null, onDone) {
		return dispatch => {
			dispatch(commentingActions.createReactionProcessing());
			return TopicAPI.addReaction(params)
				.then(response => response.data)
				.then(data => {
					const reactions = updateReactionProfileId(data.reactions, 'like', userId);
					dispatch(commentingActions.updateComment([{ ...data, reactions }]));
					dispatch(commentingActions.createReactionSuccess());
				}, error => {
					dispatch(commentingActions.createReactionFailure(error));
				})
				.finally(() => onDone && onDone())
		}
	},

	deleteReaction(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.deleteReactionProcessing());
			return TopicAPI.deleteReaction(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.updateComment([data]))
					dispatch(commentingActions.deleteReactionSuccess());
				}, error => {
					dispatch(commentingActions.deleteReactionFailure(error));
				})
				.finally(() => onDone && onDone())
		}
	},

	flagComment(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.flagCommentProcessing());
			return TopicAPI.flagComment(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.updateComment([data]));
					dispatch(commentingActions.flagCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.flagCommentFailure(error));
					const message = get(error, 'response.data.message') || error.message;
					growl(growlTypes.error, message);
				});
		}
	},

	hideComment(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.hideCommentProcessing());
			return AdminAPI.flagComment(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.updateComment([data]));
					dispatch(commentingActions.hideCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.hideCommentFailure(error));
					const message = get(error, 'response.data.message') || error.message;
					growl(growlTypes.error, message);
				});
		}
	},

	updateFlaggedComment(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.updateFlaggedCommentProcessing());
			return AdminAPI.updateCommentFlag(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.updateComment([data]));
					dispatch(commentingActions.updateFlaggedCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.updateFlaggedCommentFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		}
	},

	deleteComment(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.deleteCommentProcessing());
			return TopicAPI.deleteComment(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.updateComment([data]));
					dispatch(commentingActions.deleteCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.deleteCommentFailure(error));
					const message = get(error, 'response.data.message') || error.message;
					growl(growlTypes.error, message);
				});
		}
	},

	editComment(params, onDone) {
		return dispatch => {
			dispatch(commentingActions.editCommentProcessing());
			return TopicAPI.updateComment(params)
				.then(response => response.data)
				.then(data => {
					dispatch(commentingActions.updateComment([data]));
					dispatch(commentingActions.editCommentSuccess());
					onDone && onDone();
				}, error => {
					dispatch(commentingActions.editCommentFailure(error));
					const message = get(error, 'response.data.message') || error.message;
					growl(growlTypes.error, message);
				});
		}
	}

};

// Selectors
export const commentingSelectors = {
	getCommentsLoading: createLoaderSelector([FETCH_COMMENTS]),
	getCommentsError: createErrorSelector([FETCH_COMMENTS]),

	getCreateCommentLoading: createLoaderSelector([CREATE_COMMENT]),
	getCreateCommentError: createErrorSelector([CREATE_COMMENT]),

	getReplyCommentLoading: createLoaderSelector([REPLY_COMMENT]),
	getReplyCommentError: createErrorSelector([REPLY_COMMENT]),

	getCreateReactionLoading: createLoaderSelector([CREATE_REACTION]),
	getCreateReactionError: createErrorSelector([CREATE_REACTION]),

	getDeleteReactionLoading: createLoaderSelector([DELETE_REACTION]),
	getDeleteReactionError: createErrorSelector([DELETE_REACTION]),

	getFlagLoading: createLoaderSelector([FLAG_COMMENT]),
	getFlagError: createErrorSelector([FLAG_COMMENT]),

	getUpdateFlaggedCommentLoading: createLoaderSelector([UPDATE_FLAGGED_COMMENT]),
	getUpdateFlaggedCommentError: createErrorSelector([UPDATE_FLAGGED_COMMENT]),

	getHideLoading: createLoaderSelector([HIDE_COMMENT]),
	getHideError: createErrorSelector([HIDE_COMMENT]),

	getDeleteCommentLoading: createLoaderSelector([DELETE_COMMENT]),
	getDeleteCommentError: createErrorSelector([DELETE_COMMENT]),

	getEditCommentLoading: createLoaderSelector([EDIT_COMMENT]),
	getEditCommentError: createErrorSelector([EDIT_COMMENT]),


	createFindParentIdSelector: commentId => state => {
		if (commentId) {
			const repliesById = commentingSelectors.getRepliesById(state);
			const commentsById = commentingSelectors.getCommentsById(state);
			return findParentId(commentId, repliesById, commentsById, true);
		}
	},
	getCommentIds: state => state.getIn(['entities', 'commenting', 'comments', 'allIds']),
	getCommentsById: state => state.getIn(['entities', 'commenting', 'comments', 'byId']),
	getComments: state => {
		const commentsById = commentingSelectors.getCommentsById(state);
		const commentIds = commentingSelectors.getCommentIds(state);
		return commentIds.map(commentId => commentsById.get(commentId.toString()));
	},

	getReplyToUser: (replyId) => (state) => {
		if (replyId) {
			const repliesById = commentingSelectors.getRepliesById(state);
			return repliesById.get(replyId.toString()) || null;
		}
		return null;
	},
	getRepliesById: state => state.getIn(['entities', 'commenting', 'replies', 'byId']),
	getReplyIds: state => state.getIn(['entities', 'commenting', 'replies', 'allIds']),
	getReplies: (replyIds = List()) => (state) => {
		const repliesById = commentingSelectors.getRepliesById(state);
		return replyIds.map(replyId => repliesById.get(replyId.toString()));
	}
};

export default combineReducers({
	comments: commentsReducer,
	replies: repliesReducer,
});