import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRefactor as Button, SelectBox, DropdownItem, DatetimePicker, FontIcon } from 'fs-toolkit-react';
import { Map, fromJS } from 'immutable';
import moment from 'moment';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

const MOMENT_FORMAT = "YYYY-MM-DD";

class Condition extends React.Component {
    handleAttributeChange = (val) => {
        const { onChange, map } = this.props;
        if(onChange){
            onChange(map.merge({ attribute: val, value: "", eval: "eq" }));
        }
    }

    handleValueChange = (val) => {
        const { onChange, map } = this.props;
        if(onChange){
            // need to convert the moment object to a date string
            if(map.get("attribute") === "graduation" || map.get("attribute") === "start"){
                onChange(map.set("value", val.format(MOMENT_FORMAT)));
            } else {
                onChange(map.set("value", val));
            }
        }
    }

    handleEvaluatorChange = (val) => {
        const { onChange, map } = this.props;
        if(onChange){
            onChange(map.set("eval", val));
        }
    }

    render(){
        const { map, options, onRemove, disabled } = this.props;
        let opts = [];
        if(options.has(map.get("attribute"))){
            opts = options.get(map.get("attribute"));
        }
        
        return (
            <div className="condition condition-node">
                <div className="condition__container">
                    <div className="map-indicator">
                        <div className="map-indicator__line"></div>
                        <div className="map-indicator__line"></div>
                    </div>
                    <div className="selection">
                        <SelectBox fullWidth defaultOption={"Choose a property"} selected={map.get("attribute")} onChange={this.handleAttributeChange} disabled={disabled}>
                            { ( { onClick, selected } ) => ( 
                                <React.Fragment>
                                    <DropdownItem value="degree" onClick={onClick} selected={selected}>Degree</DropdownItem>
                                    <DropdownItem value="journey" onClick={onClick} selected={selected}>Journey</DropdownItem>  
                                    <DropdownItem value="audience" onClick={onClick} selected={selected}>Audience</DropdownItem> 
                                    <DropdownItem value="graduation" onClick={onClick} selected={selected}>Graduation Date</DropdownItem> 
                                    <DropdownItem value="start" onClick={onClick} selected={selected}>Start Date</DropdownItem> 
                                </React.Fragment>
                            ) }
                        </SelectBox>
                        { map.get("attribute") === "graduation" || map.get("attribute") === "start" ?
                            <SelectBox fullWidth defaultOption="is equal to" selected={map.get("eval")} onChange={this.handleEvaluatorChange}>
                            { ( { onClick, selected } ) => ( 
                                    <React.Fragment>
                                        <DropdownItem value="eq" onClick={onClick} selected={selected}>is equal to</DropdownItem>
                                        <DropdownItem value="neq" onClick={onClick} selected={selected}>is not equal to</DropdownItem>
                                        <DropdownItem value="lt" onClick={onClick} selected={selected}>is less than</DropdownItem> 
                                        <DropdownItem value="lte" onClick={onClick} selected={selected}>is less than or equal to</DropdownItem> 
                                        <DropdownItem value="gt" onClick={onClick} selected={selected}>is greater than</DropdownItem> 
                                        <DropdownItem value="gte" onClick={onClick} selected={selected}>is greater than or equal to</DropdownItem> 
                                    </React.Fragment>
                                ) }
                            </SelectBox> :
                            <SelectBox fullWidth defaultOption="is equal to" selected={map.get("eval")} onChange={this.handleEvaluatorChange}>
                            { ( { onClick, selected } ) => ( 
                                    <React.Fragment>
                                        <DropdownItem value="eq" onClick={onClick} selected={selected}>is equal to</DropdownItem>
                                        <DropdownItem value="neq" onClick={onClick} selected={selected}>is not equal to</DropdownItem>
                                    </React.Fragment>
                                ) }
                            </SelectBox>
                        }
                        { map.get("attribute") === "graduation" || map.get("attribute") === "start" ?
                            <DatetimePicker timeFormat={false} 
                                            dateFormat={MOMENT_FORMAT} 
                                            placeholder={MOMENT_FORMAT} 
                                            onChange={this.handleValueChange} 
                                            value={map.get("value") && map.get("value").length ? moment(map.get("value"), MOMENT_FORMAT) : moment()} /> :
                            <SelectBox fullWidth defaultOption={""} selected={map.get("value")} disabled={map.get("attribute") === "" || disabled} onChange={this.handleValueChange}>
                                { ( { onClick, selected } ) => (
                                    <React.Fragment>
                                        { opts.map((opt) => {
                                                return <DropdownItem key={opt.get("value")} value={opt.get("value")} onClick={onClick} selected={selected}>{opt.get("label")}</DropdownItem> 
                                            }) 
                                        }  
                                    </React.Fragment>
                                ) }
                            </SelectBox>
                        }
                        <Button modifier="secondary" onClick={onRemove.bind(this, map)}  disabled={disabled}>
                            <FontIcon icon={faTrashAlt} />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

Condition.defaultProps = {
    disabled: false,
    map: fromJS({ 
        id: new Date().getTime(), 
        attribute: "",
        value: ""
    }),
    options: fromJS({ audience: [], degree: []})
}

Condition.propTypes = {
    map: PropTypes.instanceOf(Map),
    disabled: PropTypes.bool,
    options: PropTypes.instanceOf(Map),
    onChange: PropTypes.func,
    onRemove:PropTypes.func
}

export default Condition;