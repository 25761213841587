import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: [],
	processing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.LAUNCH_ENROLLMENTS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.LAUNCH_ENROLLMENTS_SUCCESS:
			return state.merge({
				processing: false,
				data: action.payload
			});
		case ActionTypes.LAUNCH_ENROLLMENTS_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false
			});
		case ActionTypes.LAUNCH_ENROLLMENTS_RESET:
			return initialState;
		default:
			return state;
	}
}
