import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FallbackPoller from './FallbackPoller';
import ActionTypes from 'actions/actionTypes';
import { commentingActions } from 'state/ducks/commenting';
import { getUnreadNotificationCount } from "api/user/NotificationAPI";
import { Map } from 'immutable';

import { getAccessToken } from 'api/authentication/Auth';

const SocksContext = createContext(null);

export { SocksContext };

const USER_CHANNEL = "/user/queue/updates";

class SocksProvider extends React.Component {
    constructor(props){
        super(props);

        this.stompClient = null;
        // this will serve as a way to keep the "real-time" behavior working if
        // the socket disconnects or is just unable to connect
        this.fallbackPoller = new FallbackPoller(props.dispatch);
        // the user update channel is subscribed by default, so need to register it with the poller
        this.fallbackPoller.register("/user/queue/updates");

        this.state = {
            subscriptions: { "/user/queue/updates": null }
        }
    }

    componentDidMount(){
        // only attempt to start the socket connect if a server is defined, and we are not impersonating a user.
        // impersonating isn't possible cause the JWT is for the authenticated user, but the fallback should behave well enough
        if(process.env.REACT_APP_SOCKET_SERVER && !window.localStorage.getItem('impersonation')) {
            getAccessToken().then((accessToken) => {
                this.stompClient = new window.StompJs.Client({
                    connectHeaders: {
                        Authorization: `Bearer ${accessToken}`
                    },
                    debug: (str) => { console.debug(str) },
                    reconnectDelay: 5000,
                    heartbeatIncoming: 10000,
                    heartbeatOutgoing: 10000
                });

                this.stompClient.webSocketFactory = function () {
                    return new window.SockJS(process.env.REACT_APP_SOCKET_SERVER);
                };
                
                this.stompClient.onConnect = (frame) => {
                    const { subscriptions } = this.state;
                    console.debug("Full Sail One - Streaming updates are enabled")
                    // this callback is triggered on reconnect attempts as well, so need to go through all
                    // subscribed channels and resub
                    let updatedSubscriptions = {};
                    Object.keys(subscriptions).forEach((channel) => {
                        getAccessToken().then((accessToken) => {
                            let sub = this.stompClient.subscribe(channel, this.onMessage, { "Authorization": `Bearer ${accessToken}`, "ack": "client" } );
                            updatedSubscriptions[channel] = sub;
                            // need to register the channel with the fallback poller
                            this.fallbackPoller.register(channel);
                        });
                    });
                    this.setState({ subscriptions: updatedSubscriptions });
                    // stop the fallback poller for subscribed channels when the socket is connected
                    this.fallbackPoller.stop();
                };
                this.stompClient.onWebSocketClose = (frame) =>{
                    // start the fallback poller for subscribed channels if the socket disconnects
                    this.fallbackPoller.start();
                };
                
                this.stompClient.activate();
            });
        } else {
            // if a socket server isn't defined, just start the poller
            this.fallbackPoller.start();
        }
    }

    componentWillUnmount(){
        if(this.stompClient && this.stompClient.connected) {
            this.stompClient.deactivate();
        }
        // make sure the poller is stopped
        this.fallbackPoller.stop();
    }

    onMessage = (msg) => {
        if(msg.body){
            let message = JSON.parse(msg.body);
            let data = JSON.parse(message.data);
            switch(message.type){
                case "MY_PROFILE_UPDATE":
                    this.props.dispatch({ type: ActionTypes.PROFILE_SUCCESS, data });
                    break;
                case "NEW_NOTIFICATION":
                case "READ_NOTIFICATION":
                    getUnreadNotificationCount().then((response) => this.props.dispatch({ type: ActionTypes.SET_NOTIFICATION_COUNT, data: response.data })).catch((err) => {});
                    break;
                case "NEW_TOPIC_COMMENT":
                    this.props.dispatch(commentingActions.newComments([data]));
                    break;
                case "UPDATED_TOPIC_COMMENT":
                    this.props.dispatch(commentingActions.updateComments([data]));
                    break;
                case "NEW_RSVP":
                case "UPDATED_RSVP":
                    this.props.dispatch({ type: ActionTypes.EVENT_RSVP_UPDATES, data: [data] });
                    break;
                case "CANCELED_RSVP":
                    this.props.dispatch({ type: ActionTypes.EVENT_RSVP_CANCELATIONS, data: [data] });
                    break;
                case "UPDATED_EVENT":
                    this.props.dispatch({ type: ActionTypes.EVENT_DETAIL_SUCCESS, payload: data });
                    this.props.dispatch({ type: ActionTypes.SCANNER_EVENT_SUCCESS, data });
                    break;    
                default: 
                    break;
            }
        } 
        // acknowledge the message was received successfully
        // noticed that the ack ID is null at times which causes disconnects; going to check before acking
        if(msg.headers && msg.headers.ack) {
            msg.ack();
        }
    }

    subscribe = (channel) => {
        const { subscriptions } = this.state;
        let updatedSubscriptions = {};
        if(this.stompClient && this.stompClient.connected){
            getAccessToken().then((accessToken) => {
                let sub = this.stompClient.subscribe(channel, this.onMessage, { "Authorization": `Bearer ${accessToken}` } );
                updatedSubscriptions[channel] = sub;
                this.setState({ subscriptions: Object.assign({}, subscriptions, updatedSubscriptions) });
            });
        } else {
            updatedSubscriptions[channel] = null;
            this.setState({ subscriptions: Object.assign({}, subscriptions, updatedSubscriptions) });
        }
        this.fallbackPoller.register(channel);
    }

    unsubscribe = (channel) => {
        const { subscriptions } = this.state;
        let updatedSubscriptions = Object.assign({}, subscriptions);
        if(this.stompClient && this.stompClient.connected && (subscriptions[channel] && subscriptions[channel].unsubscribe)){
            updatedSubscriptions[channel].unsubscribe();
        }
        // don't want to remove the "inbox" channel from the state as we want to ensure that we are always
        // subscribed to it
        if(channel === USER_CHANNEL){
            updatedSubscriptions[channel] = null;
        } else {
            // for channels a component/view is using, need to remove it from the subscriptions store, so it isn't
            // resubscribed to if the stomp client has to reconnect
            delete updatedSubscriptions[channel];
        }
        this.setState({ subscriptions: updatedSubscriptions });
        this.fallbackPoller.unregister(channel);
    }

    render() {
        return (
            <SocksContext.Provider value={{
                subscribe: this.subscribe,
                unsubscribe: this.unsubscribe
            }}>
                {this.props.children}
            </SocksContext.Provider>
        );
    }
}

SocksProvider.propTypes = {
    profile: PropTypes.instanceOf(Map)
}

const mapStateToProps = (state) => {
    return {
        profile: state.getIn(['profile', 'data'])
    };
}

export default connect(mapStateToProps)(SocksProvider);