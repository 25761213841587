let initialFormData = {};
const dirtyFields = {};

export const setInitialFormData = data => {
	initialFormData = data;
};
export const setInitialFormItem = (prop, val) => {
	initialFormData[prop] = val;
};
export const isDirty = (property, newValue) => {
	const initialValue = initialFormData[property];
	const newValueIsArray = Array.isArray(newValue);

	if (
		newValueIsArray &&
		(initialValue.length !== newValue.length ||
			!newValue.every(v => initialValue.includes(v)))
	) {
		dirtyFields[property] = true;
	} else if (!newValueIsArray && initialValue !== newValue) {
		dirtyFields[property] = true;
	} else if (property in dirtyFields) {
		delete dirtyFields[property];
	}

	return Object.values(dirtyFields).length > 0;
};

export const decodeHtml = html => {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};

// redactor adds an empty div or p to the end of the content
// the div is only deleted if an extra "delete" is hit
// this function filters that div so the form could validate if user doesn't hit that extra "delete"
export const getFilteredEditorContent = editorContent => {
	const regExp = /<div><\/div>|<p><br><\/p>/g;
	return editorContent ? editorContent.replace(regExp, '') : '';
};
