import React from 'react';
import PropTypes from 'prop-types';
import SearchFilterItem from './SearchFilterItem';

export default function SearchFilterList({ items, onFilter, selectedValue }) {
	return (
		<div className="type-filter-list">
			{items.map((item, i) => {
				return (
					<SearchFilterItem
						onFilter={onFilter}
						item={item}
						key={i}
						selectedValue={selectedValue}
					/>
				);
			})}
		</div>
	);
}

SearchFilterList.propTypes = {
	items: PropTypes.array.isRequired,
	onFilter: PropTypes.func.isRequired,
	selectedValue: PropTypes.string.isRequired
};
