import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	processing: false,
	error: null,
	updatedMembership: {},
	processingMembershipUpdate: false,
	membershipUpdateError: null,
	deleteData: {},
	deleteError: null,
	deleteProcessing: false,
	createData: {},
	createError: null,
	createProcessing: false
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_MEMBERSHIP_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.GET_MEMBERSHIP_SUCCESS:
			return state.merge({
				processing: false,
				data: action.payload
			});
		case ActionTypes.GET_MEMBERSHIP_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false
			});
		case ActionTypes.UPDATE_MEMBERSHIP_PROCESSING:
			return state.merge({
				processingMembershipUpdate: true
			});
		case ActionTypes.UPDATE_MEMBERSHIP_SUCCESS:
			return state.merge({
				processingMembershipUpdate: false,
				updatedMembership: action.payload
			});
		case ActionTypes.UPDATE_MEMBERSHIP_FAILURE:
			return state.merge({
				membershipUpdateError: action.payload,
				processingMembershipUpdate: false
			});

		case ActionTypes.DELETE_MEMBERSHIP_PROCESSING:
			return state.merge({
				deleteProcessing: true
			});
		case ActionTypes.DELETE_MEMBERSHIP_SUCCESS:
			return state.merge({
				deleteProcessing: false,
				deleteData: action.payload
			});
		case ActionTypes.DELETE_MEMBERSHIP_FAILURE:
			return state.merge({
				deleteError: action.payload,
				deleteProcessing: false,
				followProcessing: false
			});

		case ActionTypes.CREATE_MEMBERSHIP_PROCESSING:
			return state.merge({
				createProcessing: true
			});
		case ActionTypes.CREATE_MEMBERSHIP_SUCCESS:
			return state.merge({
				createProcessing: false,
				createData: action.payload
			});
		case ActionTypes.CREATE_MEMBERSHIP_FAILURE:
			return state.merge({
				createError: action.payload,
				createProcessing: false,
				followProcessing: false
			});
		case ActionTypes.FOLLOW_MEMBERSHIP_PROCESSING:
			return state.merge({
				followProcessing: true
			});
		default:
			return state;
	}
}
