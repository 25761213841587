import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import { Root } from 'components/layout';

function FourZeroThree(props) {
	return (
		<Root>
			<ErrorPage
				{...props}
				hideFooter
				icon="faLockAltLight"
				title="No access"
				message="Oops! Looks like you don't have access to this page."
			/>
		</Root>
	);
}

export default FourZeroThree;