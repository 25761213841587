import { fromJS } from 'immutable';
import { makeActionCreator } from '../utils/reducerUtils';
import KnowledgeBaseAPI from 'api/knowledge-base/KnowledgeBaseAPI';
import { capitalize } from 'utils/globalUtils';

const KB_CATEGORY_ARTICLES_PROCESSING = 'KB_CATEGORY_ARTICLES_PROCESSING';
const KB_CATEGORY_ARTICLES_PROCESSING_SCROLL =
	'KB_CATEGORY_ARTICLES_PROCESSING_SCROLL';
const KB_CATEGORY_ARTICLES_SUCCESS = 'KB_CATEGORY_ARTICLES_SUCCESS';
const KB_CATEGORY_ARTICLES_FAILURE = 'KB_CATEGORY_ARTICLES_FAILURE';
const KB_CATEGORY_ARTICLES_RESET = 'KB_CATEGORY_ARTICLES_RESET';

// reducer
const initialState = fromJS({
	articlesData: {}
});

export default function(state = initialState, action) {
	switch (action.type) {
		case KB_CATEGORY_ARTICLES_PROCESSING:
			return state.merge({
				articlesData: state.get('articlesData').merge({
					[action.catId]: {
						processing: true,
						articles: [],
						error: null,
						data: null
					}
				})
			});
		case KB_CATEGORY_ARTICLES_PROCESSING_SCROLL:
			return state.mergeDeep(state, {
				articlesData: { [action.catId]: { processingScroll: true } }
			});
		case KB_CATEGORY_ARTICLES_SUCCESS:
			const { content, ...rest } = action.payload;
			const currentArticles = state.getIn([
				'articlesData',
				action.catId,
				'articles'
			]);
			return state.merge({
				articlesData: state.get('articlesData').merge({
					[action.catId]: {
						processing: false,
						processingScroll: false,
						articles: currentArticles
							? currentArticles.update(articles =>
									articles.concat(fromJS(content))
							  )
							: content,
						error: null,
						data: rest
					}
				})
			});
		case KB_CATEGORY_ARTICLES_FAILURE:
			return state.merge({
				articlesData: state.get('articlesData').merge({
					[action.catId]: {
						processing: false,
						processingScroll: false,
						articles: null,
						error: action.payload,
						data: null
					}
				})
			});
		case KB_CATEGORY_ARTICLES_RESET:
			return state.merge({
				articlesData: state.get('articlesData').set({})
			});

		default:
			return state;
	}
}

const processing = makeActionCreator(KB_CATEGORY_ARTICLES_PROCESSING, 'catId');
const processingScroll = makeActionCreator(
	KB_CATEGORY_ARTICLES_PROCESSING_SCROLL,
	'catId'
);
const success = makeActionCreator(
	KB_CATEGORY_ARTICLES_SUCCESS,
	'payload',
	'catId'
);
const failure = makeActionCreator(
	KB_CATEGORY_ARTICLES_FAILURE,
	'payload',
	'catId'
);
const categoryArticlesReset = makeActionCreator(KB_CATEGORY_ARTICLES_RESET);

export const KBCategoryArticlesActions = {
	getCategoryArticles(params) {
		const { catId, type, processingType, ...rest } = params;

		return (dispatch, getState) => {
			if (processingType === 'scroll') {
				dispatch(processingScroll(catId));
			} else {
				dispatch(processing(catId));
			}

			const groupId = getState().getIn(['app', 'group', 'id']);

			return KnowledgeBaseAPI.getCategoryArticles({
				groupId,
				catId,
				type: capitalize(type),
				...rest
			}).then(
				({ data }) => dispatch(success(data, catId)),
				err => dispatch(failure(err, catId))
			);
		};
	},

	categoryArticlesReset: categoryArticlesReset
};
