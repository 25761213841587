import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionTypes from 'actions/actionTypes';
import { fetchProfile, updateProfile } from 'actions/peopleActions';
import { withRouter } from 'react-router-dom';
import Error from 'components/views/error/Error';
import AdminAccountPanel from 'components/views/connect/people/components/AdminAccountPanel';
import AdminGroupsPanel from 'components/views/connect/people/components/AdminGroupsPanel';
import AdminRolesPanel from 'components/views/connect/people/components/AdminRolesPanel';
import AdminAuditPanel from 'components/views/connect/people/components/AdminAuditPanel';
import { Avatar, TabMenu, ButtonRefactor as Button, Typography, Loader } from 'fs-toolkit-react';
import {
	isGlobalAdmin,
	impersonate,
	isImpersonating
} from 'utils/profileUtils';
import withResize from 'components/HOC/WithResize';
import { Map, List } from 'immutable';

const ADMIN_MENUS = [
	{ label: 'Account', value: 'account' },
	{ label: 'Groups', value: 'groups' },
	{ label: 'Permissions', value: 'permissions' },
	{ label: 'Audit', value: 'audit' }
];

class PeopleDetail extends React.Component {
	constructor(props) {
		super(props);

		this.toggleProfileAccess = this.toggleProfileAccess.bind(this);
		this.impersonateProfile = this.impersonateProfile.bind(this);

		this.state = {
			selectedTab: 'account'
		};
	}

	componentDidMount() {
		const { dispatch, match } = this.props;
		dispatch(fetchProfile(match.params.fsOneId));
	}

	toggleProfileAccess() {
		const { dispatch, profile } = this.props;
		dispatch({
			type: ActionTypes.TOOGLE_PROFILE_ACCESS,
			isActive: !profile.get('isActive')
		});
		dispatch(updateProfile());
	}

	impersonateProfile() {
		const { profile } = this.props;
		impersonate(profile);
	}

	render() {
		const {
			currentProfile,
			processing,
			error,
			profile,
			roles,
			match,
			updating
		} = this.props;
		const canEditProfile = isGlobalAdmin({
			profile: currentProfile,
			adminType: 'profileAdmin'
		});
		const canImpersonation = isGlobalAdmin({
			profile: currentProfile,
			adminType: 'impersonation'
		});
		const canViewProfile =
			isGlobalAdmin({ profile: currentProfile, adminType: 'profileViewer' }) ||
			isGlobalAdmin({ profile: currentProfile, adminType: 'profileAdmin' });

		let isNotImpersonatingOrViewProfile =
			currentProfile.get('fsOneId') === match.params.fsOneId ||
			isImpersonating();

		let tabs = [];

		// if the user is a profile admin or profile viewer, include the admin menus
		if (canViewProfile) {
			tabs = tabs.concat(ADMIN_MENUS);
		}

		return (
			<Fragment>
				  { (processing || profile.isEmpty()) && !error ? <Loader /> :
					<Fragment>
						{ error ? 
							<Error
								icon="faSadTearLight"
								title="Profile Error"
								message="An error occurred while loading the profile. Please try again."
							/>: 
							<div className="people-admin-profile">
								<section className="section--name">
									<div className="flex flex-align-center">
										<Avatar
											name={`${profile.get('firstName')} ${profile.get(
												'lastName'
											)}`}
											size={this.props.isMobile ? 'medium' : 'giant'}
											url={profile.get('avatarUrl')}
										/>

										<div className="details">
											<Typography component='h2'>{`${profile.get('firstName')} ${profile.get(
												'lastName'
											)}`}</Typography>
											<Typography modifier='meta'>
												{profile.getIn(['universityEmail', 'address'])}
											</Typography>
										</div>
									</div>
									<div className="admin-actionbar">
										{canImpersonation ? (
											<Button
												onClick={this.impersonateProfile}
												disabled={isNotImpersonatingOrViewProfile}
											>Impersonate</Button>
										) : null}
										{canEditProfile ? (
											<Button
												modifier="secondary"
												active={profile.get('isActive')}
												onClick={this.toggleProfileAccess}
												disabled={isNotImpersonatingOrViewProfile}
											>{profile.get('isActive') ? 'Enabled' : 'Disabled'}</Button>
										) : null}
									</div>
								</section>
								{canViewProfile ? (
									<section>
										<TabMenu
											items={tabs}
											color="lime"
											selectedTab={this.state.selectedTab}
											onChange={selectedTab => {
												this.setState({ selectedTab: selectedTab });
											}}
										/>
										{this.state.selectedTab === 'account' ? (
											<AdminAccountPanel
												currentProfile={currentProfile}
												profile={profile}
											/>
										) : null}
										{this.state.selectedTab === 'groups' ? (
											<AdminGroupsPanel
												memberships={profile.get('memberships')}
												history={this.props.history}
											/>
										) : null}
										{this.state.selectedTab === 'permissions' ? (
											<AdminRolesPanel
												currentProfile={currentProfile}
												roles={roles}
												dispatch={this.props.dispatch}
												editable={
													!isNotImpersonatingOrViewProfile &&
													canEditProfile &&
													!updating
												}
											/>
										) : null}
										{this.state.selectedTab === 'audit' ? (
											<AdminAuditPanel />
										) : null}
									</section>
								) : null}
							</div>
						}
					</Fragment>
				}
			</Fragment>
		);
	}
}

PeopleDetail.propTypes = {
	currentProfile: PropTypes.instanceOf(Map).isRequired,
	profile: PropTypes.instanceOf(Map).isRequired,
	roles: PropTypes.instanceOf(List).isRequired,
	processing: PropTypes.bool,
	error: PropTypes.instanceOf(Map)
};

function mapStateToProps(state) {
	return {
		currentProfile: state.getIn(['profile', 'data']),
		profile: state.getIn(['peopleDetail', 'profile']),
		roles: state.getIn(['peopleDetail', 'roles']),
		processing: state.getIn(['peopleDetail', 'processing']),
		updating: state.getIn(['peopleDetail', 'updateProcessing']),
		error: state.getIn(['peopleDetail', 'error'])
	};
}

export default connect(mapStateToProps)(withResize(withRouter(PeopleDetail)));
