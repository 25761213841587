import React from 'react';
import PropTypes from 'prop-types';
import { SelectBox, DropdownItem } from 'fs-toolkit-react';
import SearchFilterList from './SearchFilterList';

export default function SearchFilterHandler({
	isMobile,
	items,
	onFilter,
	selectedValue
}) {
	return (
		<>
			{!isMobile ? (
				<SearchFilterList
					items={items}
					onFilter={onFilter}
					selectedValue={selectedValue}
				/>
			) : (
				<SelectBox
					defaultOption={items.find(item => item.value === selectedValue).text}
					fullWidth
					onChange={onFilter}
				>
					{({ onClick, selected }) => (
						<>
							{items.map(({ text, value }, i) => {
								return (
									<DropdownItem
										key={i}
										value={value}
										onClick={onClick}
										selected={selected}
									>
										{text}
									</DropdownItem>
								);
							})}
						</>
					)}
				</SelectBox>
			)}
		</>
	);
}

SearchFilterHandler.propTypes = {
	isMobile: PropTypes.bool,
	items: PropTypes.array.isRequired,
	onFilter: PropTypes.func.isRequired,
	selectedValue: PropTypes.string.isRequired
};
