const formatStringForUrl = string =>
	string &&
	string
		.trim()
		.toLowerCase()
		.replace(/[^A-Za-z0-9\s]/g, '')
		.split(' ')
		.join('-');

// works with current group types: Department, Program, Club
const formatGroupTypeForUrl = group => `${formatStringForUrl(group)}s`;

export { formatStringForUrl, formatGroupTypeForUrl };
