/* eslint-disable no-unused-vars */
import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toast } from 'react-toastify';

const taskProcessing = () => ({
	type: ActionTypes.TASK_PROCESSING
});

const taskSuccess = data => ({
	type: ActionTypes.TASK_SUCCESS,
	data
});

const taskFailure = (error, type = 'fetch') => {
	if (type === 'submit') {
		toast.error(error.message || 'There was an error submitting this task.', {
			position: toast.POSITION.BOTTOM_RIGHT,
			className: 'toast-override toast-override--error'
		});
	}

	return {
		type: ActionTypes.TASK_FAILURE,
		error
	};
};

export const resetTask = () => ({
	type: ActionTypes.RESET_TASK
});

export const fetchTask = (id, history) => {
	return dispatch => {
		dispatch(taskProcessing());

		const blockerTaskPromise = APIClient.get(
			'/launch/my/task_assignments?isBlocker=true'
		);
		const taskPromise = APIClient.get(`/launch/my/task_assignments/${id}`);
		const isEsign = code => code === 'AMESIGNA';

		return Promise.all([blockerTaskPromise, taskPromise]).then(
			([blockerTask, task]) => {
				const [{ status, id }] = blockerTask.data.content;

				if (!isEsign(task.data.task.code) && status === 'Incomplete') {
					return history.push(`/esign/${id}`);
				}
				dispatch(taskSuccess(task.data));
			},
			({ response: { data } }) => dispatch(taskFailure(data))
		);
	};
};

// takes a "new FormData()" param
export const submitTask = (formData, id) => {
	return dispatch => {
		dispatch(taskProcessing());

		return APIClient.postFormData(
			`launch/my/task_assignments/${id}/submissions`,
			formData
		).then(
			({ data }) => dispatch(taskSuccess(data)),
			({ response: { data } }) => dispatch(taskFailure(data, 'submit'))
		);
	};
};

export const markTaskSubmitted = id => {
	return dispatch => {
		dispatch(taskProcessing());

		return APIClient.postFormData(
			`launch/my/task_assignments/${id}/mark_submitted`
		).then(
			({ data }) => dispatch(taskSuccess(data)),
			({ response: { data } }) => dispatch(taskFailure(data, 'submit'))
		);
	};
};
