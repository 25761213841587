import React from 'react';
import { withResponsiveProp } from './helpers/withResponsiveProp';
import { fsCardLoader } from './helpers/cardClassNameList';
import { Loader } from 'fs-toolkit-react';

class CardLoader extends React.Component {
	/**
	 * Refer to Card.js for propTypes
	 */
	render() {
		return (
			<div>
				<div className={fsCardLoader}>
					<Loader {...this.props} />
				</div>
			</div>
		);
	}
}

const responsivePropConfig = [{ propName: 'size', mobile: 48, desktop: 64 }];

export default withResponsiveProp(CardLoader)(responsivePropConfig);
