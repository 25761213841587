import { fromJS } from 'immutable';
import * as GroupAPI from 'api/groups/GroupsAPI';
import * as UserAPI from 'api/user/UserAPI';
import { getErrorMessage, growl, types as growlTypes } from 'utils/errorUtils';
import { makeActionCreator } from '../utils/reducerUtils';
import { createLoaderSelector } from './loader';
import { createErrorSelector } from './error';

// Types

// Data management
const UPDATE_TOPICS = 'UPDATE_TOPICS';
const RESET_TOPICS = 'RESET_TOPICS';
const UPDATE_TOPICS_QUERY = 'UPDATE_TOPICS_QUERY';

const FETCH_TOPICS = 'FETCH_TOPICS';
const FETCH_TOPICS_PROCESSING = 'FETCH_TOPICS_PROCESSING';
const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';

const SCROLL_FETCH_TOPICS = 'SCROLL_FETCH_TOPICS';
const SCROLL_FETCH_TOPICS_PROCESSING = 'SCROLL_FETCH_TOPICS_PROCESSING';
const SCROLL_FETCH_TOPICS_SUCCESS = 'SCROLL_FETCH_TOPICS_SUCCESS';
const SCROLL_FETCH_TOPICS_FAILURE = 'SCROLL_FETCH_TOPICS_FAILURE';

// Reducer
const initialTopicListState = fromJS({
	list: [],
	query: '',
	last: false,
	page: 0
});

export default function topicsReducer(state = initialTopicListState, action) {
	switch (action.type) {
		case UPDATE_TOPICS: {
			const content = fromJS(action.payload.content);
			const newState = action.payload.number > 0
				? state.updateIn(['list'], list => list.concat(content))
				: state.merge({ list: content });
			return newState.merge({
				last: action.payload.last,
				page: action.payload.number
			});
		}
		case UPDATE_TOPICS_QUERY:
			return state.merge({
				query: action.payload
			});
		case RESET_TOPICS:
			return initialTopicListState;
		default:
			return state;
	}
}


export const topicListActions = {
	// action creators
	updateTopics: makeActionCreator(UPDATE_TOPICS, 'payload'),
	resetTopics: makeActionCreator(RESET_TOPICS),
	updateTopicsQuery: makeActionCreator(UPDATE_TOPICS_QUERY, 'payload'),

	fetchTopicsProcessing: makeActionCreator(FETCH_TOPICS_PROCESSING),
	fetchTopicsSuccess: makeActionCreator(FETCH_TOPICS_SUCCESS),
	fetchTopicsFailure: makeActionCreator(FETCH_TOPICS_FAILURE, 'payload'),

	scrollFetchTopicsProcessing: makeActionCreator(SCROLL_FETCH_TOPICS_PROCESSING),
	scrollFetchTopicsSuccess: makeActionCreator(SCROLL_FETCH_TOPICS_SUCCESS),
	scrollFetchTopicsFailure: makeActionCreator(SCROLL_FETCH_TOPICS_FAILURE, 'payload'),

	// actions with side effects
	getGroupTopics(params, scroll = false) {
		return (dispatch) => {
			const mappedActions = {
				success: scroll
					? topicListActions.scrollFetchTopicsSuccess
					: topicListActions.fetchTopicsSuccess,
				processing: scroll
					? topicListActions.scrollFetchTopicsProcessing
					: topicListActions.fetchTopicsProcessing,
				failure: scroll
					? topicListActions.scrollFetchTopicsFailure
					: topicListActions.fetchTopicsFailure
			};

			dispatch(mappedActions.processing());
			return GroupAPI.getTopics(params)
				.then(({ data }) => {
					dispatch(topicListActions.updateTopics(data));
					dispatch(mappedActions.success());
				}, error => {
					dispatch(mappedActions.failure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		};
	},

	getUserTopics(params) {
		return (dispatch) => {
			dispatch(topicListActions.fetchTopicsProcessing())
			return UserAPI.getTopics(params)
				.then(({ data }) => {
					dispatch(topicListActions.updateTopics(data));
					dispatch(topicListActions.fetchTopicsSuccess());
				}, error => {
					dispatch(topicListActions.fetchTopicsFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		}
	}
};

export const topicListSelectors = {
	getFetchTopicsLoading: createLoaderSelector([FETCH_TOPICS]),
	getFetchTopicsError: createErrorSelector([FETCH_TOPICS]),

	getScrollFetchTopicsLoading: createLoaderSelector([SCROLL_FETCH_TOPICS]),
	getScrollFetchTopicsError: createErrorSelector([SCROLL_FETCH_TOPICS]),

	getPage: state => state.getIn(['topics', 'page']),
	getQuery: state => state.getIn(['topics', 'query']),
	getIsLastPage: state => state.getIn(['topics', 'last']),
	getTopics: state => state.getIn(['topics', 'list']),
};