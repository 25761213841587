import { useContext, useEffect } from 'react';
import { LayoutContext } from 'components/layout/LayoutProvider';
import useActivePanel from '../hooks/useActivePanel';

function FooterLayout({ children }) {
	const { action, state } = useContext(LayoutContext);
	const { setFooterActive, resetPanel, resetSubNavigation } = action;
	const { isMobile } = state;

	// toggles active state panel when mounted/unmounted
	useActivePanel(setFooterActive);

	useEffect(() => {
		return () => {
			isMobile && resetSubNavigation();
		}
	}, [resetPanel, resetSubNavigation, isMobile]);

	return children;
}

export default FooterLayout;