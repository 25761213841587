import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default function FooterAdmin({
	children,
	flexRules,
	fixed,
	isMobile,
	styles
}) {
	const footerContainerClasses = [
		'fs-footer',
		'fs-footer-container',
		'footer--admin',
		{
			'footer--fixed': fixed
		}
	];
	const footerClasses = [
		'flex',
		'content',
		'flex-align-center',
		'footer',
		'footer--admin',
		{
			'footer--desktop': !isMobile
		}
	];

	const updatedChildren = React.Children.map(children, child => {
		const { flexChildCss } = child ? child.props : {};

		if (!flexChildCss) return child;

		return React.cloneElement(child, {
			ref: node => {
				if (node) {
					ReactDOM.findDOMNode(node).setAttribute('style', flexChildCss);
				}
			}
		});
	});

	return (
		<div className={cn(footerContainerClasses)}>
			<footer className={cn(footerClasses)} style={{ ...flexRules, ...styles }}>
				{updatedChildren}
			</footer>
		</div>
	);
}

FooterAdmin.protoTypes = {
	flexRules: PropTypes.object
};

FooterAdmin.defaultProps = {
	flexRules: {
		justifyContent: 'flex-end'
	},
	fixed: true
};
