import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsDateBox, fsCardImage } from './helpers/cardClassNameList';
import { CardContext } from './CardContext';
import { findChildComponent } from 'utils/findChildComponent';
import { withHide } from './helpers/withHide';


class CardImage extends React.Component {
	static contextType = CardContext;

	static propTypes = {
		backgroundImgUrl: PropTypes.string,
		position: PropTypes.oneOf(['top', 'left']).isRequired,
		hideImgOnMobile: PropTypes.bool
	};

	static defaultProps = {
		backgroundImgUrl: '',
		hideImgOnMobile: false
	};

	mergeModifierToState = (modifier) => {
		this.setState({
			modifiers: Object.assign(this.state.modifiers, { [`${fsCardImage}--${modifier}`]: true })
		});
	};

	imageRef = React.createRef();

	state = {
		modifiers: {}
	};

	componentDidMount() {
		findChildComponent(fsDateBox, this.imageRef) && this.mergeModifierToState('date');
		!this.props.backgroundImgUrl && this.mergeModifierToState('missing');

		if (this.props.position) {
			this.context.fromChild({ modifier: `image-${this.props.position}` });
		}
	}

	render() {
		const cardImageStyles = [
			fsCardImage,
			this.state.modifiers
		];

		let inlineStyle = {};

		if (!!this.props.backgroundImgUrl) {
			inlineStyle = { backgroundImage: `url('${this.props.backgroundImgUrl}')`};
		}

		if (this.props.hideImgOnMobile && this.context.isMobile) {
			inlineStyle = {};
		}


		return (
			<div ref={this.imageRef} className={cn(cardImageStyles)} style={inlineStyle}>
				{this.props.children}
			</div>
		);
	}
}

export default withHide(CardImage);
