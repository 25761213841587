import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Avatar, AvatarGroup, StatusIndicator } from 'fs-toolkit-react';
import Panel from './Panel';
import { LayoutContext } from 'components/layout/LayoutProvider';
import useUmbrella from '../hooks/useUmbrella';
import { getUsername } from 'utils/globalUtils';
import { isSteward } from 'utils/groupUtils';

function SidePanel(props) {
	const {
		className = '',
		avatar = '',
		owner = Map(),
		title = '',
		connectGroup = false,
		children,
		onClick,
		isLoading,
		width
	} = props;

	const { state } = useContext(LayoutContext);
	const { isMobile } = state;

	const [umbrella] = useUmbrella();

	// classname
	const sidePanel = 'side-panel';
	const sidePanelStyles = [
		sidePanel,
		umbrella && `${sidePanel}--${umbrella}`,
		className
	];

	return (
		<Panel
			hide={isMobile}
			className={cn(sidePanelStyles)}
			isLoading={isLoading}
			width={width}
		>
			{connectGroup && (
				<div className="fs-sidebar__avatar">
					<AvatarGroup
						size="small"
						overlap=".8rem"
						reverseDirection
						border={false}
					>
						<Avatar type="group" url={avatar} />
						{!owner.isEmpty() && (
							<Avatar url={owner.get('avatarUrl')} name={getUsername(owner)}>
								{isSteward(owner) ? (
									<StatusIndicator
										icon="faStarSolid"
										size="1.8rem"
										iconSize="1.1rem"
										border={false}
										type='neutral'
									/>
								) : null}
							</Avatar>
						)}
					</AvatarGroup>
				</div>
			)}
			{!isMobile && (
				<h4 onClick={onClick} className="fs-sidebar__title">
					{title}
				</h4>
			)}
			{children}
		</Panel>
	);
}

SidePanel.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	onClick: PropTypes.func,
	title: PropTypes.string,
	avatar: PropTypes.string,
	connectGroup: PropTypes.bool,
	isLoading: PropTypes.bool,
	width: PropTypes.number,
	owner: PropTypes.instanceOf(Map)
};

export default SidePanel;
