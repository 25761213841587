import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'fs-toolkit-react';

class NotificationHeader extends React.PureComponent {
  render() {
    const { onDismiss, label } = this.props;
    return (
      <div className="flex flex-align-center flex-space notification-panel__header">
        <span className="notification-panel__header__title">{label}</span>
          <FontIcon icon='faTimesLight' onClick={onDismiss}/>
      </div>
    );
  }
}

NotificationHeader.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default NotificationHeader;