import notificationTypes from './notificationTypes';

const notificationLookup = (eventType, prop, defaultItem = false) => {
	const notificationItem = eventType && notificationTypes[eventType.toLowerCase()];
	if (notificationItem) return prop ? notificationItem[prop] : notificationItem;
	return defaultItem;
};

export  {
	notificationLookup
}