import APIClient from '../APIClient';
import { buildUrl } from '../../utils/globalUtils';

export const getArticles = params => {
	const { groupId, ...urlParams } = params;
	const endpoint = groupId
		? `/groups/${groupId}/news_articles`
		: `/my/news_articles`;

	return APIClient.get(buildUrl(endpoint, urlParams));
};
