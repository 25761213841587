import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from 'fs-toolkit-react';

export default function TaskMessage({ status }) {
	const statuses = {
		approved: {
			message:
				"Success! Your submission has been approved. There's nothing else you need to do.",
			type: 'success'
		},
		pending: {
			message: 'Pending! Please be patient as we review your submission.',
			type: 'warning'
		}
	};

	const { message, type } = statuses[status.toLowerCase()];

	return <Banner type={type} children={message} />;
}

TaskMessage.propTypes = {
	status: PropTypes.string.isRequired
};
