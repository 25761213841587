import ActionTypes from 'actions/actionTypes';
import { getMyProfile } from 'api/user/UserAPI';

const profileProcessing = () => ({
  type: ActionTypes.PROFILE_PROCESSING
});

const profileSuccess = ({ data }) => ({
  type: ActionTypes.PROFILE_SUCCESS,
  data
});

const profileFailure = data => ({
  type: ActionTypes.PROFILE_FAILURE,
  error: data
});

// exports
export const fetchProfile = () => {
  return dispatch => {
    dispatch(profileProcessing());

    return getMyProfile().then(
      res => dispatch(profileSuccess(res)),
      res => 
        // on logout res.response is undefined
        res.response && dispatch(profileFailure(res.response.data))
    );
  };
};

export const updateLocalProfile = data => {
  return (dispatch, getState) => {
    const profile = getState()
      .getIn(['profile', 'data'])
      .merge(data)
      .toJS();

    dispatch({
      type: ActionTypes.UPDATE_LOCAL_PROFILE,
      data: profile
    });
  };
};
