import React from 'react';
import PropTypes from 'prop-types';
import { colors, FontIcon, Divider, Navigation } from 'fs-toolkit-react';
import { fsSubNavOpen } from '../helpers/layoutClassNameList';

class SubNavigationDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.subnavRef = React.createRef();
    this.state = {
      dropdownOpen: false
    };
  }

  static propTypes = {
    children: PropTypes.object.isRequired,
    selectedItem: PropTypes.string.isRequired,
    navigationType: PropTypes.oneOf(['connect', 'support', 'learn']).isRequired,
    subnavigationTitle: PropTypes.string,
    dropdownOpen: PropTypes.bool,
    onDropdownToggle: PropTypes.func
  };

  static defaultProps = {
    subnavigationTitle: '',
    dropdownOpen: false,
    onDropdownToggle: () => {}
  };

  handleClickOutsidePanel = (event) => {
    this.subnavRef &&
    this.subnavRef.current &&
    !this.subnavRef.current.contains(event.target) &&
    this.setState({ dropdownOpen: false });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsidePanel);
    document.body.classList.remove(fsSubNavOpen);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsidePanel);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
		this.state.dropdownOpen ? document.body.classList.add(fsSubNavOpen) : document.body.classList.remove(fsSubNavOpen);
	}

	getSubNavigationDropdown = () => {
  	if (this.subnavRef.current) {
  		let { bottom } = this.subnavRef.current.getBoundingClientRect();
  		return (
  			<div className="subnavigation__dropdown" style={{ height: `calc(100vh - ${bottom}px)` }}>
					{this.props.children}
				</div>
			);
		}
  };

  toggleDropdown = () => {
  	this.setState((state, props) => {
			const dropdownOpen = !state.dropdownOpen;
			props.onDropdownToggle(dropdownOpen);
			return { dropdownOpen };
		});
	};

  // only toggle when an anchor/link is clicked
	onContentClick = (event) => {
		const anchor = event.target.closest('a');
		if (anchor) this.toggleDropdown();
	};

  render = () => {
    return (
      <div ref={this.subnavRef}>
        <Navigation type='sub' ref={this.subnavRef}>
          <Navigation.Section>
            <div className='fs-flex-center fs-flex-justify-space-between' onClick={this.toggleDropdown}>
              <div className='fs-flex-center'>
                <h4>{this.props.subnavigationTitle}</h4>

                {!this.state.dropdownOpen && <Divider direction='vertical' style={{height: '3.2rem', marginLeft: '2rem', marginRight: '2rem'}} />}

                <span className="fs-truncate title">
                  {!this.state.dropdownOpen ? this.props.selectedItem : ''}
                </span>
              </div>

              <div className='fs-flex-center'>
                { !this.state.dropdownOpen
                  ? <FontIcon icon='faChevronDown' iconColor={colors.warning} />
                  : <FontIcon icon='faTimesLight' iconColor={colors.grayDarkest} />
                }
              </div>
            </div>
          </Navigation.Section>
        </Navigation>

				<span onClick={this.onContentClick}>
					{this.state.dropdownOpen && this.getSubNavigationDropdown()}
				</span>
      </div>
    );
  };
}

export default SubNavigationDropdown;