import React from 'react';
import { colors, Divider, FontIcon } from 'fs-toolkit-react';
import { SidePanelLink } from 'components/layout';
import { withRouter } from 'react-router';
import WithResize from 'components/HOC/WithResize';

import connectNavigationList from './connectNavigationList';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ConnectLinks extends React.PureComponent {
	static propTypes = {
		basePath: PropTypes.string,
		isAppGroup: PropTypes.bool,
		toggledOffGroupFeatures: PropTypes.array,
		defaultSelected: PropTypes.oneOf([
			'home',
			'stories',
			'events',
			'programs',
			'departments',
			'clubs',
			'about',
			'people',
			'spaces',
			'topics'
		])
	};

	static defaultProps = {
		basePath: '/connect',
		isAppGroup: true,
		toggledOffGroupFeatures: []
	};

	state = { selected: this.props.defaultSelected };

	handleNavigation = path => () => this.props.history.push(path);

	getItemListPath = () => {
		return this.props.basePath.match(/\/connect\/(.*?)\//);
	};

	handleNavigationToList = itemListFullPath => {
		this.props.history.push(itemListFullPath);
	};

	setSelected = title => {
		this.setState({
			selected: title.toLowerCase()
		});
	};

	componentDidUpdate(prevProps, _prevState) {
		if (prevProps.defaultSelected !== this.props.defaultSelected) {
			this.setState({
				selected: this.props.defaultSelected
			});
		}
	}

	render() {
		const { basePath, isAppGroup, toggledOffGroupFeatures } = this.props;
		const [itemListFullPath, itemListPath] = this.getItemListPath() || [];

		return (
			<React.Fragment>
				<ul className="fs-reset list--connect fs-sidebar__link">
					{connectNavigationList
						.filter(link => !toggledOffGroupFeatures.includes(link.title))
						.map(connectNavigation => {
							let {
								title,
								icon,
								activeState,
								path,
								showOnGroupHome,
								showOnConnectHome,
								size
							} = connectNavigation;
							const showNavigationComponent =
								(!isAppGroup && showOnGroupHome) ||
								(isAppGroup && showOnConnectHome);

							return (
								showNavigationComponent && (
									<div
										onClick={this.setSelected.bind(null, title)}
										style={{ display: 'block', width: 'auto' }}
										key={title}
									>
										<SidePanelLink
											listItem
											type="connect"
											iconPosition="left"
											title={title}
											icon={icon}
											RouteComponent={Link}
											RouteProps={{
												to:
													!this.props.isMobile &&
													`${basePath}${path}` === window.location.pathname
														? null
														: `${basePath}${path}`
											}}
											active={this.state.selected === activeState}
											iconSize={size}
										/>
									</div>
								)
							);
						})}
					<li>
						<Divider small />
					</li>
				</ul>
				{itemListPath && (
					<div
						className="fs-list-link"
						onClick={this.handleNavigationToList.bind(this, itemListFullPath)}
					>
						<FontIcon
							icon={'faArrowLeft'}
							iconColor={colors.orangeDark}
							iconSize="20px"
						/>
						{itemListPath}
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default withRouter(WithResize(ConnectLinks));
