import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toast } from 'react-toastify';

const profileProcessing = () => ({
	type: ActionTypes.VIEW_PROFILE_PROCESSING
});

const profileSuccess = ({ data }) => ({
	type: ActionTypes.VIEW_PROFILE_SUCCESS,
	data
});

const profileFailure = error => ({
	type: ActionTypes.VIEW_PROFILE_FAILURE,
	error
});

const updateProfileProcessing = () => ({
	type: ActionTypes.ADMIN_UPDATE_PROFILE_PROCESSING
});

const updateProfileSuccess = ({ data }) => {
	return {
		type: ActionTypes.ADMIN_UPDATE_PROFILE_SUCCESS,
		data
	};
};

const updateProfileFailure = error => {
	toast.error('An error occurred while updating the profile.', {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--error'
	});
	return {
		type: ActionTypes.ADMIN_UPDATE_PROFILE_FAILURE,
		error
	};
};

const getAuditsProcessing = () => ({
	type: ActionTypes.GET_AUDITS_PROCESSING
});

const getAuditsSuccess = ({ data }) => {
	return {
		type: ActionTypes.GET_AUDITS_SUCCESS,
		data
	};
};

const getAuditsFailure = error => {
	toast.error('An error occurred while fetching the audits.', {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--error'
	});
	return {
		type: ActionTypes.GET_AUDITS_FAILURE,
		error
	};
};

// exports
export const updateProfile = () => {
	return (dispatch, getState) => {
		dispatch(updateProfileProcessing());
		const data = {
			data: {
				isActive:
					getState().getIn(['peopleDetail', 'profile', 'isActive']) || false,
				roles: getState().getIn(['peopleDetail', 'roles'])
			}
		};
		return APIClient.put(
			`/admin/profiles/${getState().getIn([
				'peopleDetail',
				'profile',
				'fsOneId'
			])}`,
			data
		).then(
			res => dispatch(updateProfileSuccess(res)),
			err => dispatch(updateProfileFailure(err))
		);
	};
};

export const fetchProfile = fsOneId => {
	return dispatch => {
		dispatch(profileProcessing());

		return APIClient.get(`/admin/profiles/${fsOneId}`).then(
			res => dispatch(profileSuccess(res)),
			err => dispatch(profileFailure(err))
		);
	};
};

export const fetchAudits = () => {
	return (dispatch, getState) => {
		dispatch(getAuditsProcessing());

		const fsOneId = getState().getIn(['peopleDetail', 'profile', 'fsOneId']);
		const filter = getState().getIn(['peopleDetail', 'filter']);
		const startDate = getState()
			.getIn(['peopleDetail', 'startDate'])
			.utc()
			.toISOString();
		const endDate = getState()
			.getIn(['peopleDetail', 'endDate'])
			.utc()
			.toISOString();
		const page = getState().getIn(['peopleDetail', 'auditPage']);
		const limit = getState().getIn(['peopleDetail', 'auditPageSize']);
		let url = `/admin/profiles/${fsOneId}/audits?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
		if (filter && filter !== 'All Activity') {
			url = `${url}&filter=${filter}`;
		}
		return APIClient.get(url).then(
			res => dispatch(getAuditsSuccess(res)),
			err => dispatch(getAuditsFailure(err))
		);
	};
};
