import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Navigation, Image, FontIcon, HeadingDropdown, DropdownItem, Typography } from 'fs-toolkit-react';
import Grouper from 'components/views/tools/grouper/Grouper';
import WithResize from 'components/HOC/WithResize';
import { LayoutContext } from 'components/layout/LayoutProvider';


class ToolsHandler extends React.Component {
	static contextType = LayoutContext;

	getToolTitle = () => {
        const { location, history } = this.props;

        if(location.pathname.match(/tools\/grouper/)){
            const selectedGrouperFunc = location.pathname.match(/segments/) ? "Segments" : "Rules";
            return (
                <HeadingDropdown gutter customClass="tool-heading__dropdown" defaultHeading={selectedGrouperFunc} headingLevel="h3" onChange={(value) => history.replace(`/tools/grouper/${value}`)}>
                    {({ onClick, selected }) => (
                        <React.Fragment>
                            <DropdownItem value="segments" onClick={onClick} selected={selected === "segments"}>Segments</DropdownItem> 
                            <DropdownItem value="rules" onClick={onClick} selected={selected === "rules"}>Rules</DropdownItem> 
                        </React.Fragment>
                    )}
                </HeadingDropdown>
            );
        } else {
            return <Typography component='h3'>Tool</Typography>;
        }
    }

    componentDidMount() {
    	this.context.action.setNavigationActive(false);
		}

		componentWillUnmount() {
    	this.context.action.setNavigationActive(true);
		}

		render() {
			return (
				<Fragment>
					<div className='wrapper__header'>
						<Navigation>
							<Navigation.Section>
								<Image imageUrl={process.env.REACT_APP_MOBILE_LOGO} imageWidth='32px' />
								<div style={{'marginLeft' : '20px'}}>{this.getToolTitle()}</div>
							</Navigation.Section>
							<Navigation.Section>
								<FontIcon icon='faTimesLight' onClick={() => { this.props.history.replace("/") }} />
							</Navigation.Section>
						</Navigation>
					</div>
					<Switch>
						<Route path="/tools/grouper" component={Grouper} />
						<Redirect to="/" />
					</Switch>
				</Fragment>
			);
    }
}

export default WithResize(ToolsHandler);