import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router';
import Error from 'components/views/error/Error';
import WithResize from 'components/HOC/WithResize';
import { Header, Container, FooterContainer } from 'components/layout';
import { LayoutContext } from 'components/layout/LayoutProvider';
import useActivePanel from 'components/layout/hooks/useActivePanel';

function ErrorPage(props) {
	const {
		useEmbeddedError = false,
		hideFooter = false,
		networkErrorStatus
	} = props;

	const handleOnSupportClick = () => {
		let mailto = document.createElement('a');
		mailto.href = "mailto:technicalsupport@fullsail.edu?Subject=FS%20One%20Encountered%20Error";
		let mailClickEvent = new MouseEvent("click");
		mailto.dispatchEvent(mailClickEvent);
	};

	const { action } = useContext(LayoutContext);
	const { setNavigationActive } = action;
	useActivePanel(setNavigationActive, { negate: true });

	return (
		<Fragment>
			{!useEmbeddedError && (
				<Header networkErrorStatus={networkErrorStatus} hideSubnavigation navigationType='global' isPublicPage={props.isPublicPage} />
			)}

			<Container>
				<Error {...props}/>
			</Container>

			{!(hideFooter || useEmbeddedError) && (
				<FooterContainer onSupportClick={handleOnSupportClick} />
			)}
		</Fragment>
	);
}

export default withRouter(WithResize(ErrorPage));