import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toast } from 'react-toastify';

// group
const getGroupProcessing = () => ({
	type: ActionTypes.GET_GROUP_PROCESSING
});

const getGroupSuccess = ({ data }) => ({
	type: ActionTypes.GET_GROUP_SUCCESS,
	payload: data
});

const getGroupFailure = error => ({
	type: ActionTypes.GET_GROUP_FAILURE,
	payload: error
});

export const getGroupReset = () => ({
	type: ActionTypes.GET_GROUP_RESET
});

export const getGroup = id => {
	return dispatch => {
		dispatch(getGroupProcessing());

		return APIClient.get(`/groups/${id}`).then(
			response => dispatch(getGroupSuccess(response)),
			({ response: { data } }) => dispatch(getGroupFailure(data))
		);
	};
};

// delete
const deleteGroupProcessing = () => ({
	type: ActionTypes.GROUP_DELETE_PROCESSING
});

const deleteGroupSuccess = ({ data }) => ({
	type: ActionTypes.GROUP_DELETE_SUCCESS,
	payload: data
});

const deleteGroupFailure = error => {
	toast.error('There was an error deleting the group.', {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--error'
	});

	return {
		type: ActionTypes.GROUP_DELETE_FAILURE,
		payload: error
	};
};

export const deleteGroup = id => {
	return dispatch => {
		dispatch(deleteGroupProcessing());

		return APIClient.delete(`/admin/groups/${id}`)
			.then(response => dispatch(deleteGroupSuccess(response)))
			.catch(error => dispatch(deleteGroupFailure(error)));
	};
};

// update
const updateGroupProcessing = () => ({
	type: ActionTypes.GROUP_UPDATE_PROCESSING
});

const updateGroupSuccess = ({ data }) => ({
	type: ActionTypes.GROUP_UPDATE_SUCCESS,
	payload: data
});

const updateGroupFailure = error => {
	const errorMsg = error.message || 'There was an error updating the group.';

	toast.error(errorMsg, {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--error'
	});

	return {
		type: ActionTypes.GROUP_UPDATE_FAILURE,
		payload: error
	};
};

export const resetGroupEdit = error => ({
	type: ActionTypes.RESET_GROUP_EDIT
});

export const updateGroup = (id, data) => {
	return dispatch => {
		dispatch(updateGroupProcessing());

		return APIClient.put(`/admin/groups/${id}`, { data }).then(
			response => dispatch(updateGroupSuccess(response)),
			({ response: { data } }) => dispatch(updateGroupFailure(data))
		);
	};
};

// app group
const getAppGroupProcessing = () => ({
	type: ActionTypes.LOAD_APP_GROUP_PROCESSING
});

const getAppGroupSuccess = ({ data }) => ({
	type: ActionTypes.LOAD_APP_GROUP_SUCCESS,
	data
});

const getAppGroupFailure = error => ({
	type: ActionTypes.LOAD_APP_GROUP_FAILURE,
	error
});

export const getAppGroup = () => {
	return dispatch => {
		dispatch(getAppGroupProcessing());

		return APIClient.get(`/groups/${process.env.REACT_APP_GROUP_CONFIG_ID}`)
			.then(response => dispatch(getAppGroupSuccess(response)))
			.catch(error => dispatch(getAppGroupFailure(error)));
	};
};
