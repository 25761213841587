export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const PROFILE_APP_URL = process.env.REACT_APP_PROFILE_API_ENDPOINT;
export const LMS_URL = process.env.REACT_APP_LMS_API_ENDPOINT;
export const PLATINUM_CMS_BASE_URL = process.env.REACT_APP_PLATINUM_API_ENDPOINT;

export const HTTPMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};
