import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = {
	content: [],
	calendarContent: [],
	calendarError: null,
	calendarProcessing: false,
	page: 0,
	query: '',
	filter: 'upcoming',
	following: false,
	lastPage: false,
	processing: false,
	processingScroll: false,
	error: null
};

export default function(state = fromJS(initialState), action) {
	switch (action.type) {
		case ActionTypes.EVENTS_PAGINATION_RESET:
			return state.merge({
				page: 0
			});
		case ActionTypes.EVENTS_PAGINATION_INCREMENT:
			return state.merge({
				page: state.get('page') + 1
			});
		case ActionTypes.EVENTS_UPDATE_QUERY:
			return state.merge({
				query: action.payload
			});
		case ActionTypes.EVENTS_UPDATE_FILTER:
			return state.merge({
				filter: action.payload
			});
		case ActionTypes.EVENTS_UPDATE_FOLLOWING_FILTER:
			return state.merge({
				following: action.payload
			});
		case ActionTypes.EVENTS_PROCESSING:
			return state.merge({
				processing: true,
				processingScroll: false
			});
		case ActionTypes.EVENTS_PROCESSING_SCROLL:
			return state.merge({
				processing: false,
				processingScroll: true
			});
		case ActionTypes.EVENTS_SUCCESS:
			return state.merge({
				processing: false,
				processingScroll: false,
				content: action.payload.content,
				error: null,
				lastPage: action.payload.last
			});
		case ActionTypes.EVENTS_FAILURE:
			return state.merge({
				processing: false,
				processingScroll: false,
				error: action.payload
			});
		case ActionTypes.EVENTS_RESET:
			return state.merge(initialState);
		default:
			return state;
	}
}
