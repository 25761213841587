import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	processing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.DOCUSIGN_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.DOCUSIGN_SUCCESS:
			return state.merge({
				processing: false,
				data: action.data
			});
		case ActionTypes.DOCUSIGN_FAILURE:
			return state.merge({
				error: action.error,
				processing: false
			});
		case ActionTypes.DOCUSIGN_RESET:
			return initialState;
		default:
			return state;
	}
}
