import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'fs-toolkit-react';
import WithResize from 'components/HOC/WithResize.js';
import { capitalize } from 'utils/globalUtils';

class ListFilter extends React.PureComponent {
	componentDidMount() {
		this.props.filterBreakpoint &&
			this.props.breakpoint(this.props.filterBreakpoint);
	}

	static defaultProps = {
		children: null
	};

	static propTypes = {
		type: PropTypes.oneOf([
			'event',
			'article',
			'people',
			'schedules',
			'attendance',
			'grades',
			'tasks'
		]),
		filterBreakpoint: PropTypes.number,
		onMobileFilterClick: PropTypes.func,
		title: PropTypes.string,
		isAdmin: PropTypes.bool
	};

	static typesWithHeadingDropdown = {
		event: 'event',
		schedules: 'schedules',
		tasks: 'tasks'
	};

	render() {
		const {
			children,
			isMobile,
			onMobileFilterClick,
			title,
			isAdmin,
			type
		} = this.props;

		const getHeading = () =>
			(isAdmin && children) || type in ListFilter.typesWithHeadingDropdown ? (
				children
			) : (
				<h2 className="list-filter__title">{capitalize(title)}</h2>
			);

		const getFilterToggle = () => (
			<div
				className="list-filter__toggle fs-flex-align-center fs-flex-space"
				onClick={onMobileFilterClick}
			>
				<FontIcon icon="faSlidersH" />
				<span className="fs-label">Filter</span>
			</div>
		);

		return (
			<div className="list-filter fs-flex-align-center fs-flex-space">
				<>{getHeading()}</>
				<>{isMobile ? getFilterToggle() : null}</>
			</div>
		);
	}
}

export default WithResize(ListFilter);
