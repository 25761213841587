import moment from 'moment';
import { formatDate } from 'utils/dateTimeUtils';

const eventExpireSameDay = (start, end) => moment(new Date(start)).isSame(new Date(end), 'day');

const getDateboxProps = ({ startsAt: date, endsAt: endDate }) => {
  const expired = moment(new Date()).isAfter(new Date(endDate));
  return eventExpireSameDay(date, endDate)
    ? { expired, date }
    : { expired, date, endDate };
};

const getCategoryList = categories => categories.size ? categories.map(category => category.get('title')).join(' · ') : [];

// TODO: check if this method is still needed
const formatDatePickerInput = date => date
  ? formatDate({ date, format: 'MM/DD/YYYY h:mm A', timeZone: process.env.REACT_APP_LOCAL_TIMEZONE }).tz()
  : date;

export {
  getDateboxProps,
  getCategoryList,
  eventExpireSameDay,
  formatDatePickerInput
};