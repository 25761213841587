import React from 'react';
import PropTypes from 'prop-types';
import { CardContext } from '../CardContext';

/**
 * Allows a wrapped component to use hideOnMobile and hide.
 * @param WrappedComponent
 * @returns {Component}
 */
const withHide = (WrappedComponent) => {
	class Component extends React.Component {
		static contextType = CardContext;
		static propTypes = {
			hideOnMobile: PropTypes.bool,
			hide: PropTypes.bool
		};
		static defaultProps = {
			hideOnMobile: false,
			hide: false
		};
		render() {
			return (this.props.hideOnMobile && this.context.isMobile) || this.props.hide
				? null
				: <WrappedComponent {...this.props} />;
		}
	}
	return Component;
};

export { withHide };