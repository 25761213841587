import React from 'react';
import QRCode from 'react-qr-code';

function MyTicketCode({code, isMobile}){
    return (
        <svg width={isMobile ? 275 : 240} height={isMobile ? 275 : 240} >
            <rect width={isMobile ? 275 : 240} height={isMobile ? 275 : 240} style={{ fill: "rgb(255,255,255)"}} />
            <svg x="20" y="20">
                <QRCode
                    value={code}
                    level="H"
                    size={isMobile ? 235 : 200}
                />
            </svg>
        </svg>
    );
}

export default MyTicketCode;