import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	content: [],
	processing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.CLASSES_PROCESSING:
			return state.merge({
				processing: true
			});
    case ActionTypes.CLASSES_SUCCESS:
			return state.merge({
				processing: false,
				content: fromJS(action.payload)
			});
		case ActionTypes.CLASSES_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false
			});
		default:
			return state;
	}
}
