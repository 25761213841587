import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import { Root } from 'components/layout';

function NetworkError(props) {
	return (
		<Root>
			<ErrorPage
				{...props}
				hideFooter
				icon="faBanLight"
				networkErrorStatus="0"
				title="Network Error"
				message="There was a network error. Don't worry, we are working on it."
			/>
		</Root>
	);
}

export default NetworkError;