import { toast } from 'react-toastify';
import get from 'lodash/get';

const types = {
	success: 'success',
	warning: 'warning',
	error: 'error',
	info: 'info'
};

const growl = (type, message) => {
	toast[type](message, {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--' + type
	});
};

const getErrorMessage = (error) => get(error, 'response.data.message') || error.message;
const getErrorStatus = (error) => get(error, 'response.status');

export {
	types,
	growl,
	getErrorMessage,
	getErrorStatus
};