import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';

function Maintenance(props) {
	return (
		<ErrorPage
			{...props}
			isPublicPage={true}
			hideFooter
			icon="faWrenchLight"
			title="Temporary Maintenance"
			message="We are performing system maintenance at this time and should be back online shortly. We apologize for the inconvenience."
		/>
	);
}

export default Maintenance;