import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toParams } from 'utils/globalUtils';

const globalSearchProcessing = () => ({
	type: ActionTypes.GLOBAL_SEARCH_PROCESSING
});

const globalSearchSuccess = data => ({
	type: ActionTypes.GLOBAL_SEARCH_SUCCESS,
	data
});

const globalSearchFailure = error => ({
	type: ActionTypes.GLOBAL_SEARCH_FAILURE,
	error
});

export const globalSearch = params => {
	return dispatch => {
		dispatch(globalSearchProcessing());

		return APIClient.get(`/search?${toParams(params)}`).then(
			({ data }) => dispatch(globalSearchSuccess(data)),
			err => dispatch(globalSearchFailure(err))
		);
	};
};
