import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'fs-toolkit-react';
import WithResize from 'components/HOC/WithResize.js';
import { withRouter } from 'react-router';

const TasksCTA = ({ tasksCount, isMobile, history }) => {
	const handleClick = () => history.push('/tasks');

	return (
		<div className="tasks-cta">
			<p className="tasks-cta-text">
				You have <span className="tasks-cta-count">{tasksCount}</span>{' '}
				incomplete tasks
			</p>

			<Button
				label="Take me there"
				fullWidth={isMobile}
				onClick={handleClick}
			/>
		</div>
	);
};

TasksCTA.propTypes = {
	tasksCount: PropTypes.number.isRequired
};

export default withRouter(WithResize(TasksCTA));
