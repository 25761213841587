import React from 'react';
import { Divider } from 'fs-toolkit-react';
import { SidePanelLink } from 'components/layout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import learnNavigationList from './learnNavigationList';
import PropTypes from 'prop-types';
import withResize from 'components/HOC/WithResize';
import { List, Map } from 'immutable';
import {
	getPanelEnrollments,
	resetPanelEnrollments
} from 'actions/enrollmentsPanelActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { learningActions, learningSelectors } from '../../../state/ducks/learn';

class LearnLinks extends React.PureComponent {
	static propTypes = {
		active: PropTypes.oneOf([
			'classes',
			'schedules',
			'attendance',
			'grades',
			'gps'
		]).isRequired,
		basePath: PropTypes.string,
		currentEnrollment: PropTypes.instanceOf(Map),
		enrollments: PropTypes.instanceOf(List),
		enrollmentsProcessing: PropTypes.bool,
		isMobile: PropTypes.bool,
		iconSize: PropTypes.string,
		linksDisplayed: PropTypes.array
	};

	static defaultProps = {
		basePath: '/learn'
	};

	componentDidMount() {
		this.props.dispatch(getPanelEnrollments());
		this.props.dispatch(learningActions.getLearningStyleStatus());
	}

	componentWillUnmount() {
		this.props.dispatch(resetPanelEnrollments());
	}

	componentDidUpdate(prevProps) {
		if (prevProps.smartMeasureUrl !== this.props.smartMeasureUrl) {
			if (this.props.smartMeasureUrl) {
				window.open(this.props.smartMeasureUrl, '_blank');
			}
		}
	}

	getLink = link => {
		const linkHtml = (
			<SidePanelLink
				listItem
				type="learn"
				iconPosition="left"
				key={link.title}
				title={link.title}
				icon={link.icon}
				RouteComponent={Link}
				RouteProps={{
					to:
						!this.props.isMobile &&
						`${this.props.basePath}${link.path}` === window.location.pathname
							? null
							: `${this.props.basePath}${link.path}`
				}}
				active={!this.props.isMobile && this.props.active === link.activeState}
				iconActiveColor={!this.props.isMobile && 'learn'}
				iconSize={link.size}
			/>
		);

		if (link.title === 'Classes') {
			return linkHtml;
		} else {
			if (!this.props.enrollments.isEmpty()) {
				if (link.title === 'Grades' || link.title === 'GPS') {
					return linkHtml;
				} else {
					if (
						this.props.enrollments.find(
							enrollment => enrollment.get('deliveryType') !== 'online'
						)
					) {
						if (link.title === 'Attendance') {
							return linkHtml;
						} else if (link.title === 'Schedules') {
							if (
								this.props.enrollments.find(
									enrollment => enrollment.get('status') !== 'Graduate'
								)
							) {
								return linkHtml;
							}
						}
					}
				}
			}
		}
	};

	static externalLinksData = {
		lynda: {
			current: { title: 'Lynda', url: 'http://lynda.fullsail.edu' },
			updated: {
				title: 'LinkedIn Learning',
				url: 'http://linkedinlearning.fullsail.edu'
			},
			updateDate: {
				month: 11,
				day: 12
			}
		},
		safari: {
			current: {
				title: 'Safari Books',
				url: 'http://ce.safaribooksonline.com/sso/fullsail'
			},
			updated: {
				title: 'O’Reilly Learning',
				url: 'http://ebooks.fullsail.edu'
			},
			updateDate: {
				month: 10,
				day: 28
			}
		}
	};

	// the code that updates the links dynamically should be removed after
	// the dates they are updated; values should be hardcoded then
	useUpdatedLink({ year = 2019, month = '', day = '', hour = 8 }) {
		const momentLocal = moment().tz(process.env.REACT_APP_LOCAL_TIMEZONE);
		const date = momentLocal.format('YYYY-MM-DD');
		const currentHour = momentLocal.hour();
		const updateDate = `${year}-${month}-${day}`;
		const updateToday = moment(date).isSame(updateDate);
		const updateInThePast = moment(date).isAfter(updateDate);

		return updateInThePast || (updateToday && currentHour >= hour);
	}

	getLinkData(type) {
		const types = {
			safari: 'safari',
			lynda: 'lynda'
		};

		const linkData = LearnLinks.externalLinksData[types[type]];
		const useUpdatedLink = this.useUpdatedLink({
			month: linkData.updateDate.month,
			day: linkData.updateDate.day
		});

		return useUpdatedLink ? linkData['updated'] : linkData['current'];
	}

	handleNavigation = path => () => this.props.history.push(path);

	handleNonAppRedirect = url => () => window.open(url, '_blank');

	handleSmartMeasureUrl = () => this.props.dispatch(learningActions.getLearningStyleURL());

	render() {
		const safariLinkData = this.getLinkData('safari');
		const lyndaLinkData = this.getLinkData('lynda');

		return (
			!this.props.enrollmentsProcessing && (
				<ul className="fs-reset list--learn">
					{learnNavigationList.map(link => {
						return this.getLink(link);
					})}

					<li>
						<Divider small />
					</li>
					<SidePanelLink
						title={'Library'}
						onClick={this.handleNavigation(`/connect/departments/library/23`)}
						listItem
						type="learn"
					/>
					<SidePanelLink
						title={safariLinkData.title}
						onClick={this.handleNonAppRedirect(safariLinkData.url)}
						icon="faExternalLinkAlt"
						listItem
						type="learn"
					/>
					<SidePanelLink
						title={lyndaLinkData.title}
						onClick={this.handleNonAppRedirect(lyndaLinkData.url)}
						icon="faExternalLinkAlt"
						listItem
						type="learn"
					/>
					{this.props.learningAssessmentStatus && this.props.learningAssessmentStatus === 'approved' && (
						<SidePanelLink
							title="Learning Styles Results"
							onClick={this.props.smartMeasureUrlProcessing ? null : this.handleSmartMeasureUrl}
							icon="faExternalLinkAlt"
							listItem
							type="learn"
						/>
					)}
				</ul>
			)
		);
	}
}

const mapStateToProps = state => ({
	enrollments: state.getIn(['enrollmentsPanel', 'data']),
	enrollmentsProcessing: state.getIn(['enrollmentsPanel', 'processing']),
	learningAssessmentStatus: learningSelectors.getLearningStatus(state),
	learningAssessmentStatusProcessing: learningSelectors.getLearningStatusLoading(state),
	smartMeasureUrl: learningSelectors.getLearningLink(state),
	smartMeasureUrlProcessing: learningSelectors.getLearningLinkLoading(state),
});

export default withResize(withRouter(connect(mapStateToProps)(LearnLinks)));
