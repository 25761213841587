import React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FooterContainer, Container, FooterAdmin } from 'components/layout';
import { Form, Input, Modal, ButtonRefactor as Button, Banner, FontIcon } from 'fs-toolkit-react';
import ConditionGroup from './condition_builder/ConditionGroup';
import Error from 'components/views/error/Error';
import { Map } from 'immutable';
import ActionTypes from 'actions/actionTypes';
import { loadSegment, createSegment, deleteSegment, updateSegment } from 'actions/grouperActions';
import * as yup from 'yup';
import WithResize from 'components/HOC/WithResize';
import { faCheckDouble, faTimes } from '@fortawesome/pro-light-svg-icons';

const segmentSchema = yup.object().shape({
    title: yup.string().max(100, "Title cannot be greater than 100 characters.").required("Title is required."),
    description: yup.string().max(255, "Description cannot be greater than 255 characters."),
    conditions: yup.object().test(
        'conditions',
        'Cannot contain empty groups or incomplete conditions.',
        (value) => {
            const isNodeValid = (node) => {
                if(node.isEmpty()){
                    return false;
                }
                // is a group
                if(node.has("conditions")){
                    // groups have to have at least one condition
                    if(node.get('conditions').count() === 0){
                        return false;
                    }
                    // check all nested groups and conditions
                    let childrenValidity = [];
                    node.get("conditions").forEach((child) => {
                        childrenValidity.push(isNodeValid(child));
                    });
                    return !childrenValidity.includes(false);
                } else {
                    // is a condition
                    return(node.get("attribute") !== "" && node.get("attribute") !== "");
                }
            };         
            return isNodeValid(value);
        },
    )
});

class SegmentForm extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isDeleteConfirmationOpen: false,
            isUpdateConfirmationOpen: false
        }
    }

    componentDidMount() {
        const { dispatch, match } = this.props;
        let id = match.params.id || null;
        dispatch(loadSegment(id));
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({ type: ActionTypes.TOOLS_GROUPER_SEGMENT_FORM_RESET });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.saved || this.props.deleted){
            this.props.history.push("/tools/grouper/segments");
        }
    }

    handleInputChange = (evt) => {
        this.handleFieldChange(evt.currentTarget.name, evt.currentTarget.value);
    }

    handleFieldChange = (field, value) => {
        const { dispatch } = this.props;
        dispatch({ type: ActionTypes.TOOLS_GROUPER_CHANGE_SEGMENT_FORMFIELD, field: field, value: value });
    }

    handleSubmit = () => {
        const { dispatch, title, description, conditions, ruleCount, match } = this.props;
        segmentSchema.validate({ title, description, conditions },{abortEarly: false})
        .then(() => {
            // new segments cannot be associated to rules
            if(ruleCount){
                this.toggleUpdateConfirmation();
            } else {
                if(match.params.id){
                    dispatch(updateSegment(match.params.id));
                } else {
                    dispatch(createSegment());
                }
            }
        })
        .catch(errors => {
            let validationErrors = { title: "", description: "", conditions: "" };
            errors.inner.forEach((error) => {
                validationErrors[error.path] = error.message;
            });
            dispatch({ type: ActionTypes.TOOLS_GROUPER_VALIDATE_SEGMENT_FORM, validationErrors });
        });
    }

    toggleDeleteConfirmation = () => {
        this.setState({ isDeleteConfirmationOpen: !this.state.isDeleteConfirmationOpen });
    }

    toggleUpdateConfirmation = () => {
        this.setState({ isUpdateConfirmationOpen: !this.state.isUpdateConfirmationOpen });
    }

    handleDelete = () => {
        const { dispatch, match } = this.props;
        dispatch(deleteSegment(match.params.id));
    }

    handleUpdate = () => {
        const { dispatch, match } = this.props;
        dispatch(updateSegment(match.params.id));
    }

    render(){
        const { history, match, conditions, title, description, ruleCount, processing, saving, deleting, saved, isDirty, error, options, validationErrors, isMobile, systemControlled } = this.props;
        return (
            <Form formClass="page-form" onSubmit={ this.handleSubmit } novalidate>
                {({onSubmit}) => (
                    <React.Fragment>
                        {this.state.isDeleteConfirmationOpen && (
                            <Modal height='30%' onClose={this.toggleDeleteConfirmation} width='30%'>
                                <Modal.Header title='Confirmation' showCloseButton />
                                { ruleCount > 0 ?
                                    "This segment is being used in one or more rules. Are you sure you want to save your changes?" :
                                    "Are you sure you want to delete this segment?"
                                }
                                <Modal.Footer>
                                    { ruleCount > 0 ?
                                      <Button modifier="secondary" onClick={this.toggleDeleteConfirmation}>Ok</Button> :
                                        <React.Fragment>
                                            <Button modifier="secondary" onClick={this.toggleDeleteConfirmation} disabled={deleting}>Cancel</Button>
                                            <Button onClick={this.handleDelete} isLoading={deleting}>Delete</Button>
                                        </React.Fragment>
                                    }
                                    
                                </Modal.Footer>
                            </Modal>
                        )}
                        {this.state.isUpdateConfirmationOpen && (
                            <Modal height='30%' onClose={this.toggleDeleteConfirmation} width='30%'>
                                <Modal.Header title='Confirmation' showCloseButton />
                                <p>This segment is being used in one or more rules. Are you sure you want to save your changes?</p>
                                <Modal.Footer> 
                                    <Button modifier="secondary" onClick={this.toggleUpdateConfirmation} disabled={saving}>Cancel</Button>
                                    <Button onClick={this.handleUpdate} isLoading={saving}>Save</Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                        <Container loaderError={!error.isEmpty()} isLoading={processing}
                                    errorComponent={
                                        <Error
                                            icon="faFrownLight"
                                            title="Error"
                                            message="Sorry, an error occurred while loading the form."
                                        />
                                    }>
                            <Prompt when={isDirty && !saved} message="Are you sure you want to leave this page without saving? Your information will be lost."/>

                            <h2>{`${match.params.id ? "Edit" : "New"} Segment`}</h2>
                            
                            { systemControlled ?
                                <Banner type="info">
                                    This segment is managed by the system and cannot be updated or deleted.
                                </Banner> : null
                            }

                            <h3 style={{ marginTop: '20px', marginBottom: '20px' }} className='fs-slashed-header fs-text--left'>Details</h3>

                            <Input
                                name="title"
                                label="Name"
                                onChange={ this.handleInputChange }
                                value={title}
                                id="title"
                                placeholder="Give your segment a name"
                                isFormField
                                validationMessage={validationErrors.get('title')}
                                invalid={validationErrors.get('title') !== ""}
                                disabled={systemControlled}
                                large
                            />
            
                            <Input
                                name="description"
                                label="Description"
                                onChange={ this.handleInputChange }
                                value={description}
                                id="description"
                                placeholder="Describe what this segment is for"
                                validationMessage={validationErrors.get('description')}
                                invalid={validationErrors.get('description') !== ""}
                                disabled={systemControlled}
                                isFormField
                            />

                            <h3 style={{ marginTop: '40px', marginBottom: '20px' }} className='fs-slashed-header fs-text--left'>Conditions</h3>
                            <strong><p>Define what it means for a user to be in this segment.</p></strong>
                            <div className="fs-form-field">
                                <ConditionGroup rootNode map={conditions} options={options} onChange={(conditions) => { this.handleFieldChange("conditions", conditions) }} disabled={systemControlled}/>
                                { validationErrors.get('conditions') !== "" ?
                                    <div className="fs-form-field__message">{validationErrors.get('conditions')}</div> : null
                                }
                            </div>
                        </Container>

                        <FooterContainer custom fixed>
                            {match.params.id ?
                                <FooterAdmin>
                                    <Button
                                        onClick={this.toggleDeleteConfirmation}
                                        disabled={saving || systemControlled}
                                        modifier="secondary"
                                    >
                                        {isMobile ? <FontIcon icon={faTimes} />
                                        : 'Delete Segment' }
                                    </Button>

                                    <Button
                                      onClick={() => {
                                          history.push("/tools/grouper/segments")
                                      }}
                                      disabled={saving || deleting }
                                      modifier="secondary"
                                      style={{marginLeft: 'auto'}}
                                    >
                                        {isMobile ? <FontIcon icon={faTimes} />
                                          : 'Cancel' }
                                    </Button>

                                    <Button
                                      onClick={onSubmit}
                                      disabled={deleting || systemControlled}
                                      isLoading={saving}
                                    >
                                        {isMobile ? <FontIcon icon={faCheckDouble} />
                                          : 'Save' }
                                    </Button>
                                </FooterAdmin>
                            :
                                <FooterAdmin>
                                    <Button
                                      onClick={() => {
                                          history.push("/tools/grouper/segments")
                                      }}
                                      disabled={saving}
                                      modifier='secondary'
                                    >
                                        {isMobile ? <FontIcon icon={faTimes} />
                                          : 'Cancel' }
                                    </Button>

                                    <Button
                                      onClick={onSubmit}
                                      isLoading={saving}
                                    >
                                        {isMobile ? <FontIcon icon={faCheckDouble} />
                                          : 'Create' }
                                    </Button>
                                </FooterAdmin>
                            }
                        </FooterContainer>
                    </React.Fragment>
                )}
            </Form>
            
        );
    }
}

SegmentForm.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    conditions: PropTypes.instanceOf(Map),
    ruleCount: PropTypes.number,
    systemControlled: PropTypes.bool,
    options: PropTypes.instanceOf(Map),
    processing: PropTypes.bool,
    saving: PropTypes.bool,
    saved: PropTypes.bool,
    deleting: PropTypes.bool,
    deleted: PropTypes.bool,
    isDirty: PropTypes.bool,
    error: PropTypes.instanceOf(Map),
    validationErrors: PropTypes.instanceOf(Map)
}

function mapStateToProps(state){
    return  {
        title: state.getIn(['segment','title']),
        description: state.getIn(['segment','description']),
        conditions: state.getIn(['segment','conditions']),
        ruleCount: state.getIn(['segment','ruleCount']),
        systemControlled: state.getIn(['segment','systemControlled']),
        options: state.getIn(['segment','options']),
        processing: state.getIn(['segment','processing']),
        saving: state.getIn(['segment','saving']),
        saved: state.getIn(['segment', 'saved']),
        deleting: state.getIn(['segment','deleting']),
        deleted: state.getIn(['segment', 'deleted']),
        isDirty: state.getIn(['segment', 'isDirty']),
        error: state.getIn(['segment','error']),
        validationErrors: state.getIn(['segment','validationErrors'])
    }
}

export default WithResize(connect(mapStateToProps)(SegmentForm));