import React from 'react';
import { Loader } from 'fs-toolkit-react';
import Error from 'components/views/error/Error';

const AsyncImporter = ({ isLoading, error }) => {
	// handle the loading state
	if (isLoading) {
		return <Loader />;
	}
	// handle the error state
	else if (error) {
		return <Error />;
	} else {
		return null;
	}
};

export default AsyncImporter;
