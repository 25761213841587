import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionTypes from 'actions/actionTypes';
import { fetchAudits } from 'actions/peopleActions';
import {
	ButtonRefactor as Button,
	FontIcon,
	Input,
	SelectBox,
	DropdownItem,
	Loader,
	DatetimePicker
} from 'fs-toolkit-react';
import { formatDate } from 'utils/dateTimeUtils';
import { List } from 'immutable';
import {
	faChevronLeft,
	faChevronRight
} from '@fortawesome/pro-light-svg-icons';

const ACTIVITY_OPTIONS = [
	'Search Profiles',
	'View Profile',
	'Update Profile',
	'View Profile Audits',
	'Search Groups',
	'View Group',
	'Update Group',
	'Delete Group',
	'Create Group',
	'Follow Group',
	'Unfollow Group',
	'Create Membership',
	'Update Membership',
	'Delete Membership',
	'Batch Create Membership',
	'Batch Update Membership',
	'Batch Delete Membership',
	'Search Stories',
	'View Story',
	'Update Story',
	'Delete Story',
	'Create Story',
	'Removed Story Promotion',
	'Search Events',
	'View Event',
	'Update Event',
	'Delete Event',
	'Create Event',
	'Removed Event Promotion',
	'Create Event RSVP',
	'Delete Event RSVP',
	'Event Check-in',
	'View Event Ticket',
	'Create Case',
	'Reply to Case',
	'View Case',
	'Search KB Articles',
	'View KB Article',
	'Update KB Article',
	'Delete KB Article',
	'Create KB Article',
	'Global Search',
	'Search Schedule',
	'Search Class Attendances',
	'View Class Attendance',
	'Search Class Grades',
	'View Class Grade',
	'View GPS',
	'List Tasks',
	'View Task',
	'Mark Task Submitted',
	'Create Task Submission',
	'Task Signing Ceremony',
	'Get DocuSign Recipient URL',
	'Search Segments',
	'View Segment',
	'Update Segment',
	'Create Segment',
	'Delete Segment',
	'Search Rules',
	'View Rule',
	'Update Rule',
	'Create Rule',
	'Delete Rule',
	'Activate Rule',
	'Deactivate Rule',
	'Search Topics',
	'View Topic',
	'Update Topic',
	'Create Topic',
	'Delete Topic',
	'Flag Topic',
	'Process Topic Flag',
	'List Comments',
	'Reply to Topic',
	'Update Reply on Topic',
	'Delete Reply on Topic',
	'Follow Topic',
	'Unfollow Topic',
	'Flag Reply',
	'Process Reply Flag',
	'Add Reaction to Reply',
	'Remove Reaction from Reply',
	'Open Commenting',
	'Close Commenting',
	'Search Attestations',
	'Reset Attestation',
	'Process Attestation',
	'Export Attestations',
	'Search Audits'
];

class AdminAuditPanel extends React.Component {
	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(fetchAudits());
	}

	handlePageSizeChange = value => {
		const { dispatch } = this.props;
		dispatch({ type: ActionTypes.AUDIT_CHANGE_PER_PAGE, pageSize: value });
		dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: 0 });
		dispatch(fetchAudits());
	};

	handlePageChange = evt => {
		const { dispatch, numPages } = this.props;
		if (evt.currentTarget.value !== '') {
			let page = parseInt(evt.currentTarget.value) - 1;

			if (page >= numPages) {
				page = numPages - 1;
			}

			if (page < 0) {
				page = 0;
			}

			dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: page });
			dispatch(fetchAudits());
		}
	};

	handlePageForward = () => {
		const { dispatch, page } = this.props;

		dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: page + 1 });
		dispatch(fetchAudits());
	};

	handlePageBack = () => {
		const { dispatch, page } = this.props;

		dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: page - 1 });
		dispatch(fetchAudits());
	};

	handleStartDateChange = dt => {
		const { dispatch } = this.props;
		if (dt._isAMomentObject) {
			dispatch({
				type: ActionTypes.AUDIT_CHANGE_START_DATE,
				startDate: dt.startOf('day')
			});
			dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: 0 });
			dispatch(fetchAudits());
		}
	};

	handleEndDateChange = dt => {
		const { dispatch } = this.props;

		if (dt._isAMomentObject) {
			dispatch({
				type: ActionTypes.AUDIT_CHANGE_END_DATE,
				endDate: dt.endOf('day')
			});
			dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: 0 });
			dispatch(fetchAudits());
		}
	};

	handleActivityChange = value => {
		const { dispatch } = this.props;
		dispatch({ type: ActionTypes.AUDIT_CHANGE_ACTIVITY_FILTER, filter: value });
		dispatch({ type: ActionTypes.AUDIT_CHANGE_PAGE, page: 0 });
		dispatch(fetchAudits());
	};

	formatPayloadValue = (key, value) => {
		const { currentProfile } = this.props;
		if (
			key.toLowerCase().indexOf('date') !== -1 ||
			key.toLowerCase().indexOf('createdAt') !== -1 ||
			key.toLowerCase().indexOf('publishedAt') !== -1 ||
			key.toLowerCase().indexOf('updatedAt') !== -1
		) {
			return formatDate({
				date: value,
				format: 'ddd MMM DD @ h:mm:ss a',
				timeZone: currentProfile.get('timeZone')
			}).tz();
		} else {
			return value !== null && value !== undefined ? value.toString() : '';
		}
	};

	render() {
		const {
			currentProfile,
			audits,
			page,
			pageSize,
			totalAudits,
			numPages,
			processing,
			startDate,
			endDate,
			filter
		} = this.props;

		return (
			<React.Fragment>
				<div className="pagination-filter-bar">
					<SelectBox
						fullWidth
						defaultOption={filter}
						onChange={this.handleActivityChange}
						customClass="activity-selector"
						bottomMargin="0"
					>
						{({ onClick, selected }) => (
							<React.Fragment>
								{ACTIVITY_OPTIONS.map(option => {
									return (
										<DropdownItem
											key={option}
											value={option}
											onClick={onClick}
											selected={selected}
										>
											{option}
										</DropdownItem>
									);
								})}
							</React.Fragment>
						)}
					</SelectBox>
					<span className="fs-label">From</span>
					<DatetimePicker
						timeFormat={false}
						onChange={this.handleStartDateChange}
						value={startDate}
						bottomMargin="0"
					/>
					<span className="fs-label">To</span>
					<DatetimePicker
						timeFormat={false}
						onChange={this.handleEndDateChange}
						value={endDate}
						bottomMargin="0"
					/>
				</div>
				<div className="table account-table">
					<table>
						<thead>
							<tr>
								<th>Timestamp</th>
								<th>Activity</th>
								<th style={{ maxWidth: '200px' }}>Details</th>
								<th style={{ maxWidth: '200px' }}>Payload</th>
								<th>Impersonator?</th>
							</tr>
						</thead>
						<tbody style={{ fontSize: '14px' }}>
							{processing ? (
								<tr>
									<td colSpan="5">
										<Loader />
									</td>
								</tr>
							) : (
								<React.Fragment>
									{audits.count() > 0 ? (
										audits.map(audit => {
											return (
												<tr key={audit.get('id')}>
													<td>
														{formatDate({
															date: audit.get('createdAt'),
															format: 'ddd MMM DD @ h:mm:ss a',
															timeZone: currentProfile.get('timeZone')
														}).tz()}
													</td>
													<td>{audit.get('type')}</td>
													<td style={{ maxWidth: '200px' }}>
														{audit.get('details')}
													</td>
													<td style={{ maxWidth: '200px' }}>
														{audit
															.get('payload')
															.entrySeq()
															.map(([key, value]) => (
																<p key={key}>
																	<strong>{key}</strong>:{' '}
																	{this.formatPayloadValue(key, value)}
																</p>
															))}
													</td>
													<td>
														{audit.getIn(['profile', 'id']) !==
														audit.getIn(['authenticatedProfile', 'id'])
															? `${audit.getIn([
																	'authenticatedProfile',
																	'firstName'
															  ])} ${audit.getIn([
																	'authenticatedProfile',
																	'lastName'
															  ])}`
															: ''}
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td colSpan="5">
												There are no audits; try adjusting the date range or
												filter.
											</td>
										</tr>
									)}
								</React.Fragment>
							)}

							<tr className="pagination-row">
								<td colSpan="5">
									<div className="pagination-menu">
										<div className="options">
											<span className="title">{totalAudits} ITEMS</span>
											<div className="dropdown-container">
												<SelectBox
													fullWidth
													defaultOption={pageSize.toString()}
													onChange={this.handlePageSizeChange}
												>
													{({ onClick, selected }) => (
														<React.Fragment>
															<DropdownItem
																value="12"
																onClick={onClick}
																selected={selected}
															>
																12
															</DropdownItem>
															<DropdownItem
																value="24"
																onClick={onClick}
																selected={selected}
															>
																24
															</DropdownItem>
															<DropdownItem
																value="48"
																onClick={onClick}
																selected={selected}
															>
																48
															</DropdownItem>
															<DropdownItem
																value="96"
																onClick={onClick}
																selected={selected}
															>
																96
															</DropdownItem>
														</React.Fragment>
													)}
												</SelectBox>
											</div>
											<span className="title">PER PAGE</span>
										</div>
										<div className="pages">
											<Button
												modifier="secondary"
												onClick={this.handlePageBack}
												disabled={page === 0}
											>
												<FontIcon icon={faChevronLeft} />
											</Button>
											<Input
												value={numPages === 0 ? 0 : page + 1}
												defaultValue={numPages === 0 ? 0 : page + 1}
												onChange={this.handlePageChange}
												leftText="Page"
												rightText={`of ${numPages}`}
												bottomMargin="0"
											/>
											<Button
												modifier="secondary"
												onClick={this.handlePageForward}
												disabled={numPages === 0 || page + 1 === numPages}
											>
												<FontIcon icon={faChevronRight} />
											</Button>
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		);
	}
}

AdminAuditPanel.propTypes = {
	audits: PropTypes.instanceOf(List).isRequired
};

function mapStateToProps(state) {
	return {
		currentProfile: state.getIn(['profile', 'data']),
		audits: state.getIn(['peopleDetail', 'audits']),
		page: state.getIn(['peopleDetail', 'auditPage']),
		pageSize: state.getIn(['peopleDetail', 'auditPageSize']),
		totalAudits: state.getIn(['peopleDetail', 'totalAudits']),
		numPages: state.getIn(['peopleDetail', 'pagesOfAudits']),
		filter: state.getIn(['peopleDetail', 'filter']),
		startDate: state.getIn(['peopleDetail', 'startDate']),
		endDate: state.getIn(['peopleDetail', 'endDate']),
		processing: state.getIn(['peopleDetail', 'auditProcessing'])
	};
}

export default connect(mapStateToProps)(AdminAuditPanel);
