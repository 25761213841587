import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from 'reducers/index';
import { Map } from 'immutable';


export default function configureStore() {
	const middlewares = [thunkMiddleware];
	const middlewareEnhancer = applyMiddleware(...middlewares);
	const initialState = Map();

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = composeWithDevTools(...enhancers);

	const store = createStore(appReducer, initialState, composedEnhancers);

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('reducers', () =>
			store.replaceReducer(require('reducers/index'))
		);
	}

	return store;
}
