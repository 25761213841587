import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchEventDetails } from 'actions/eventDetailActions';
import { withRouter } from 'react-router-dom';
import { Container } from 'components/layout';
import { formatDate } from 'utils/dateTimeUtils';
import MyTicketCode from 'components/shared/MyTicketCode';

class EventTicket extends React.Component {
	getDateTime = format =>
		formatDate({ date: this.props.details.get('startsAt'), format }).tz();

	searchParams = search => new URLSearchParams(search);

	getStudentNumber = profile => {
		const identities = profile.get('identityLinks');
		return identities.size
			? identities
					.find(
						identity =>
							identity.getIn(['identityLookup', 'name']) ===
							'CampusVue Student Number'
					)
					.get('value')
			: null;
	};

	componentDidMount() {
		const params = this.searchParams(this.props.location.search);
		const groupId = params.get('groupId');
		const eventId = params.get('eventId');
		const sequenceId = params.get('sequenceId');

		eventId &&
			sequenceId &&
			this.props.dispatch(
				fetchEventDetails({ eventId, sequenceId }, { groupId })
			);
	}

	render() {
		const { profile, details, detailsProcessing } = this.props;
		const studentNumber = this.getStudentNumber(profile);
		const startDate = this.getDateTime('MM/DD/YY');
		const startTime = this.getDateTime('LT');

		return (
			<Fragment>
				<Container isLoading={detailsProcessing}>
					<div className="event-ticket flex flex-column">
						<section>
							<div className="flex flex-align-center flex-space">
								<p className="reset title large">{`${profile.get(
									'firstName'
								)} ${profile.get('lastName')}`}</p>
								<p className="reset title">
									{!!studentNumber && `#${studentNumber}`}
								</p>
							</div>
						</section>

						<section className="event-ticket__title">
							<div>
								<h3 className="reset">{details.get('title')}</h3>
							</div>
						</section>

						<section className="event-ticket__date-time">
							<div className="flex flex-align-center flex-space">
								<div className="event-ticket__date flex flex-column">
									<p className="reset label">Date</p>
									<h4>{startDate}</h4>
								</div>
								<div className="event-ticket__time flex flex-end flex-column">
									<p className="reset label">Time</p>
									<h4>{startTime}</h4>
								</div>
							</div>
						</section>

						<section className="event-ticket__rip">
							<div>
								<p className="reset">{details.get('location')}</p>
								{/*<p className='reset meta'>GPS Data</p>*/}
							</div>
						</section>

						<section>
							<div>
								<div className="event-ticket__qr">
									<MyTicketCode code={profile.get('digitalTicket')} />
								</div>
							</div>
						</section>
					</div>
				</Container>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	details: state.getIn(['eventDetail', 'details']),
	detailsProcessing: state.getIn(['eventDetail', 'processing']),
	profile: state.getIn(['profile', 'data'])
});

export default withRouter(connect(mapStateToProps)(EventTicket));
