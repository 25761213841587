import APIClient from '../APIClient';
import { buildUrl } from '../../utils/globalUtils';

export default {
	get(id) {
		return APIClient.get(`/support_articles/${id}`);
	},
	post(data) {
		return APIClient.post('/admin/support_articles/', {
			data
		});
	},
	update(id, data) {
		return APIClient.put(`/admin/support_articles/${id}`, {
			data
		});
	},
	delete(id) {
		return APIClient.delete(`/admin/support_articles/${id}`);
	},
	sendFeedback(id, data) {
		return APIClient.post(`/support_articles/${id}/ratings`, {
			data
		});
	},
	getPublicArticle(UUID) {
		return APIClient.getPublic(`/public/support_articles/${UUID}`);
	},
	getTopArticles(groupId, params) {
		return APIClient.get(
			buildUrl(`/groups/${groupId}/support_articles`, params)
		);
	},
	getCategories(params) {
		const { groupId, ...rest } = params;
		return APIClient.get(
			buildUrl(`/groups/${groupId}/knowledge_base_categories`, rest)
		);
	},
	getCategoryArticles(params) {
		const { groupId, ...rest } = params;
		return APIClient.get(buildUrl(`/groups/${groupId}/support_articles`, rest));
	}
};
