import React from 'react';
import PropTypes from 'prop-types';
import ActionTypes from 'actions/actionTypes';
import { updateProfile } from 'actions/peopleActions';
import { List, Map } from 'immutable';
import { Checkbox } from 'fs-toolkit-react';
import { getRoleOptions, isSuperAdmin } from 'utils/profileUtils';
import sortBy from 'lodash/sortBy';

class AdminRolesPanel extends React.Component {
	handleChange = evt => {
		const { dispatch } = this.props;
		if (evt.currentTarget.checked) {
			dispatch({
				type: ActionTypes.ADD_PROFILE_ROLE,
				role: evt.currentTarget.value
			});
		} else {
			dispatch({
				type: ActionTypes.REMOVE_PROFILE_ROLE,
				role: evt.currentTarget.value
			});
		}
		dispatch(updateProfile());
	};

	render() {
		const { roles, editable, currentProfile } = this.props;

		//remove the super admin option if the user isn't a super admin
		const options = isSuperAdmin(currentProfile)
			? getRoleOptions()
			: getRoleOptions().filter(role => role['value'] !== 'SUPER_ADMIN');

		return (
			<div className="table account-table">
				<table>
					<thead>
						<tr>
							<th>Permission</th>
							<th>Description</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{sortBy(options, ['title']).map((option, i) => {
							return (
								<tr key={option['value'] + i}>
									<td>{option['title']}</td>
									<td>{option['description']}</td>
									<td>
										<Checkbox
											checked={roles.contains(option['value'])}
											onChange={this.handleChange}
											value={option['value']}
											disabled={!editable}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

AdminRolesPanel.propTypes = {
	currentProfile: PropTypes.instanceOf(Map).isRequired,
	roles: PropTypes.instanceOf(List).isRequired,
	dispatch: PropTypes.func.isRequired,
	editable: PropTypes.bool.isRequired
};

export default AdminRolesPanel;
