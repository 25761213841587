import React, { createContext, useReducer } from 'react';
import layoutReducer, { layoutState } from './state/layoutReducer';
import { useLayoutActions } from './state/layoutActions';

const LayoutContext = createContext(null);

function LayoutProvider(props) {
	const { children } = props;
	const [state, dispatch] = useReducer(layoutReducer, layoutState);

	// layout actions wrapped with the dispatch method
	// to avoid using dispatch(action())
	const action = useLayoutActions(dispatch);

	return (
		<LayoutContext.Provider value={{ state, action }}>
			{children}
		</LayoutContext.Provider>
	);
}

export default LayoutProvider;

export { LayoutContext };