import React from 'react';
import PropTypes from 'prop-types';
import { colors, FontIcon } from 'fs-toolkit-react';

class EventOverviewItem extends React.PureComponent {
  render() {
    return (
      <li className='event-overview-item'>
        <FontIcon icon={this.props.icon} iconColor={colors.gray} iconSize="20px" />

        <p>{ this.props.link
          ? <a href={this.props.label} rel="noopener noreferrer" target="_blank" className="reset">{this.props.label}</a>
          : this.props.label
        }</p>
      </li>
    );
  }
}

EventOverviewItem.propTypes = {
  icon: PropTypes.PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  label: PropTypes.node,
  link: PropTypes.bool
};

export default EventOverviewItem;
