import { Auth0Client } from '@auth0/auth0-spa-js';
import * as jwtDecode from 'jwt-decode';

const auth0 = new Auth0Client({
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
	client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
	redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/callback`,
	audience: process.env.REACT_APP_AUTH0_AUDIENCE,
	useRefreshTokens: true,
	cacheLocation: 'localstorage'
});

const handleRedirectCallback = () => auth0.handleRedirectCallback();

const getAccessToken = async () => {
	try {
		return await auth0.getTokenSilently();
	} catch (e) {
		logout();
	}
};

const getClaim = async claim => {
	const accessToken = await getAccessToken();
	const decoded = jwtDecode(accessToken);
	let val = '';
	if (Object.keys(decoded).includes(claim)) {
		val = decoded[claim];
	}
	return val;
};

const getDecodedAccessToken = async () => {
	const accessToken = await getAccessToken();
	return jwtDecode(accessToken);
};

const logout = () => {
	auth0.logout({
		returnTo: process.env.REACT_APP_PUBLIC_URL
	});
};

const login = () => {
	auth0.loginWithRedirect({
		redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/callback`
	});
};

export {
	handleRedirectCallback,
	getAccessToken,
	getClaim,
	getDecodedAccessToken,
	login,
	logout
};
