import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  categories: [],
  processing: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TASK_CATEGORIES_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.TASK_CATEGORIES_SUCCESS:
      return state.merge({
        processing: false,
        categories: action.data
      });
    case ActionTypes.TASK_CATEGORIES_FAILURE:
      return state.merge({
        error: action.error,
        processing: false
      });
    default:
      return state;
  }
}
