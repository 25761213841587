import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  processing: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CASE_DETAIL_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.CASE_DETAIL_SUCCESS:
      return state.merge({
        processing: false,
        data: action.data,
        error: null
      });
    case ActionTypes.CASE_DETAIL_FAILURE:
      return state.merge({
        processing: false,
        error: action.error.response.data
      });
    default:
      return state;
  }
}
