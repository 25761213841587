import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';
import _ from 'lodash';

const initialState = {
	data: {},
	dirty: false,
	formPopulated: false,
	isRecurring: false,
	error: null,
	title: '',
	startDateTime: '',
	endDateTime: '',
	content: '',
	excerpt: '',
	location: '',
	room: '',
	url: '',
	allowRsvps: true,
	rsvpLimit: '',
	postform: '',
	registrationForm: '',
	tracksAttendance: false,
	imageId: null,
	categories: [],
	segments: [],
	tags: [],
	processing: false,
	success: false
};

const _internalState = {};
const dirtyInputsInitialState = (
	initStatePropName,
	{ data, setInitial, state }
) => {
	const stateWasSet = Object.keys(_internalState).some(
		key => key === initStatePropName
	);

	if (!stateWasSet) {
		let valueToCompare = data;
		if (setInitial) {
			const valueFromState = state.get(initStatePropName);
			valueToCompare =
				valueFromState !== null && typeof valueFromState === 'object'
					? valueFromState.toJS()
					: valueFromState;
		}
		_internalState[initStatePropName] = valueToCompare;
	}

	const dirty = !_.isEqual(data, _internalState[initStatePropName]);
	return setInitial ? { dirty } : {};
};

export default function(state = fromJS(initialState), action) {
	// all input actions can use the same config file since they're sending the data with the same prop name
	let dirtyInputConfig = {};
	if (
		action.payload &&
		action.payload.hasOwnProperty('data') &&
		action.payload.hasOwnProperty('initial')
	) {
		dirtyInputConfig = {
			state,
			data: action.payload.data,
			setInitial: action.payload.initial
		};
	}

	switch (action.type) {
		case ActionTypes.EVENT_UPDATE_INPUT_TITLE:
			return state.merge({
				title: action.payload.data,
				...dirtyInputsInitialState('title', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_START_DATE:
			return state.merge({
				startDateTime: action.payload.data,
				...dirtyInputsInitialState('startDateTime', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_END_DATE:
			return state.merge({
				endDateTime: action.payload.data,
				...dirtyInputsInitialState('endDateTime', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_CONTENT:
			return state.merge({
				content: action.payload.data,
				...dirtyInputsInitialState('content', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_EXCERPT:
			return state.merge({
				excerpt: action.payload.data,
				...dirtyInputsInitialState('excerpt', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_IMAGE:
			return state.merge({
				imageId: action.payload.data,
				...dirtyInputsInitialState('imageId', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_RECURRING:
			return state.merge({
				isRecurring: action.payload.data,
				...dirtyInputsInitialState('isRecurring', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_CATEGORIES:
			return state.merge({
				categories: action.payload.data,
				...dirtyInputsInitialState('categories', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_TAGS:
			return state.merge({
				tags: action.payload.data,
				...dirtyInputsInitialState('tags', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_LOCATION:
			return state.merge({
				location: action.payload.data,
				...dirtyInputsInitialState('location', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_ROOM:
			return state.merge({
				room: action.payload.data,
				...dirtyInputsInitialState('room', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_URL:
			return state.merge({
				url: action.payload.data,
				...dirtyInputsInitialState('url', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_AUDIENCE:
			return state.merge({
				segments: action.payload.data,
				...dirtyInputsInitialState('segments', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_POST_FORM:
			return state.merge({
				postform: action.payload.data,
				...dirtyInputsInitialState('postform', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_TRACK_ATTENDANCE:
			return state.merge({
				tracksAttendance: action.payload.data,
				...dirtyInputsInitialState('tracksAttendance', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_ALLOW_RSVP:
			return state.merge({
				allowRsvps: action.payload.data,
				...dirtyInputsInitialState('allowRsvps', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_RSVP_LIMIT:
			return state.merge({
				rsvpLimit: action.payload.data,
				...dirtyInputsInitialState('rsvpLimit', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_INPUT_PRE_FORM:
			return state.merge({
				registrationForm: action.payload.data,
				...dirtyInputsInitialState('registrationForm', dirtyInputConfig)
			});
		case ActionTypes.EVENT_UPDATE_PROCESSING:
		  return state.merge({ processing: true });
		case ActionTypes.EVENT_UPDATE_BATCH_INPUT:
			return state.merge({
				formPopulated: true,
				...action.payload
			});
		case ActionTypes.EVENT_UPDATE_FORM_POPULATED:
			return state.merge({
				formPopulated: action.payload
			});
		case ActionTypes.EVENT_UPDATE_SUCCESS_RESET:
			return state.merge({
				success: false
			});
		case ActionTypes.EVENT_UPDATE_SUCCESS:
			return state.merge({
				data: action.payload,
				dirty: false,
				success: true,
				processing: false,
				error: null
			});
		case ActionTypes.EVENT_UPDATE_FAILURE:
			return state.merge({
				success: false,
				processing: false,
				error: action.payload
			});
		case ActionTypes.EVENT_UPDATE_RESET_FORM:
			return state.merge(initialState);
		default:
			return state;
	}
}
