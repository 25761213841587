import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';


const dangerBarSuccess = data => ({
  type: ActionTypes.DANGER_BAR_SUCCESS,
  data
});

const dangerBarFailure = error => ({
  type: ActionTypes.DANGER_BAR_FAILURE,
  error
});

export const setLocalUrlLink = url => {
  return (dispatch, getState) => dispatch({
    type: ActionTypes.DANGER_BAR_SET_URL_LOCALLY,
    data: url
  });
}

export const getDangerBar = () => {
  return (dispatch, getState) => {
    return APIClient.getPlatinumCMS(`/api/danger-bar/?ts=${new Date().getTime()}`)
      .then(
        ({ data }) => {
          // component expects an object but in the past platinum's api sent an error that was not caught by axios
          // and ended up breaking the view. By making the check here we can avoid extra checks on the component and keep it cleaner
          if (typeof data === 'string') {
            data = {};
          }
         dispatch(dangerBarSuccess(data))
        },
        (error) => dispatch(dangerBarFailure(error))
      );
  };
};
