import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	processing: false,
	error: null,
	labOptions: [],
	labsStringForApi: ''
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.LAB_OPTIONS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.LAB_OPTIONS_SUCCESS:
			return state.merge({
				processing: false,
				data: action.data,
				labOptions: Object.keys(action.data).map(key => ({
					classCode: key,
					labOption: `${key}_ALL`
				}))
			});
		case ActionTypes.LAB_OPTIONS_FAILURE:
			return state.merge({
				error: action.error,
				processing: false
			});
		case ActionTypes.LAB_FILTERED_OPTIONS:
			return state.merge({
				processing: false,
				labOptions: action.data,
				labsStringForApi: action.data
					.map(item => item.get('labOption'))
					.toJS()
					.toString()
			});
		default:
			return state;
	}
}
