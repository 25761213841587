import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'fs-toolkit-react';

class SearchInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func,
    onIconClick: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
  };

  render = () => (
    <Input
      name="group-search"
      onChange={this.props.onChange}
      onKeyUp={this.props.onKeyUp}
      onIconClick={this.props.onIconClick}
      value={this.props.value}
      faIcon='faSearch'
      placeholder={this.props.placeholder}
    />
  );
}

export default SearchInput;