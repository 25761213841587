import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { ButtonRefactor as Button, FontIcon } from 'fs-toolkit-react';
import { formatStringForUrl, formatGroupTypeForUrl } from 'utils/urlUtils';

class AdminGroupsPanel extends React.Component {
	navigateTo = link => {
		this.props.history.push(link);
	};

	getAddedType = membership => {
		if (membership.get('systemGenerated')) {
			return 'System';
		} else if (membership.get('ruleGenerated')) {
			return 'Rule';
		} else {
			return 'Joined';
		}
	};

	render() {
		const { memberships } = this.props;

		return (
			<div className="table account-table">
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Type</th>
							<th>Permissions</th>
							<th>Added</th>
							<th style={{'textAlign': 'center'}}>Private</th>
						</tr>
					</thead>
					<tbody>
						{memberships
							.filter(membership => {
								return !membership
									.getIn(['group', 'name'])
									.match(/(.)+\s-\s(ARCHIVED)\s\d{13}/);
							})
							.sortBy(membership => {
								return membership.getIn(['group', 'name']);
							})
							.map(membership => {
								let peopleLink =
									membership.getIn(['group', 'type']) === 'University'
										? '/connect/people'
										: `/connect/${formatGroupTypeForUrl(
												membership.getIn(['group', 'type'])
										  )}/${formatStringForUrl(
												membership.getIn(['group', 'name'])
										  )}/${membership.getIn(['group', 'id'])}/people`;
								return (
									<tr key={membership.get('id')}>
										<td>
											<Button
												modifier={['text']}
												onClick={this.navigateTo.bind(this, peopleLink)}
											>
												{membership.getIn(['group', 'name'])}
											</Button>
										</td>
										<td>{membership.getIn(['group', 'type'])}</td>
										<td>
											{membership.get('roles').map(role => {
												return <p key={role.get('id')}>{role.get('label')}</p>;
											})}
										</td>
										<td>
											<p key={membership.get('id')}>
												{this.getAddedType(membership)}
											</p>
										</td>
										<td style={{'textAlign': 'center'}}>
											<div key={membership.get('id')}>
												{membership.get('isMember') ? (
													<FontIcon icon="faCheck" />
												) : (
													''
												)}
											</div>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		);
	}
}

AdminGroupsPanel.propTypes = {
	memberships: PropTypes.instanceOf(List).isRequired,
	history: PropTypes.object.isRequired
};

export default AdminGroupsPanel;
