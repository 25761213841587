import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CardGroupContext } from './CardGroupContext';
import { CardContext } from './CardContext';
import CardAvatar from './CardAvatar';
import CardBadge from './CardBadge';
import CardBody from './CardBody';
import CardButton from './CardButton';
import CardCategory from './CardCategory';
import CardCustom from './CardCustom';
import CardError from './CardError';
import CardExcerpt from './CardExcerpt';
import CardFooter from './CardFooter';
import CardHeader from './CardHeader';
import CardIcon from './CardIcon';
import CardImage from './CardImage';
import CardListGroup from './CardListGroup';
import CardListItem from './CardListItem';
import CardMeta from './CardMeta';
import CardStatus from './CardStatus';
import CardTitle from './CardTitle';
import CardDate from './CardDate';
import CardLoader from './CardLoader';
import {
	fsCardListGroup,
	fsCardSelector,
	fsCardHeader,
	fsCardFooter
} from './helpers/cardClassNameList';

import { findChildComponent } from 'utils/findChildComponent';
import { withCardResize } from './helpers/withCardResize';
import { componentUtils } from 'utils/componentUtils';

// public modifiers
const modifiers = ['slim', 'full-width', 'notification'];

/**
 * Card component it the main component to wrap all of the sub-components during implementation
 */
class Card extends React.PureComponent {
	static contextType = CardGroupContext;

	static propTypes = {
		modifier: PropTypes.oneOfType([
			PropTypes.oneOf(modifiers),
			PropTypes.arrayOf(PropTypes.oneOf(modifiers))
		]),
		url: PropTypes.string,
		onClick: PropTypes.func,
		className: PropTypes.string,
		active: PropTypes.bool,
		read: PropTypes.bool,
		errorComponent: PropTypes.object,
		loaderError: PropTypes.bool,
		isLoading: PropTypes.bool,
		size: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.shape({
				mobile: PropTypes.number,
				desktop: PropTypes.number
			})
		]),
		customStyles: PropTypes.object,
		RouteComponent: PropTypes.object
	};

	static defaultProps = {
		loaderError: false,
		isLoading: false,
		errorComponent: null,
		active: false,
		read: false,
		size: {
			mobile: componentUtils.loader.size.m,
			desktop: componentUtils.loader.size.l
		}
	};

	/**
	 * Card sub-components (static methods)
	 */
	static Avatar = CardAvatar;
	static Badge = CardBadge;
	static Body = CardBody;
	static Button = CardButton;
	static Category = CardCategory;
	static Date = CardDate;
	static Custom = CardCustom;
	static Error = CardError;
	static Excerpt = CardExcerpt;
	static Header = CardHeader;
	static Icon = CardIcon;
	static Image = CardImage;
	static ListGroup = CardListGroup;
	static ListItem = CardListItem;
	static Meta = CardMeta;
	static Footer = CardFooter;
	static Status = CardStatus;
	static Title = CardTitle;

	getWrappedErrorComponent = () => {
		return <div>{this.props.errorComponent}</div>;
	};

	/**
	 * Checks to see if at least one item is in the list
	 * @param item {*} item to compare
	 * @param list {array} list of items
	 * @returns {boolean}
	 */
	isOneOf = (item, list) => list.some(listItem => item === listItem);

	/**
	 * FromChild is the method used by the child component to communicate with the
	 * parent component through the context api
	 * @param data {object} any type of data
	 */
	fromChild = data => {
		if (typeof data === 'object' && data !== null)
			this.mergeModifierToState(data.modifier);
	};

	/**
	 * Checks if the modifier is valid and merges it into the modifier state
	 * @param modifier
	 */
	mergeModifierToState = modifier => {
		if (modifier) {
			this.setState({
				modifiers: Object.assign(this.state.modifiers, {
					[`${fsCardSelector}--${modifier}`]: true
				})
			});
		}
	};

	/**
	 * Returns an object with various card layout structure
	 */
	getCardStructure = () => {
		const {
			url,
			isLoading,
			loaderError,
			RouteComponent = null,
			RouteProps = null
		} = this.props;

		let linkProps = {};
		let Tag = 'div';
		if (url) {
			linkProps = { href: url };
			Tag = 'a';
		} else if (RouteProps && RouteComponent) {
			linkProps = RouteProps;
			Tag = RouteComponent;
		}

		const cardClassName =
			isLoading || loaderError ? { className: 'fs-hide' } : {};
		const cardProps = Object.assign({}, linkProps, cardClassName);
		const childrenCount = React.Children.count(this.props.children);

		return {
			initialLoadLayout: () => (
				<div {...cardClassName}>{this.props.children}</div>
			),
			defaultLayout: () => <Tag {...cardProps}>{this.props.children}</Tag>,
			imageFooterLayout: () => {
				const lastTwoChildElements = this.props.children.slice(
					childrenCount - 2,
					childrenCount
				);
				const beginningChildElements = this.props.children.slice(
					0,
					childrenCount - 2
				);
				return (
					<Tag {...cardProps}>
						{beginningChildElements}
						<div>{lastTwoChildElements}</div>
					</Tag>
				);
			},
			headerLayout: () => {
				const headerComponent = this.props.children.slice(0, 1);
				const cardChildren = this.props.children.slice(1, childrenCount);
				return (
					<React.Fragment>
						{headerComponent}
						<Tag {...cardProps}>{cardChildren}</Tag>
					</React.Fragment>
				);
			},
			notificationLayout: () => {
				const leftComponent = this.props.children.slice(0, 1);
				const cardChildren = this.props.children.slice(1, childrenCount);
				return (
					<React.Fragment>
						<Tag {...cardProps}>
							{leftComponent}
							<div className="fs-flex">{cardChildren}</div>
						</Tag>
					</React.Fragment>
				);
			}
		};
	};

	cardRef = React.createRef();

	state = {
		// className modifiers
		modifiers: {},

		initialCardRender: false
	};

	componentDidMount() {
		const {
			url,
			onClick,
			RouteComponent,
			active,
			read,
			isLoading,
			loaderError
		} = this.props;

		if (findChildComponent(fsCardListGroup, this.cardRef))
			this.mergeModifierToState('list');

		if (findChildComponent(fsCardHeader, this.cardRef))
			this.mergeModifierToState('header');

		if ((onClick || url || RouteComponent) && !isLoading && !loaderError)
			this.mergeModifierToState('click');

		if (active) this.mergeModifierToState('active');

		if (read) this.mergeModifierToState('read');

		this.props.modifier && Array.isArray(this.props.modifier)
			? this.props.modifier.forEach(modifier =>
					this.mergeModifierToState(modifier)
			  )
			: this.mergeModifierToState(this.props.modifier);

		this.context.hasGroup &&
			this.context.childData({
				ref: this.cardRef.current,
				modifiers: this.state.modifiers
			});

		this.setState({ initialCardRender: true });
	}

	render() {
		const { onClick, className, isLoading, loaderError } = this.props;
		const {
			initialLoadLayout,
			defaultLayout,
			headerLayout,
			imageFooterLayout,
			notificationLayout
		} = this.getCardStructure();

		const cardStyles = [fsCardSelector, className, this.state.modifiers];

		let cardComponent = null;

		if (!this.state.initialCardRender) cardComponent = initialLoadLayout();

		if (this.state.initialCardRender) cardComponent = defaultLayout();

		if (this.state.modifiers[`${fsCardSelector}--notification`])
			cardComponent = notificationLayout();

		if (
			this.state.modifiers[`${fsCardSelector}--image-left`] &&
			findChildComponent(fsCardFooter, this.cardRef)
		)
			cardComponent = imageFooterLayout();

		if (
			this.state.modifiers[`${fsCardSelector}--header`] &&
			findChildComponent(fsCardHeader, this.cardRef)
		)
			cardComponent = headerLayout();

		return (
			<CardContext.Provider
				value={{
					RouteComponent: this.props.RouteComponent,
					isMobile: this.context.hasGroup
						? this.context.isMobile
						: this.props.isMobile,
					fromChild: this.fromChild,
					cardRef: this.cardRef
				}}
			>
				<div
					ref={this.cardRef}
					className={cn(cardStyles)}
					onClick={onClick}
					style={{ ...this.props.customStyles }}
				>
					{loaderError && !isLoading && this.getWrappedErrorComponent()}
					{isLoading && <CardLoader size={this.props.size} />}
					{cardComponent}
				</div>
			</CardContext.Provider>
		);
	}
}

export default withCardResize(Card);
