import React from 'react';
import PropTypes from 'prop-types';
import { CardContext } from './CardContext';
import { FontIcon } from 'fs-toolkit-react';
import { withHide } from './helpers/withHide';
import { colors } from 'utils/cssVariables';
import { withResponsiveProp } from './helpers/withResponsiveProp';

/**
 * FontIcon component wrapped with card functionality.
 * The feature added was the ability to send data from this component up to
 * the parent Card component through the context api
 */
class CardIcon extends React.Component {
	static contextType = CardContext;

	static propTypes = {
		position: PropTypes.oneOf([
			'top',
			'left',
			'right',
			'js-ignore' /* null only used within error component */
		]).isRequired,
		icon: PropTypes.oneOfType([
			PropTypes.string.isRequired,
			PropTypes.object.isRequired
		]),
		url: PropTypes.string,
		iconColor: PropTypes.string,
		iconSize: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				mobile: PropTypes.string,
				desktop: PropTypes.string
			})
		])
	};

	componentDidMount() {
		if (this.props.position) {
			this.context.fromChild({ modifier: `icon-${this.props.position}` });
		}
	}

	render() {
		return (
			<FontIcon
				iconColor={this.props.iconColor ? this.props.iconColor : colors.gray}
				{...this.props}
				{...this.props.rest}
			/>
		);
	}
}

const responsivePropConfig = [
	{ propName: 'iconSize', mobile: '48px', desktop: '64px' }
];
export default withResponsiveProp(withHide(CardIcon))(responsivePropConfig);
