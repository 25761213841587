import React from 'react';
import cn from 'classnames';
import { colors, FontIcon } from 'fs-toolkit-react';
import PropTypes from 'prop-types';

class IconMessage extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		iconColor: PropTypes.string,
		iconSize: PropTypes.string,
		maxWidth: PropTypes.number,
		message: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		style: PropTypes.object,
		subtext: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		title: PropTypes.string
	};

	render() {
		const {
			children,
			className,
			icon,
			iconColor,
			iconSize,
			message,
			style,
			subtext,
			title
		} = this.props;

		return (
			<div className={cn('fs-icon-message', className)} style={style}>
				{icon && (
					<FontIcon
						icon={icon}
						iconColor={iconColor ? iconColor : colors.gray}
						iconSize={iconSize ? iconSize : '8rem'}
					/>
				)}

				{title && <h3 className="fs-icon-message__title">{title}</h3>}
				{message && (
					<p
						className="fs-icon-message__message"
						dangerouslySetInnerHTML={{ __html: message }}
					/>
				)}
				{subtext && <p className="fs-icon-message__subtext">{subtext}</p>}

				{children}
			</div>
		);
	}
}

export default IconMessage;
