import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from './Panel';
import { LayoutContext } from 'components/layout/LayoutProvider';
import cn from 'classnames';

// edit panel classname
const editPanel = 'edit-panel';

function EditPanel(props) {
	const {
		label = 'Options',
		children,
		className = ''
	} = props;

	const { isMobile } = useContext(LayoutContext);

	const editPanelStyles = [
		editPanel,
		className
	];

	return (
		<Panel
			position="right"
			className={cn(editPanelStyles)}
			width={!isMobile && 352}
			inlinePanel={true}
		>
			<h4 className={`${editPanel}__title`}>{label}</h4>
			{children}
		</Panel>
	);
}

EditPanel.propTypes = {
	label: PropTypes.string,
	children: PropTypes.any,
	className: PropTypes.string
};

export default EditPanel;