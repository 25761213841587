import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'fs-toolkit-react';
import ScrollBottom from 'components/ui/ScrollBottom';

class NotificationWrapper extends React.PureComponent {
  render() {
    const {
      children,
      showLoader,
      showLoaderOverlay,
      onScrollToBottom
    } = this.props;

    return (
      <ScrollBottom onScrollBottom={onScrollToBottom} customClass={'notification-panel__wrapper'}>
       {
          showLoaderOverlay
            ? <React.Fragment>
              <div className="notification-panel__loader-overlay" />
              <div className="notification-panel__loader-wrapper flex flex-align-center flex-center">
                <Loader loaderSize={34} />
              </div>
            </React.Fragment>
            : null
        }
        {showLoader ? <Loader loaderSize={34} /> : <React.Fragment>{children}</React.Fragment> }   
      </ScrollBottom>
    );
  }
}

NotificationWrapper.defaultProps = {
  processing: false,
  children: [],
  defaultText: '',
  showLoaderOverlay: false,
  showLoader: false
};

NotificationWrapper.propTypes = {
  children: PropTypes.node,
  onScrollToBottom: PropTypes.func.isRequired,
  showLoader: PropTypes.bool
};

export default NotificationWrapper;