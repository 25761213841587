import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { LayoutContext } from 'components/layout/LayoutProvider';


function Panel(props) {
	const { state, action  } = useContext(LayoutContext);
	const { isMobile } = state;
	const { setPageModifier, removePageModifier } = action;

	const {
		position = 'left',
		inlinePanel = false,
		style,
		className = '',
		children,
		width = 216,
		hide = false,
		isLoading = false
	} = props;

	const fsSideBar = 'fs-sidebar';
	const panelStyles = [
		fsSideBar,
		`${fsSideBar}--${position}`,
		inlinePanel ? `${fsSideBar}--inline` : `${fsSideBar}--fixed`,
		className
	];

	const sideBarPosition = `sidebar-${position}`;
	useEffect(() => {
		setPageModifier(sideBarPosition);
		return () => removePageModifier(sideBarPosition);
	}, [position, removePageModifier, setPageModifier, sideBarPosition]);


	return (
		!hide && (
			<div className={cn(panelStyles)} style={{ width: !isMobile ? `${width}px` : undefined, ...style }}>
				<div style={{ width: '100%' }}>
					{!isLoading && children}
				</div>
			</div>
		)
	);
}

Panel.propTypes = {
	className: PropTypes.string,
	position: PropTypes.oneOf(['right', 'left']),
	inlinePanel: PropTypes.bool,
	style: PropTypes.object,
	width: PropTypes.number,
	hide: PropTypes.bool
};

export default Panel;
