import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchTasks } from 'actions/tasksActions';
import { fetchTaskCategories } from 'actions/taskCategoriesActions';
import { fetchTaskStatuses } from 'actions/taskStatusesActions';
import { getLaunchEnrollments } from 'actions/launchEnrollmentsActions';
import ListFilter from 'components/views/connect/shared-components/ListFilter';
import { Container } from 'components/layout';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import Error from 'components/views/error/Error';
import {
	Card,
	CardGroup,
	SelectBox,
	HeadingDropdown,
	DropdownItem,
	ToolTip
} from 'fs-toolkit-react';
import SearchInput from 'components/shared/searchInput/SearchInput';
import { scrolledToBottom } from 'utils/DOMUtils';
import WithResize from 'components/HOC/WithResize';
import {
	getCardIcon,
	getDefaultLaunchEnrollment
} from 'components/views/tasks/utils/tasksUtils';
import { formatDate } from 'utils/dateTimeUtils';
import { cnWrapperMain } from '../../../constants/UIConstants';
import InfiniteScroller from 'components/shared/InfiniteScroller';
import { sortImmutableItems } from 'utils/globalUtils';

class TaskList extends React.Component {
	state = {
		enrollmentId: null,
		headingDropdownItems: null,
		status: '',
		category: '',
		query: '',
		mobileFiltersVisible: false
	};

	static propTypes = {
		tasks: PropTypes.instanceOf(List).isRequired,
		tasksItem: PropTypes.instanceOf(Map).isRequired,
		tasksProcessing: PropTypes.bool.isRequired,
		tasksProcessingScroll: PropTypes.bool.isRequired,
		tasksProcessingSearch: PropTypes.bool.isRequired,
		taskCategories: PropTypes.instanceOf(List).isRequired,
		taskCategoriesProcessing: PropTypes.bool.isRequired,
		taskStatuses: PropTypes.instanceOf(List).isRequired,
		taskStatusesProcessing: PropTypes.bool.isRequired,
		launchEnrmts: PropTypes.instanceOf(List).isRequired,
		launchEnrmtsProcessing: PropTypes.bool.isRequired
	};

	handleQueryChange = e => {
		this.setState({ query: e.target.value });
	};

	handleKeyup = e => {
		e.key === 'Enter' && this.search();
	};

	handleIconClick = () => this.search();

	search = () => {
		const { enrollmentId, query, status, category } = this.state;
		this.props.dispatch(
			fetchTasks({
				params: { enrollmentId, status, category, query },
				processingType: 'searchProcessing'
			})
		);
	};

	handleStatusChange = status => {
		this.setState({ status });
	};

	handleCategoryChange = category => {
		this.setState({ category });
	};

	handleHeadingDropdownChange = enrollmentId => {
		this.setState({ enrollmentId });
	};

	handleResize = e => {
		this.setState({
			mobileFiltersVisible:
				this.props.isMobile && this.state.mobileFiltersVisible
		});
	};

	handleScroll = e => {
		const { tasksItem } = this.props;

		const { enrollmentId, query, status, category } = this.state;
		this.props.dispatch(
			fetchTasks({
				params: {
					enrollmentId,
					status,
					category,
					query,
					page: tasksItem.get('number') + 1
				},
				processingType: 'scrollProcessing'
			})
		);
	};

	toggleMobileFilters = () => {
		this.setState({ mobileFiltersVisible: !this.state.mobileFiltersVisible });
	};

	displayFilters = () => {
		return !this.props.isMobile || this.state.mobileFiltersVisible;
	};

	handleNavigation = id => {
		this.props.history.push(`/tasks/${id}`);
	};

	componentDidMount = () => {
		this.props.dispatch(getLaunchEnrollments());
		this.props.dispatch(fetchTaskCategories());
		this.props.dispatch(fetchTaskStatuses());
		window.addEventListener('resize', this.handleResize);
		this.page = document.querySelector(cnWrapperMain);
		window.removeEventListener('resize', this.handleResize);
		if (
			(process.env.REACT_APP_ENV === 'production' ||
			process.env.NODE_ENV === 'production') && window.utag
		) {
			// track the user for tealium
			window.utag.view({ fsid: this.props.profile.get('fsOneId') });
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const { launchEnrmts, history, tasksItem } = this.props;
		const { enrollmentId, query, status, category } = this.state;

		// Note: For devices with odd display resolution that doesnt render enough
		// data to trigger a scroll event.
		// Checks on initial page load if 'scrolledToBottom' is true
		// then loads more data to fill the (UI) space.
		if (prevProps.tasksItem.isEmpty() && !tasksItem.isEmpty() && this.page) {
			if (scrolledToBottom(this.page) && !tasksItem.get('last')) {
				this.handleScroll();
			}
		}

		if (prevProps.launchEnrmts !== launchEnrmts) {
			const enrollment = getDefaultLaunchEnrollment({ launchEnrmts });
			const headingDropdownItems = sortImmutableItems({
				items: launchEnrmts,
				comparator: 'startDate',
				comparatorIsDate: true
			}).map(enrmt => {
				return Map({
					key: enrmt.get('id'),
					text: enrmt.getIn(['program', 'name']),
					id: enrmt.get('id'),
					startDate: enrmt.get('startDate')
				});
			});

			this.setState(
				{
					enrollmentId: enrollment.get('id'),
					headingDropdownItems
				},
				() => {
					this.props.dispatch(
						fetchTasks({
							params: {
								enrollmentId: this.state.enrollmentId,
								status,
								category,
								query
							},
							checkTaskBlocker: true,
							history
						})
					);
				}
			);
		}

		if (
			prevState.enrollmentId !== enrollmentId ||
			prevState.status !== status ||
			prevState.category !== category
		) {
			this.props.dispatch(
				fetchTasks({
					params: {
						enrollmentId,
						status,
						category,
						query
					},
					processingType: 'searchProcessing'
				})
			);
		}
	}

	render() {
		const {
			tasks,
			tasksItem,
			tasksProcessing,
			tasksProcessingScroll,
			tasksProcessingSearch,
			tasksError,
			launchEnrmtsProcessing,
			launchEnrmtsError,
			taskCategories,
			taskCategoriesProcessing,
			taskCategoriesError,
			taskStatuses,
			taskStatusesProcessing,
			taskStatusesError
		} = this.props;
		const statusesDefaultOption = 'All Statuses';
		const categoriesDefaultOption = 'All Categories';

		const getDropdown = (list, defaultValue, onChange) => {
			const defaultOption = Map({ name: defaultValue });

			return (
				<SelectBox fullWidth defaultOption={defaultValue} onChange={onChange}>
					{({ onClick, selected }) => (
						<React.Fragment>
							{list.unshift(defaultOption).map((item, i) => (
								<DropdownItem
									value={i === 0 ? '' : item.get('name')}
									onClick={onClick}
									selected={selected}
									key={i}
								>
									{item.get('name')}
								</DropdownItem>
							))}
						</React.Fragment>
					)}
				</SelectBox>
			);
		};

		const getHeading = () => {
			const { headingDropdownItems, enrollmentId } = this.state;

			if (headingDropdownItems.size === 1) {
				return <h2 className='program-name'>{headingDropdownItems.getIn([0, 'text'])}</h2>;
			}

			const getDate = date =>
				formatDate({
					date: date,
					format: 'MMM D, YYYY',
					timeZone: this.props.profile.get('timeZone') || "America/New_York"
				}).tz();

			const defaultHeading = headingDropdownItems
				.find(item => item.get('id') === parseInt(enrollmentId))
				.get('text');

			return (
				<HeadingDropdown
					gutter
					defaultHeading={defaultHeading}
					headingLevel="h2"
					onChange={this.handleHeadingDropdownChange}
				>
					{({ onClick, selected }) => (
						<React.Fragment>
							{headingDropdownItems.map(item => (
								<DropdownItem
									key={item.get('id')}
									value={item.get('id')}
									onClick={onClick}
									selected={selected}
									metaText={`Start: ${getDate(item.get('startDate'))}`}
								>
									{item.get('text')}
								</DropdownItem>
							))}
						</React.Fragment>
					)}
				</HeadingDropdown>
			);
		};

		const getErrorComponent = () => {
			const contingencyItems = {
				error: {
					icon: 'faFrownLight',
					title: 'No results found',
					message:
						'You didn’t do anything wrong. We may have moved the page you’re looking for somewhere else.'
				},
				emptyList: {
					icon: 'faSmileWinkLight',
					title: 'Nothing to show',
					message: "Looks like you don't have any tasks at this time."
				}
			};
			const contingency = this.props.tasksItem.isEmpty()
				? contingencyItems.emptyList
				: contingencyItems.error;

			return (
				<Fragment>
					<h2>Tasks</h2>
					<Error
						icon={contingency.icon}
						title={contingency.title}
						message={contingency.message}
					/>
				</Fragment>
			);
		};

		return (
			<Container
				isLoading={
					launchEnrmtsProcessing ||
					tasksProcessing ||
					taskCategoriesProcessing ||
					taskStatusesProcessing
				}
				loaderError={
					tasksError ||
					launchEnrmtsError ||
					taskCategoriesError ||
					taskStatusesError ||
					tasksItem.isEmpty()
				}
				errorComponent={getErrorComponent()}
				modifier="list"
				className='task-list'
			>
				<ListFilter
					title="Tasks"
					type="tasks"
					onMobileFilterClick={this.toggleMobileFilters}
				>
					{this.state.headingDropdownItems && getHeading()}
				</ListFilter>

				{this.displayFilters() && (
					<div className="fs-form-row--stacked">
						<SearchInput
							onChange={this.handleQueryChange}
							onIconClick={this.handleIconClick}
							onKeyUp={this.handleKeyup}
							value={this.state.query}
							placeholder="Search"
						/>
						<React.Fragment>
							<React.Fragment>
								{getDropdown(
									taskStatuses,
									statusesDefaultOption,
									this.handleStatusChange
								)}
							</React.Fragment>
							<React.Fragment>
								{getDropdown(
									taskCategories,
									categoriesDefaultOption,
									this.handleCategoryChange
								)}
							</React.Fragment>
						</React.Fragment>
					</div>
				)}

				<LoaderWrapper
					loaderError={!tasksProcessingSearch && tasks.isEmpty()}
					isLoading={tasksProcessingSearch}
					errorComponent={
						<Error
							icon="faFrownLight"
							title="No results found"
							message="Try adjusting your search or filter to find what you are looking for."
						/>
					}
				>
					<CardGroup>
						<InfiniteScroller
							next={this.handleScroll}
							hasMore={!tasksItem.get('last')}
							processing={tasksProcessingScroll}
						>
							{tasks.map(task => {
								const status = task.get('status');
								const item = task.get('task');
								const id = task.get('id');

								return (
									<Card
										onClick={this.handleNavigation.bind(null, id)}
										modifier={['full-width']}
										key={id}
									>
										<Card.Status
											position="left"
											icon={getCardIcon(status)}
											complete={!status.includes('Incomplete')}
											background="#CC4B00"
											tooltip={
												<ToolTip
													items={[status]}
													arrowPosition="bottom"
													size="small"
													style={{ width: '12rem' }}
												/>
											}
										/>
										<Card.Body>
											<Card.Title lines={1}>
												{item.get('displayName')}
											</Card.Title>
											<Card.Meta lines={{ mobile: 1, desktop: 2 }}>
												{item.get('category')}
											</Card.Meta>
										</Card.Body>
										{status.includes('Reopened') && (
											<Card.Footer>
												<Card.Badge
													label={status.substring(
														status.indexOf('-') + 1,
														status.length
													)}
													slim
												/>
											</Card.Footer>
										)}
									</Card>
								);
							})}
						</InfiniteScroller>
					</CardGroup>
				</LoaderWrapper>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	profile: state.getIn(['profile', 'data']),
	tasksItem: state.getIn(['tasks', 'item']),
	tasks: state.getIn(['tasks', 'tasks']),
	tasksReset: state.getIn(['tasks', 'reset']),
	tasksProcessing: state.getIn(['tasks', 'processing']),
	tasksProcessingScroll: state.getIn(['tasks', 'processingScroll']),
	tasksProcessingSearch: state.getIn(['tasks', 'processingSearch']),
	tasksError: state.getIn(['tasks', 'error']),
	taskCategories: state.getIn(['taskCategories', 'categories']),
	taskCategoriesProcessing: state.getIn(['taskCategories', 'processing']),
	taskCategoriesError: state.getIn(['taskCategories', 'error']),
	taskStatuses: state.getIn(['taskStatuses', 'statuses']),
	taskStatusesProcessing: state.getIn(['taskStatuses', 'processing']),
	taskStatusesError: state.getIn(['taskStatuses', 'error']),
	launchEnrmts: state.getIn(['launchEnrollments', 'data']),
	launchEnrmtsProcessing: state.getIn(['launchEnrollments', 'processing']),
	launchEnrmtsError: state.getIn(['launchEnrollments', 'error'])
});

export default withRouter(connect(mapStateToProps)(WithResize(TaskList)));
