import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import AsyncImporter from 'components/handlers/AsyncImporter';
import ErrorPage from 'components/views/error/ErrorPage';

import AppRoute from 'components/layout/AppRoute';
import AdminLayout from 'components/layout/containers/AdminLayout';
import SidePanelLayout from 'components/layout/containers/SidePanelLayout';

function ConnectRoutes(props) {
	const { authenticated } = props;
	const { path } = useRouteMatch();
	return (
		<Switch>
			<AppRoute
				exact
				path={path}
				component={Connect}
				layout={SidePanelLayout}
			/>

			{/* Events */}
			<AppRoute
				exact
				path={[
					`${path}/events`,
					`${path}/:groupType/:groupName/:groupId/events`
				]}
				component={Events}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={[
					`${path}/events/create`,
					`${path}/:groupType/:groupName/:groupId/events/create`
				]}
				component={CreateEvent}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={[
					`${path}/events/edit/:eventId/:sequenceId`,
					`${path}/:groupType/:groupName/:groupId/events/edit/:eventId/:sequenceId`
				]}
				component={EditEvent}
				layout={AdminLayout}
			/>
			{/* fallback to support the old link path */}
			<AppRoute
				exact
				path={[
					`${path}/events/:eventId/:sequenceId`,
					`${path}/:groupType/:groupName/:groupId/events/:eventId/:sequenceId`
				]}
				component={EventDetail}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={[
					`${path}/events/:eventId/:sequenceId/:title`,
					`${path}/:groupType/:groupName/:groupId/events/:eventId/:sequenceId/:title`
				]}
				component={EventDetail}
				layout={SidePanelLayout}
			/>

			{/* Topics */}
			<AppRoute
				exact
				path={[
					`${path}/topics`,
					`${path}/:groupType/:groupName/:groupId/topics`
				]}
				component={Topics}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={[
					`${path}/topics/create`,
					`${path}/:groupType/:groupName/:groupId/topics/create`
				]}
				component={CreateTopic}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={[
					`${path}/topics/edit/:topicId`,
					`${path}/:groupType/:groupName/:groupId/topics/edit/:topicId`
				]}
				component={EditTopic}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={[
					`${path}/topics/:title/:topicId`,
					`${path}/:groupType/:groupName/:groupId/topics/:topicId/:title`,
					// path used to anchor to a comment
					`${path}/:groupType/:groupName/:groupId/topics/:topicId/:commentId/:title`
				]}
				component={TopicDetail}
				layout={SidePanelLayout}
			/>

			{/* Stories */}
			<AppRoute
				exact
				path={`${path}/stories`}
				component={Stories}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={`${path}/stories/create`}
				component={CreateStory}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={`${path}/stories/:articleId/:title/`}
				component={StoryDetail}
				layout={SidePanelLayout}
			/>

			<AppRoute
				exact
				path={`${path}/stories/:articleId/:title/edit`}
				component={EditStory}
				layout={AdminLayout}
			/>

			{/* Group Stories */}
			<AppRoute
				exact
				path={`${path}/stories/:groupId/:title/:articleId`}
				component={StoryDetail}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={`${path}/stories/:groupId/:title/:articleId/edit`}
				component={EditStory}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/stories`}
				component={Stories}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/stories/create`}
				component={CreateStory}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/stories/:articleId/:articleName`}
				component={StoryDetail}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/stories/:articleId/:articleName/edit`}
				component={EditStory}
				layout={AdminLayout}
			/>

			{/* People */}
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/people`}
				component={People}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={`${path}/people`}
				component={People}
				layout={SidePanelLayout}
			/>

			{/* Groups */}
			<AppRoute
				exact
				path={`${path}/:groupType`}
				component={Groups}
				layout={SidePanelLayout}
			/>
			<AppRoute
				exact
				path={`${path}/group/create`}
				component={CreateGroup}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/edit`}
				component={EditGroup}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId/about`}
				component={GroupAbout}
				layout={SidePanelLayout}
			/>

			<AppRoute
				exact
				path={`${path}/:groupType/:groupName/:groupId`}
				component={GroupHome}
				layout={SidePanelLayout}
			/>

			{/* Membership tool */}
			<Route
				exact
				path={`${path}/:groupType/:groupName/:groupId/memberships`}
				component={MembershipList}
			/>

			{/* Handle 404s */}
			<Route path="*">
				{authenticated ? (
					<ErrorPage useEmbeddedError={true} />
				) : (
					<Redirect to="/404" />
				)}
			</Route>
		</Switch>
	);
}

const Connect = loadable(() => import('components/views/connect/Connect'), {
	fallback: <AsyncImporter />
});

const Events = loadable(
	() => import('components/views/connect/events/Events'),
	{ fallback: <AsyncImporter /> }
);

const EventDetail = loadable(
	() => import('components/views/connect/events/detail/EventDetail'),
	{ fallback: <AsyncImporter /> }
);

const EditEvent = loadable(
	() => import('components/views/connect/events/edit/EditEvent'),
	{ fallback: <AsyncImporter /> }
);

const CreateEvent = loadable(
	() => import('components/views/connect/events/create/CreateEvent'),
	{ fallback: <AsyncImporter /> }
);

const Stories = loadable(
	() => import('components/views/connect/stories/Stories'),
	{ fallback: <AsyncImporter /> }
);

const StoryDetail = loadable(
	() => import('components/views/connect/stories/story/StoryDetail'),
	{ fallback: <AsyncImporter /> }
);

const CreateStory = loadable(
	() => import('components/views/connect/stories/story/CreateStory'),
	{ fallback: <AsyncImporter /> }
);

const EditStory = loadable(
	() => import('components/views/connect/stories/story/EditStory'),
	{ fallback: <AsyncImporter /> }
);

const Groups = loadable(
	() => import('components/views/connect/groups/Groups'),
	{ fallback: <AsyncImporter /> }
);

const CreateGroup = loadable(
	() => import('components/views/connect/groups/group/CreateGroup'),
	{ fallback: <AsyncImporter /> }
);

const EditGroup = loadable(
	() => import('components/views/connect/groups/group/EditGroup'),
	{ fallback: <AsyncImporter /> }
);

const GroupAbout = loadable(
	() => import('components/views/connect/groups/group/GroupAbout'),
	{ fallback: <AsyncImporter /> }
);

const GroupHome = loadable(
	() => import('components/views/connect/groups/group/GroupHome'),
	{ fallback: <AsyncImporter /> }
);

const MembershipList = loadable(
	() => import('components/views/tools/memberships/MembershipList'),
	{ fallback: <AsyncImporter /> }
);

const People = loadable(
	() => import('components/views/connect/people/People'),
	{ fallback: <AsyncImporter /> }
);

const Topics = loadable(
	() => import('components/views/connect/topics/public/Topics'),
	{ fallback: <AsyncImporter /> }
);

const CreateTopic = loadable(
	() => import('components/views/connect/topics/admin/CreateTopic'),
	{ fallback: <AsyncImporter /> }
);

const EditTopic = loadable(
	() => import('components/views/connect/topics/admin/EditTopic'),
	{ fallback: <AsyncImporter /> }
);

const TopicDetail = loadable(
	() => import('components/views/connect/topics/public/TopicDetail'),
	{ fallback: <AsyncImporter /> }
);

export default ConnectRoutes;
