import React from 'react';
import _ from 'lodash';
import { CardGroupContext } from '../CardGroupContext';

const withCardResize = (WrappedComponent, config = {}) => {
	class Component extends React.Component {
		static contextType = CardGroupContext;

		// used for checking if the component is actually mounted before
		// add or removing event listeners as well as setting state
		_isMounted = false;

		// set breakpoint for component
		breakpoint = config.breakpoint || 960;

		state = {
			isMobile: null,
			windowInnerWidth: window.innerWidth,
		};

		isMobile = () => window.innerWidth < this.breakpoint;

		setResizeState = () => this.setState({
			isMobile: this.isMobile(),
			windowInnerWidth: window.innerWidth
		});

		handleResize = _.debounce(() => {
			if (this._isMounted) {
				if (this.state.isMobile !== this.isMobile()) {
					this.setResizeState();
				}
			}
		}, 250);

		componentDidMount = () => {
			if (!this.context.hasGroup) {
				this._isMounted = true;
				setTimeout(() => {
					this._isMounted && this.setResizeState();
				}, 0);
				window.addEventListener('resize', this.handleResize, false);
			}
		};

		componentWillUnmount = () => {
			if (!this.context.hasGroup) {
				this._isMounted = false;
				this._isMounted && window.removeEventListener('resize', this.handleResize, false);
			}
		};

		render = () => (
			<WrappedComponent
				{...this.props}
				isMobile={this.state.isMobile}
				windowInnerWidth={this.state.windowInnerWidth}
			/>
		);
	}

	// copy over static methods
	Component.Avatar = WrappedComponent.Avatar;
	Component.Badge = WrappedComponent.Badge;
	Component.Body = WrappedComponent.Body;
	Component.Button = WrappedComponent.Button;
	Component.Category = WrappedComponent.Category;
	Component.Custom = WrappedComponent.Custom;
	Component.Date = WrappedComponent.Date;
	Component.Error = WrappedComponent.Error;
	Component.Excerpt = WrappedComponent.Excerpt;
	Component.Header = WrappedComponent.Header;
	Component.Icon = WrappedComponent.Icon;
	Component.Image = WrappedComponent.Image;
	Component.ListGroup = WrappedComponent.ListGroup;
	Component.ListItem = WrappedComponent.ListItem;
	Component.Meta = WrappedComponent.Meta;
	Component.Footer = WrappedComponent.Footer;
	Component.Title = WrappedComponent.Title;
	Component.Status = WrappedComponent.Status;

	return Component;
};

export { withCardResize };