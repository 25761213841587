import axios from 'axios';

export default () => {
	axios.interceptors.response.use(
		res => res,
		error => {
			const { config } = error;
			// if the one api is down, direct the maintenance page
			if (
				error.message === 'Network Error' && 
				process.env.REACT_APP_API_ENDPOINT === config.baseURL && 
				(error.code === undefined || error.code === 502 || error.code === 503)
			) {
				window.location.href = '/maintenance';
			}

			return Promise.reject(error);
		}
	);
};
