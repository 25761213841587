import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import AsyncImporter from 'components/handlers/AsyncImporter';
import ErrorPage from 'components/views/error/ErrorPage';

import AppRoute from 'components/layout/AppRoute';
import AdminLayout from 'components/layout/containers/AdminLayout';
import FooterLayout from 'components/layout/containers/FooterLayout';
import SidePanelFooterLayout from 'components/layout/containers/SidePanelFooterLayout';

const Support = loadable(
	() => import('components/views/support/Support'),
	{ fallback: <AsyncImporter /> }
);

const TicketDetail = loadable(
	() => import('components/views/support/ticket/TicketDetail'),
	{ fallback: <AsyncImporter /> }
);

const Ticket = loadable(
	() => import('components/views/support/ticket/Ticket'),
	{ fallback: <AsyncImporter /> }
);

const SupportKB = loadable(
	() => import('components/views/support/knowledge-base/SupportKB'),
	{ fallback: <AsyncImporter /> }
);

const KBArticleDetail = loadable(
	() =>
		import('components/views/support/knowledge-base/article/KBArticleDetail'),
	{ fallback: <AsyncImporter /> }
);

const KbCreateArticle = loadable(
	() =>
		import('components/views/support/knowledge-base/article/KBCreateArticle'),
	{ fallback: <AsyncImporter /> }
);

const KbEditArticle = loadable(
	() =>
		import('components/views/support/knowledge-base/article/KBEditArticle'),
	{ fallback: <AsyncImporter /> }
);

const KBCategoryArticles = loadable(
	() => import('components/views/support/knowledge-base/category/KBCategoryArticles'),
	{ fallback: <AsyncImporter /> }
);

const SupportKBSearch = loadable(
	() => import('components/shared/searchResults/SearchResults'),
	{ fallback: <AsyncImporter /> }
);

function SupportRoutes(props) {
	const { authenticated } = props;
	const { path } = useRouteMatch();
	return (
		<Switch>
			<AppRoute
				exact
				path={`${path}`}
				component={Support}
				layout={FooterLayout}
			/>
			<Route
				exact
				path={`${path}/ticket`}
				component={Ticket}
			/>
			<Route
				exact
				path={`${path}/ticket/:id`}
				component={TicketDetail}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/technical`}
				component={SupportKB}
				layout={FooterLayout}
			/>
			{/* fallback to support the old link path */}
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/technical/:id`}
				component={KBArticleDetail}
				layout={SidePanelFooterLayout}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/technical/:id/:title`}
				component={KBArticleDetail}
				layout={SidePanelFooterLayout}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/administrative`}
				component={SupportKB}
				layout={FooterLayout}
			/>
			{ /* fallback to support the old link path */}
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/administrative/:id`}
				component={KBArticleDetail}
				layout={SidePanelFooterLayout}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/administrative/:id/:title`}
				component={KBArticleDetail}
				layout={SidePanelFooterLayout}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/:superCategory/:category/:catId/list`}
				component={KBCategoryArticles}
				layout={FooterLayout}
			/>
			<Route
				exact
				path={`${path}/knowledge_base_articles/search`}
				component={SupportKBSearch}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/create/:type`}
				component={KbCreateArticle}
				layout={AdminLayout}
			/>
			<AppRoute
				exact
				path={`${path}/knowledge_base_articles/edit/:type/:id`}
				component={KbEditArticle}
				layout={AdminLayout}
			/>


			{/* Handle 404s */}
			<Route path="*">
				{authenticated
					? <ErrorPage useEmbeddedError={true} />
					: <Redirect to="/404" />
				}
			</Route>
		</Switch>
	)
}

export default SupportRoutes;