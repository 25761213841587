import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import { Root } from 'components/layout';

function FourZeroFour(props) {
	return (
		<Root>
			<ErrorPage
				{...props}
				icon="faSadTearLight"
				title="Sorry, that page isn't here"
				message="You didn't do anything wrong. We may have moved the page you're looking for somewhere else."
			/>
		</Root>
	)
}

export default FourZeroFour;