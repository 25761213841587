/* eslint-disable no-unused-vars */
import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toParams } from 'utils/globalUtils';

const initialLoadProcessing = () => ({
	type: ActionTypes.TASKS_PROCESSING
});

const tasksSuccess = data => ({
	type: ActionTypes.TASKS_SUCCESS,
	data
});

const tasksFailure = error => ({
	type: ActionTypes.TASKS_FAILURE,
	error
});

const scrollProcessing = () => ({
	type: ActionTypes.TASKS_PROCESSING_SCROLL
});

const searchProcessing = () => ({
	type: ActionTypes.TASKS_PROCESSING_SEARCH
});

export const resetTasks = () => ({
	type: ActionTypes.RESET_TASKS
});

export const fetchTasks = ({
	params = {},
	processingType = 'initialLoadProcessing',
	checkTaskBlocker = false,
	history
}) => {
	return dispatch => {
		const processingTypes = {
			initialLoadProcessing,
			scrollProcessing,
			searchProcessing
		};

		dispatch(processingTypes[processingType]());

		if (checkTaskBlocker) {
			const blockerTaskPromise = APIClient.get(
				'/launch/my/task_assignments?isBlocker=true'
			);

			const tasksPromise = APIClient.get(
				`/launch/my/task_assignments?${toParams(params)}`
			);

			return Promise.all([blockerTaskPromise, tasksPromise]).then(
				([blockerTask, tasks]) => {
					const [{ status, id }] = blockerTask.data.content;

					if (status === 'Incomplete') {
						return history.push(`/esign/${id}`);
					}
					dispatch(tasksSuccess(tasks.data));
				},
				({ response: { data } }) => dispatch(tasksFailure(data))
			);
		} else {
			return APIClient.get(
				`/launch/my/task_assignments?${toParams(params)}`
			).then(
				({ data }) => dispatch(tasksSuccess(data)),
				({ response: { data } }) => dispatch(tasksFailure(data))
			);
		}
	};
};

export const fetchTasksForCta = ({ status = 'Incomplete' }) => {
	return dispatch => {
		dispatch(initialLoadProcessing());

		return APIClient.get('/launch/my/enrollments')
			.then(({ data }) => {
				const applyingEnrollment = data.find(
					({ status }) => status === 'Applying'
				);

				if (applyingEnrollment) {
					return APIClient.get(
						`/launch/my/task_assignments?${toParams({
							status,
							enrollmentId: applyingEnrollment.id
						})}`
					).then(({ data }) => dispatch(tasksSuccess(data)));
				} else {
					dispatch(tasksSuccess({}));
				}
			})
			.catch(({ response: { data } }) => dispatch(tasksFailure(data)));
	};
};
