import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Map, List } from 'immutable';
import WithResize from 'components/HOC/WithResize';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import SearchList from './SearchList';
import Error from 'components/views/error/Error';
import { Input, ButtonRefactor as Button, FontIcon } from 'fs-toolkit-react';
import ReactPaginate from 'react-paginate';
import { search } from 'actions/searchResultsActions';
import { toParams, capitalize } from 'utils/globalUtils';
import { Container, FooterContainer } from 'components/layout';
import { fsPageFooterPush } from '../../layout/helpers/layoutClassNameList';
import SearchFilterHandler from './searchFilter/SearchFilterHandler';
import { filterItems } from './searchFilter/searchUtils/iconUtils';

class SearchResults extends React.Component {
	constructor(props) {
		super(props);
		const params = this.searchParams(props.location.search);
		this.state = {
			filter: '',
			query: params.get('query') || '',
			superCategory: capitalize(params.get('type') || ''),
			page: 0,
			modalOpen: false,
			isGlobalSearch: !props.location.pathname.includes('support')
		};
	}

	static propTypes = {
		articles: PropTypes.instanceOf(Map).isRequired,
		processing: PropTypes.bool.isRequired,
		error: PropTypes.object
	};

	// event handlers
	handleFilter = filter => {
		this.setState({ filter, page: 0 }, () => {
			this.props.dispatch(
				search(
					{
						type: this.state.superCategory,
						query: this.state.query,
						page: 0,
						filter: this.state.filter
					},
					this.state.isGlobalSearch
				)
			);
		});
	};

	handleSearchInputChange = event => {
		this.setState({ query: event.target.value });
	};

	handleSearchIconClick = () => this.search();

	handleOpenTicket = () => this.props.history.push('/support/ticket');

	handlePaginationItemClick = ({ selected }) => {
		this.setState({ page: selected, firstPageLoad: false }, () =>
			this.props.dispatch(
				search(
					{
						type: this.state.superCategory,
						query: this.state.query,
						page: this.state.page,
						filter: this.state.filter
					},
					this.state.isGlobalSearch
				)
			)
		);
	};

	// utility methods
	searchParams = search => new URLSearchParams(search);

	scrollToTop = () =>
		document.getElementsByTagName('h1')[0].scrollIntoView({ block: 'end' });

	search = () => {
		if (!this.state.query) {
			return;
		}

		const params = {
			query: this.state.query,
			type: this.state.superCategory
		};
		let url = this.state.isGlobalSearch
			? `/search?query=${this.state.query}`
			: `/support/knowledge_base_articles/search?${toParams(params)}`;

		this.props.history.push(url);
	};

	getNoResultsError = () => (
		<Error
			icon="faFrownLight"
			title="No results found"
			message={
				this.state.isGlobalSearch
					? 'Try another search.'
					: "Try adjusting your search or filter to find what you're looking for."
			}
		/>
	);

	// lifecycle methods
	componentDidMount = () => {
		this.props.dispatch(
			search(
				{
					page: this.state.page,
					query: this.state.query,
					type: this.state.superCategory,
					filter: this.state.filter
				},
				this.state.isGlobalSearch
			)
		);
	};

	handleKeyUp = e => {
		e.preventDefault();
		e.key === 'Enter' && this.search();
	};

	componentDidUpdate = prevProps => {
		if (
			prevProps.processing !== this.props.processing &&
			!this.props.processing
		) {
			this.scrollToTop();
		}

		if (prevProps.location.search !== this.props.location.search) {
			const { superCategory } = this.state;
			const params = this.searchParams(this.props.location.search);
			const query = params.get('query');
			const page = 0;
			this.setState({ query, page });
			this.props.dispatch(
				search(
					{ query, type: superCategory, page, filter: this.state.filter },
					this.state.isGlobalSearch
				)
			);
		}
	};

	render = () => {
		const articleList = this.props.articles.get('content') || List();
		const numberOfArticlesToDisplay = 12;
		const numberOfArticles = this.props.articles.get('totalElements') || 0;

		return (
			<div className={fsPageFooterPush}>
				<Container className="wrapper--search-results" size="m">
					<h1>Search Results</h1>
					<Input
						isFormField
						faIcon="faSearch"
						onChange={this.handleSearchInputChange}
						onIconClick={this.handleSearchIconClick}
						value={this.state.query}
						onKeyUp={this.handleKeyUp}
						large
					/>
				
				  {this.state.isGlobalSearch &&	(
						<SearchFilterHandler
							onFilter={this.handleFilter}
							items={filterItems}
							isMobile={this.props.isMobile}
							selectedValue={this.state.filter}
						/>)
					}

					<LoaderWrapper
						loaderError={
							!this.props.processing &&
							(this.props.error || articleList.isEmpty())
						}
						isLoading={this.props.processing}
						errorComponent={this.getNoResultsError()}
					>
						{!this.props.processing && !articleList.isEmpty() && (
							<SearchList
								articles={articleList}
								superCategory={this.state.superCategory}
								isGlobalSearch={this.state.isGlobalSearch}
							/>
						)}
					</LoaderWrapper>

					{!this.props.error && !articleList.isEmpty() &&
						numberOfArticles > numberOfArticlesToDisplay && (
							<ReactPaginate
								previousLabel={
									<FontIcon icon="faChevronLeft" iconSize="1rem" />
								}
								nextLabel={<FontIcon icon="faChevronRight" iconSize="1rem" />}
								breakLabel="..."
								breakClassName="pagination__break"
								pageCount={this.props.articles.get('totalPages')}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								onPageChange={this.handlePaginationItemClick}
								containerClassName="pagination"
								activeClassName="active"
								forcePage={this.state.page}
							/>
						)}
				</Container>

				{!this.state.isGlobalSearch && (
					<FooterContainer title="Can't find what you're looking for?">
						<Button modifier="secondary" onClick={this.handleOpenTicket}>
							Open a Ticket
						</Button>
					</FooterContainer>
				)}
			</div>
		);
	};
}

const mapStateToProps = state => ({
	articles: state.getIn(['search', 'articles']),
	processing: state.getIn(['search', 'processing']),
	error: state.getIn(['search', 'error'])
});

export default withRouter(connect(mapStateToProps)(WithResize(SearchResults)));
