import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  roles: [],
  processing: false,
  error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ROLES_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.ROLES_SUCCESS:
      return state.merge({
        processing: false,
        roles: action.data
      });
    case ActionTypes.ROLES_FAILURE:
      return state.merge({
        error: action.error,
        processing: false
      });
    default:
      return state;
  }
}
