import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	item: {},
	tasks: [],
	processing: false,
	processingScroll: false,
	processingSearch: false,
	error: null
});

const getTasks = (tasks, tasksItem) => {
	const newTasks = tasksItem.content || [];
	return tasksItem.number > 0 ? tasks.toJS().concat(newTasks) : newTasks;
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.TASKS_PROCESSING:
			return state.merge({
				processing: true,
				processingScroll: false,
				processingSearch: false
			});
		case ActionTypes.TASKS_PROCESSING_SCROLL:
			return state.merge({
				processing: false,
				processingScroll: true,
				processingSearch: false
			});
		case ActionTypes.TASKS_PROCESSING_SEARCH:
			return state.merge({
				processing: false,
				processingScroll: false,
				processingSearch: true
			});
		case ActionTypes.TASKS_SUCCESS:
			const tasks = getTasks(state.get('tasks'), action.data);

			return state.merge({
				processing: false,
				processingScroll: false,
				processingSearch: false,
				item: action.data,
				tasks: tasks
			});
		case ActionTypes.TASKS_FAILURE:
			return state.merge({
				error: action.error,
				processing: false,
				processingScroll: false,
				processingSearch: false
			});
		case ActionTypes.RESET_TASKS:
			return initialState;
		default:
			return state;
	}
}
