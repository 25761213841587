import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { notificationLookup } from 'components/layout/components/notification-panel';
import PathFinder from 'components/shared/path-finder/PathFinder';
import { fromJS } from 'immutable';
import * as GroupAPI from 'api/groups/GroupsAPI';
import * as EventAPI from 'api/events/EventAPI';

import { getNotifications, getUnreadNotificationCount } from 'api/user/NotificationAPI';
import { formatStringForUrl } from '../utils/urlUtils';

import moment from 'moment';

const notificationSuccess = ({ data }) => ({
  type: ActionTypes.NOTIFICATION_SUCCESS,
  data
});

const notificationProcessing = () => ({
  type: ActionTypes.NOTIFICATION_PROCESSING
});

const markedNotificationProcessing = () => ({
  type: ActionTypes.NOTIFICATION_MARKED_PROCESSING
});

const markedNotificationSuccess = ({ data }) => ({
  type: ActionTypes.NOTIFICATION_MARKED_SUCCESS,
  data
});

export const notificationFailure = error => ({
  type: ActionTypes.NOTIFICATION_FAILURE,
  error
});


export const fetchNotification = (page) => {
  return (dispatch) => {
    dispatch(notificationProcessing());

    return getNotifications(page)
			.then(response => dispatch(notificationSuccess(response)))
			.catch(error => dispatch(notificationFailure(error)));
  }
};

export const getUnreadCount = () => {
  return (dispatch) => {
    return getUnreadNotificationCount()
			.then(response => dispatch({ type: ActionTypes.SET_NOTIFICATION_COUNT, data: response.data }));
  }
};

export const setAcknowledgedNotification = id => {
  return dispatch => {
    dispatch(markedNotificationProcessing());
    return APIClient.post(`/my/notifications/${id}/mark_acknowledged`)
      .then(response => dispatch(markedNotificationSuccess(response)))
      .catch(error => dispatch(notificationFailure(error)));
  };
};


const notificationUrlProcessing = () => ({
  type: ActionTypes.NOTIFICATION_URL_PROCESSING
});

const notificationUrlSuccess = (url) => ({
  type: ActionTypes.NOTIFICATION_URL_SUCCESS,
  payload: url
});

const notificationUrlFailure = (error) => ({
  type: ActionTypes.NOTIFICATION_URL_FAILURE,
  payload: error
});

export const notificationUrlReset = () => ({
  type: ActionTypes.NOTIFICATION_URL_RESET
});

export const buildRedirectUrl = ({ linkParams, type }) => {
  const { umbrella, root, path, propToPath } = notificationLookup(type);
  const pathFromParams = propToPath.map(prop => linkParams.get(prop)).join('/');

  return dispatch => {
    dispatch(notificationUrlProcessing());

    if (umbrella === 'connect') {
    	if (path === '/events') {
    		let basePath, eventTitle;
    		return GroupAPI.getGroup({ groupId: linkParams.get('groupId')})
					.then(response => response.data)
					.then(group => {
						basePath = PathFinder.getBasePath({ root, group: fromJS(group) });
						eventTitle = formatStringForUrl(linkParams.get('eventTitle'));
						// provide redirect url if a sequenceId is already in linkParams
						// or if the event is canceled then the redirect url will be the connect events list page
						if (linkParams.get('sequenceId') || type.toLowerCase() === 'event canceled') {
							dispatch(notificationUrlSuccess(`${basePath}${path}/${pathFromParams}/${eventTitle}`));
							return null;
						}
						return EventAPI.getEvent({ eventId: linkParams.get('eventId')});
					})
					.then(response => {
						if (response) {
							const event = fromJS(response.data);
							const eventId = event.get('id');
							// need to get the sequence id for the next occurrence
							const sequenceId = moment(event.get('nextRecurrence')).valueOf();
							dispatch(notificationUrlSuccess(`${basePath}${path}/${eventId}/${sequenceId}/${eventTitle}`));
						}
					}, error => {
						dispatch(notificationUrlFailure(error));
					});
			}

    	else if (path === '/topics') {
    		return GroupAPI.getGroup({ groupId: linkParams.get('groupId') })
					.then(({ data }) => {
						const topicGroup = fromJS(data);
						const basePath = PathFinder.getBasePath({ root, group: topicGroup });
						const title = formatStringForUrl(linkParams.get('topicTitle'));
						dispatch(notificationUrlSuccess(`${basePath}${path}/${pathFromParams}/${title}`));
					}, error => {
						dispatch(notificationUrlFailure(error));
					});
			}
    }

    else if (umbrella === 'support') {
      dispatch(notificationUrlSuccess(`${root}${path}/${pathFromParams}`));
    }
  };
};
