import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CardListItem from './CardListItem';
import { fsCardListGroup } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';

/**
 * CardListGroup is a component that displays a card with a list of links
 * with a default limit.
 */
const CardListGroup = (props) => {
	const limitedChildren = Array.isArray(props.children)
		? props.children.slice(0, props.limit)
		: props.children;
	return (
		<ul className={cn(fsCardListGroup, 'fs-reset', props.className)}>
			{limitedChildren}
			{(!!props.moreInfoUrl || props.RouteProps) && (
				<CardListItem {...props} label={props.moreInfoLabel} />
			)}
		</ul>
	);
};

CardListGroup.propTypes = {
	limit: PropTypes.number,
	moreInfoUrl: PropTypes.string,
	moreInfoLabel: PropTypes.string,
	className: PropTypes.string
};

CardListGroup.defaultProps = {
	limit: 5,
	moreInfoLabel: 'More...'
};

export default withHide(CardListGroup);