import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import { Root } from 'components/layout';

function FourZeroOne(props) {
	return (
		<Root>
			<ErrorPage
				{...props}
				icon="faLockAltLight"
				title="No access"
				message="You do not have permission to access this."
			/>
		</Root>
	);
}

export default FourZeroOne;