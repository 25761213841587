import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	peopleQuery: '',
	storiesQuery: '',
	eventsQuery: '',
	groupsQuery: '',
	attendanceQuery: '',
	gradesQuery: '',

	storiesDropdownFilter: '',
	eventsDropdownFilter: '',

	storiesTabFilter: '',
	eventsTabFilter: '',
	groupsTabFilter: ''
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SAVE_PEOPLE_SEARCH:
			return state.merge({
				peopleQuery: action.payload
			});
		case ActionTypes.RESET_PEOPLE_SEARCH:
			return state.merge({
				peopleQuery: action.payload
			});
		case ActionTypes.SAVE_STORIES_SEARCH:
			return state.merge({
				storiesQuery: action.payload
			});
		case ActionTypes.RESET_STORIES_SEARCH:
			return state.merge({
				storiesQuery: action.payload
			});
		case ActionTypes.SAVE_EVENTS_SEARCH:
			return state.merge({
				eventsQuery: action.payload
			});
		case ActionTypes.RESET_EVENTS_SEARCH:
			return state.merge({
				eventsQuery: action.payload
			});
		case ActionTypes.SAVE_GROUPS_SEARCH:
			return state.merge({
				groupsQuery: action.payload
			});
		case ActionTypes.RESET_GROUPS_SEARCH:
			return state.merge({
				groupsQuery: action.payload
			});
		case ActionTypes.SAVE_ATTENDANCE_SEARCH:
			return state.merge({
				attendanceQuery: action.payload
			});
		case ActionTypes.RESET_ATTENDANCE_SEARCH:
			return state.merge({
				attendanceQuery: action.payload
			});
		case ActionTypes.SAVE_GRADES_SEARCH:
			return state.merge({
				gradesQuery: action.payload
			});
		case ActionTypes.RESET_GRADES_SEARCH:
			return state.merge({
				gradesQuery: action.payload
			});

		// dropdown filter
		case ActionTypes.SAVE_STORIES_DROPDOWN_FILTER:
			return state.merge({
				storiesDropdownFilter: action.payload
			});
		case ActionTypes.RESET_STORIES_DROPDOWN_FILTER:
			return state.merge({
				storiesDropdownFilter: action.payload
			});
		case ActionTypes.SAVE_EVENTS_DROPDOWN_FILTER:
			return state.merge({
				eventsDropdownFilter: action.payload
			});
		case ActionTypes.RESET_EVENTS_DROPDOWN_FILTER:
			return state.merge({
				eventsDropdownFilter: action.payload
			});

		// TAB filter
		case ActionTypes.SAVE_STORIES_TAB_FILTER:
			return state.merge({
				storiesTabFilter: action.payload
			});
		case ActionTypes.RESET_STORIES_TAB_FILTER:
			return state.merge({
				storiesTabFilter: action.payload
			});
		case ActionTypes.SAVE_EVENTS_TAB_FILTER:
			return state.merge({
				eventsTabFilter: action.payload
			});
		case ActionTypes.RESET_EVENTS_TAB_FILTER:
			return state.merge({
				eventsTabFilter: action.payload
			});
		case ActionTypes.SAVE_GROUPS_TAB_FILTER:
			return state.merge({
				groupsTabFilter: action.payload
			});
		case ActionTypes.RESET_GROUPS_TAB_FILTER:
			return state.merge({
				groupsTabFilter: action.payload
			});
		default:
			return state;
	}
}
