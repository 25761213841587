import APIClient from '../APIClient';

const basePath = '/admin';

// Topics
export const createTopic = (params) => {
	const { payload } = params;
	return APIClient.post(`${basePath}/topics`, { data: payload });
};

export const updateTopic = (params = {}) => {
	const { topicId, payload } = params;
	return APIClient.put(`${basePath}/topics/${topicId}`, { data: payload });
};

export const deleteTopic = (params = {}) => {
	const { topicId } = params;
	return APIClient.delete(`${basePath}/topics/${topicId}`);
};

// Commenting on topics
export const openCommenting = (params = {}) => {
	const { topicId } = params;
	return APIClient.post(`${basePath}/topics/${topicId}/commenting`);
};

export const closeCommenting = (params = {}) => {
	const { topicId } = params;
	return APIClient.delete(`${basePath}/topics/${topicId}/commenting`);
};

// Flagging comments
export const flagComment = (params = {}) => {
	const { topicId, commentId, payload } = params;
	return APIClient.post(`${basePath}/topics/${topicId}/comments/${commentId}/flags`, { data: payload });
};

export const updateCommentFlag = (params = {}) => {
	const { topicId, commentId, flagId, payload } = params;
	return APIClient.put(`${basePath}/topics/${topicId}/comments/${commentId}/flags/${flagId}`, { data: payload });
};


// Flagging topics
export const flagTopic = (params = {}) => {
	const { topicId, payload } = params;
	return APIClient.post(`${basePath}/topics/${topicId}/flags`, { data: payload });
};

export const updateTopicFlag = (params = {}) => {
	const { topicId, flagId, payload } = params;
	return APIClient.put(`${basePath}/topics/${topicId}/flags/${flagId}`, { data: payload });
};

