import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: [],
  processing: false,
  currentEnrollment: {},
  error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_ENROLLMENT:
      return state.merge({
        currentEnrollment: action.payload
      });
    case ActionTypes.RESET_CURRENT_ENROLLMENT:
      return state.merge({
        currentEnrollment: {},
        data: []
      });
    case ActionTypes.ENROLLMENTS_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.ENROLLMENTS_SUCCESS:
      return state.merge({
        processing: false,
        data: action.payload,
        error: {}
      });
    case ActionTypes.ENROLLMENTS_FAILURE:
      return state.merge({
        error: action.payload,
        processing: false
      });
    default:
      return state;
  }
}
