import React from 'react';
import _ from 'lodash';
import { layoutUtils } from '../../utils/layoutUtils';

export default ComposedComponent => {
	class ComponentWrapper extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				isMobile: this.isMobile(),
				isTablet: null,
				isDesktop: null,
				isHD: null,
				windowInnerWidth: window.innerWidth
			};
		}
		_isMounted = false;

		//breakpoint = 960;
		hdBreakpoint = layoutUtils.breakpoint.hd;
		desktopBreakpoint = layoutUtils.breakpoint.desktop;
		tabletBreakpoint = layoutUtils.breakpoint.tablet;
		// mobileBreakpoint = layoutUtils.breakpoint.mobile;

		trackAll = false;

		isMobile = () => window.innerWidth < this.tabletBreakpoint;

		isTablet = () =>
			window.innerWidth >= this.tabletBreakpoint &&
			window.innerWidth < this.desktopBreakpoint;

		isDesktop = () =>
			window.innerWidth >= this.desktopBreakpoint &&
			window.innerWidth < this.hdBreakpoint;

		isHD = () => window.innerWidth >= this.hdBreakpoint;

		setResizeState = () =>
			this.setState({
				isHD: this.isHD(),
				isDesktop: this.isDesktop(),
				isTablet: this.isTablet(),
				isMobile: this.isMobile(),
				windowInnerWidth: window.innerWidth
			});

		handleResize = _.debounce(() => {
			if (this._isMounted) {
				// const shouldUpdateState = this.trackAll
				//   ? this.trackAll
				//   : (
				//     this.state.isMobile !== this.isMobile()
				//   );

				this.setResizeState();
			}
		}, 250);

		setBreakpoint = breakpoint => {
			this.breakpoint = breakpoint;
		};

		setTrackAll = status => {
			this.trackAll = status;
		};

		componentDidMount = () => {
			this._isMounted = true;
			this._isMounted && this.setResizeState();
			window.addEventListener('resize', this.handleResize, false);
		};

		componentWillUnmount() {
			this._isMounted = false;
			window.removeEventListener('resize', this.handleResize, false);
		}

		render = () => (
			<ComposedComponent
				{...this.props}
				isHD={this.state.isHD}
				isDesktop={this.state.isDesktop || this.state.isHD}
				isTablet={this.state.isTablet}
				isMobile={this.state.isMobile || this.state.isTablet}
				windowInnerWidth={this.state.windowInnerWidth}
				breakpoint={this.setBreakpoint}
				trackAll={this.setTrackAll}
			/>
		);
	}
	return ComponentWrapper;
};
