import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  processing: false,
  success: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.EVENT_CANCEL_PROCESSING:
      return state.merge({
        processing: true,
        success: false
      });
    case ActionTypes.EVENT_CANCEL_SUCCESS_RESET:
      return state.merge({
        success: false
      });
    case ActionTypes.EVENT_CANCEL_SUCCESS:
      return state.merge({
        processing: false,
        data: action.payload,
        success: true,
        error: null
      });
    case ActionTypes.EVENT_CANCEL_FAILURE:
      return state.merge({
        processing: false,
        success: false,
        error: action.payload
      });
    default:
      return state;
  }
}