import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: [],
  processing: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.EVENT_CATEGORIES_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.EVENT_CATEGORIES_SUCCESS:
      return state.merge({
        processing: false,
        data: action.payload,
        error: null
      });
    case ActionTypes.EVENT_CATEGORIES_FAILURE:
      return state.merge({
        processing: false,
        error: action.payload
      });
    default:
      return state;
  }
}
