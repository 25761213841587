import { faClock, faStar } from '@fortawesome/pro-regular-svg-icons';

export default [
  {
    title: 'Classes',
    icon: 'faChalkboardTeacher',
    activeState: 'classes',
    path: '/classes',
    size: '2rem'
  },
  {
    title: 'Schedules',
    icon: faClock,
    activeState: 'schedules',
    path: '/schedules',
    size: '2rem'
  },
  {
    title: 'Attendance',
    icon: 'faClipboardCheck',
    activeState: 'attendance',
    path: '/attendance',
    size: '1.5rem'
  },
  {
    title: 'Grades',
    icon: faStar,
    activeState: 'grades',
    path: '/grades',
    size: '2rem'
  },
  {
    title: 'GPS',
    icon: 'faUserTie',
    activeState: 'gps',
    path: '/gps',
    size: '1.75rem'
  }
];