import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const learnClassesProcessing = () => ({
	type: ActionTypes.CLASSES_PROCESSING
});

const learnClassesSuccess = ({ data }) => ({
	type: ActionTypes.CLASSES_SUCCESS,
	payload: data
});

const learnClassesFailure = error => ({
	type: ActionTypes.CLASSES_FAILURE,
	payload: error
});

export const fetchLearnClasses = () => {
	return dispatch => {
		dispatch(learnClassesProcessing());

		const getRoles = APIClient.getFSO(`management/current_user/roles`);
		const getClasses = APIClient.getFSO(
			`portal/class_sections?limit=100&offset=0&chrono_ref=current&search_str=`
		);

		return Promise.all([getRoles, getClasses])
			.then(values => {
				let classes = values[1].data;
				if (classes.length > 0) {
					let studentClasses = values[0].data['class_section_roles']
						.filter(r => {
							return r.role === 'Student';
						})
						.map(r => {
							return r.class_section_id;
						});
					classes = classes.filter(c => {
						return studentClasses.includes(c.id);
					});
				}
				dispatch(learnClassesSuccess({ data: classes }));
			})
			.catch((error) => {
				dispatch(learnClassesFailure(error));
			});
	};
};
