import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	articles: [],
	processing: false,
	error: {}
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.MY_NEWS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.MY_NEWS_SUCCESS:
			return state.merge({
				processing: false,
				articles: action.data
			});
		case ActionTypes.MY_NEWS_FAILURE:
			return state.merge({
				error: action.error,
				processing: false
			});
		default:
			return state;
	}
}
