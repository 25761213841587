import * as type from './types';
import without from 'lodash/without';
import isArray from 'lodash/isArray';
import union from 'lodash/union';
import compact from 'lodash/compact';
import values from 'lodash/values';

const initialComponentState = {
	props: {},
	component: null,
	children: null
};

export const layoutState = {
	isMobile: false,
	adminActive: false,
	pageModifier: [],
	pageLoading: false,

	navigationRef: null,
	subNavigation: { ...initialComponentState, title: '' },
	navigationActive: true,

	footer: { ...initialComponentState },
	footerRef: null,
	footerActive: false,

	panel: { ...initialComponentState },
	panelActive: false,

	refHeights: {},
	calculatedContainerHeight: 0
};

const sumList = list => list.reduce((result, current) => result + current, 0);
const toArray = value => isArray(value) ? value : [value];
const getRefBoundingRect = (ref, boundingRectProp = '') => {
	if (ref === null || typeof ref === 'undefined') return 0;

	const node = ref.current ? ref.current : ref;
	if (node instanceof HTMLElement) {
		const domRect = node.getBoundingClientRect();
		return boundingRectProp ? domRect[boundingRectProp] : 0;
	}
};


function layoutReducer(state, action) {
	switch (action.type) {
		case type.RESET_REF_HEIGHT_CALCULATION:
			return {
				...state,
				calculatedContainerHeight: 0,
				refHeights: {}
			}
		case type.SET_REF_HEIGHT_CALCULATION:{
			const newState = {
				refHeights: {
					...state.refHeights,
					[action.payload.name]: getRefBoundingRect(action.payload.ref, 'height')
				}
			};
			return {
				...state,
				...newState,
				calculatedContainerHeight: sumList(values(newState.refHeights).map(num => Number(num)))
			}
		}
		// TODO may remove this
		case type.CALC_CONTAINER_HEIGHT: {
			return { ...state }
		}
		case type.RESET_PAGE_MODIFIER:
			return { ...state, pageModifier: [] };
		case type.SET_PAGE_MODIFIER:
			return {
				...state,
				pageModifier: union(state.pageModifier, compact(toArray(action.payload)))
			};
		case type.REMOVE_PAGE_MODIFIER:
			return {
				...state,
				pageModifier: without(state.pageModifier, ...compact(toArray(action.payload)))
			};
		case type.SET_NAVIGATION_ACTIVE:
			return { ...state, navigationActive: action.payload };
		case type.SET_ADMIN_ACTIVE:
			return { ...state, adminActive: action.payload };
		case type.SET_IS_MOBILE:
			return { ...state, isMobile: action.payload };
		case type.SET_NAVIGATION_REF: {
			const newState = {
				navigationRef: action.payload,
				refHeights: {
					...state.refHeights,
					navigation: getRefBoundingRect(action.payload, 'height')
				}
			};
			return {
				...state,
				...newState,
				calculatedContainerHeight: sumList(values(newState.refHeights).map(num => Number(num)))
			}
		}
		case type.LOAD_SUB_NAVIGATION:
			return { ...state, subNavigation: action.payload };
		case type.LOAD_FOOTER:
			return { ...state, footer: action.payload };
		case type.SET_FOOTER_REF: {
			const newState =  {
				footerRef: action.payload,
				refHeights: {
					...state.refHeights,
					footer: getRefBoundingRect(action.payload, 'height')
				}
			};
			return {
				...state,
				...newState,
				calculatedContainerHeight: sumList(Object.values(newState.refHeights).map(num => Number(num)))
			}
		}
		case type.SET_FOOTER_ACTIVE:
			return { ...state, footerActive: action.payload };
		case type.SET_PANEL_ACTIVE:
			return { ...state, panelActive: action.payload };
		case type.SET_PAGE_LOADING:
			return { ...state, pageLoading: action.payload };
		case type.LOAD_PANEL:
			return { ...state, panel: action.payload };
		case type.RESET_PANEL:
			return { ...state, panel: {} };
		case type.RESET_SUB_NAVIGATION:
			return { ...state, subNavigation: {} };
		default:
			return state;
	}
}

export default layoutReducer;



