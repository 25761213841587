import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { colors, FontIcon, Typography } from 'fs-toolkit-react';

class SidePanelLink extends React.PureComponent {
	static defaultProps = {
		displayForMobile: false,
		iconPosition: 'right',
		listItem: false
	};

	static propTypes = {
		active: PropTypes.bool,
		activeColor: PropTypes.string,
		activeIconColor: PropTypes.string,
		className: PropTypes.string,
		defaultColor: PropTypes.string,
		defaultIconColor: PropTypes.string,
		displayForMobile: PropTypes.bool,
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		iconPosition: PropTypes.oneOf(['left', 'right']),
		iconSize: PropTypes.string,
		iconSpace: PropTypes.string,
		listItem: PropTypes.bool,
		onClick: PropTypes.func,
		title: PropTypes.string.isRequired,
		type: PropTypes.oneOf(['connect', 'learn', 'support']),
		url: PropTypes.string
	};

	render() {
		const {
			active,
			activeColor,
			activeIconColor,
			className,
			defaultColor,
			defaultIconColor,
			displayForMobile,
			icon,
			iconPosition,
			iconSize,
			iconSpace,
			listItem,
			onClick,
			title,
			type,
			url,
			RouteComponent,
			RouteProps
		} = this.props;

		const Component = RouteComponent || 'a';
		const componentProps = RouteProps || { href: url };

		const linkColor = color => {
			return type === 'learn'
				? colors.blue
				: type === 'connect'
				? colors.lime
				: type === 'support'
				? colors.aqua
				: color;
		};

		const link = () => {
			const iconElement = (
				<span
					className="fs-link__icon flex flex-align-center"
					style={{
						color:
							active && activeIconColor
								? linkColor(activeIconColor)
								: !active && defaultIconColor
								? linkColor(defaultIconColor)
								: active && type
								? linkColor()
								: null,
						width: iconSize ? iconSize : null,
						fontSize: iconSize ? iconSize : null,
						marginLeft:
							iconSpace && iconPosition === 'right' ? iconSpace : null,
						marginRight: iconSpace && iconPosition === 'left' ? iconSpace : null
					}}
				>
					<FontIcon
						icon={icon}
						iconSize={
							iconSize ? iconSize : iconPosition === 'right' ? '1.6rem' : '2rem'
						}
					/>
				</span>
			);

			return (
				<Component
					onClick={onClick}
					className={cn(
						'fs-link',
						type && !listItem && `fs-link--${type}`,
						icon &&
							iconPosition &&
							!listItem &&
							`fs-link--icon-${iconPosition}`,
						active && !listItem && 'fs-link--active',
						!displayForMobile && !listItem && 'fs-link--desktop',
						displayForMobile && !listItem && 'fs-link--mobile'
					)}
					{...componentProps}
				>
					{icon ? (
						<span className={cn('flex', 'flex-align-center')}>
							{icon && iconPosition === 'left' && iconElement}

							<Typography
								className={cn('fs-link__title', className)}
								modifier={['title', 'medium']}
								style={
									active && activeColor
										? { color: linkColor(activeColor) }
										: !active && defaultColor
										? { color: linkColor(defaultColor) }
										: null
								}
							>
								{title}
							</Typography>

							{((icon && iconPosition !== 'left') ||
								(icon && iconPosition === 'right')) &&
								iconElement}
						</span>
					) : (
						<Typography
							className={cn('fs-link__title', className)}
							modifier={['title', 'medium']}
							style={
								active && activeColor
									? { color: linkColor(activeColor) }
									: !active && defaultColor
									? { color: linkColor(defaultColor) }
									: null
							}
						>
							{title}
						</Typography>
					)}
				</Component>
			);
		};

		return listItem ? (
			<li
				className={cn(
					'fs-link-container',
					type && `fs-link-container--${type}`,
					icon && iconPosition && `fs-link-container--icon-${iconPosition}`,
					active && 'fs-link-container--active',
					!displayForMobile && 'fs-link-container--desktop',
					displayForMobile && 'fs-link-container--mobile'
				)}
			>
				{link()}
			</li>
		) : (
			link()
		);
	}
}

export default SidePanelLink;
