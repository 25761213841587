import { Map } from 'immutable';
import compact from 'lodash';

// Code snippet from:
// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
export default function errorReducer(state = Map(), action) {
	const { type, payload } = action;
	const matches = /(.*)_(PROCESSING|FAILURE)/.exec(type);

	// not a *_PROCESSING / *_FAILURE actions, so we ignore them
	if (!matches) return state;

	const [, requestName, requestState] = matches;
	return state.merge({
		// Store errorMessage
		// e.g. stores error when receiving GET_TASKS_FAILURE
		//      else clear error when receiving GET_TASKS_PROCESSING
		[requestName]: requestState === 'FAILURE' ? payload : '',
	});
};


export const createErrorSelector = (actions) => (state) => {
	// returns the first error for actions
	// * We assume when any request fails on a page that
	//   requires multiple API calls, we shows the first error
	const [error = null] = compact(actions.map(action => state.getIn(['error', action])));
	return error;
};