import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const eventDetailProcessing = () => ({
	type: ActionTypes.EVENT_DETAIL_PROCESSING
});

const eventDetailSuccess = ({ data }) => ({
	type: ActionTypes.EVENT_DETAIL_SUCCESS,
	payload: data
});

const eventDetailFailure = error => ({
	type: ActionTypes.EVENT_DETAIL_FAILURE,
	payload: error
});

const eventDetailCategoriesProcessing = () => ({
	type: ActionTypes.EVENT_DETAIL_CATEGORIES_PROCESSING
});

const eventDetailCategoriesSuccess = ({ data }) => ({
	type: ActionTypes.EVENT_DETAIL_CATEGORIES_SUCCESS,
	payload: data
});

const eventDetailCategoriesFailure = error => ({
	type: ActionTypes.EVENT_DETAIL_CATEGORIES_FAILURE,
	payload: error
});

const fetchEventCategories = ({ dispatch, groupId }) => {
	dispatch(eventDetailCategoriesProcessing());
	return APIClient.get(`/groups/${groupId}/event_categories`);
};

const demoteEventProcessing = () => ({
	type: ActionTypes.DEMOTE_EVENT_PROCESSING
});

const demoteEventSuccess = ({ data }) => ({
	type: ActionTypes.DEMOTE_EVENT_SUCCESS,
	payload: data
});

const demoteEventFailure = error => ({
	type: ActionTypes.DEMOTE_EVENT_FAILURE,
	payload: error.response ? error.response.data : {}
});

export const demotePromotedEvent = ({ eventId }) => {
	return dispatch => {
		dispatch(demoteEventProcessing());
		return APIClient.delete(`/admin/events/${eventId}/promotion`)
			.then(response => dispatch(demoteEventSuccess(response)))
			.catch(error => dispatch(demoteEventFailure(error)));
	};
};

export const fetchEventDetails = ({ eventId, sequenceId }, options = {}) => {
	return (dispatch, getState) => {
		dispatch(eventDetailProcessing());

		return APIClient.get(`/events/${eventId}/occurrences/${sequenceId}`)
			.then(
				response => {
					dispatch(eventDetailSuccess(response));
					return fetchEventCategories({
						dispatch,
						groupId: options.groupId || getState().getIn(['app', 'group', 'id'])
					});
				},
				error => dispatch(eventDetailFailure(error))
			)
			.then(
				response => dispatch(eventDetailCategoriesSuccess(response)),
				error => dispatch(eventDetailCategoriesFailure(error))
			);
	};
};
