import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  details: {
    categories: [],
    event: {},
    tags: []
  },

  fromApi: false,
  processing: false,
  error: null,

  categories: [],
  categoriesProcessing: false,
  categoriesError: null,

  demotedEvent: {},
  demoteEventProcessing: false,
  demoteEventError: {},

  rsvp: {},
  rsvpProcessing: false,
  rsvpError: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.DEMOTE_EVENT_SUCCESS:
      return state.merge({
        demotedEvent: action.payload,
        demoteEventProcessing: false
      });
    case ActionTypes.DEMOTE_EVENT_PROCESSING:
      return state.merge({
        demoteEventProcessing: true,
      });
    case ActionTypes.DEMOTE_EVENT_FAILURE:
      return state.merge({
        demoteEventError: action.payload,
        demoteEventProcessing: false
      });
    case ActionTypes.EVENT_DETAIL_PROCESSING:
      return state.merge({
        processing: true,
        fromApi: false,
      });
    case ActionTypes.EVENT_DETAIL_SUCCESS:
      return state.merge({
        processing: false,
        details: action.payload,
        error: null,
        fromApi: true,
      });
    case ActionTypes.EVENT_DETAIL_FAILURE:
      return state.merge({
        processing: false,
        error: action.payload,
        fromApi: false
      });
    case ActionTypes.EVENT_DETAIL_CATEGORIES_SUCCESS:
      return state.merge({
        categories: action.payload,
        categoriesProcessing: false,
        categoriesError: null
      });
    case ActionTypes.EVENT_DETAIL_CATEGORIES_PROCESSING:
      return state.merge({
        categoriesProcessing: true
      });
    case ActionTypes.EVENT_DETAIL_CATEGORIES_FAILURE:
      return state.merge({
        categoriesProcessing: false,
        categoriesError: action.payload
      });
    case ActionTypes.EVENT_RSVP_CANCEL_PROCESSING:
      return state.merge({
        rsvpProcessing: true
      });
    case ActionTypes.EVENT_RSVP_CANCEL_SUCCESS:
      return state.merge({
        rsvpProcessing: false,
        rsvp: fromJS({}),
        rsvpError: {}
      }).setIn(['details', 'rsvpCount'], state.getIn(['details', 'rsvpCount']) - 1);
    case ActionTypes.EVENT_RSVP_CANCEL_FAILURE:
      return state.merge({
        rsvpProcessing: false,
        rsvpError: action.payload
      });
    case ActionTypes.EVENT_RSVP_CREATE_PROCESSING:
      return state.merge({
        rsvpProcessing: true
      });
    case ActionTypes.EVENT_RSVP_CREATE_SUCCESS:
      return state.merge({
        rsvpProcessing: false,
        rsvp: fromJS(action.payload),
        rsvpError: {}
      }).setIn(['details', 'rsvpCount'], state.getIn(['details', 'rsvpCount']) + 1);
    case ActionTypes.EVENT_RSVP_CREATE_FAILURE:
      return state.merge({
        rsvpProcessing: false,
        rsvpError: action.payload
      });
    case ActionTypes.MY_EVENT_RSVP_SUCCESS:
      return state.merge({ rsvp: fromJS(action.payload) });
    case ActionTypes.RSVP_REGISTRATION_SUCCESS:
      return state.merge({ rsvp: fromJS(action.payload) });
    case ActionTypes.EVENT_DETAIL_RESET:
      return initialState;
    default:
      return state;
  }
}
