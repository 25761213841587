import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { fsCardListItem } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';
import { withClamp } from './helpers/withClamp';
import { forwardRefWrapper } from 'utils/forwardRefWrapper';
import { CardContext } from './CardContext';
import { Typography } from 'fs-toolkit-react';

/**
 * Card list item is the list item used within the card list group
 */
function CardListItem({
	url = '',
	className,
	label = '',
	innerRef,
	RouteProps = null
}) {
	const props = { ref: innerRef };
	return (
		<CardContext.Consumer>
			{({ RouteComponent }) => {
				const Tag = RouteComponent || 'a';
				const linkProps = RouteProps ? RouteProps : { href: url };
				return (
					<li className={cn(fsCardListItem, className)}>
						<Typography component={Tag} {...props} {...linkProps}>
							{label}
						</Typography>
					</li>
				);
			}}
		</CardContext.Consumer>
	);
}

CardListItem.propTypes = {
	RouteProps: PropTypes.element,
	label: PropTypes.string,
	className: PropTypes.string,
	url: PropTypes.string
};

CardListItem.defaultProps = {
	className: ''
};

export default withClamp(forwardRefWrapper(withHide(CardListItem), 'innerRef'))(
	1
);
