import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import { Root } from 'components/layout';

function TokenExpired(props) {
	return (
		<Root>
			<ErrorPage
				{...props}
				hideFooter
				icon="faSadTearLight"
				title="Login Expired"
				message={'Unable to login due to an expired token. Please perform a browser reset and make sure your device\'s clock is set to your current time zone. If you are still experiencing issues, please <a href="mailto:technicalsupport@fullsail.edu?Subject=FS%20One%20Token%20Expired">contact support</a>.'}
			/>
		</Root>
	);
}

export default TokenExpired;