import { isAdmin } from 'utils/profileUtils';
import { useGroupId } from 'components/hooks';
import { useSelector } from 'react-redux';

function useAdmin(adminType) {
	const groupId = useGroupId();
	const profile = useSelector(state => state.getIn(['profile', 'data']));
	return !profile.isEmpty() && isAdmin({ profile, adminType, groupId });
}

export default useAdmin;