import { Map } from 'immutable';

// Code snippet from:
// https://medium.com/stashaway-engineering/react-redux-tips-better-way-to-handle-loading-flags-in-your-reducers-afda42a804c6
export default function loaderReducer(state = Map(), action) {
	const { type } = action;
	const matches = /(.*)_(PROCESSING|SUCCESS|FAILURE)/.exec(type);

	// not a *_PROCESSING / *_SUCCESS /  *_FAILURE actions, so we ignore them
	if (!matches) return state;

	const [, requestName, requestState] = matches;
	return state.merge({
		// Store whether a request is happening at the moment or not
		// e.g. will be true when receiving GET_TASKS_PROCESSING
		//      and false when receiving GET_TASKS_SUCCESS / GET_TASKS_FAILURE
		[requestName]: requestState === 'PROCESSING',
	});
}

export const createLoaderSelector = (actions) => (state) => {
	return actions.some((action) => state.getIn(['loader', action]));
};
