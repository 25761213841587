import pick from 'lodash/pick';
import { List, Map } from 'immutable';
import { isAdmin, isGroupOwner } from 'utils/profileUtils';

const featuresFriendlyName = {
	topicFeature: 'Topics',
	newsFeature: 'Stories',
	eventFeature: 'Events'
};

const pickFeatureKeysFromGroup = group => {
	return Object.keys(
		pick(group.toJS(), 'topicFeature', 'newsFeature', 'eventFeature')
	);
};

export const getGroupFeatures = group => {
	const on = [];
	const off = [];

	pickFeatureKeysFromGroup(group).forEach(f => {
		if (group.get(f)) {
			on.push(featuresFriendlyName[f]);
		} else {
			off.push(featuresFriendlyName[f]);
		}
	});

	return {
		on,
		off,
		has: f => on.includes(f)
	};
};

const getSegmentIds = entity => {
	return entity.has('segments')
		? entity.get('segments').map(s => s.get('id'))
		: List();
};

export const userHasLimitedAccess = (profile, group) => {
	const profileSegmentIds = getSegmentIds(profile);
	const groupSegmentIds = getSegmentIds(group);
	const membership = getMembershipFromProfile(profile, group.get('id'));

	return (
		!isAdmin({
			profile,
			groupId: group.get('id'),
			adminType: 'groupInfoAdmin'
		}) &&
		!isOwner(profile, group.get('id')) &&
		!profileSegmentIds.some(psId => groupSegmentIds.includes(psId)) &&
		!membership.get('systemGenerated')
	);
};

export const getMembershipFromProfile = (profile, groupId) => {
	return (
		profile
			.get('memberships')
			.find(membership => membership.get('groupId') === groupId) || Map()
	);
};

export const isOwner = (profile, groupId) => {
	let membership = getMembershipFromProfile(profile, groupId);
	return !membership.isEmpty() ? isGroupOwner(membership) : false;
};

export const isSteward = member => {
	const flair = member.get('flair');
	return flair && flair.includes('Steward');
};

export function getStewardship(userId = '', memberships = List()) {
	if (!memberships.isEmpty()) {
		const member = memberships.find(member => member.getIn(['profile', 'fsOneId']) === userId);
		return member ? member.get('flair').find(flair => flair === 'Steward') : undefined;
	}
}
