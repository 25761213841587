import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useHistory } from 'react-router-dom';
import { ButtonRefactor as Button, Typography } from 'fs-toolkit-react';

export default function TaskFooter({ taskItem, error }) {
	const history = useHistory();
	const navigate = () => history.push(urlPath);
	const navItems = {
		next: {
			directions: 'Up next',
			anchor: taskItem && taskItem.getIn(['task', 'displayName']),
			urlPath: taskItem && `/tasks/${taskItem.get('id')}`
		},
		back: {
			directions: 'You have reached the end',
			anchor: 'Back to Tasks',
			urlPath: '/tasks'
		},
		error: {
			directions: 'There was an error getting the next task',
			anchor: 'Back to Tasks',
			urlPath: '/tasks'
		}
	};

	const getNavigationItems = () => {
		if (error) {
			return navItems.error;
		}

		return taskItem ? navItems.next : navItems.back;
	};

	const { directions, anchor, urlPath } = getNavigationItems();

	return (
		<div className="task-footer">
			<Typography modifier='label'>{directions}</Typography>
			<Button modifier='text' onClick={navigate}>
				<Typography component='h3'>{anchor}</Typography>
			</Button>
		</div>
	);
}

TaskFooter.propTypes = {
	taskItem: PropTypes.instanceOf(Map)
};
