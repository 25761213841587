import React from 'react';
import cn from 'classnames';
import NotificationHeader from './NotificationHeader';

class NotificationPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.panelRef = React.createRef();
  }

  handleClickOutsidePanel = (event) => {
    if (this.panelRef && this.panelRef.current && !this.panelRef.current.contains(event.target)) {
      this.props.onDismiss();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsidePanel);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsidePanel);
  }

  render() {
    const { children, onDismiss, label, isOpen } = this.props;
    return (
      <div ref={this.panelRef} className={cn(isOpen && 'notification-panel--open', 'notification-panel')}>
        <NotificationHeader label={label} onDismiss={onDismiss}/>
        {children}
      </div>
    );
  }
}

export default NotificationPanel;