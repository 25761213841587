import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsCardCategory } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';
import { colors, Typography } from 'fs-toolkit-react';
import { withClamp } from './helpers/withClamp';
import { forwardRefWrapper } from 'utils/forwardRefWrapper';
import { CardContext } from './CardContext';

const CardCategory = ({ label, url, className, innerRef }) => {
	const Tag = url ? 'a' : 'span';
	const props = { ref: innerRef };
	const categoryProps = {
		...(url ? { href: url } : {})
	};

	return (
		<Typography
			component={Tag}
			color={colors.gray}
			modifier={['meta', 'medium']}
			className={cn(fsCardCategory, className)}
			{...props}
			{...categoryProps}
		>
			{label}
		</Typography>
	);
};

CardCategory.context = CardContext;

CardCategory.propTypes = {
	lines: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			mobile: PropTypes.number,
			desktop: PropTypes.number
		})
	]),
	style: PropTypes.object,
	url: PropTypes.string,
	label: PropTypes.string,
	className: PropTypes.string
};

CardCategory.defaultProps = {
	lines: 1,
	url: '',
	label: '',
	className: ''
};

export default withClamp(forwardRefWrapper(withHide(CardCategory), 'innerRef'))(
	1
);
