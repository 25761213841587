import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'fs-toolkit-react';
import { Card, CardGroup } from 'components/patterns/card';
import { notificationLookup } from './utils';
import moment from 'moment';
import { List, Map } from 'immutable';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

class NotificationList extends React.Component {
	static propTypes = {
		content: PropTypes.instanceOf(List).isRequired,
		onCardClick: PropTypes.func.isRequired
	};

	render() {
		return (
			<CardGroup modifier="notification">
				{!this.props.content.isEmpty() ? (
					this.props.content.map(
						notification =>
							Map.isMap(notification) &&
							!notification.isEmpty() && (
								<Card
									read={!!notification.get('readAt')}
									key={notification.get('id')}
									type="notification"
									onClick={this.props.onCardClick({
										id: notification.get('id'),
										readAt: notification.get('readAt'),
										linkParams: notification.get('linkParams'),
										type: notification.get('type')
									})}
									modifier="notification"
								>
									<Card.Icon
										icon={notificationLookup(
											notification.get('type'),
											'icon',
											'faDotCircle'
										)}
										// iconColor={colors.gray}
										iconSize={notificationLookup(
											notification.get('type'),
											'iconSize',
											'faDotCircle'
										)}
										position="left"
									/>
									<Card.Body>
										<Card.Title lines={2}>
											{notification.get('content')}
										</Card.Title>
										<Card.Meta lines={1}>
											{moment(
												new Date(notification.get('createdAt'))
											).fromNow()}
										</Card.Meta>
									</Card.Body>
									<Card.Icon
										icon={faCircle}
										iconColor={notificationLookup(
											notification.get('type'),
											'color'
										)}
										iconSize="1rem"
										position="right"
									/>
								</Card>
							)
					)
				) : (
					<Typography className="notification-panel__default-text">
						{this.props.defaultText}
					</Typography>
				)}
			</CardGroup>
		);
	}
}

export default NotificationList;
