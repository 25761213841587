import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import Raven from 'raven-js';
import { Root } from 'components/layout';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Raven.captureException(error);
  }

  render() {
		if (this.state.hasError) {
      return (
        <Root>
				  <ErrorPage />
        </Root>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
