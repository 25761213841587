import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toParams } from 'utils/globalUtils';
import { growl, types } from 'utils/errorUtils';

const loadSegmentsProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_SEGMENTS_PROCESSING
});

const loadSegmentsSuccess = ({ data }) => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_SEGMENTS_SUCCESS,
	payload: data
});

const loadSegmentsFailure = error => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_SEGMENTS_FAILURE,
	payload: error
});

export const loadSegments = ({ page, query, filter, limit = 12 }) => {
	return dispatch => {
		dispatch(loadSegmentsProcessing());

		return APIClient.get(
			`/admin/segments?${toParams({
				page: page,
				query: query,
				filter: filter,
				limit: limit
			})}`
		)
			.then(response => dispatch(loadSegmentsSuccess(response)))
			.catch(error => dispatch(loadSegmentsFailure(error)));
	};
};

const loadSegmentProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_SEGMENT_FORM_PROCESSING
});

const loadSegmentSuccess = responses => {
	return {
		type: ActionTypes.TOOLS_GROUPER_LOAD_SEGMENT_FORM_SUCCESS,
		payload: responses[3].data,
		options: {
			audience: responses[0].data.filter(d => d.value !== 'Public'), // don't want the public audience showing
			degree: responses[1].data,
			journey: responses[2].data
		}
	};
};

const loadSegmentFailure = error => {
	return {
		type: ActionTypes.TOOLS_GROUPER_LOAD_SEGMENT_FORM_FAILURE,
		payload: error
	};
};

export const loadSegment = id => {
	return dispatch => {
		dispatch(loadSegmentProcessing());

		const audienceOptions = APIClient.get(
			'/admin/segment_condition_options/audience'
		);
		const degreeOptions = APIClient.get(
			'/admin/segment_condition_options/degree'
		);
		const journeyOptions = APIClient.get(
			'/admin/segment_condition_options/journey'
		);
		const segmentData =
			id === null || id === undefined
				? { data: {} }
				: APIClient.get(`/admin/segments/${id}`);

		return Promise.all([
			audienceOptions,
			degreeOptions,
			journeyOptions,
			segmentData
		])
			.then(responses => dispatch(loadSegmentSuccess(responses)))
			.catch(error => dispatch(loadSegmentFailure(error)));
	};
};

const createSegmentProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_CREATE_SEGMENT_PROCESSING
});

const createSegmentSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_CREATE_SEGMENT_SUCCESS,
		payload: data
	};
};

const createSegmentFailure = error => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_CREATE_SEGMENT_FAILURE,
		payload: error
	};
};

export const createSegment = () => {
	return (dispatch, getState) => {
		dispatch(createSegmentProcessing());
		const data = {
			title: getState().getIn(['segment', 'title']),
			description: getState().getIn(['segment', 'description']),
			conditions: getState().getIn(['segment', 'conditions'])
		};
		return APIClient.post('/admin/segments', { data }).then(
			({ data }) => dispatch(createSegmentSuccess(data)),
			({ response: { data } }) => dispatch(createSegmentFailure(data))
		);
	};
};

const deleteSegmentProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_DELETE_SEGMENT_PROCESSING
});

const deleteSegmentSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_DELETE_SEGMENT_SUCCESS,
		payload: data
	};
};

const deleteSegmentFailure = error => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_DELETE_SEGMENT_FAILURE,
		payload: error
	};
};

export const deleteSegment = id => {
	return dispatch => {
		dispatch(deleteSegmentProcessing());
		return APIClient.delete(`/admin/segments/${id}`).then(
			({ data }) => dispatch(deleteSegmentSuccess(data)),
			({ response: { data } }) => dispatch(deleteSegmentFailure(data))
		);
	};
};

const updateSegmentProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_UPDATE_SEGMENT_PROCESSING
});

const updateSegmentSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_UPDATE_SEGMENT_SUCCESS,
		payload: data
	};
};

const updateSegmentFailure = error => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_UPDATE_SEGMENT_FAILURE,
		payload: error
	};
};

export const updateSegment = id => {
	return (dispatch, getState) => {
		dispatch(updateSegmentProcessing());
		const data = {
			title: getState().getIn(['segment', 'title']),
			description: getState().getIn(['segment', 'description']),
			conditions: getState().getIn(['segment', 'conditions'])
		};
		return APIClient.put(`/admin/segments/${id}`, { data }).then(
			({ data }) => dispatch(updateSegmentSuccess(data)),
			({ response: { data } }) => dispatch(updateSegmentFailure(data))
		);
	};
};

const loadRulesProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_RULES_PROCESSING
});

const loadRulesSuccess = ({ data }) => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_RULES_SUCCESS,
	payload: data
});

const loadRulesFailure = error => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_RULES_FAILURE,
	payload: error
});

export const loadRules = (page, query) => {
	return dispatch => {
		dispatch(loadRulesProcessing());

		return APIClient.get(
			`/admin/rules?${toParams({ page: page, query: query })}`
		)
			.then(response => dispatch(loadRulesSuccess(response)))
			.catch(error => dispatch(loadRulesFailure(error)));
	};
};

const loadRuleProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_LOAD_RULE_FORM_PROCESSING
});

const loadRuleSuccess = responses => {
	return {
		type: ActionTypes.TOOLS_GROUPER_LOAD_RULE_FORM_SUCCESS,
		payload: responses[2].data,
		options: {
			segments: responses[0].data.content,
			groups: responses[1].data.content.filter(d => d.type !== 'University')
		}
	};
};

const loadRuleFailure = error => {
	return {
		type: ActionTypes.TOOLS_GROUPER_LOAD_RULE_FORM_FAILURE,
		payload: error
	};
};

export const loadRule = id => {
	return dispatch => {
		dispatch(loadRuleProcessing());

		const segmentOptions = APIClient.get('/admin/segments?limit=100000');
		const groupOptions = APIClient.get('/groups?limit=100000');
		const ruleData =
			id === null || id === undefined
				? { data: {} }
				: APIClient.get(`/admin/rules/${id}`);

		return Promise.all([segmentOptions, groupOptions, ruleData])
			.then(responses => dispatch(loadRuleSuccess(responses)))
			.catch(error => dispatch(loadRuleFailure(error)));
	};
};

const createRuleProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_CREATE_RULE_PROCESSING
});

const createRuleSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_CREATE_RULE_SUCCESS,
		payload: data
	};
};

const createRuleFailure = error => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_CREATE_RULE_FAILURE,
		payload: error
	};
};

export const createRule = () => {
	return (dispatch, getState) => {
		dispatch(createRuleProcessing());
		const data = {
			title: getState().getIn(['rule', 'title']),
			description: getState().getIn(['rule', 'description']),
			segmentIds: getState()
				.getIn(['rule', 'segmentIds'])
				.map(segmentId => parseInt(segmentId, 10)),
			executors: getState()
				.getIn(['rule', 'executors'])
				.map(executor =>
					executor.set('groupId', parseInt(executor.get('groupId'), 10))
				)
		};
		return APIClient.post('/admin/rules', { data }).then(
			({ data }) => dispatch(createRuleSuccess(data)),
			({ response: { data } }) => dispatch(createRuleFailure(data))
		);
	};
};

const deleteRuleProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_DELETE_RULE_PROCESSING
});

const deleteRuleSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_DELETE_RULE_SUCCESS,
		payload: data
	};
};

const deleteRuleFailure = error => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_DELETE_RULE_FAILURE,
		payload: error
	};
};

export const deleteRule = id => {
	return dispatch => {
		dispatch(deleteRuleProcessing());
		return APIClient.delete(`/admin/rules/${id}`).then(
			({ data }) => dispatch(deleteRuleSuccess(data)),
			({ response: { data } }) => dispatch(deleteRuleFailure(data))
		);
	};
};

const updateRuleProcessing = () => ({
	type: ActionTypes.TOOLS_GROUPER_UPDATE_RULE_PROCESSING
});

const updateRuleSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_UPDATE_RULE_SUCCESS,
		payload: data
	};
};

const updateRuleFailure = error => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_UPDATE_RULE_FAILURE,
		payload: error
	};
};

export const updateRule = id => {
	return (dispatch, getState) => {
		dispatch(updateRuleProcessing());
		const data = {
			title: getState().getIn(['rule', 'title']),
			description: getState().getIn(['rule', 'description']),
			segmentIds: getState()
				.getIn(['rule', 'segmentIds'])
				.map(segmentId => parseInt(segmentId, 10)),
			executors: getState()
				.getIn(['rule', 'executors'])
				.map(executor =>
					executor.set('groupId', parseInt(executor.get('groupId'), 10))
				)
		};
		return APIClient.put(`/admin/rules/${id}`, { data }).then(
			({ data }) => dispatch(updateRuleSuccess(data)),
			({ response: { data } }) => dispatch(updateRuleFailure(data))
		);
	};
};

const toggleRuleActiveStateProcessing = id => ({
	type: ActionTypes.TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_PROCESSING,
	id
});

const toggleRuleActiveStateSuccess = data => {
	return {
		type: ActionTypes.TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_SUCCESS,
		payload: data
	};
};

const toggleRuleActiveStateFailure = (id, error) => {
	growl(types.error, error.message);
	return {
		type: ActionTypes.TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_FAILURE,
		payload: error,
		id
	};
};

export const toggleRuleActiveState = (id, active) => {
	return dispatch => {
		dispatch(toggleRuleActiveStateProcessing(id));
		return APIClient.put(
			`/admin/rules/${id}/${active ? 'activate' : 'deactivate'}`
		).then(
			({ data }) => dispatch(toggleRuleActiveStateSuccess(data)),
			({ response: { data } }) =>
				dispatch(toggleRuleActiveStateFailure(id, data))
		);
	};
};
