import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  group: {},
  processing: false,
  error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_GROUP_PROCESSING:
      return state.merge({
        processing: true,
        error: {}
      });
    case ActionTypes.GET_GROUP_SUCCESS:
      return state.merge({
        processing: false,
        group: action.payload,
        error: {},
      });
    case ActionTypes.GET_GROUP_FAILURE:
      return state.merge({
        error: action.payload,
        processing: false
      });
    case ActionTypes.GET_GROUP_RESET:
      return state.merge({
        error: {},
        processing: false,
        group: {}
      });
    default:
      return state;
  }
}
