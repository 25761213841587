import ActionTypes from 'actions/actionTypes';
import moment from 'moment';
import { getUnreadNotificationCount } from "api/user/NotificationAPI";
import { getMyProfile } from "api/user/UserAPI";
import { getEventOccurrence } from 'api/events/EventAPI';
import { getNewRsvps, getUpdatedRsvps, getCanceledRsvps, getMyRsvp } from 'api/events/RsvpAPI';
import * as TopicAPI from 'api/topics/TopicsAPI';
import { commentingActions } from 'state/ducks/commenting';

export default function FallbackPoller(dispatch) {
    this.dispatch = dispatch;
    this.isRunning = false;
    // map of the channel to the supporting poller/interval reference
    this.registeredChannels = {};

    this.start = () => {
        if(!this.isRunning){
            Object.keys(this.registeredChannels).forEach(channel => {
                // check that a poller isn't already assigned; don't need more than one per channel
                if(!this.registeredChannels[channel]){
                    this.registeredChannels[channel] = generatePoller(channel);
                }
            });
        }
        this.isRunning = true;
    }

    this.stop = () => {
        if(this.isRunning){
            Object.keys(this.registeredChannels).forEach(channel => {
                if(this.registeredChannels[channel]){
                    // if multiple pollers are associated with the channel, stop them all
                    if(Array.isArray(this.registeredChannels[channel])){
                        this.registeredChannels[channel].forEach((chan) => {
                            clearInterval(chan);
                        })
                    } else {
                        clearInterval(this.registeredChannels[channel]);
                    }
                    this.registeredChannels[channel] = null;
                }
            });
        }
        this.isRunning = false;
    }

    this.register = (channel) => {
        // if the channel isn't already registered with the poller
        if(!this.registeredChannels.hasOwnProperty(channel)){
            // if the fallback is currently running, generate the interval for this channel
            if(this.isRunning){
                this.registeredChannels[channel] = generatePoller(channel);
            } else {
                this.registeredChannels[channel] = null;
            }
        }
    }

    this.unregister = (channel) => {
        // make sure the channel is registered
        if(this.registeredChannels.hasOwnProperty(channel)){
            // if the fallback is running, need to stop the interval first before removing the 
            if(this.isRunning && this.registeredChannels[channel]){
                // if multiple pollers are associated with the channel, stop them all
                if(Array.isArray(this.registeredChannels[channel])){
                    this.registeredChannels[channel].forEach((chan) => {
                        clearInterval(chan);
                    })
                } else {
                    clearInterval(this.registeredChannels[channel]);
                }
            }
            // remove the channel key
            delete this.registeredChannels[channel];
        }
    }

    const generatePoller = (channel) => {
        if(channel.match("/user/queue/updates")){
            // the user channel has updates that need to be scheduled on different intervals, so return an array of pollers
            let userPollers = [];
            // every 60 secs look for new notifications
            userPollers.push(setInterval(() => {
                getUnreadNotificationCount().then((response) => this.dispatch({ type: ActionTypes.SET_NOTIFICATION_COUNT, data: response.data })).catch((err) => {});
            }, 30000));
            // every 5 mins refresh the profile
            userPollers.push(setInterval(() => {
                getMyProfile().then((response) => this.dispatch({ type: ActionTypes.PROFILE_SUCCESS, data: response.data })).catch((err) => {});
            }, 300000));
            return userPollers;
        } else if (channel.match("/topic/fsone/topics/\\d+")) {
        	// Topics
            const topicId = channel.match("topics/\\d+")[0].split("/")[1];
            const ts = moment().subtract(30000, 'milliseconds').unix() * 1000;
            return setInterval((topicId) => {
                TopicAPI.getUpdatedComments({ topicId, ts })
                    .then(({ data }) => this.dispatch(commentingActions.updateComments(data)));
                TopicAPI.getNewComments({ topicId, ts })
                    .then(({ data }) => this.dispatch(commentingActions.newComments(data)));
            }, 30000, topicId);
        } else if (channel.match("/topic/fsone/admin/topics/\\d+")) {
        	// Topics Admin
            const topicId = channel.match("topics/\\d+")[0].split("/")[1];
            const ts = moment().subtract(30000, 'milliseconds').unix() * 1000;
            return setInterval((topicId) => {
                TopicAPI.getUpdatedComments({ topicId, ts })
                    .then(({ data }) => this.dispatch(commentingActions.updateComments(data)));
                TopicAPI.getNewComments({ topicId, ts })
                    .then(({ data }) => this.dispatch(commentingActions.newComments(data)));
            }, 30000, topicId);
        } else if (channel.match("/topic/fsone/admin/events/\\d+/occurrences/\\d+")) {
            const eventId = channel.match("events/\\d+")[0].split("/")[1];
            const sequenceId = channel.match("occurrences/\\d+")[0].split("/")[1];
            return setInterval((eventId, sequenceId) => {
                const ts = moment().subtract(30,'seconds').unix() * 1000;
                getEventOccurrence(eventId, sequenceId).then((response) => this.dispatch({ type: ActionTypes.SCANNER_EVENT_SUCCESS, data: response.data })).catch((err) => {});
                getNewRsvps(eventId, sequenceId, ts).then((response) => this.dispatch({ type: ActionTypes.EVENT_RSVP_UPDATES, data: response.data })).catch((err) => {});
                getUpdatedRsvps(eventId, sequenceId, ts).then((response) => this.dispatch({ type: ActionTypes.EVENT_RSVP_UPDATES, data: response.data })).catch((err) => {});
                getCanceledRsvps(eventId, sequenceId, ts).then((response) => this.dispatch({ type: ActionTypes.EVENT_RSVP_CANCELATIONS, data: response.data })).catch((err) => {});
            }, 30000, eventId, sequenceId);
        } else if(channel.match("/topic/fsone/events/\\d+/occurrences/\\d+")) {
            const eventId = channel.match("events/\\d+")[0].split("/")[1];
            const sequenceId = channel.match("occurrences/\\d+")[0].split("/")[1];
            return setInterval((eventId, sequenceId) => {
                getEventOccurrence(eventId, sequenceId).then((response) => this.dispatch({ type: ActionTypes.EVENT_DETAIL_SUCCESS, payload: response.data })).catch((err) => {});
                getMyRsvp(eventId, sequenceId).then((response) => this.dispatch({ type: ActionTypes.MY_EVENT_RSVP_SUCCESS, payload: response.data })).catch((err) => {});
            }, 60000, eventId, sequenceId);
         } else {
            // return null for channels that don't have a polling function.
            // this allows the unregister to handle this channel properly
            return null;
        }
    }
};