import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	status: false
});

export default function(state = initialState, action) {
	if (action.type === ActionTypes.SET_BYPASS_PAGE_LOADER_STATUS) {
		return state.merge({
			status: action.payload
		});
	}
	return state;
}