import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { faPenFancy } from '@fortawesome/pro-light-svg-icons';
import { ButtonRefactor as Button, Modal } from 'fs-toolkit-react';
import WithResize from 'components/HOC/WithResize';
import { IconMessage, Container } from 'components/layout';

function TaskEsignLanding({ isMobile }) {
	const { id } = useParams();
	const history = useHistory();
	const [modal, toggleModal] = useState({ open: false });

	const handleSignClick = ({ agreed = 'true' }) => e => {
		history.push(`/tasks/${id}?agreed=${agreed}`);
	};

	const handleToggleModal = e => {
		toggleModal({ open: !modal.open });
	};

	return (
		<Container className="wrapper--task-esign" size="s">
			<IconMessage
				icon={faPenFancy}
				title="How do you want to sign your documents?"
				message="Electronic signatures are the easy, fast, and secure way to sign. <br/>Alternatively, you can print, sign, and upload or fax your documents."
			>
				<div style={{ marginBottom: '24px' }}>
					<Button
						onClick={handleSignClick({})}
						className="esign-button"
						modifier={[isMobile && 'full']}
					>
						I want to sign electronically
					</Button>
				</div>
				<Button modifier="text" onClick={handleToggleModal}>
					No, I’ll print and upload or fax instead
				</Button>
			</IconMessage>
			{modal.open && (
				<Modal width="40%" height="40%" onClose={handleToggleModal}>
					<Modal.Header title="Are you sure?" />
					<p>
						If you print, sign, and upload/fax, it will take longer and require
						more steps. It is easy, fast, and safe to sign electronically –
						please call 800.226.7625 if you have any concerns or questions.
					</p>
					<Modal.Footer
						primaryButtonText="Yes, print and sign"
						onPrimaryButtonClick={handleSignClick({ agreed: 'false' })}
					>
						<Button modifier="secondary" onClick={handleToggleModal}>
							No, eSign instead
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</Container>
	);
}

export default WithResize(TaskEsignLanding);
