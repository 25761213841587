import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
	fsCardHeader,
	fsCardTitle,
	fsCardHeaderTitle
} from './helpers/cardClassNameList';
import { findParentComponent } from 'utils/findParentComponent';
import { CardContext } from './CardContext';
import { withClamp } from './helpers/withClamp';
import { forwardRefWrapper } from 'utils/forwardRefWrapper';
import { withHide } from './helpers/withHide';
import { Typography } from 'fs-toolkit-react';

/**
 * Card title component
 */
class CardTitle extends React.Component {
	static contextType = CardContext;

	static propTypes = {
		url: PropTypes.string,
		className: PropTypes.string,
		lines: PropTypes.oneOfType([
			PropTypes.number,
			PropTypes.shape({
				mobile: PropTypes.number,
				desktop: PropTypes.number
			})
		]),
		style: PropTypes.object
	};

	static defaultProps = {
		url: '',
		className: ''
	};

	state = {
		titleClassName: ''
	};

	componentDidMount() {
		const titleClassName = findParentComponent(
			fsCardHeader,
			this.props.innerRef
		)
			? fsCardHeaderTitle
			: fsCardTitle;
		this.setState({ titleClassName });
	}

	render() {
		const {
			url,
			children,
			className,
			style,
			innerRef,
			modifier,
			RouteProps = null
		} = this.props;
		const props = { ref: innerRef };
		let linkProps = {};
		let Tag = 'span';

		return (
			<CardContext.Consumer>
				{({ RouteComponent }) => {
					if (url) {
						linkProps = { href: url };
						Tag = 'a';
					} else if (RouteProps) {
						linkProps = RouteProps;
						Tag = RouteComponent;
					}
					return (
						<Typography
							component={Tag}
							modifier={modifier ? modifier : 'title'}
							className={cn(this.state.titleClassName, className)}
							style={style}
							{...props}
							{...linkProps}
						>
							{children}
						</Typography>
					);
				}}
			</CardContext.Consumer>
		);
	}
}

const mapResponsiveClamp = { mobile: 1, desktop: 2 };

export default withClamp(forwardRefWrapper(withHide(CardTitle), 'innerRef'))(
	mapResponsiveClamp
);
