import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonRefactor as Button, Card, ToggleSwitch } from 'fs-toolkit-react';
import { Container } from 'components/layout';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import SearchInput from 'components/shared/searchInput/SearchInput';
import InfiniteScroller from 'components/shared/InfiniteScroller';
import Error from 'components/views/error/Error';
import ActionTypes from 'actions/actionTypes';
import { loadRules, toggleRuleActiveState } from 'actions/grouperActions';
import { List, Map } from 'immutable';
import { formatDate} from 'utils/dateTimeUtils';

class Rules extends React.Component {
    componentDidMount(){
        this._fetch(0);
    }

    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch({ type: ActionTypes.TOOLS_GROUPER_RULES_RESET });
    }

    handleSearch = () => {
        this._fetch(0);
    }

    handleKeyup = (evt) => {
        if (evt.key === 'Enter') {
			this.handleSearch();
		}
    }

    navigateToEdit = (id, evt) => {
        if(evt.target.className !== "fs-form-field__toggle-custom" 
           && evt.target.className !==  "fs-form-field__toggle-input"){
            this.props.history.push(`/tools/grouper/rules/${id}`);
        }
    }

    handleSearchChange = (evt) => {
        const { dispatch } = this.props;
        dispatch({ type: ActionTypes.TOOLS_GROUPER_CHANGE_SEARCH, query: evt.currentTarget.value });
    }

    fetchMoreRules = () => {
        const { page, dispatch } = this.props;
        const nextPage = page + 1;
        dispatch({ type: ActionTypes.TOOLS_GROUPER_INCREMENT_PAGE, page: nextPage });
        setTimeout(() => this._fetch(nextPage), 100);
    }


    _fetch = (page) => {
        const { dispatch, query } = this.props;
        dispatch(loadRules(page, query.trim()));
    }

    toggleActiveState = (id, evt) => {
        const { dispatch } = this.props;
        dispatch(toggleRuleActiveState(id, evt.currentTarget.checked));
    }

    render(){
        const { history, profile, data, query, page, processing, processingActivations, lastPage, error, noContentMessage } = this.props;

        return (
            <Container>
                <React.Fragment>
                    <div className="list-header">
                        <h2>Rules</h2>
                        <Button onClick={() => history.push("/tools/grouper/rules/new")} >New Rule</Button>
                    </div>
                    <SearchInput placeholder="Search" onChange={this.handleSearchChange} onIconClick={this.handleSearch} value={query} onKeyUp={this.handleKeyup}/>
                    <LoaderWrapper loaderError={!error.isEmpty()} isLoading={processing && page === 0}
                        errorComponent={
                            <Error
                                icon="faFrownLight"
                                title="Error"
                                message="Sorry, an error occurred while loading the rules."
                            />
                        }>
                        { !data.isEmpty() ?
                            <InfiniteScroller next={this.fetchMoreRules} hasMore={!lastPage} processing={processing}>
                                <React.Fragment>
                                    { data.map((rule) => {
                                        return (
                                            <Card key={rule.get('id')} onClick={this.navigateToEdit.bind(this, rule.get('id'))} modifier={['full-width']}>
                                                <Card.Body>
                                                    <Card.Title lines={1}>{rule.get('title')}</Card.Title>
                                                    <Card.Meta lines={{ mobile: 1, desktop: 2}}>{[`Last ran ${rule.get('lastRanAt') === null ? "never" : formatDate({ date: rule.get('lastRanAt'), format: "ddd MMM DD @ h:mm A z", timeZone: profile.get('timeZone') }).tz()}`, rule.get('description')].filter((element) => element !== "" && element !== null).join(" ᛫ ")}</Card.Meta>
                                                </Card.Body>
                                                <Card.Footer>
                                                    <React.Fragment>
                                                        <ToggleSwitch checked={rule.get("active")} disabled={processingActivations.contains(rule.get('id'))} name="active" onChange={this.toggleActiveState.bind(this, rule.get('id'))} value="toggle" />
                                                    </React.Fragment>
                                                </Card.Footer>
                                            </Card>
                                        );
                                    }) }
                                </React.Fragment>
                            </InfiniteScroller> : 
                            <Error
                                icon="faFrownLight"
                                title="No Rules found"
                                message={noContentMessage}
                            /> 
                        }
                    </LoaderWrapper>
                </React.Fragment>
            </Container>       
        );
    }
}

Rules.propTypes = {
    profile: PropTypes.instanceOf(Map),
    data: PropTypes.instanceOf(List),
    page: PropTypes.number,
    query: PropTypes.string,
    lastPage: PropTypes.bool,
    noContentMessage: PropTypes.string,
    processing: PropTypes.bool,
    processingActivations: PropTypes.instanceOf(List),
    error: PropTypes.instanceOf(Map)
}

function mapStateToProps(state){
    return {
        profile: state.getIn(['profile', 'data']),
        data: state.getIn(['rules','data']),
        page: state.getIn(['rules','page']),
        query: state.getIn(['rules','query']),
        lastPage: state.getIn(['rules','lastPage']),
        noContentMessage: state.getIn(['rules','noContentMessage']),
        processing: state.getIn(['rules','processing']),
        processingActivations: state.getIn(['rules','processingActivations']),
        error: state.getIn(['rules','error'])
    }
}

export default connect(mapStateToProps)(Rules);