export const colors = {
	// Brand
	orange:					'#E65400',
	orangeDark: 		'#CC4B00',

	// Umbrellas
	blue: 					'#13B5EA',
	blueDark: 			'#0F92BD',
	lime: 					'#B7D433',
	limeDark: 			'#869C21',
	aqua: 					'#83CFC4',
	aquaDark: 			'#389285',

	// Status
	successLight: 	'#E4ECD5',
	success: 				'#78A22F',
	errorLight: 		'#FFE5E5',
	error: 					'#FF0000',
	warningLight: 	'#FEF8E7',
	warning: 				'#FDB913',
	infoLight: 			'#E5F3F6',
	info: 					'#008DA8',

	// Interface
	white: 					'#FFF',
	grayLightest: 	'#F8F8F8',
	grayLighter: 		'#F1F1F0',
	grayLight: 			'#D6D6D6',
	grayMedium:			'#E0E0E0',
	gray: 					'#838383',
	grayDarker: 		'#626262',
	grayDarkest: 		'#505050',
	blackLighter:		'#1D1D1D',
	blackLight:		 	'#0C0C0C',
	black: 					'#000',

  // Transparent
	shadow: 				'rgba(80, 80, 80, 0.15)',
	overlay:				'rgba(0, 0, 0, 0.35)',

	// Toolkit Friendly Naming
	default: {
		anchorText:			'#CC4B00', // orangeDark
		errorText:			'#FF0000', // error
		inactiveText:		'#838383', // gray
		metaText:				'#838383', // gray
		primaryText:		'#505050' // grayDarkest
	},

	dark: {
		primaryText:		'',
		metaText:				'',
		errorText:			''
	}
}