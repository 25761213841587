import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  updatedGroup: {},
  processingUpdate: false,
  updateError: null,
  deletedGroup: {},
  processingDelete: false,
  deleteError: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GROUP_UPDATE_PROCESSING:
      return state.merge({
        processingUpdate: true
      });
    case ActionTypes.GROUP_UPDATE_SUCCESS:
      return state.merge({
        processingUpdate: false,
        updatedGroup: action.payload
      });
    case ActionTypes.GROUP_UPDATE_FAILURE:
      return state.merge({
        updateError: action.payload,
        processingUpdate: false
      });

    case ActionTypes.GROUP_DELETE_PROCESSING:
      return state.merge({
        processingDelete: true
      });
    case ActionTypes.GROUP_DELETE_SUCCESS:
      return state.merge({
        processingDelete: false,
        deletedGroup: action.payload
      });
    case ActionTypes.GROUP_DELETE_FAILURE:
      return state.merge({
        deleteError: action.payload,
        processingDelete: false
      });

    case ActionTypes.RESET_GROUP_EDIT:
      return state.merge({
        updatedGroup: {},
        deletedGroup: {},
        updateError: null,
        deleteError: null
      });
    default:
      return state;
  }
}
