import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsCardExcerpt } from './helpers/cardClassNameList';
import { CardContext } from './CardContext';
import { withHide } from './helpers/withHide';
import { withClamp } from './helpers/withClamp';
import { forwardRefWrapper } from 'utils/forwardRefWrapper';
import { Typography } from 'fs-toolkit-react';

const CardExcerpt = ({ className, children, modifier, innerRef }) => {
	const props = { ref: innerRef };

	return (
		<Typography
			ref={innerRef}
			className={cn(fsCardExcerpt, className)}
			modifier={modifier ? modifier : ['medium']}
			{...props}
		>
			{children}
		</Typography>
	);
};

CardExcerpt.context = CardContext;

CardExcerpt.propTypes = {
	className: PropTypes.string,
	lines: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			mobile: PropTypes.number,
			desktop: PropTypes.number
		})
	])
};

CardExcerpt.defaultProps = {
	className: ''
};

const linesToClamp = 3;

export default withClamp(forwardRefWrapper(withHide(CardExcerpt), 'innerRef'))(
	linesToClamp
);
