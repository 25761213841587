import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  processing: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GLOBAL_SEARCH_PROCESSING:
      return state.merge({
        processing: true,
        error: null
      });
    case ActionTypes.GLOBAL_SEARCH_SUCCESS:
      return state.merge({
        processing: false,
        data: action.data
      });
    case ActionTypes.GLOBAL_SEARCH_FAILURE:
      return state.merge({
        error: action.error,
        processing: false
      });
    default:
      return state;
  }
}