import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsCardCustom } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';

const CardCustom = ({ children, className, style }) => {
	return (
		<div className={cn(fsCardCustom, className)} style={style}>{children}</div>
	);
};

CardCustom.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object
};

CardCustom.defaultProps = {
	className: ''
};

export default withHide(CardCustom);