import React, { Fragment, Children } from 'react';
import PropTypes from 'prop-types';
import { fsContainerCenter, fsContainerContent, fsContainerFooterPush } from '../helpers/layoutClassNameList';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import cn from 'classnames';

function ContainerFooterLayout(props) {
	const {
		children,
		...loaderProps
	} = props;


	const childrenCount = Children.count(children);
	const childrenArray = Children.toArray(children);

	const footer = childrenArray.slice(childrenCount - 1, childrenCount);
	const main = childrenArray.slice(0, childrenCount - 1);

	const { isLoading } = loaderProps;

	return (
		<Fragment>
			<div className={fsContainerFooterPush}>
				<div className={fsContainerCenter}>
					<div className={cn(fsContainerContent)}>
						<LoaderWrapper {...loaderProps}>
							{main}
						</LoaderWrapper>
					</div>
				</div>
			</div>
			{!isLoading && footer}
		</Fragment>
	);
}

ContainerFooterLayout.propTypes = {
	children: PropTypes.any
};

export default ContainerFooterLayout;