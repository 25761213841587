import { fromJS } from 'immutable';
import { makeActionCreator } from '../utils/reducerUtils';
import KnowledgeBaseAPI from 'api/knowledge-base/KnowledgeBaseAPI';

const KB_PUBLIC_ARTICLE_PROCESSING = 'KB_PUBLIC_ARTICLE_PROCESSING';
const KB_PUBLIC_ARTICLE_SUCCESS = 'KB_PUBLIC_ARTICLE_SUCCESS';
const KB_PUBLIC_ARTICLE_FAILURE = 'KB_PUBLIC_ARTICLE_FAILURE';

const initialState = fromJS({
	article: {},
	processing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case KB_PUBLIC_ARTICLE_PROCESSING:
			return state.merge({
				processing: true
			});
		case KB_PUBLIC_ARTICLE_SUCCESS:
			return state.merge({
				processing: false,
				article: action.payload
			});
		case KB_PUBLIC_ARTICLE_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false
			});
		default:
			return state;
	}
}

const processing = makeActionCreator(KB_PUBLIC_ARTICLE_PROCESSING);
const success = makeActionCreator(KB_PUBLIC_ARTICLE_SUCCESS, 'payload');
const failure = makeActionCreator(KB_PUBLIC_ARTICLE_FAILURE, 'payload');

export const getKBPublicArticle = UUID => {
	return dispatch => {
		dispatch(processing());

		return KnowledgeBaseAPI.getPublicArticle(UUID).then(
			({ data }) => dispatch(success(data)),
			err => dispatch(failure(err))
		);
	};
};
