import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsCardBody } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';

const CardBody = ({ children, className }) => {
	return (
		<div className={cn(fsCardBody, className)}>{children}</div>
	);
};

CardBody.propTypes = {
	className: PropTypes.string
};

CardBody.defaultProps = {
	className: ''
};

export default withHide(CardBody);