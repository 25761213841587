import { fromJS, List, Map } from 'immutable';
import { toast } from 'react-toastify';
import { FileUtils } from 'fs-toolkit-react';
import { makeActionCreator } from '../utils/reducerUtils';
import KnowledgeBaseAPI from 'api/knowledge-base/KnowledgeBaseAPI';
import {
	isDirty,
	setInitialFormData,
	setInitialFormItem,
	getFilteredEditorContent
} from 'state/utils/formUtils';

// actions
const KB_ARTICLE_PROCESSING = 'KB_ARTICLE_PROCESSING';
const KB_ARTICLE_SUCCESS = 'KB_ARTICLE_SUCCESS';
const KB_ARTICLE_FAILURE = 'KB_ARTICLE_FAILURE';
const KB_ARTICLE_RESET = 'KB_ARTICLE_RESET';

const KB_CREATE_PROCESSING = 'KB_CREATE_PROCESSING';
const KB_CREATE_SUCCESS = 'KB_CREATE_SUCCESS';
const KB_CREATE_FAILURE = 'KB_CREATE_FAILURE';

const KB_UPDATE_PROCESSING = 'KB_UPDATE_PROCESSING';
const KB_UPDATE_SUCCESS = 'KB_UPDATE_SUCCESS';
const KB_UPDATE_FAILURE = 'KB_UPDATE_FAILURE';

const KB_DELETE_PROCESSING = 'KB_DELETE_PROCESSING';
const KB_DELETE_SUCCESS = 'KB_DELETE_SUCCESS';
const KB_DELETE_FAILURE = 'KB_DELETE_FAILURE';

const KB_SEND_FEEDBACK_PROCESSING = 'KB_SEND_FEEDBACK_PROCESSING';
const KB_SEND_FEEDBACK_SUCCESS = 'KB_SEND_FEEDBACK_SUCCESS';
const KB_SEND_FEEDBACK_FAILURE = 'KB_SEND_FEEDBACK_FAILURE';
const KB_FEEDBACK_RESET = 'KB_FEEDBACK_RESET';
const KB_FEEDBACK_FORM_CLOSE = 'KB_FEEDBACK_FORM_CLOSE';

// form create/edit
const KB_SET_DEFAULT_SUPER_CATEGORY = 'KB_SET_DEFAULT_SUPER_CATEGORY';
const KB_SET_INITIAL_CONTENT = 'KB_SET_INITIAL_CONTENT';
const KB_CHANGE_TITLE = 'KB_CHANGE_TITLE';
const KB_CHANGE_CONTENT = 'KB_CHANGE_CONTENT';
const KB_CHANGE_SUMMARY = 'KB_CHANGE_SUMMARY';
const KB_CHANGE_SUPER_CATEGORY = 'KB_CHANGE_SUPER_CATEGORY';
const KB_CHANGE_CATEGORIES = 'KB_CHANGE_CATEGORIES';
const KB_CHANGE_SEGMENTS = 'KB_CHANGE_SEGMENTS';
const KB_ADD_FILES = 'KB_ADD_FILES';
const KB_REMOVE_FILE = 'KB_REMOVE_FILE';

// reducer
const initialState = fromJS({
	article: {},
	processing: false,
	error: null,

	created: false,
	createProcessing: false,
	createError: null,

	updated: false,
	updateProcessing: false,
	updateError: null,

	deleted: false,
	deleteProcessing: false,
	deleteError: null,

	// feedback
	feedbackProcessing: false,
	isFeedbackSubmitted: false,
	isFeedbackFormOpen: false,
	rating: null,

	// admin form
	formData: {},
	initialContentSet: false,
	files: FileUtils.toFileUploadObject(Map()),
	isDirty: false
});

const processFormData = data => {
	return {
		content: data.content,
		publishedAt: data.publishedAt,
		superCategory: data.superCategory,
		summary: data.summary,
		title: data.title,
		categoryIds: data.categoryIds || data.categories.map(c => `${c.id}`),
		segmentIds: data.segmentIds || data.segments.map(s => `${s.id}`),
		attachmentIds: data.attachmentIds || data.attachments.map(a => `${a.id}`),
		groupId: data.groupId // create only
	};
};

function updateArticleRatingCount(article, ratingData) {
	let ratings = article.get('ratings');
	// is there a rating for the user already
	const index = ratings.findIndex(rating => {
		return rating.get('id') === ratingData.id;
	});
	if (index === -1) {
		//doesn't exit, so append it
		ratings = ratings.push(fromJS(ratingData));
	} else {
		if (ratings.getIn([index, 'isHelpful']) === ratingData.isHelpful) {
			// remove the rating
			ratings = ratings.filter(rating => rating.get('id') !== ratingData.id);
		} else {
			// update rating
			ratings = ratings.set(
				index,
				ratings.get(index).set('isHelpful', ratingData.isHelpful)
			);
		}
	}
	//recalculate the total
	const positiveCount = ratings
		.filter(rating => {
			return rating.get('isHelpful');
		})
		.count();
	const negativeCount = ratings
		.filter(rating => {
			return !rating.get('isHelpful');
		})
		.count();

	return article.merge({
		ratings: ratings,
		positiveRatings: positiveCount,
		negativeRatings: negativeCount
	});
}

function shouldAskForFeedback(article, ratingData) {
	// don't ask if the user has a rating and the rating return is the same id and is helpful value as this is effectively "deleting"
	// the vote
	let ratings = article.get('ratings');
	// is there a rating for the user already
	const foundRating = ratings.find(rating => {
		return rating.get('id') === ratingData.id;
	});
	return (
		!ratingData.isHelpful &&
		((foundRating && foundRating.get('isHelpful') !== ratingData.isHelpful) ||
			!foundRating)
	);
}

export default function(state = initialState, action) {
	switch (action.type) {
		case KB_ARTICLE_PROCESSING:
			return state.merge({
				processing: true
			});
		case KB_ARTICLE_SUCCESS:
			const files = action.payload.attachments
				? action.payload.attachments.map(attachment => {
						return FileUtils.toFileUploadObject(Map(attachment));
				  })
				: [];
			let data = !state.get('formData').isEmpty()
				? Object.assign({}, action.payload, state.get('formData').toJS())
				: action.payload; // merge default superCategory into form data when it set in edit article
			data = processFormData(data);
			setInitialFormData(data);

			return state.merge({
				processing: false,
				article: action.payload,
				formData: data,
				files: List(fromJS(files)),
				error: null
			});
		case KB_ARTICLE_FAILURE:
			return state.merge({
				processing: false,
				error: action.payload
			});
		case KB_ARTICLE_RESET:
			return state.merge(initialState);

		// edit
		case KB_CREATE_PROCESSING:
			return state.merge({
				createProcessing: true
			});
		case KB_CREATE_SUCCESS:
			return state.merge({
				created: true,
				article: action.payload,
				createProcessing: false
			});
		case KB_CREATE_FAILURE:
			return state.merge({
				createProcessing: false,
				createError: action.palyload
			});

		case KB_UPDATE_PROCESSING:
			return state.merge({
				updateProcessing: true
			});
		case KB_UPDATE_SUCCESS:
			return state.merge({
				updateProcessing: false,
				updated: true
			});
		case KB_UPDATE_FAILURE:
			return state.merge({
				updateProcessing: false,
				updateError: action.palyload
			});

		case KB_DELETE_PROCESSING:
			return state.merge({
				deleteProcessing: true
			});
		case KB_DELETE_SUCCESS:
			return state.merge({
				deleteProcessing: false,
				deleted: true
			});
		case KB_DELETE_FAILURE:
			return state.merge({
				deleteProcessing: false,
				deleteError: action.palyload
			});

		// feedback
		case KB_SEND_FEEDBACK_PROCESSING:
			return state.merge({
				feedbackProcessing: true
			});
		case KB_SEND_FEEDBACK_SUCCESS:
			return state.merge({
				article: updateArticleRatingCount(state.get('article'), action.payload),
				feedbackProcessing: false,
				isFeedbackSubmitted: true,
				isFeedbackFormOpen: shouldAskForFeedback(
					state.get('article'),
					action.payload
				)
			});
		case KB_SEND_FEEDBACK_FAILURE:
			return state.merge({
				feedbackProcessing: false
			});
		case KB_FEEDBACK_RESET:
			return state.merge({
				feedbackProcessing: false,
				isFeedbackSubmitted: false
			});
		case KB_FEEDBACK_FORM_CLOSE:
			return state.merge({
				isFeedbackFormOpen: false
			});

		// form state
		case KB_SET_DEFAULT_SUPER_CATEGORY:
			return state.merge({
				formData: state.get('formData').set('superCategory', action.payload)
			});
		case KB_SET_INITIAL_CONTENT:
			setInitialFormItem('content', action.payload);

			return state.merge({
				formData: state.get('formData').set('content', action.payload),
				initialContentSet: true
			});
		case KB_CHANGE_TITLE:
			return state.merge({
				formData: state.get('formData').set('title', action.payload),
				isDirty: isDirty('title', action.payload)
			});
		case KB_CHANGE_SUMMARY:
			return state.merge({
				formData: state.get('formData').set('summary', action.payload),
				isDirty: isDirty('summary', action.payload)
			});
		case KB_CHANGE_CONTENT:
			// need to filter content because redactor adds extra tags when content is deleted
			const filteredContent = getFilteredEditorContent(action.payload);
			const initialContentSet = state.get('initialContentSet');

			// set initial content individually (sent from redactor, not the) because redactor adds values to tag attributes and changes html entities into characters
			if (!initialContentSet) {
				setInitialFormItem('content', filteredContent);
			}

			return state.merge({
				formData: state.get('formData').set('content', filteredContent),
				isDirty: isDirty('content', filteredContent),
				initialContentSet: true
			});
		case KB_CHANGE_SUPER_CATEGORY:
			return state.merge({
				formData: state.get('formData').set('superCategory', action.payload),
				isDirty: isDirty('superCategory', action.payload)
			});
		case KB_CHANGE_CATEGORIES:
			return state.merge({
				formData: state
					.get('formData')
					.set('categoryIds', List(action.payload)),
				isDirty: isDirty('categoryIds', action.payload)
			});
		case KB_CHANGE_SEGMENTS:
			return state.merge({
				formData: state.get('formData').set('segmentIds', List(action.payload)),
				isDirty: isDirty('segmentIds', action.payload)
			});
		case KB_ADD_FILES:
			const attIds = action.payload.map(file => file.get('id'));
			return state.merge({
				formData: state.get('formData').set('attachmentIds', attIds),
				files: action.payload,
				isDirty: isDirty('attachmentIds', attIds.toJS())
			});
		case KB_REMOVE_FILE:
			const updatedAttchmentIds = state
				.getIn(['formData', 'attachmentIds'])
				.filter(id => id !== `${action.payload.get('id')}`);

			return state.merge({
				formData: state
					.get('formData')
					.set('attachmentIds', updatedAttchmentIds),
				files: state
					.get('files')
					.filter(
						localFile => localFile.get('id') !== action.payload.get('id')
					),
				isDirty: isDirty('attachmentIds', updatedAttchmentIds.toJS())
			});
		default:
			return state;
	}
}
const processing = makeActionCreator(KB_ARTICLE_PROCESSING);
const success = makeActionCreator(KB_ARTICLE_SUCCESS, 'payload');
const failure = makeActionCreator(KB_ARTICLE_FAILURE, 'payload');
const articleReset = makeActionCreator(KB_ARTICLE_RESET);

const createProcessing = makeActionCreator(KB_CREATE_PROCESSING);
const createSuccess = makeActionCreator(KB_CREATE_SUCCESS, 'payload');
const createFailure = makeActionCreator(KB_CREATE_FAILURE, 'payload');

const updateProcessing = makeActionCreator(KB_UPDATE_PROCESSING);
const updateSuccess = makeActionCreator(KB_UPDATE_SUCCESS, 'payload');
const updateFailure = makeActionCreator(KB_UPDATE_FAILURE, 'payload');

const deleteProcessing = makeActionCreator(KB_DELETE_PROCESSING);
const deleteSuccess = makeActionCreator(KB_DELETE_SUCCESS, 'payload');
const deleteFailure = makeActionCreator(KB_DELETE_FAILURE, 'payload');

const sendFeedbackProcessing = makeActionCreator(KB_SEND_FEEDBACK_PROCESSING);
const sendFeedbackSuccess = makeActionCreator(
	KB_SEND_FEEDBACK_SUCCESS,
	'payload'
);
const sendFeedbackFailure = makeActionCreator(
	KB_SEND_FEEDBACK_FAILURE,
	'payload'
);

// form data
const setDefaultSuperCategory = makeActionCreator(
	KB_SET_DEFAULT_SUPER_CATEGORY,
	'payload'
);
const setInitialContent = makeActionCreator(KB_SET_INITIAL_CONTENT, 'payload');
const changeTitle = makeActionCreator(KB_CHANGE_TITLE, 'payload');
const changeSummary = makeActionCreator(KB_CHANGE_SUMMARY, 'payload');
const changeContent = makeActionCreator(KB_CHANGE_CONTENT, 'payload');
const changeSuperCategory = makeActionCreator(
	KB_CHANGE_SUPER_CATEGORY,
	'payload'
);
const changeSegments = makeActionCreator(KB_CHANGE_SEGMENTS, 'payload');
const changeCategories = makeActionCreator(KB_CHANGE_CATEGORIES, 'payload');
const addFiles = makeActionCreator(KB_ADD_FILES, 'payload');
const removeFile = makeActionCreator(KB_REMOVE_FILE, 'payload');
const closeFeedbackForm = makeActionCreator(KB_FEEDBACK_FORM_CLOSE);

export const KBActions = {
	get(id) {
		return dispatch => {
			dispatch(processing());

			return KnowledgeBaseAPI.get(id).then(
				({ data }) => dispatch(success(data)),
				err => dispatch(failure(err))
			);
		};
	},
	post(published) {
		return (dispatch, getState) => {
			dispatch(createProcessing());

			const data = {
				...getState()
					.getIn(['knowledgeBase', 'formData'])
					.toJS(),
				published
			};

			return KnowledgeBaseAPI.post(data).then(
				({ data }) => dispatch(createSuccess(data)),
				err => dispatch(createFailure(err))
			);
		};
	},
	update(id, published) {
		return (dispatch, getState) => {
			dispatch(updateProcessing());
			const data = {
				...getState()
					.getIn(['knowledgeBase', 'formData'])
					.toJS(),
				published
			};

			return KnowledgeBaseAPI.update(id, data).then(
				({ data }) => dispatch(updateSuccess(data)),
				err => {
					toast.error('There was an error updating the article.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(updateFailure(err));
				}
			);
		};
	},
	delete(id) {
		return dispatch => {
			dispatch(deleteProcessing());

			return KnowledgeBaseAPI.delete(id).then(
				({ data }) => dispatch(deleteSuccess(data)),
				err => {
					toast.error('There was an error deleting the article.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(deleteFailure(err));
				}
			);
		};
	},
	sendFeedback(id, data) {
		return dispatch => {
			dispatch(sendFeedbackProcessing());

			return KnowledgeBaseAPI.sendFeedback(id, data).then(
				({ data }) => {
					toast.success('Thank you for your feedback.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--success'
					});
					dispatch(sendFeedbackSuccess(data));
				},
				err => {
					toast.error('There was an error submitting your feedback.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(sendFeedbackFailure(err));
				}
			);
		};
	},

	articleReset: articleReset,

	// form data
	success: success,
	setDefaultSuperCategory: setDefaultSuperCategory,
	setInitialContent: setInitialContent,
	changeTitle: changeTitle,
	changeSummary: changeSummary,
	changeContent: changeContent,
	changeSuperCategory: changeSuperCategory,
	changeSegments: changeSegments,
	changeCategories: changeCategories,
	addFiles: addFiles,
	removeFile: removeFile,
	closeFeedbackForm: closeFeedbackForm
};
