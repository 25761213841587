import APIClient from '../APIClient';
import { buildUrl } from '../../utils/globalUtils';
import flatten from 'lodash/flatten';

const basePath = '/topics';

export const getTopic = (params) => {
	const { topicId } = params;
	return APIClient.get(`${basePath}/${topicId}`);
};

export const getComments = (params) => {
	const { topicId } = params;
	return APIClient.get(`${basePath}/${topicId}/comments`);
};

export const getNewComments = (params) => {
	const { topicId, ...urlParams } = params;
	return APIClient.get(buildUrl(`/updates/topics/${topicId}/new-comments`, { ...urlParams }));
};

export const getUpdatedComments = params => {
	const { topicId, ...urlParams } = params;
	return APIClient.get(buildUrl(`/updates/topics/${topicId}/updated-comments`, { ...urlParams }));
};

export const getAllComments = (params) => {
	const { topicId, limit = 25, commentCount = 0 } = params;

	const endpoint = `${basePath}/${topicId}/comments`;
	const commentPromises = [];
	for (let i=0; i<Math.ceil(commentCount/limit); i+=1) {
		commentPromises.push(
			APIClient.get(buildUrl(endpoint, { limit, page: i }))
		);
	}
	return Promise
		.all(commentPromises)
		.then(pages => ({
			data: {
				content: flatten(pages.map(response => response.data.content))
			}
		}));
}

export const createComment = (params) => {
	const { topicId, payload } = params;
	return APIClient.post(`${basePath}/${topicId}/comments`, { data: payload })
};

export const updateComment = (params) => {
	const { topicId, commentId, payload } = params;
	return APIClient.put(`${basePath}/${topicId}/comments/${commentId}`, { data: payload });
};

export const deleteComment = (params) => {
	const { topicId, commentId } = params;
	return APIClient.delete(`${basePath}/${topicId}/comments/${commentId}`);
};

export const flagComment = (params) => {
	const { topicId, commentId, payload } = params;
	return APIClient.post(`${basePath}/${topicId}/comments/${commentId}/flags`, { data: payload });
};

export const addReaction = (params) => {
	const { topicId, commentId, payload } = params;
	return APIClient.post(`${basePath}/${topicId}/comments/${commentId}/reactions`, { data: payload });
};

export const deleteReaction = (params) => {
	const { topicId, commentId } = params;
	return APIClient.delete(`${basePath}/${topicId}/comments/${commentId}/reactions`);
};

export const followTopic = (params) => {
	const { topicId } = params;
	return APIClient.post(`${basePath}/${topicId}/followers`);
};

export const unfollowTopic = (params) => {
	const { topicId } = params;
	return APIClient.delete(`${basePath}/${topicId}/followers`);
};