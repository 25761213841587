import React from 'react';
import PropTypes from 'prop-types';
import { CardContext } from './CardContext';
import { StatusCircle } from 'fs-toolkit-react';
import { withHide } from './helpers/withHide';
import { withResponsiveProp } from './helpers/withResponsiveProp';

/**
 * StatusCircle component wrapped with card functionality.
 * The feature added was the ability to send data from this component up to
 * the parent Card component through the context api
 *
 * NOTE: when this component takes a 'tooltip' prop (the tooltip component itself)
 * it positions it in relation to the circle status and card component.
 * The calculation of the tooltip 'bottom' and 'left' position values relies on the current structure of the Card's node tree.
 */
class CardStatus extends React.Component {
	static contextType = CardContext;

	static propTypes = {
		position: PropTypes.oneOf([
			'top',
			'left',
			'right',
			'js-ignore' /* null only used within error component */
		]).isRequired,
		complete: PropTypes.bool,
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		iconSize: PropTypes.string,
		onClick: PropTypes.func,
		size: PropTypes.oneOf(['giant', 'large', 'medium', 'small', 'tiny']),
		status: PropTypes.number,
		text: PropTypes.string,
		tooltip: PropTypes.object,
		debug: PropTypes.bool // temp prop
	};

	static defaultProps = {
		tooltip: null
	};

	state = {
		tooltipPosition: {}
	};

	circleRef = React.createRef();
	tooltipRef = React.createRef();

	getTooltipPosition(cardRef, circleRect, tooltipEl) {
		const getPropertyValue = (element, property) => {
			const propertyValue = window
				.getComputedStyle(element, null)
				.getPropertyValue(property);

			return parseInt(propertyValue.replace('px', ''), 10);
		};

		return {
			get left() {
				let leftPadding = getPropertyValue(
					cardRef.firstElementChild,
					'padding-left'
				);

				const offsetLeft = circleRect.width / 2 + leftPadding;
				const tooltipOffsetLeft = offsetLeft - tooltipEl.offsetWidth / 2;

				return tooltipOffsetLeft;
			},
			get bottom() {
				let topPadding = getPropertyValue(
					cardRef.firstElementChild,
					'padding-top'
				);

				const tooltipTriangleHeight = tooltipEl.firstElementChild.offsetHeight;
				const tooltipOffsetBottom =
					circleRect.height +
					tooltipEl.offsetHeight +
					tooltipTriangleHeight +
					topPadding;

				return tooltipOffsetBottom;
			}
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			const cardRef = this.context.cardRef.current;
			const circleRect = this.circleRef.current.getBoundingClientRect();
			const tooltipRef = this.tooltipRef.current;
			const tooltipEl = tooltipRef && tooltipRef.firstElementChild;

			if (cardRef && tooltipEl) {
				const { left, bottom } = this.getTooltipPosition(
					cardRef,
					circleRect,
					tooltipEl
				);

				this.setState({ tooltipPosition: { left, bottom } });
			}
		}
	}

	componentDidMount() {
		if (this.props.position) {
			this.context.fromChild({ modifier: `status-${this.props.position}` });
		}
	}

	render() {
		const { tooltip } = this.props;
		return (
			<div className="fs-card-status-container" ref={this.circleRef}>
				<StatusCircle {...this.props} />
				<div
					className="fs-card-status__tooltip-wrapper"
					ref={this.tooltipRef}
					style={this.state.tooltipPosition}
				>
					{tooltip}
				</div>
			</div>
		);
	}
}

const responsivePropConfig = [
	{ propName: 'iconSize', mobile: '48px', desktop: '64px' },
	{ propName: 'size', mobile: 'small', desktop: 'medium' }
];
export default withResponsiveProp(withHide(CardStatus))(responsivePropConfig);
