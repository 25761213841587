import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LayoutContext } from 'components/layout/LayoutProvider';
import { cnPageForm } from '../helpers/layoutClassNameList';
import useActivePanel from '../hooks/useActivePanel';

function AdminLayout(props) {
	const { children } = props;

	const { state, action } = useContext(LayoutContext);
	const { footerRef, isMobile, calculatedContainerHeight } = state;
	const {
		setPageModifier,
		removePageModifier,
		setAdminActive,
		resetSubNavigation
	} = action;

	useEffect(() => {
		if (footerRef && calculatedContainerHeight) {
			const formContainer = document.querySelector(
				`.${cnPageForm} .fs-form__container`
			);
			const formElement = document.querySelector(`.${cnPageForm} form`);
			const adminContainerHeight = `calc(100vh - ${calculatedContainerHeight}px)`;

			if (formElement) {
				formElement.style.height = isMobile && adminContainerHeight;
				formContainer.style.height = !isMobile && adminContainerHeight;
			}
		}
	}, [footerRef, isMobile, calculatedContainerHeight]);

	useEffect(() => {
		const sideBarInline = 'sidebar-inline';
		setPageModifier(sideBarInline);
		return () => {
			removePageModifier(sideBarInline);
			isMobile && resetSubNavigation();
		};
	}, [
		setPageModifier,
		removePageModifier,
		setAdminActive,
		isMobile,
		resetSubNavigation
	]);

	useActivePanel(setAdminActive);

	return children;
}

AdminLayout.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any
};

export default AdminLayout;
