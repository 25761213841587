import React, { Component } from 'react';

export default function withTracker(WrappedComponent, options = {}) {

  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}
