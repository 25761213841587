import React from 'react';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, FooterContainer, FooterAdmin } from 'components/layout';
import {
	Form,
	Input,
	Modal,
	ButtonRefactor as Button,
	SelectBox,
	DropdownItem,
	Checkbox,
	FontIcon
} from 'fs-toolkit-react';
import Error from 'components/views/error/Error';
import { Map, List, fromJS } from 'immutable';
import ActionTypes from 'actions/actionTypes';
import {
	loadRule,
	createRule,
	deleteRule,
	updateRule
} from 'actions/grouperActions';
import * as yup from 'yup';
import WithResize from 'components/HOC/WithResize';
import { faCheckDouble, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons';

const ruleSchema = yup.object().shape({
	title: yup.string().max(100, 'Title cannot be greater than 100 characters.').required('Title is required.'),
    description: yup.string().max(255, 'Description cannot be greater than 255 characters.'),
    segmentIds: yup.array().test(
        'segmentIds',
        'One or more segment selections are blank.',
        (value) => {
            let valid = true;
            value.forEach((val) => {
                if(val === "0"){
                    valid = false;
                }
            });
            return valid;
        }
    ),
    executors: yup.array().test(
        'executors',
        'One or more group selections are blank.',
        (value) => {
            let valid = Object.keys(value).length > 0;
            value.forEach((val) => {
                if(!val.groupId){
                    valid = false;
                }
            });
            return valid;
        }
    )
});

class RuleForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDeleteConfirmationOpen: false,
			isUpdateConfirmationOpen: false
		};
	}

	componentDidMount() {
		const { dispatch, match } = this.props;
		let id = match.params.id || null;
		dispatch(loadRule(id));
	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch({ type: ActionTypes.TOOLS_GROUPER_RULE_FORM_RESET });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.saved || this.props.deleted) {
			this.props.history.push('/tools/grouper/rules');
		}
	}

	handleInputChange = evt => {
		this.handleFieldChange(evt.currentTarget.name, evt.currentTarget.value);
	};

	handleFieldChange = (field, value) => {
		const { dispatch } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: field,
			value: value
		});
	};

	handleAddSegment = () => {
		const { dispatch, segmentIds } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: 'segmentIds',
			value: segmentIds.push('0')
		});
	};

	handleRemoveSegment = index => {
		const { dispatch, segmentIds } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: 'segmentIds',
			value: segmentIds.delete(index)
		});
	};

	handleSegmentChange = (index, val) => {
		const { dispatch, segmentIds } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: 'segmentIds',
			value: segmentIds.set(index, val)
		});
    };
    
    handleAddExecutor = () => {
		const { dispatch, executors } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: 'executors',
			value: executors.push(fromJS({}))
		});
	};

	handleRemoveExecutor = index => {
		const { dispatch, executors } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: 'executors',
			value: executors.delete(index)
		});
	};

	handleExecutorChange = (index, key, val) => {
        const { dispatch, executors } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD,
			field: 'executors',
			value:executors.setIn([index, key], val)
		});
	};

	handleSubmit = () => {
		const { dispatch, title, description, segmentIds, executors, match } = this.props;
		ruleSchema
			.validate({ title, description, segmentIds: segmentIds.toJS(), executors: executors.toJS() }, { abortEarly: false })
			.then(() => {
				if (match.params.id) {
					dispatch(updateRule(match.params.id));
				} else {
					dispatch(createRule());
				}
			})
			.catch(errors => {
                let validationErrors = { title: '', description: '', segmentIds: '', executors: '' };
				errors.inner.forEach(error => {
					validationErrors[error.path] = error.message;
				});
				dispatch({
					type: ActionTypes.TOOLS_GROUPER_VALIDATE_RULE_FORM,
					validationErrors
				});
			});
	};

	toggleSubmitConfirmation = () => {
		this.setState({
			isUpdateConfirmationOpen: !this.state.isUpdateConfirmationOpen
		});
	};

	toggleDeleteConfirmation = () => {
		this.setState({
			isDeleteConfirmationOpen: !this.state.isDeleteConfirmationOpen
		});
	};

	handleDelete = () => {
		const { dispatch, match } = this.props;
		dispatch(deleteRule(match.params.id));
	};

	handleUpdate = () => {
		const { dispatch, match } = this.props;
		dispatch(updateRule(match.params.id));
	};

	render() {
		const {
			history,
			match,
			segmentIds,
			executors,
			title,
			description,
			processing,
			saving,
			deleting,
			saved,
			isDirty,
			error,
			options,
			validationErrors,
			isMobile
		} = this.props;

		const submitFunc = match.params.id ? this.toggleSubmitConfirmation : this.handleSubmit;

		return (
				<Form formClass="page-form rule-form" onSubmit={submitFunc} novalidate>
					{({ onSubmit }) => (
						<React.Fragment>
							{this.state.isDeleteConfirmationOpen && (
								<Modal
									height="30%"
									onClose={this.toggleDeleteConfirmation}
									width="30%"
								>
									<Modal.Header title="Confirmation" showCloseButton />
									<p>
										Deleting this rule will affect group placements, are you
										sure you want to do this?
									</p>
									<Modal.Footer>
										<Button
											modifier="secondary"
											onClick={this.toggleDeleteConfirmation}
											disabled={deleting}
										>Cancel</Button>
										<Button
											onClick={this.handleDelete}
											isLoading={deleting}
										>Delete</Button>
									</Modal.Footer>
								</Modal>
							)}
							{this.state.isUpdateConfirmationOpen && (
								<Modal
									height="35%"
									onClose={this.toggleSubmitConfirmation}
									width="30%"
								>
									<Modal.Header title="Confirmation" showCloseButton />
									<p>
										Once saved, this rule will be disabled and need to be enabled again to run. 
										Are you sure you want to save this rule?
									</p>
									<Modal.Footer>
										<Button
											modifier="secondary"
											onClick={this.toggleSubmitConfirmation}
											disabled={saving}
										>Cancel</Button>
										<Button
											onClick={this.handleSubmit}
											loading={saving}
										>Save</Button>
									</Modal.Footer>
								</Modal>
							)}
							<Container loaderError={!error.isEmpty()}
                                        isLoading={processing}
                                        errorComponent={
                                            <Error
                                                icon="faFrownLight"
                                                title="Error"
                                                message="Sorry, an error occurred while loading the form."
                                            />
                                        }>
								<Prompt
									when={isDirty && !saved}
									message="Are you sure you want to leave this page without saving? Your information will be lost."
								/>

                                <h2>{`${match.params.id ? 'Edit' : 'New'} Rule`}</h2>

                                <h3
                                    style={{ marginTop: '20px', marginBottom: '20px' }}
                                    className="fs-slashed-header fs-text--left"
                                >
                                    Details
                                </h3>

                                <Input
                                    name="title"
                                    label="Name"
                                    onChange={this.handleInputChange}
                                    value={title}
                                    id="title"
                                    placeholder="Give your rule a name"
                                    isFormField
                                    validationMessage={validationErrors.get('title')}
                                    invalid={validationErrors.get('title') !== ''}
                                    large
                                />

                                <Input
                                    name="description"
                                    label="Description"
                                    onChange={this.handleInputChange}
                                    value={description}
                                    id="description"
                                    placeholder="Describe what this rule is for"
                                    validationMessage={validationErrors.get('description')}
                                    invalid={validationErrors.get('description') !== ''}
                                    isFormField
                                />

                                <h3
                                    style={{ marginTop: '40px', marginBottom: '20px' }}
                                    className="fs-slashed-header fs-text--left"
                                >
                                    Segments
                                </h3>
                                <strong>
                                    <p>Which users should be added to this action?</p>
                                </strong>
                                <div className="fs-form-field">
                                    <React.Fragment>
                                        {segmentIds.map((id, index) => {
                                            return (
                                                <div key={`${index}${id}`} className="flex-field">
                                                    <SelectBox
																												fullWidth
                                                        defaultOption="Choose a segment"
                                                        selected={id}
                                                        onChange={this.handleSegmentChange.bind(
                                                            this,
                                                            index
                                                        )}
                                                    >
                                                        {({ onClick, selected }) => (
                                                            <React.Fragment>
                                                                {options.has('segments') &&
                                                                    options.get('segments').map(opt => {
                                                                        return (
                                                                            <DropdownItem
                                                                                key={opt.get('id')}
                                                                                value={`${opt.get('id')}`}
                                                                                onClick={onClick}
                                                                                selected={selected}
                                                                            >
                                                                                {opt.get('title')}
                                                                            </DropdownItem>
                                                                        );
                                                                    })}
                                                            </React.Fragment>
                                                        )}
                                                    </SelectBox>

                                                    {segmentIds.count() > 1 ? (
                                                        <Button
                                                            modifier="secondary"
                                                            onClick={this.handleRemoveSegment.bind(
                                                                this,
                                                                index
                                                            )}
																												>
																													<FontIcon icon={faTrashAlt} />
																												</Button>
                                                    ) : null}
                                                </div>
                                            );
                                        })}
                                        {validationErrors.get('segmentIds') !== '' ? (
                                            <div className="fs-form-field__message non-form-field">
                                                {validationErrors.get('segmentIds')}
                                            </div>
                                        ) : null}
                                        <Button
                                            modifier="secondary"
                                            onClick={this.handleAddSegment}
																				>Add Another</Button>
                                    </React.Fragment>

                                </div>

                                <h3
                                    style={{ marginTop: '40px', marginBottom: '20px' }}
                                    className="fs-slashed-header fs-text--left"
                                >
                                    Group Placement
                                </h3>
                                <strong>
                                    <p>
                                        Choose the group(s) to place users into and if the Member
                                        audience should be assigned.
                                    </p>
                                </strong>
                                <div className="fs-form-field">
                                    <React.Fragment>
                                        {executors.map((executor, index) => {
                                            return (
                                                <div key={`${index}|${executor.get('groupId') || new Date().getTime()}`} className="group-field">
                                                    <label>Group</label>
                                                    <div className="flex-field">
                                                        <SelectBox
																														fullWidth
                                                            defaultOption={'Choose a group'}
                                                            selected={executor.get('groupId')}
                                                            onChange={this.handleExecutorChange.bind(
                                                                this,
                                                                index,
                                                                "groupId"
                                                            )}
                                                        >
                                                            {({ onClick, selected }) => (
                                                                <React.Fragment>
                                                                    {options.has('groups') &&
                                                                        options
                                                                            .get('groups')
                                                                            .map(opt => {
                                                                                return (
                                                                                    <DropdownItem
                                                                                        key={opt.get('id')}
                                                                                        value={`${opt.get('id')}`}
                                                                                        metaText={opt.get('type')}
                                                                                        onClick={onClick}
                                                                                        selected={selected}
                                                                                    >
                                                                                        {opt.get('name')}
                                                                                    </DropdownItem>
                                                                                );
                                                                            })}
                                                                </React.Fragment>
                                                            )}
                                                        </SelectBox>
                                                        {executors.count() > 1 ? (
                                                            <Button
                                                                modifier="secondary"
                                                                onClick={this.handleRemoveExecutor.bind(this, index)}
																														>
																															<FontIcon icon={faTrashAlt} />
																														</Button>
                                                        ) : null}
                                                    </div>
                                                    <label style={{textTransform: 'uppercase'}}>Private Content</label>
                                                    <Checkbox
                                                        checked={executor.get('member') || false}
                                                        text="Can View Private Content"
                                                        name="member"
                                                        onChange={ (evt) => this.handleExecutorChange(
                                                            index,
                                                            "member",
                                                            evt.currentTarget.checked

                                                        )}
                                                        value={true}
                                                    />
                                                </div>
                                            );
                                        })}
                                        {validationErrors.get('executors') !== '' ? (
                                            <div className="fs-form-field__message non-form-field">
                                                {validationErrors.get('executors')}
                                            </div>
                                        ) : null}
                                        <Button
                                            modifier="secondary"
                                            onClick={this.handleAddExecutor}
																				>Add Another</Button>
                                    </React.Fragment>

                                </div>
							</Container>

							<FooterContainer custom fixed>
								{match.params.id ?
									<FooterAdmin>
										<Button
											onClick={this.toggleDeleteConfirmation}
											disabled={saving}
											modifier="secondary"
										>
											{isMobile ? <FontIcon icon={faTimes} />
												: 'Delete Rule'}
										</Button>

										<Button
											onClick={() => {
												history.push("/tools/grouper/rules")
											}}
											disabled={saving || deleting}
											modifier="secondary"
											style={{marginLeft: 'auto'}}
										>
											{isMobile ? <FontIcon icon={faTimes} />
												: 'Cancel'}
										</Button>

										<Button
											isLoading={saving}
											disabled={deleting}
											onClick={onSubmit}
										>
											{isMobile ? <FontIcon icon={faCheckDouble} />
												: 'Save'}
										</Button>
									</FooterAdmin>
									:
									<FooterAdmin>
										<Button
											onClick={() => {
												history.push("/tools/grouper/rules")
											}}
											disabled={saving}
											modifier="secondary"
										>
											{isMobile ? <FontIcon icon={faTimes} />
											: 'Cancel'}
										</Button>

										<Button
											isLoading={saving}
											onClick={onSubmit}
										>
											{isMobile ? <FontIcon icon={faCheckDouble} />
											: 'Create'}
										</Button>
									</FooterAdmin>
								}
							</FooterContainer>
						</React.Fragment>
					)}
				</Form>
		);
	}
}

RuleForm.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	segmentIds: PropTypes.instanceOf(List),
	executors: PropTypes.instanceOf(List),
	options: PropTypes.instanceOf(Map),
	processing: PropTypes.bool,
	saving: PropTypes.bool,
	saved: PropTypes.bool,
	deleting: PropTypes.bool,
	deleted: PropTypes.bool,
	isDirty: PropTypes.bool,
	error: PropTypes.instanceOf(Map),
	validationErrors: PropTypes.instanceOf(Map)
};

function mapStateToProps(state) {
	return {
		title: state.getIn(['rule', 'title']),
		description: state.getIn(['rule', 'description']),
		segmentIds: state.getIn(['rule', 'segmentIds']),
		executors: state.getIn(['rule', 'executors']),
		options: state.getIn(['rule', 'options']),
		processing: state.getIn(['rule', 'processing']),
		saving: state.getIn(['rule', 'saving']),
		saved: state.getIn(['rule', 'saved']),
		deleting: state.getIn(['rule', 'deleting']),
		deleted: state.getIn(['rule', 'deleted']),
		isDirty: state.getIn(['rule', 'isDirty']),
		error: state.getIn(['rule', 'error']),
		validationErrors: state.getIn(['rule', 'validationErrors'])
	};
}

export default WithResize(connect(mapStateToProps)(RuleForm));
