export const componentUtils = {
	circle: {
		size: {
			xs: 32,
			s:	48,
			m:	64,
			l:	80,
			xl:	96
		}
	},

	layout: {
		// breakpoints in px
		breakpoint: {
			hd:				1920, // change to 1630 or below to test on laptop
			desktop:	1024,
			tablet:		808,
			mobile:		375
		},

		// layout widths in px
		size: {
			xs:			'',
			s:			680,
			m:			856,
			l:			1032,
			xl:			1384,
			full: 	'100%'
		},

		// padding in px
		spacing: {
			size: {
				xxs: 	4,
				xs:	 	8,
				s:	 	16,
				m:	 	24,
				l:	 	32,
				xl:	 	48,
				xxl: 	64,
				xxxl:	96
			}
		}
	},

	loader: {
		size: {
			s: 20,
			m: 48,
			l: 64
		}
	}
};

export const sizeNameMapping = {
	'x-small':	'xs',
	'small':		's',
	'medium':		'm',
	'large':		'l',
	'x-large':	'xl'
};
