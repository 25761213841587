import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	members: [],
	numOfMembers: 0,
	processing: false,
	error: null,
	refreshProcessing: false,
	followProcessing: false,
	scrollProcessing: false,
	filterProcessing: false,
	fsProcessing: false,
	initialized: false,

	// save fs memberships data on a different state
	// for membership tool
	fsData: {},
	fsMembers: [],
	fsError: null,

	// batch create memberships
	batchCreateMembershipsError: null,
	batchCreateMembershipsProcessing: false,

	// batch update memberships
	batchUpdateMembershipsError: null,
	batchUpdateMembershipsProcessing: false,

	// batch delete memberships
	batchDeleteMembershipsError: null,
	batchDeleteMembershipsProcessing: false
});

const getMemberships = (members, data) => {
	const newMembers = data.content;
	return data.number > 0 ? members.toJS().concat(newMembers) : newMembers;
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GET_MEMBERSHIPS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.GET_MEMBERSHIPS_SUCCESS:
			const members = getMemberships(state.get('members'), action.payload);
			return state.merge({
				processing: false,
				filterProcessing: false,
				scrollProcessing: false,
				followProcessing: false,
				fsProcessing: false,
				initialized: true,
				data: action.payload,
				error: null,
				members,
				numOfMembers: action.payload.totalElements
			});
		case ActionTypes.UPDATE_LOCAL_MEMBERSHIPS_CONTENT:
			return state.merge({
				members: action.payload
			});
		case ActionTypes.GET_MEMBERSHIPS_FAILURE:
			return state.merge({
				error: action.payload,
				filterProcessing: false,
				scrollProcessing: false,
				followProcessing: false,
				processing: false
			});
		case ActionTypes.REFRESH_MEMBERSHIPS_PROCESSING:
			return state.merge({
				refreshProcessing: true
			});
		case ActionTypes.FILTER_MEMBERSHIPS_PROCESSING:
			return state.merge({
				filterProcessing: true
			});
		case ActionTypes.SCROLL_MEMBERSHIPS_PROCESSING:
			return state.merge({
				scrollProcessing: true
			});

		// fs group memberships
		case ActionTypes.FS_MEMBERSHIPS_PROCESSING:
			return state.merge({
				fsProcessing: true
			});
		case ActionTypes.FS_MEMBERSHIPS_SUCCESS:
			const fsMembers = getMemberships(state.get('fsMembers'), action.payload);
			return state.merge({
				fsProcessing: false,
				fsdData: action.payload,
				fsError: null,
				fsMembers
			});
		case ActionTypes.FS_MEMBERSHIPS_FAILURE:
			return state.merge({
				fsError: action.payload,
				fsProcessing: false
			});

		// batch create memberships
		case ActionTypes.BATCH_CREATE_MEMBERSHIPS_PROCESSING:
			return state.merge({
				batchCreateMembershipsProcessing: true
			});
		case ActionTypes.BATCH_CREATE_MEMBERSHIPS_SUCCESS:
			return state.merge({
				members: action.payload,
				batchCreateMembershipsProcessing: false
			});
		case ActionTypes.BATCH_CREATE_MEMBERSHIPS_FAILURE:
			return state.merge({
				batchCreateMembershipsError: action.payload,
				batchCreateMembershipsProcessing: false
			});

		// batch update memberships
		case ActionTypes.BATCH_UPDATE_MEMBERSHIPS_FAILURE:
			return state.merge({
				batchUpdateMembershipsError: action.payload,
				batchUpdateMembershipsProcessing: false
			});
		case ActionTypes.BATCH_UPDATE_MEMBERSHIPS_PROCESSING:
			return state.merge({
				batchUpdateMembershipsProcessing: true
			});
		case ActionTypes.BATCH_UPDATE_MEMBERSHIPS_SUCCESS:
			return state.merge({
				members: action.payload,
				batchUpdateMembershipsProcessing: false
			});

		// batch delete memberships
		case ActionTypes.BATCH_DELETE_MEMBERSHIPS_FAILURE:
			return state.merge({
				batchDeleteMembershipsError: action.payload,
				batchDeleteMembershipsProcessing: false
			});
		case ActionTypes.BATCH_DELETE_MEMBERSHIPS_PROCESSING:
			return state.merge({
				batchDeleteMembershipsProcessing: true
			});
		case ActionTypes.BATCH_DELETE_MEMBERSHIPS_SUCCESS:
			return state.merge({
				members: action.payload,
				batchDeleteMembershipsProcessing: false
			});

		case ActionTypes.RESET_MEMBERSHIPS:
			return state.merge(initialState);
		default:
			return state;
	}
}
