import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  group: {},
  processing: false,
  error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GROUP_CREATE_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.GROUP_CREATE_SUCCESS:
      return state.merge({
        processing: false,
        group: action.data
      });
    case ActionTypes.GROUP_CREATE_FAILURE:
      return state.merge({
        error: action.error,
        processing: false
      });
    default:
      return state;
  }
}
