import APIClient from '../APIClient';

export default {
	get: articleId => {
		return APIClient.get(`/news_articles/${articleId}`);
	},
	post: data => {
		return APIClient.post('/admin/news_articles', {
			data
		});
	},
	update: (articleId, data) => {
		return APIClient.put(`/admin/news_articles/${articleId}`, {
			data
		});
	},
	delete: articleId => {
		return APIClient.delete(`/admin/news_articles/${articleId}`);
	},
	getCategories: groupId => {
		return APIClient.get(`/groups/${groupId}/news_categories`);
	}
};
