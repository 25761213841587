import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { layoutUtils } from 'utils/layoutUtils';


const getPixels = number => typeof number === 'number' ? `${number}px` : number;

function ContainerLite({ children, className, size }) {

	return (
		<div
			className={cn(className)}
			style={{ maxWidth: getPixels(layoutUtils.size[size]) }}
		>
			{children}
		</div>
	)
}

ContainerLite.propTypes = {
	// width sizes defined in layoututils.js and _variables.scss
	// full always = 100%
	size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'full']),
};

export default ContainerLite;