import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const taskStatusesProcessing = () => ({
	type: ActionTypes.TASK_STATUSES_PROCESSING
});

const taskStatusesSuccess = data => ({
	type: ActionTypes.TASK_STATUSES_SUCCESS,
	data
});

const taskStatusesFailure = error => ({
	type: ActionTypes.TASK_STATUSES_FAILURE,
	error
});

export const fetchTaskStatuses = params => {
	return dispatch => {
		dispatch(taskStatusesProcessing());

		return APIClient.get('/task_statuses').then(
			({ data }) => dispatch(taskStatusesSuccess(data)),
			({ response: { data } }) => dispatch(taskStatusesFailure(data))
		);
	};
};
