import queryString from 'query-string';

const digitalFormData = {
	type: 'digitalForm',
	signedValFromUrl: 'signing_complete'
};

const isDigitalFormTask = type => digitalFormData.type === type;

const isDocusignSigned = urlSearch =>
	queryString.parse(urlSearch).event === digitalFormData.signedValFromUrl;

export { isDigitalFormTask, isDocusignSigned };
