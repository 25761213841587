import APIClient from '../APIClient';
import { buildUrl } from 'utils/globalUtils';

export const getMyRsvp = (eventId, sequenceId) => {
	return APIClient.get(buildUrl(`/my/events/${eventId}/occurrences/${sequenceId}/rsvp`, {}));
}

export const getRsvps = (eventId, sequenceId, { query, isScanned, page }) => {
	return APIClient.get(buildUrl(`/admin/attendance_tracking/events/${eventId}/occurrences/${sequenceId}/rsvps`, { page, query, isScanned }));
}

export const lookupRsvpFromScan = (eventId, sequenceId, { encodedData }) => {
	return APIClient.post(`/admin/attendance_tracking/events/${eventId}/occurrences/${sequenceId}/rsvps`, { data: { encodedData } });
}

export const getNewRsvps = (eventId, sequenceId, ts) => {
	return APIClient.get(buildUrl(`/updates/events/${eventId}/occurrences/${sequenceId}/new-rsvps`, { ts }));
}

export const getUpdatedRsvps = (eventId, sequenceId, ts) => {
	return APIClient.get(buildUrl(`/updates/events/${eventId}/occurrences/${sequenceId}/updated-rsvps`, { ts }));
}

export const getCanceledRsvps = (eventId, sequenceId, ts) => {
	return APIClient.get(buildUrl(`/updates/events/${eventId}/occurrences/${sequenceId}/canceled-rsvps`, { ts }));
}