import { faRocket } from '@fortawesome/pro-solid-svg-icons';

const iconTypes = {
	article: 'faInfoCircle',
	story: 'faNewspaper',
	event: 'faCalendarAlt',
	department: 'faLandmark',
	program: 'faGraduationCap',
	club: 'faGamepad',
	space: faRocket,
	people: 'faUser',
	topic: 'faComments'
};

const getIconType = article => {
	switch (article.get('searchableType')) {
		case 'Group':
			return article.getIn(['searchable', 'type']).toLowerCase();
		case 'EventOccurrence':
			return 'event';
		case 'NewsArticle':
			return 'story';
		case 'Topic':
			return 'topic';
		case 'KnowledgeBaseArticle':
		default:
			return 'article';
	}
};

export const getIcon = article => {
	return iconTypes[getIconType(article)];
};

export const filterItems = [
	{
		icon: '',
		text: 'All',
		value: ''
	},
	{
		icon: iconTypes.story,
		text: 'Stories',
		value: 'stories'
	},
	{
		icon: iconTypes.event,
		text: 'Events',
		value: 'events'
	},
	{
		icon: iconTypes.topic,
		text: 'Topics',
		value: 'topics'
	},
	// TO BE USED In THE FUTURE
	// {
	// 	icon: iconTypes.people,
	// 	text: 'People',
	// 	value: 'people'
	// },
	{
		icon: iconTypes.article,
		text: 'Articles',
		value: 'support'
	}
];
