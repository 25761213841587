import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';
import moment from 'moment';

const initialState = fromJS({
  profile: {},
  roles: [],
  audits: [],
  filter: "All Activity",
  startDate: moment().startOf('day'),
  endDate: moment().endOf('day'),
  auditProcessing: false,
  auditPage: 0,
  auditPageSize: 12,
  totalAudits: 0,
  pagesOfAudits: 0,
  processing: false,
  updateProcessing: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.VIEW_PROFILE_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.VIEW_PROFILE_SUCCESS:
      return state.merge({
        processing: false,
        profile: fromJS(action.data),
        roles: action.data.roles ? action.data.roles.map((role) => { return role.value; }) : []
      });
    case ActionTypes.VIEW_PROFILE_FAILURE:
      return state.merge({
        processing: false,
        error: action.error
      });
    case ActionTypes.ADMIN_UPDATE_PROFILE_PROCESSING:
      return state.merge({
        updateProcessing: true
      });
    case ActionTypes.ADMIN_UPDATE_PROFILE_SUCCESS:
      return state.merge({
        updateProcessing: false,
        profile: fromJS(action.data),
        roles: action.data.roles.map((role) => { return role.value; })
      });
    case ActionTypes.ADMIN_UPDATE_PROFILE_FAILURE:
      return state.merge({
        updateProcessing: false
      });
    case ActionTypes.REMOVE_PROFILE_ROLE:
      return state.merge({
        roles: state.get('roles').filter((role) => { return role !== action.role })
      });
    case ActionTypes.ADD_PROFILE_ROLE:
      return state.merge({
        roles: state.get('roles').push(action.role)
      });
    case ActionTypes.TOOGLE_PROFILE_ACCESS:
      return state.setIn(['profile','isActive'], action.isActive);
    case ActionTypes.AUDIT_CHANGE_PAGE:
      return state.merge({
        auditPage: action.page
      });
    case ActionTypes.AUDIT_CHANGE_PER_PAGE:
      return state.merge({
        auditPageSize: action.pageSize
      });
    case ActionTypes.AUDIT_CHANGE_ACTIVITY_FILTER:
      return state.merge({
        filter: action.filter
      });
    case ActionTypes.AUDIT_CHANGE_START_DATE:
      return state.merge({
        startDate: action.startDate
      });
    case ActionTypes.AUDIT_CHANGE_END_DATE:
      return state.merge({
        endDate: action.endDate
      });
    case ActionTypes.GET_AUDITS_PROCESSING:
      return state.merge({
        auditProcessing: true
      });
    case ActionTypes.GET_AUDITS_SUCCESS:
      return state.merge({
        auditProcessing: false,
        audits: fromJS(action.data.content),
        totalAudits: action.data.totalElements,
        pagesOfAudits: action.data.totalPages,
      });
    case ActionTypes.GET_AUDITS_FAILURE:
      return state.merge({
        auditProcessing: false,
        error: action.error
      });
    default:
      return state;
  }
}
