import { combineReducers } from 'redux-immutable';
import commenting from './commenting';
/**
 * Comment entity structure
 * @example
 * {
 *   entities: {
 *     commenting: {
 *       comments: { byId: {}, allIds: [] },
 *       replies: { byId: {}, allIds: [] }
 *     }
 *   }
 * }
 */

export default combineReducers({
	commenting,
});