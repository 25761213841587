import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  processing: false,
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PROFILE_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.PROFILE_SUCCESS:
      return state.merge({
        processing: false,
        data: action.data
      });
    case ActionTypes.PROFILE_FAILURE:
      return state.merge({
        processing: false,
        error: action.error
      });
    case ActionTypes.UPDATE_LOCAL_PROFILE:
      return state.merge({
        data: fromJS(action.data)
      });
    default:
      return state;
  }
}
