import { formatStringForUrl } from './urlUtils';
import { Map, List } from 'immutable';

const friendlyItemTypes = {
	Group: 'Group',
	KnowledgeBaseArticle: 'Article',
	NewsArticle: 'Story',
	EventOccurrence: 'Event',
	Topic: 'Topic'
};

export const getSearchItemMetadata = item => {
	const typesWithGroup = ['Story', 'Event', 'Topic'];
	const type = friendlyItemTypes[item.get('searchableType')];
	const group = typesWithGroup.includes(type)
		? item.getIn(['searchable', 'group', 'name'])
		: '';

	let metadata = type;

	if (type === 'Group') {
		metadata = item.getIn(['searchable', 'type']);
	} else if (group) {
		metadata = `${type} · ${group}`;
	}

	return metadata;
};

const getConnectGroupBasePath = (item, type, groupId) => {
	let groupType,
		groupName = '';

	if (type === 'Group') {
		groupType = formatStringForUrl(item.getIn(['searchable', 'type']));
		groupName = formatStringForUrl(item.getIn(['searchable', 'name']));
	} else {
		groupType = formatStringForUrl(item.getIn(['searchable', 'group', 'type']));
		groupName = formatStringForUrl(item.getIn(['searchable', 'group', 'name']));
	}

	return `/connect/${groupType}s/${groupName}/${groupId}`;
};

const getGroupId = (type, item) => {
	return type === 'Group'
		? item.getIn(['searchable', 'id'])
		: item.getIn(['searchable', 'group', 'id']);
};

const getFriendlyItemType = item => {
	return friendlyItemTypes[item.get('searchableType')];
};

const getItemId = item => {
	return item.getIn(['searchable', 'id']);
};

const getArticleType = item => {
	return item.getIn(['searchable', 'categories', 0, 'type']).toLowerCase();
};

const getArticleUrl = item => {
	return `/support/knowledge_base_articles/${getArticleType(item)}/${getItemId(item)}`;
};

const getStoryUrl = (item, type, groupId) => {
	let title = formatStringForUrl(item.getIn(['searchable', 'title']));
	let id = getItemId(item);

	return groupId === 1
		? `/connect/stories/${id}/${title}`
		: `${getConnectGroupBasePath(item, type, groupId)}/stories/${id}/${title}`;
};

const getEventUrl = (item, type, groupId) => {
	const seqId = item.getIn(['searchable', 'sequenceId']);
	const eventId = item.getIn(['searchable', 'eventId']);
	const title = formatStringForUrl(item.getIn(['searchable', 'title']));

	return groupId === 1
		? `/connect/events/${eventId}/${seqId}/${title}`
		: `${getConnectGroupBasePath(item, type, groupId)}/events/${eventId}/${seqId}/${title}`;
};

const getTopicUrl = (item, type, groupId) => {
	let title = formatStringForUrl(item.getIn(['searchable', 'title']));
	const id = getItemId(item);

	return `${getConnectGroupBasePath(item, type,	groupId)}/topics/${id}/${title}`;
};

export const getSearchItemUrl = item => {
	const type = getFriendlyItemType(item);
	const groupId = getGroupId(type, item);

	switch (type) {
		case 'Group':
			return getConnectGroupBasePath(item, type, groupId);
		case 'Article':
			return getArticleUrl(item, type, groupId);
		case 'Story':
			return getStoryUrl(item, type, groupId);
		case 'Event':
			return getEventUrl(item, type, groupId);
		case 'Topic':
			return getTopicUrl(item, type, groupId);
		default:
			return getConnectGroupBasePath(item, type, groupId);
	}
};

export const getSearchDropdownItems = (globalSearchData = Map()) => {
	const content = globalSearchData.get('content') || List();
	return content.map(item => ({
		id: item.get('id'),
		metaText: getSearchItemMetadata(item),
		value: getSearchItemUrl(item),
		label:
			item.getIn(['searchable', 'title']) || item.getIn(['searchable', 'name']),
		key: item.get('id')
	}));
};
