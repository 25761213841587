import mergeWith from 'lodash/mergeWith';
import { findParentId } from './commentingUtils';

function arrayCustomizer(objValue, srcValue) {
	if (Array.isArray(objValue)) {
		return objValue.concat(srcValue);
	}
}

export function normalizeComments(comments) {
	const entities = {
		comments: { byId: {}, allIds: [] },
		replies: { byId: {}, allIds: [] }
	};

	return comments.reduce((result, comment) => {
		if (comment.parentId === null) { // Parent comment
			return mergeWith({}, result, {
				comments: {
					byId: { [comment.id]: {...comment, replies: [] } },
					allIds: [comment.id]
				}
			}, arrayCustomizer);
		}

		const reply = mergeWith({}, result, { // Reply
			replies: {
				byId: { [comment.id]: comment },
				allIds: [comment.id]
			}
		}, arrayCustomizer);

		// update comment reply ids
		return mergeWith({}, reply, {
			comments: {
				byId: {
					[findParentId(
						comment.parentId,
						reply.replies.byId,
						reply.comments.byId
					)]: { replies: [comment.id] }
				}
			}
		}, arrayCustomizer);

	}, entities);
}