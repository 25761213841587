import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
    data: [],
    page: 0,
    query: '',
    lastPage: false,
    processing: false,
    noContentMessage: '',
    processingActivations: [],
    error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TOOLS_GROUPER_INCREMENT_PAGE:
        return state.merge({ page: action.page });
    case ActionTypes.TOOLS_GROUPER_LOAD_RULES_PROCESSING:
        return state.merge({
            processing: true
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_RULES_SUCCESS:
        return state.merge({
            processing: false,
            error: {},
            data: (action.payload.pageable.pageNumber === 0 ? 
                action.payload.content : state.get('data').concat(fromJS(action.payload.content))
            ),
            page: action.payload.pageable.pageNumber,
            lastPage: action.payload.last,
            noContentMessage: ( 
                action.payload.totalElements === 0 ?
                    (state.get('query').trim() !== '' ? 
                        "Try adjusting your search or filter to find what you are looking for." : 
                        "There are no rules yet." 
                    ) 
                : ''
            )
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_RULES_FAILURE:
        return state.merge({
            processing: false,
            error: action.payload
        });
    case ActionTypes.TOOLS_GROUPER_CHANGE_SEARCH:
        return state.merge({
            query: action.query
        });
    case ActionTypes.TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_PROCESSING:
        return state.set("processingActivations", state.get('processingActivations').push(action.id));
    case ActionTypes.TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_SUCCESS: 
        return state.merge({
            data: state.get('data').setIn([state.get('data').findIndex((d) => d.get('id') === action.payload.id)], fromJS(action.payload)),
            processingActivations: state.get('processingActivations').delete(state.get('processingActivations').findIndex((i) => i === action.id))
        });
    case ActionTypes.TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_FAILURE: 
        return state.set("processingActivations", state.get('processingActivations').delete(state.get('processingActivations').findIndex((i) => i === action.id)));
    case ActionTypes.TOOLS_GROUPER_SEGMENTS_RESET:
        return initialState;
    default:
      return state;
  }
}