import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProfile } from 'actions/profileActions';
import { getAppGroup } from 'actions/groupActions';
import { Map } from 'immutable';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import { LayoutContext } from 'components/layout/LayoutProvider';
import { getAccessToken, login } from 'api/authentication/Auth';
import { isImpersonating } from 'utils/profileUtils';
import { Root } from 'components/layout';
import withTracker from 'components/HOC/WithTracker';

import { Switch, Route, Redirect } from 'react-router-dom';

import ConnectRoutes from 'components/views/connect/routes/ConnectRoutes';
import LearnRoutes from 'components/views/learn/routes/LearnRoutes';
import SupportRoutes from 'components/views/support/routes/SupportRoutes';
import Home from 'components/views/Home';
import GlobalSearchResults from 'components/shared/searchResults/SearchResults';
import EventTicket from 'components/views/check-in-code/EventTicket';
import EventTicketScanner from 'components/views/check-in-code/EventTicketScanner';
import PeopleDetail from 'components/views/connect/people/PeopleDetail';
import TaskList from 'components/views/tasks/TaskList';
import TaskDetail from 'components/views/tasks/task/TaskDetail';
import TaskEsignLanding from 'components/views/tasks/esign/TaskEsignLanding';
import ToolsHandler from 'components/handlers/ToolsHandler';
import moment from 'moment';

import SocksProvider from 'components/shared/sockets/SocksProvider';

class AuthenticatedWrapper extends React.Component {
	static contextType = LayoutContext;
	static propTypes = {
		profileError: PropTypes.instanceOf(Map),
		profileProcessing: PropTypes.bool.isRequired,
		profile: PropTypes.instanceOf(Map).isRequired,
		groupError: PropTypes.instanceOf(Map),
		groupProcessing: PropTypes.bool.isRequired,
		group: PropTypes.instanceOf(Map).isRequired
	};

	componentDidMount() {
		const initialize = async () => {
			const accessToken = await getAccessToken();

			if (accessToken) {
				if (this.props.profile.isEmpty()) {
					this.props.dispatch(fetchProfile());
				}
				if (this.props.group.isEmpty()) {
					this.props.dispatch(getAppGroup());
				}
			} else {
				const { location } = this.props;
				//store the current url
				localStorage.removeItem('redirectTo'); // clear out any previous url redirects
				localStorage.setItem(
					'redirectTo',
					`${location.pathname}${!!location.hash ? location.hash : ''}`
				);
				login();
			}
		};

		initialize();
	}

	componentDidUpdate(prevProps) {
		if (this.props.profileError || this.props.groupError) {
			this.props.history.push('/500');
		}

		// build the pendo identity unless impersonating
		if (
			this.props.profile !== prevProps.profile &&
			(!isImpersonating() || process.env.REACT_APP_PENDO_WITH_IMPERSONATION)
		) {
			const { profile } = this.props;
			// set the current enrollment start date
			let startDate = null;
			let currentEnrollmentIndex = profile
				.get('enrollments')
				.findIndex(
					enrollment =>
						moment(enrollment.get('startDate'), 'YYYY-MM-DD') <= moment() &&
						moment(enrollment.get('graduationDate'), 'YYYY-MM-DD') >= moment()
				);
			if (currentEnrollmentIndex !== -1) {
				startDate = moment(
					profile.getIn(['enrollments', currentEnrollmentIndex, 'startDate']),
					'YYYY-MM-DD'
				).toISOString();
			}

			const segments = profile.get('segments').map(segment => {
				return segment.get('title');
			});

			window.pendo.initialize({
				visitor: {
					id: profile.get('fsOneId'), // Required if user is logged in
					email:
						profile.get('universityEmail') &&
						profile.getIn(['universityEmail', 'address']), // Recommended if using Pendo Feedback, or NPS Email
					full_name: `${profile.get('firstName')} ${profile.get('lastName')}`,
					createdAt: profile.get('setupCompletedAt'),
					viewport: window.innerWidth,
					currentEnrollmentStartDate: startDate,
					segments: segments
				},

				account: {
					id: process.env.REACT_APP_PENDO_ID, // Highly recommended
					name: process.env.REACT_APP_PENDO_NAME
				}
			});
		}
	}

	render = () => {
		return (
			<React.Fragment>
				<LoaderWrapper
					isLoading={this.props.profileProcessing || this.props.groupProcessing}
				>
					{!this.props.group.isEmpty() && !this.props.profile.isEmpty() ? (
						<SocksProvider>
							<Root>
								<Switch>
									<Route path="/tools" component={ToolsHandler} />

									{/* Umbrella */}
									<Route path="/connect" component={ConnectRoutes} />
									<Route path="/learn" component={LearnRoutes} />
									<Route path="/support" component={SupportRoutes} />

									<Route exact path="/search" component={GlobalSearchResults} />

									{/* Event checkin code and scanner */}
									<Route exact path="/check-in-code" component={EventTicket} />
									<Route
										exact
										path="/check-in-scanner/events/:eventId/:sequenceId"
										component={EventTicketScanner}
									/>

									{/* People */}
									<Route exact path="/people/:fsOneId" component={PeopleDetail} />
									<Route
										exact
										path="/people/:groupType/:groupName/:groupId/:fsOneId"
										component={PeopleDetail}
									/>

									{/* Tasks */}
									<Route exact path="/tasks" component={TaskList} />
									<Route exact path="/tasks/:id" component={TaskDetail} />
									<Route exact path="/esign/:id" component={TaskEsignLanding} />
									<Redirect from="/todos/:id" to="/tasks/:id" />
									<Redirect from='/todos' to='/tasks' />

									{/* Home */}
									<Route exact path="/" component={Home} />
									<Redirect to="/404" />
								</Switch>
							</Root>
						</SocksProvider>
					) : (
						<div />
					)}
				</LoaderWrapper>
			</React.Fragment>
		);
	};
}

const mapStateToProps = state => ({
	profileError: state.getIn(['profile', 'error']),
	profileProcessing: state.getIn(['profile', 'processing']),
	profile: state.getIn(['profile', 'data']),
	groupError: state.getIn(['app', 'groupError']),
	groupProcessing: state.getIn(['app', 'groupProcessing']),
	group: state.getIn(['app', 'group'])
});

export default connect(mapStateToProps)(withTracker(AuthenticatedWrapper));
