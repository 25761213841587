import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const publicProfileProcessing = () => ({
	type: ActionTypes.PUBLIC_PROFILE_PROCESSING
});

const publicProfileSuccess = ({data}) => ({
	type: ActionTypes.PUBLIC_PROFILE_SUCCESS,
	data
});

const publicProfileFailure = data => ({
	type: ActionTypes.PUBLIC_PROFILE_FAILURE,
	error: data
});

export const fetchPublicProfile = fsOneId => {
	return dispatch => {
		dispatch(publicProfileProcessing());

		return APIClient.getPublicProfile(`/profiles/${fsOneId}`).then(
			res => dispatch(publicProfileSuccess(res)),
			err => dispatch(publicProfileFailure(err))
		);

	}
};

export const resetPublicProfile = () => ({
	type: ActionTypes.PUBLIC_PROFILE_RESET
});
