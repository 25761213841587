import React from 'react';
import { CardContext } from '../CardContext';

/**
 * Uses the isMobile context from the main Card to apply responsive functionality to a component
 * @example withResponsiveProp(Component)([ { propName: 'myProp', mobile: 'small', desktop: 'large' }  ])
 * the `propName` is the components attribute/prop name that will use the responsive feature
 * `mobile` and `desktop` should be the value you want it to be when the screen size of the device is triggered
 * @param WrappedComponent
 * @returns {function(array): Component}
 */
const withResponsiveProp = (WrappedComponent) => {
	return (responsivePropConfig) => {
		class Component extends React.Component {

			static contextType = CardContext;

			isObject = (value) => typeof value === 'object' && value !== null;

			getResponsiveProps = (propValue) => {
				if (this.isObject(propValue))
					return this.context.isMobile ? propValue.mobile : propValue.desktop;

				return propValue;
			};

			render() {
				const props = responsivePropConfig.reduce((accumulator, config) => {
					const { propName, mobile, desktop } = config;
					if (!accumulator[propName]) {
						const componentProps = this.props[propName];
						const defaultProps = { mobile, desktop };
						accumulator[propName] = this.getResponsiveProps(componentProps || defaultProps);
					}
					return accumulator;
				}, {});

				return <WrappedComponent {...Object.assign({}, this.props, props)} />;
			}
		}
		return Component;
	}
};

export { withResponsiveProp };