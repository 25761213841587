import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AlertBar } from 'components/layout';
import { withPolling } from 'components/HOC/WithPolling';
import { getDangerBar } from 'actions/dangerBarActions';

const pollingInterval = 300000;

class DangerBar extends React.Component {
	static propTypes = {
		dangerBar: PropTypes.object.isRequired,
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	};

	getUrlFromText = () => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(this.props.dangerBar.get('danger_bar_text'), "text/html");
		return doc.body.querySelector('a') ? doc.body.querySelector('a').href : ''
	}

	render = () => {
		let { dangerBar, error } = this.props;
		// check danger bar text for good measure
		const showBar = !error && !dangerBar.isEmpty() && dangerBar.get('danger_bar_toggle') && dangerBar.get('danger_bar_text');

		return (
			<> 
				{showBar ? (
					<AlertBar
						background={
							dangerBar.get('danger_bar_background_color') || 'rgb(249, 245, 235)'
						}
						align="left"
						color={dangerBar.get('danger_bar_content_color')}
						className="danger-bar"
						url={this.getUrlFromText()}
					>
						<div
							className="fs-title danger-bar__content"
							dangerouslySetInnerHTML={{
								__html: dangerBar.get('danger_bar_text')
							}}
						/>
					</AlertBar>) : null 
				}
			</>
			)
	};
}

const mapStateToProps = state => ({
	dangerBar: state.getIn(['dangerBar', 'data']),
	url: state.getIn(['dangerBar', 'url']),
	error: state.getIn(['dangerBar', 'error']),
});

export default withPolling(
	getDangerBar,
	pollingInterval
)(connect(mapStateToProps)(DangerBar));
