export default {
	// app setup
	LOAD_APP_GROUP_PROCESSING: 'LOAD_APP_GROUP_PROCESSING',
	LOAD_APP_GROUP_SUCCESS: 'LOAD_APP_GROUP_SUCCESS',
	LOAD_APP_GROUP_FAILURE: 'LOAD_APP_GROUP_FAILURE',

	// profile
	PROFILE_REQUEST: 'PROFILE_REQUEST',
	PROFILE_SUCCESS: 'PROFILE_SUCCESS',
	PROFILE_FAILURE: 'PROFILE_FAILURE',
	PROFILE_PROCESSING: 'PROFILE_PROCESSING',
	UPDATE_LOCAL_PROFILE: 'UPDATE_LOCAL_PROFILE',

	// onboarding
	INCREMENT_ONBOARDING_STEP: 'INCREMENT_ONBOARDING_STEP',

	// learn classes
	CLASSES_PROCESSING: 'CLASSES_PROCESSING',
	CLASSES_SUCCESS: 'CLASSES_SUCCESS',
	CLASSES_FAILURE: 'CLASSES_FAILURE',

	// my events
	MY_EVENTS_PROCESSING: 'MY_EVENTS_PROCESSING',
	MY_EVENTS_SUCCESS: 'MY_EVENTS_SUCCESS',
	MY_EVENTS_FAILURE: 'MY_EVENTS_FAILURE',

	// events
	EVENTS_SUCCESS: 'EVENTS_SUCCESS',
	EVENTS_PROCESSING: 'EVENTS_PROCESSING',
	EVENTS_PROCESSING_SCROLL: 'EVENTS_PROCESSING_SCROLL',
	EVENTS_FAILURE: 'EVENTS_FAILURE',
	EVENTS_UPDATE_QUERY: 'EVENTS_UPDATE_QUERY',
	EVENTS_UPDATE_FILTER: 'EVENTS_UPDATE_FILTER',
	EVENTS_UPDATE_FOLLOWING_FILTER: 'EVENTS_UPDATE_FOLLOWING_FILTER',
	EVENTS_PAGINATION_INCREMENT: 'EVENTS_PAGINATION_INCREMENT',
	EVENTS_PAGINATION_RESET: 'EVENTS_PAGINATION_RESET',
	EVENTS_PAGINATION_LAST_PAGE: 'EVENTS_PAGINATION_LAST_PAGE',
	EVENTS_RESET: 'EVENTS_RESET',
	CALENDAR_EVENTS_SUCCESS: 'CALENDAR_EVENTS_SUCCESS',
	CALENDAR_EVENTS_FAILURE: 'CALENDAR_EVENTS_FAILURE',
	CALENDAR_EVENTS_PROCESSING: 'CALENDAR_EVENTS_PROCESSING',

	// event details
	EVENT_DETAIL_SUCCESS: 'EVENT_DETAIL_SUCCESS',
	EVENT_DETAIL_PROCESSING: 'EVENT_DETAIL_PROCESSING',
	EVENT_DETAIL_FAILURE: 'EVENT_DETAIL_FAILURE',
	EVENT_DETAIL_CATEGORIES_SUCCESS: 'EVENT_DETAIL_CATEGORIES_SUCCESS',
	EVENT_DETAIL_CATEGORIES_PROCESSING: 'EVENT_DETAIL_CATEGORIES_PROCESSING',
	EVENT_DETAIL_CATEGORIES_FAILURE: 'EVENT_DETAIL_CATEGORIES_FAILURE',
	EVENT_DETAIL_RESET: 'EVENT_DETAIL_RESET',

	// demote event
	DEMOTE_EVENT_PROCESSING: 'DEMOTE_EVENT_PROCESSING',
	DEMOTE_EVENT_SUCCESS: 'DEMOTE_EVENT_SUCCESS',
	DEMOTE_EVENT_FAILURE: 'DEMOTE_EVENT_FAILURE',

	// event rsvp
	EVENT_RSVP_SUCCESS: 'EVENT_RSVP_SUCCESS',
	EVENT_RSVP_PROCESSING: 'EVENT_RSVP_PROCESSING',
	EVENT_RSVP_FAILURE: 'EVENT_RSVP_FAILURE',
	MY_EVENT_RSVP_SUCCESS: 'MY_EVENT_RSVP_SUCCESS',
	MY_EVENT_RSVP_PROCESSING: 'MY_EVENT_RSVP_PROCESSING',
	MY_EVENT_RSVP_FAILURE: 'MY_EVENT_RSVP_FAILURE',
	EVENT_RSVP_ADD: 'EVENT_RSVP_ADD',
	EVENT_RSVP_REMOVE: 'EVENT_RSVP_REMOVE',
	EVENT_RSVP_UPDATES: 'EVENT_RSVP_UPDATES',
	EVENT_RSVP_CANCELATIONS: 'EVENT_RSVP_CANCELATIONS',

	// registration
	RSVP_REGISTRATION_PROCESSING: 'RSVP_REGISTRATION_PROCESSING',
	RSVP_REGISTRATION_SUCCESS: 'RSVP_REGISTRATION_SUCCESS',
	RSVP_REGISTRATION_FAILURE: 'RSVP_REGISTRATION_FAILURE',

	// event check-in scanner
	SCANNER_EVENT_SUCCESS: 'SCANNER_EVENT_SUCCESS',
	SCANNER_EVENT_PROCESSING: 'SCANNER_EVENT_PROCESSING',
	SCANNER_EVENT_FAILURE: 'SCANNER_EVENT_FAILURE',
	SCANNER_EVENT_RSVPS_SUCCESS: 'SCANNER_EVENT_RSVPS_SUCCESS',
	SCANNER_EVENT_RSVPS_PROCESSING: 'SCANNER_EVENT_RSVPS_PROCESSING',
	SCANNER_EVENT_RSVPS_FAILURE: 'SCANNER_EVENT_RSVPS_FAILURE',
	SCANNER_SET_MANUAL_CHECKIN_PROFILE: 'SCANNER_SET_MANUAL_CHECKIN_PROFILE',
	SCANNER_REMOVE_MANUAL_CHECKIN_PROFILE:
		'SCANNER_REMOVE_MANUAL_CHECKIN_PROFILE',
	SCANNER_CHECKIN_SUCCESS: 'SCANNER_CHECKIN_SUCCESS',
	SCANNER_CHECKIN_PROCESSING: 'SCANNER_CHECKIN_PROCESSING',
	SCANNER_CHECKIN_FAILURE: 'SCANNER_CHECKIN_FAILURE',
	SCANNER_RESET: 'SCANNER_RESET',
	SCANNER_UPDATE_SEARCH: 'SCANNER_UPDATE_SEARCH',
	SCANNER_UPDATE_FILTER: 'SCANNER_UPDATE_FILTER',
	SCANNER_INCREMENT_PAGE: 'SCANNER_INCREMENT_PAGE',

	// event: create rsvp
	EVENT_RSVP_CREATE_SUCCESS: 'EVENT_RSVP_CREATE_SUCCESS',
	EVENT_RSVP_CREATE_PROCESSING: 'EVENT_RSVP_CREATE_PROCESSING',
	EVENT_RSVP_CREATE_FAILURE: 'EVENT_RSVP_CREATE_FAILURE',

	// event: cancel rsvp
	EVENT_RSVP_CANCEL_SUCCESS: 'EVENT_RSVP_CANCEL_SUCCESS',
	EVENT_RSVP_CANCEL_PROCESSING: 'EVENT_RSVP_CANCEL_PROCESSING',
	EVENT_RSVP_CANCEL_FAILURE: 'EVENT_RSVP_CANCEL_FAILURE',

	// event: create new event
	EVENT_CREATE_SUBMITTED: 'EVENT_CREATE_SUBMITTED',
	EVENT_CREATE_PROCESSING: 'EVENT_CREATE_PROCESSING',
	EVENT_CREATE_FAILURE: 'EVENT_CREATE_FAILURE',

	EVENT_CREATE_INPUT_TITLE: 'EVENT_CREATE_INPUT_TITLE',
	EVENT_CREATE_INPUT_START_DATE: 'EVENT_CREATE_INPUT_START_DATE',
	EVENT_CREATE_INPUT_END_DATE: 'EVENT_CREATE_INPUT_END_DATE',
	EVENT_CREATE_INPUT_CONTENT: 'EVENT_CREATE_INPUT_CONTENT',
	EVENT_CREATE_INPUT_EXCERPT: 'EVENT_CREATE_INPUT_EXCERPT',
	EVENT_CREATE_INPUT_IMAGE: 'EVENT_CREATE_INPUT_IMAGE',
	EVENT_CREATE_INPUT_RECURRING: 'EVENT_CREATE_INPUT_RECURRING',
	EVENT_CREATE_INPUT_CATEGORIES: 'EVENT_CREATE_INPUT_CATEGORIES',
	EVENT_CREATE_INPUT_TAGS: 'EVENT_CREATE_INPUT_TAGS',
	EVENT_CREATE_INPUT_LOCATION: 'EVENT_CREATE_INPUT_LOCATION',
	EVENT_CREATE_INPUT_ROOM: 'EVENT_CREATE_INPUT_ROOM',
	EVENT_CREATE_INPUT_URL: 'EVENT_CREATE_INPUT_URL',
	EVENT_CREATE_INPUT_AUDIENCE: 'EVENT_CREATE_INPUT_AUDIENCE',
	EVENT_CREATE_RESET_FORM: 'EVENT_CREATE_RESET_FORM',
	EVENT_CREATE_INPUT_POST_FORM: 'EVENT_CREATE_INPUT_POST_FORM',
	EVENT_CREATE_INPUT_TRACK_ATTENDANCE: 'EVENT_CREATE_INPUT_TRACK_ATTENDANCE',
	EVENT_CREATE_INPUT_ALLOW_RSVP: 'EVENT_CREATE_INPUT_ALLOW_RSVP',
	EVENT_CREATE_INPUT_RSVP_LIMIT: 'EVENT_CREATE_INPUT_RSVP_LIMIT',
	EVENT_CREATE_INPUT_PRE_FORM: 'EVENT_CREATE_INPUT_PRE_FORM',

	// event updates
	EVENT_UPDATE_SUCCESS: 'EVENT_UPDATE_SUCCESS',
	EVENT_UPDATE_PROCESSING: 'EVENT_UPDATE_PROCESSING',
	EVENT_UPDATE_FAILURE: 'EVENT_UPDATE_FAILURE',

	EVENT_UPDATE_INPUT_TITLE: 'EVENT_UPDATE_INPUT_TITLE',
	EVENT_UPDATE_INPUT_START_DATE: 'EVENT_UPDATE_INPUT_START_DATE',
	EVENT_UPDATE_INPUT_END_DATE: 'EVENT_UPDATE_INPUT_END_DATE',
	EVENT_UPDATE_INPUT_CONTENT: 'EVENT_UPDATE_INPUT_CONTENT',
	EVENT_UPDATE_INPUT_EXCERPT: 'EVENT_UPDATE_INPUT_EXCERPT',
	EVENT_UPDATE_INPUT_IMAGE: 'EVENT_UPDATE_INPUT_IMAGE',
	EVENT_UPDATE_INPUT_RECURRING: 'EVENT_UPDATE_INPUT_RECURRING',
	EVENT_UPDATE_INPUT_CATEGORIES: 'EVENT_UPDATE_INPUT_CATEGORIES',
	EVENT_UPDATE_INPUT_TAGS: 'EVENT_UPDATE_INPUT_TAGS',
	EVENT_UPDATE_INPUT_LOCATION: 'EVENT_UPDATE_INPUT_LOCATION',
	EVENT_UPDATE_INPUT_ROOM: 'EVENT_UPDATE_INPUT_ROOM',
	EVENT_UPDATE_INPUT_URL: 'EVENT_UPDATE_INPUT_URL',
	EVENT_UPDATE_INPUT_AUDIENCE: 'EVENT_UPDATE_INPUT_AUDIENCE',
	EVENT_UPDATE_BATCH_INPUT: 'EVENT_UPDATE_BATCH_INPUT',
	EVENT_UPDATE_FORM_POPULATED: 'EVENT_UPDATE_FORM_POPULATED',
	EVENT_UPDATE_SUCCESS_RESET: 'EVENT_UPDATE_SUCCESS_RESET',
	EVENT_UPDATE_INPUT_POST_FORM: 'EVENT_UPDATE_INPUT_POST_FORM',
	EVENT_UPDATE_INPUT_TRACK_ATTENDANCE: 'EVENT_UPDATE_INPUT_TRACK_ATTENDANCE',
	EVENT_UPDATE_RESET_FORM: 'EVENT_UPDATE_RESET_FORM',
	EVENT_UPDATE_INPUT_ALLOW_RSVP: 'EVENT_UPDATE_INPUT_ALLOW_RSVP',
	EVENT_UPDATE_INPUT_RSVP_LIMIT: 'EVENT_UPDATE_INPUT_RSVP_LIMIT',
	EVENT_UPDATE_INPUT_PRE_FORM: 'EVENT_UPDATE_INPUT_PRE_FORM',

	EVENT_CATEGORIES_PROCESSING: 'EVENT_CATEGORIES_PROCESSING',
	EVENT_CATEGORIES_SUCCESS: 'EVENT_CATEGORIES_SUCCESS',
	EVENT_CATEGORIES_FAILURE: 'EVENT_CATEGORIES_FAILURE',

	EVENT_CANCEL_PROCESSING: 'EVENT_CANCEL_PROCESSING',
	EVENT_CANCEL_SUCCESS: 'EVENT_CANCEL_SUCCESS',
	EVENT_CANCEL_FAILURE: 'EVENT_CANCEL_FAILURE',
	EVENT_CANCEL_SUCCESS_RESET: 'EVENT_CANCEL_SUCCESS_RESET',

	// support knowledge base search
	SUPPORT_KB_SEARCH_PROCESSING: 'SUPPORT_KB_SEARCH_PROCESSING',
	SUPPORT_KB_SEARCH_SUCCESS: 'SUPPORT_KB_SEARCH_SUCCESS',
	SUPPORT_KB_SEARCH_FAILURE: 'SUPPORT_KB_SEARCH_FAILURE',
	SUPPORT_KB_SEARCH_RESET: 'SUPPORT_KB_SEARCH_RESET',

	// my news articles
	MY_NEWS_PROCESSING: 'MY_NEWS_PROCESSING',
	MY_NEWS_SUCCESS: 'MY_NEWS_SUCCESS',
	MY_NEWS_FAILURE: 'MY_NEWS_FAILURE',

	// cases
	CASE_PROCESSING: 'CASE_PROCESSING',
	CASE_PAGE_PROCESSING: 'CASE_PAGE_PROCESSING',
	CASE_SUCCESS: 'CASE_SUCCESS',
	CASE_FAILURE: 'CASE_FAILURE',
	CASE_CREATE: 'CASE_CREATE',
	CASE_SUBMITTED: 'CASE_SUBMITTED',
	CASE_RESET_ERROR: 'CASE_RESET_ERROR',

	// case details
	CASE_DETAIL_PROCESSING: 'CASE_DETAIL_PROCESSING',
	CASE_DETAIL_SUCCESS: 'CASE_DETAIL_SUCCESS',
	CASE_DETAIL_FAILURE: 'CASE_DETAIL_FAILURE',

	// case comments
	CASE_COMMENTS_PROCESSING: 'CASE_COMMENTS_PROCESSING',
	CASE_COMMENTS_SUCCESS: 'CASE_COMMENTS_SUCCESS',
	CASE_COMMENTS_FAILURE: 'CASE_COMMENTS_FAILURE',
	CASE_COMMENTS_CREATE: 'CASE_COMMENTS_CREATE',
	CASE_COMMENTS_CREATE_PROCESSING: 'CASE_COMMENTS_CREATE_PROCESSING',
	CASE_COMMENTS_SUBMITTED: 'CASE_COMMENTS_SUBMITTED',
	CASE_COMMENTS_RESET_ERROR: 'CASE_COMMENTS_RESET_ERROR',
	CASE_COMMENTS_RESET: 'CASE_COMMENTS_RESET',

	// notification
	NOTIFICATION_SUCCESS: 'NOTIFICATION_SUCCESS',
	NOTIFICATION_PROCESSING: 'NOTIFICATION_PROCESSING',
	NOTIFICATION_FAILURE: 'NOTIFICATION_FAILURE',
	NOTIFICATION_MARKED_PROCESSING: 'NOTIFICATION_MARKED_PROCESSING',
	NOTIFICATION_MARKED_SUCCESS: 'NOTIFICATION_MARKED_SUCCESS',
	NOTIFICATION_MARKED_RESET: 'NOTIFICATION_MARKED_RESET',
	NOTIFICATION_UPDATE_LIST: 'NOTIFICATION_UPDATE_LIST',
	NOTIFICATION_URL_PROCESSING: 'NOTIFICATION_URL_PROCESSING',
	NOTIFICATION_URL_SUCCESS: 'NOTIFICATION_URL_SUCCESS',
	NOTIFICATION_URL_FAILURE: 'NOTIFICATION_URL_FAILURE',
	NOTIFICATION_URL_RESET: 'NOTIFICATION_URL_RESET',

	// notification count
	GET_NOTIFICATION_COUNT: 'GET_NOTIFICATION_COUNT',
	SET_NOTIFICATION_COUNT: 'SET_NOTIFICATION_COUNT',
	RESET_NOTIFICATIONS_PANEL: 'RESET_NOTIFICATIONS_PANEL',

	// tags
	TAGS_SUCCESS: 'TAGS_SUCCESS',
	TAGS_PROCESSING: 'TAGS_PROCESSING',
	TAGS_FAILURE: 'TAGS_FAILURE',

	// get group by id
	GET_GROUP_PROCESSING: 'GET_GROUP_PROCESSING',
	GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
	GET_GROUP_FAILURE: 'GET_GROUP_FAILURE',
	GET_GROUP_RESET: 'GET_GROUP_RESET',

	// delete group
	GROUP_DELETE_PROCESSING: 'GROUP_DELETE_PROCESSING',
	GROUP_DELETE_SUCCESS: 'GROUP_DELETE_SUCCESS',
	GROUP_DELETE_FAILURE: 'GROUP_DELETE_FAILURE',

	// update group
	GROUP_UPDATE_PROCESSING: 'GROUP_UPDATE_PROCESSING',
	GROUP_UPDATE_SUCCESS: 'GROUP_UPDATE_SUCCESS',
	GROUP_UPDATE_FAILURE: 'GROUP_UPDATE_FAILURE',
	RESET_GROUP_EDIT: 'RESET_GROUP_EDIT',

	// group list
	GROUPS_PROCESSING: 'GROUPS_PROCESSING',
	GROUPS_PROCESSING_SCROLL: 'GROUPS_PROCESSING_SCROLL',
	GROUPS_PROCESSING_FILTER: 'GROUPS_PROCESSING_FILTER',
	GROUPS_TAB: 'GROUPS_TAB',
	GROUPS_SUCCESS: 'GROUPS_SUCCESS',
	GROUPS_FAILURE: 'GROUPS_FAILURE',
	GROUPS_RESET: 'GROUPS_RESET',

	// group create
	GROUP_CREATE_PROCESSING: 'GROUP_CREATE_PROCESSING',
	GROUP_CREATE_SUCCESS: 'GROUP_CREATE_SUCCESS',
	GROUP_CREATE_FAILURE: 'GROUP_CREATE_FAILURE',

	// group about
	GROUP_ABOUT_PROCESSING: 'GROUP_ABOUT_PROCESSING',
	GROUP_ABOUT_SUCCESS: 'GROUP_ABOUT_SUCCESS',
	GROUP_ABOUT_FAILURE: 'GROUP_ABOUT_FAILURE',

	// memberships
	GET_MEMBERSHIPS_PROCESSING: 'GET_MEMBERSHIPS_PROCESSING',
	GET_MEMBERSHIPS_SUCCESS: 'GET_MEMBERSHIPS_SUCCESS',
	GET_MEMBERSHIPS_FAILURE: 'GET_MEMBERSHIPS_FAILURE',
	UPDATE_LOCAL_MEMBERSHIPS_CONTENT: 'UPDATE_LOCAL_MEMBERSHIPS_CONTENT',

	// actions for fs memberships--needed for pages that needs to maintain group+fs memberships states
	FS_MEMBERSHIPS_PROCESSING: 'FS_MEMBERSHIPS_PROCESSING',
	FS_MEMBERSHIPS_SUCCESS: 'FS_MEMBERSHIPS_SUCCESS',
	FS_MEMBERSHIPS_FAILURE: 'FS_MEMBERSHIPS_FAILURE',

	// batch create memberships
	BATCH_CREATE_MEMBERSHIPS_PROCESSING: 'BATCH_CREATE_MEMBERSHIPS_PROCESSING',
	BATCH_CREATE_MEMBERSHIPS_SUCCESS: 'BATCH_CREATE_MEMBERSHIPS_SUCCESS',
	BATCH_CREATE_MEMBERSHIPS_FAILURE: 'BATCH_CREATE_MEMBERSHIPS_FAILURE',

	// batch update memberships
	BATCH_UPDATE_MEMBERSHIPS_PROCESSING: 'BATCH_UPDATE_MEMBERSHIPS_PROCESSING',
	BATCH_UPDATE_MEMBERSHIPS_SUCCESS: 'BATCH_UPDATE_MEMBERSHIPS_SUCCESS',
	BATCH_UPDATE_MEMBERSHIPS_FAILURE: 'BATCH_UPDATE_MEMBERSHIPS_FAILURE',

	// batch delete memberships
	BATCH_DELETE_MEMBERSHIPS_PROCESSING: 'BATCH_DELETE_MEMBERSHIPS_PROCESSING',
	BATCH_DELETE_MEMBERSHIPS_SUCCESS: 'BATCH_DELETE_MEMBERSHIPS_SUCCESS',
	BATCH_DELETE_MEMBERSHIPS_FAILURE: 'BATCH_DELETE_MEMBERSHIPS_FAILURE',

	// membership
	GET_MEMBERSHIP_PROCESSING: 'GET_MEMBERSHIP_PROCESSING',
	GET_MEMBERSHIP_SUCCESS: 'GET_MEMBERSHIP_SUCCESS',
	GET_MEMBERSHIP_FAILURE: 'GET_MEMBERSHIPS_FAILURE',

	UPDATE_MEMBERSHIP_PROCESSING: 'UPDATE_MEMBERSHIP_PROCESSING',
	UPDATE_MEMBERSHIP_SUCCESS: 'UPDATE_MEMBERSHIP_SUCCESS',
	UPDATE_MEMBERSHIP_FAILURE: 'UPDATE_MEMBERSHIP_FAILURE',

	DELETE_MEMBERSHIP_PROCESSING: 'DELETE_MEMBERSHIP_PROCESSING',
	DELETE_MEMBERSHIP_SUCCESS: 'DELETE_MEMBERSHIPS_SUCCESS',
	DELETE_MEMBERSHIP_FAILURE: 'DELETE_MEMBERSHIP_FAILURE',

	CREATE_MEMBERSHIP_PROCESSING: 'CREATE_MEMBERSHIP_PROCESSING',
	CREATE_MEMBERSHIP_SUCCESS: 'CREATE_MEMBERSHIP_SUCCESS',
	CREATE_MEMBERSHIP_FAILURE: 'CREATE_MEMBERSHIP_FAILURE',

	FOLLOW_MEMBERSHIP_PROCESSING: 'FOLLOW_MEMBERSHIP_PROCESSING',

	REFRESH_MEMBERSHIPS_PROCESSING: 'REFRESH_MEMBERSHIPS_PROCESSING',
	FILTER_MEMBERSHIPS_PROCESSING: 'FILTER_MEMBERSHIPS_PROCESSING',
	SCROLL_MEMBERSHIPS_PROCESSING: 'SCROLL_MEMBERSHIPS_PROCESSING',

	RESET_MEMBERSHIPS: 'RESET_MEMBERSHIPS',

	// people
	VIEW_PROFILE_PROCESSING: 'VIEW_PROFILE_PROCESSING',
	VIEW_PROFILE_SUCCESS: 'VIEW_PROFILE_SUCCESS',
	VIEW_PROFILE_FAILURE: 'VIEW_PROFILE_FAILURE',
	ADMIN_UPDATE_PROFILE_PROCESSING: 'ADMIN_UPDATE_PROFILE_PROCESSING',
	ADMIN_UPDATE_PROFILE_SUCCESS: 'ADMIN_UPDATE_PROFILE_SUCCESS',
	ADMIN_UPDATE_PROFILE_FAILURE: 'ADMIN_UPDATE_PROFILE_FAILURE',
	ADD_PROFILE_ROLE: 'ADD_PROFILE_ROLE',
	REMOVE_PROFILE_ROLE: 'REMOVE_PROFILE_ROLE',
	TOOGLE_PROFILE_ACCESS: 'TOOGLE_PROFILE_ACCESS',
	GET_AUDITS_PROCESSING: 'GET_AUDITS_PROCESSING',
	GET_AUDITS_SUCCESS: 'GET_AUDITS_SUCCESS',
	GET_AUDITS_FAILURE: 'GET_AUDITS_FAILURE',
	AUDIT_CHANGE_PER_PAGE: 'AUDIT_CHANGE_PER_PAGE',
	AUDIT_CHANGE_PAGE: 'AUDIT_CHANGE_PAGE',
	AUDIT_CHANGE_ACTIVITY_FILTER: 'AUDIT_CHANGE_ACTIVITY_FILTER',
	AUDIT_CHANGE_START_DATE: 'AUDIT_CHANGE_START_DATE',
	AUDIT_CHANGE_END_DATE: 'AUDIT_CHANGE_END_DATE',
	LIST_BLOCKS_PROCESSING: 'LIST_BLOCKS_PROCESSING',
	LIST_BLOCKS_SUCCESS: 'LIST_BLOCKS_SUCCESS',
	LIST_BLOCKS_FAILURE: 'LIST_BLOCKS_FAILURE',
	REMOVE_BLOCK_PROCESSING: 'REMOVE_BLOCK_PROCESSING',
	REMOVE_BLOCK_SUCCESS: 'REMOVE_BLOCK_SUCCESS',
	REMOVE_BLOCK_FAILURE: 'REMOVE_BLOCK_FAILURE',
	RESET_PROFILE: 'RESET_PROFILE',

	//roles
	ROLES_PROCESSING: 'ROLES_PROCESSING',
	ROLES_SUCCESS: 'ROLES_SUCCESS',
	ROLES_FAILURE: 'ROLES_FAILURE',

	// enrollments
	ENROLLMENTS_PROCESSING: 'ENROLLMENTS_PROCESSING',
	ENROLLMENTS_SUCCESS: 'ENROLLMENTS_SUCCESS',
	ENROLLMENTS_FAILURE: 'ENROLLMENTS_FAILURE',
	SET_CURRENT_ENROLLMENT: 'SET_CURRENT_ENROLLMENT',
	RESET_CURRENT_ENROLLMENT: 'RESET_CURRENT_ENROLLMENT',

	// enrollments panel (learn)
	ENROLLMENTS_PANEL_PROCESSING: 'ENROLLMENTS_PANEL_PROCESSING',
	ENROLLMENTS_PANEL_SUCCESS: 'ENROLLMENTS_PANEL_SUCCESS',
	ENROLLMENTS_PANEL_FAILURE: 'ENROLLMENTS_PANEL_FAILURE',
	ENROLLMENTS_PANEL_SET_CURRENT_ENROLLMENT:
		'ENROLLMENTS_PANEL_SET_CURRENT_ENROLLMENT',
	ENROLLMENTS_PANEL_RESET_CURRENT_ENROLLMENT:
		'ENROLLMENTS_PANEL_RESET_CURRENT_ENROLLMENT',

	// class schedules
	CLASS_SCHEDULES_PROCESSING: 'CLASS_SCHEDULES_PROCESSING',
	CLASS_SCHEDULES_SUCCESS: 'CLASS_SCHEDULES_SUCCESS',
	CLASS_SCHEDULES_FAILURE: 'CLASS_SCHEDULES_FAILURE',
	CLASS_SCHEDULES_SEARCH_PROCESSING: 'CLASS_SCHEDULES_SEARCH_PROCESSING',
	CLASS_SCHEDULES_SCROLL_PROCESSING: 'CLASS_SCHEDULES_SCROLL_PROCESSING',
	CLASS_SCHEDULES_LAB_FILTER_PROCESSING:
		'CLASS_SCHEDULES_LAB_FILTER_PROCESSING',

	// lab options
	LAB_OPTIONS_PROCESSING: 'LAB_OPTIONS_PROCESSING',
	LAB_OPTIONS_SUCCESS: 'LAB_OPTIONS_SUCCESS',
	LAB_OPTIONS_FAILURE: 'LAB_OPTIONS_FAILURE',

	// lab filtered options
	LAB_FILTERED_OPTIONS: 'LAB_FILTERED_OPTIONS',

	// gps
	GPS_PROCESSING: 'GPS_PROCESSING',
	GPS_SUCCESS: 'GPS_SUCCESS',
	GPS_FAILURE: 'GPS_FAILURE',

	// attendance
	CLASS_ATTENDANCE_PROCESSING: 'CLASS_ATTENDANCE_PROCESSING',
	CLASS_ATTENDANCE_SUCCESS: 'CLASS_ATTENDANCE_SUCCESS',
	CLASS_ATTENDANCE_FAILURE: 'CLASS_ATTENDANCE_FAILURE',

	// attendance details
	CLASS_ATTENDANCE_DETAIL_PROCESSING: 'CLASS_ATTENDANCE_DETAIL_PROCESSING',
	CLASS_ATTENDANCE_DETAIL_SUCCESS: 'CLASS_ATTENDANCE_DETAIL_SUCCESS',
	CLASS_ATTENDANCE_DETAIL_FAILURE: 'CLASS_ATTENDANCE_DETAIL_FAILURE',

	// grades
	CLASS_GRADES_PROCESSING: 'CLASS_GRADES_PROCESSING',
	CLASS_GRADES_SUCCESS: 'CLASS_GRADES_SUCCESS',
	CLASS_GRADES_FAILURE: 'CLASS_GRADES_FAILURE',

	// grades detail
	CLASS_GRADES_DETAIL_PROCESSING: 'CLASS_GRADES_DETAIL_PROCESSING',
	CLASS_GRADES_DETAIL_SUCCESS: 'CLASS_GRADES_DETAIL_SUCCESS',
	CLASS_GRADES_DETAIL_FAILURE: 'CLASS_GRADES_DETAIL_FAILURE',

	// pagination
	PAGINATION_LAST_PAGE: 'PAGINATION_LAST_PAGE',
	PAGINATION_RESET: 'PAGINATION_RESET',
	PAGINATION_INCREMENT: 'PAGINATION_INCREMENT',
	PAGINATION_UPDATE_QUERY: 'PAGINATION_UPDATE_QUERY',
	PAGINATION_UPDATE_FILTER: 'PAGINATION_UPDATE_FILTER',
	PAGINATION_PROCESSING: 'PAGINATION_PROCESSING',

	// danger bar
	DANGER_BAR_SUCCESS: 'DANGER_BAR_SUCCESS',
	DANGER_BAR_FAILURE: 'DANGER_BAR_FAILURE',
	DANGER_BAR_SET_URL_LOCALLY: 'DANGER_BAR_SET_URL_LOCALLY',

	// global search
	GLOBAL_SEARCH_SUCCESS: 'GLOBAL_SEARCH_SUCCESS',
	GLOBAL_SEARCH_FAILURE: 'GLOBAL_SEARCH_FAILURE',
	GLOBAL_SEARCH_PROCESSING: 'GLOBAL_SEARCH_PROCESSING',

	// global search
	SEARCH_RESULTS_PROCESSING: 'SEARCH_RESULTS_PROCESSING',
	SEARCH_RESULTS_SUCCESS: 'SEARCH_RESULTS_SUCCESS',
	SEARCH_RESULTS_FAILURE: 'SEARCH_RESULTS_FAILURE',
	SEARCH_RESULTS_RESET: 'SEARCH_RESULTS_RESET',

	// tasks
	TASKS_PROCESSING: 'TASKS_PROCESSING',
	TASKS_SUCCESS: 'TASKS_SUCCESS',
	TASKS_FAILURE: 'TASKS_FAILURE',
	RESET_TASKS: 'RESET_TASKS',
	TASKS_PROCESSING_SCROLL: 'TASKS_PROCESSING_SCROLL',
	TASKS_PROCESSING_SEARCH: 'TASKS_PROCESSING_SEARCH',

	// unfiltered tasks
	UNFILTERED_TASKS_PROCESSING: 'UNFILTERED_TASKS_PROCESSING',
	UNFILTERED_TASKS_SUCCESS: 'UNFILTERED_TASKS_SUCCESS',
	UNFILTERED_TASKS_FAILURE: 'UNFILTERED_TASKS_FAILURE',
	RESET_UNFILTERED_TASKS: 'RESET_UNFILTERED_TASKS',

	// tasks
	TASK_PROCESSING: 'TASK_PROCESSING',
	TASK_SUCCESS: 'TASK_SUCCESS',
	TASK_FAILURE: 'TASK_FAILURE',
	RESET_TASK: 'RESET_TASK',

	// task categories
	TASK_CATEGORIES_PROCESSING: 'TASK_CATEGORIES_PROCESSING',
	TASK_CATEGORIES_SUCCESS: 'TASK_CATEGORIES_SUCCESS',
	TASK_CATEGORIES_FAILURE: 'TASK_CATEGORIES_FAILURE',

	// task statuses
	TASK_STATUSES_PROCESSING: 'TASK_STATUSES_PROCESSING',
	TASK_STATUSES_SUCCESS: 'TASK_STATUSES_SUCCESS',
	TASK_STATUSES_FAILURE: 'TASK_STATUSES_FAILURE',

	// launch enrollments
	LAUNCH_ENROLLMENTS_PROCESSING: 'LAUNCH_ENROLLMENTS_PROCESSING',
	LAUNCH_ENROLLMENTS_SUCCESS: 'LAUNCH_ENROLLMENTS_SUCCESS',
	LAUNCH_ENROLLMENTS_FAILURE: 'LAUNCH_ENROLLMENTS_FAILURE',
	LAUNCH_ENROLLMENTS_RESET: 'LAUNCH_ENROLLMENTS_RESET',

	// docusign
	DOCUSIGN_PROCESSING: 'DOCUSIGN_PROCESSING',
	DOCUSIGN_SUCCESS: 'DOCUSIGN_SUCCESS',
	DOCUSIGN_FAILURE: 'DOCUSIGN_FAILURE',
	DOCUSIGN_RESET: 'DOCUSIGN_RESET',

	// public uploader
	PUBLIC_UPLOADER_PROCESSING: 'PUBLIC_UPLOADER_PROCESSING',
	PUBLIC_UPLOADER_SUCCESS: 'PUBLIC_UPLOADER_SUCCESS',
	PUBLIC_UPLOADER_FAILURE: 'PUBLIC_UPLOADER_FAILURE',

	// list: save search query
	SAVE_PEOPLE_SEARCH: 'SAVE_PEOPLE_SEARCH',
	RESET_PEOPLE_SEARCH: 'RESET_PEOPLE_SEARCH',
	SAVE_STORIES_SEARCH: 'SAVE_STORIES_SEARCH',
	RESET_STORIES_SEARCH: 'RESET_STORIES_SEARCH',
	SAVE_EVENTS_SEARCH: 'SAVE_EVENTS_SEARCH',
	RESET_EVENTS_SEARCH: 'RESET_EVENTS_SEARCH',
	SAVE_GROUPS_SEARCH: 'SAVE_GROUPS_SEARCH',
	RESET_GROUPS_SEARCH: 'RESET_GROUPS_SEARCH',
	SAVE_ATTENDANCE_SEARCH: 'SAVE_ATTENDANCE_SEARCH',
	RESET_ATTENDANCE_SEARCH: 'RESET_ATTENDANCE_SEARCH',
	SAVE_GRADES_SEARCH: 'SAVE_GRADES_SEARCH',
	RESET_GRADES_SEARCH: 'RESET_GRADES_SEARCH',

	// list: save header dropdown filter
	SAVE_PEOPLE_DROPDOWN_FILTER: 'SAVE_PEOPLE_DROPDOWN_FILTER',
	RESET_PEOPLE_DROPDOWN_FILTER: 'RESET_PEOPLE_DROPDOWN_FILTER',
	SAVE_STORIES_DROPDOWN_FILTER: 'SAVE_STORIES_DROPDOWN_FILTER',
	RESET_STORIES_DROPDOWN_FILTER: 'RESET_STORIES_DROPDOWN_FILTER',
	SAVE_EVENTS_DROPDOWN_FILTER: 'SAVE_EVENTS_DROPDOWN_FILTER',
	RESET_EVENTS_DROPDOWN_FILTER: 'RESET_EVENTS_DROPDOWN_FILTER',

	// list: save tab filter
	SAVE_STORIES_TAB_FILTER: 'SAVE_STORIES_TAB_FILTER',
	RESET_STORIES_TAB_FILTER: 'RESET_STORIES_TAB_FILTER',
	SAVE_EVENTS_TAB_FILTER: 'SAVE_EVENTS_TAB_FILTER',
	RESET_EVENTS_TAB_FILTER: 'RESET_EVENTS_TAB_FILTER',
	SAVE_GROUPS_TAB_FILTER: 'SAVE_GROUPS_TAB_FILTER',
	RESET_GROUPS_TAB_FILTER: 'RESET_GROUPS_TAB_FILTER',

	// tools
	TOOLS_GROUPER_INCREMENT_PAGE: 'TOOLS_GROUPER_INCREMENT_PAGE',
	TOOLS_GROUPER_CHANGE_SEGMENT_FILTER: 'TOOLS_GROUPER_CHANGE_SEGMENT_FILTER',
	TOOLS_GROUPER_CHANGE_SEARCH: 'TOOLS_GROUPER_CHANGE_SEARCH',
	TOOLS_GROUPER_LOAD_SEGMENTS_PROCESSING:
		'TOOLS_GROUPER_LOAD_SEGMENTS_PROCESSING',
	TOOLS_GROUPER_LOAD_SEGMENTS_SUCCESS: 'TOOLS_GROUPER_LOAD_SEGMENTS_SUCCESS',
	TOOLS_GROUPER_LOAD_SEGMENTS_FAILURE: 'TOOLS_GROUPER_LOAD_SEGMENTS_FAILURE',
	TOOLS_GROUPER_SEGMENTS_RESET: 'TOOLS_GROUPER_SEGMENTS_RESET',
	TOOLS_GROUPER_LOAD_SEGMENT_FORM_PROCESSING:
		'TOOLS_GROUPER_LOAD_SEGMENT_FORM_PROCESSING',
	TOOLS_GROUPER_LOAD_SEGMENT_FORM_SUCCESS:
		'TOOLS_GROUPER_LOAD_SEGMENT_FORM_SUCCESS',
	TOOLS_GROUPER_LOAD_SEGMENT_FORM_FAILURE:
		'TOOLS_GROUPER_LOAD_SEGMENT_FORM_FAILURE',
	TOOLS_GROUPER_CHANGE_SEGMENT_FORMFIELD:
		'TOOLS_GROUPER_CHANGE_SEGMENT_FORMFIELD',
	TOOLS_GROUPER_VALIDATE_SEGMENT_FORM: 'TOOLS_GROUPER_VALIDATE_SEGMENT_FORM',
	TOOLS_GROUPER_CLEAR_SEGMENT_FORM_VALIDATIONS:
		'TOOLS_GROUPER_CLEAR_SEGMENT_FORM_VALIDATIONS',
	TOOLS_GROUPER_CREATE_SEGMENT_PROCESSING:
		'TOOLS_GROUPER_CREATE_SEGMENT_PROCESSING',
	TOOLS_GROUPER_CREATE_SEGMENT_SUCCESS: 'TOOLS_GROUPER_CREATE_SEGMENT_SUCCESS',
	TOOLS_GROUPER_CREATE_SEGMENT_FAILURE: 'TOOLS_GROUPER_CREATE_SEGMENT_FAILURE',
	TOOLS_GROUPER_DELETE_SEGMENT_PROCESSING:
		'TOOLS_GROUPER_DELETE_SEGMENT_PROCESSING',
	TOOLS_GROUPER_DELETE_SEGMENT_SUCCESS: 'TOOLS_GROUPER_DELETE_SEGMENT_SUCCESS',
	TOOLS_GROUPER_DELETE_SEGMENT_FAILURE: 'TOOLS_GROUPER_DELETE_SEGMENT_FAILURE',
	TOOLS_GROUPER_UPDATE_SEGMENT_PROCESSING:
		'TOOLS_GROUPER_UPDATE_SEGMENT_PROCESSING',
	TOOLS_GROUPER_UPDATE_SEGMENT_SUCCESS: 'TOOLS_GROUPER_UPDATE_SEGMENT_SUCCESS',
	TOOLS_GROUPER_UPDATE_SEGMENT_FAILURE: 'TOOLS_GROUPER_UPDATE_SEGMENT_FAILURE',
	TOOLS_GROUPER_SEGMENT_FORM_RESET: 'TOOLS_GROUPER_SEGMENT_FORM_RESET',
	TOOLS_GROUPER_LOAD_RULES_PROCESSING: 'TOOLS_GROUPER_LOAD_RULES_PROCESSING',
	TOOLS_GROUPER_LOAD_RULES_SUCCESS: 'TOOLS_GROUPER_LOAD_RULES_SUCCESS',
	TOOLS_GROUPER_LOAD_RULES_FAILURE: 'TOOLS_GROUPER_LOAD_RULES_FAILURE',
	TOOLS_GROUPER_RULES_RESET: 'TOOLS_GROUPER_RULES_RESET',
	TOOLS_GROUPER_LOAD_RULE_FORM_PROCESSING:
		'TOOLS_GROUPER_LOAD_RULE_FORM_PROCESSING',
	TOOLS_GROUPER_LOAD_RULE_FORM_SUCCESS: 'TOOLS_GROUPER_LOAD_RULE_FORM_SUCCESS',
	TOOLS_GROUPER_LOAD_RULE_FORM_FAILURE: 'TOOLS_GROUPER_LOAD_RULE_FORM_FAILURE',
	TOOLS_GROUPER_CHANGE_RULE_FORMFIELD: 'TOOLS_GROUPER_CHANGE_RULE_FORMFIELD',
	TOOLS_GROUPER_VALIDATE_RULE_FORM: 'TOOLS_GROUPER_VALIDATE_RULE_FORM',
	TOOLS_GROUPER_CLEAR_RULE_FORM_VALIDATIONS:
		'TOOLS_GROUPER_CLEAR_RULE_FORM_VALIDATIONS',
	TOOLS_GROUPER_CREATE_RULE_PROCESSING: 'TOOLS_GROUPER_CREATE_RULE_PROCESSING',
	TOOLS_GROUPER_CREATE_RULE_SUCCESS: 'TOOLS_GROUPER_CREATE_RULE_SUCCESS',
	TOOLS_GROUPER_CREATE_RULE_FAILURE: 'TOOLS_GROUPER_CREATE_RULE_FAILURE',
	TOOLS_GROUPER_DELETE_RULE_PROCESSING: 'TOOLS_GROUPER_DELETE_RULE_PROCESSING',
	TOOLS_GROUPER_DELETE_RULE_SUCCESS: 'TOOLS_GROUPER_DELETE_RULE_SUCCESS',
	TOOLS_GROUPER_DELETE_RULE_FAILURE: 'TOOLS_GROUPER_DELETE_RULE_FAILURE',
	TOOLS_GROUPER_UPDATE_RULE_PROCESSING: 'TOOLS_GROUPER_UPDATE_RULE_PROCESSING',
	TOOLS_GROUPER_UPDATE_RULE_SUCCESS: 'TOOLS_GROUPER_UPDATE_RULE_SUCCESS',
	TOOLS_GROUPER_UPDATE_RULE_FAILURE: 'TOOLS_GROUPER_UPDATE_RULE_FAILURE',
	TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_PROCESSING:
		'TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_PROCESSING',
	TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_SUCCESS:
		'TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_SUCCESS',
	TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_FAILURE:
		'TOOLS_GROUPER_TOGGLE_RULE_ACTIVE_STATE_FAILURE',
	TOOLS_GROUPER_RULE_FORM_RESET: 'TOOLS_GROUPER_RULE_FORM_RESET',

	SET_BYPASS_PAGE_LOADER_STATUS: 'SET_BYPASS_PAGE_LOADER_STATUS',

	// Public Profile
	PUBLIC_PROFILE_REQUEST: 'PUBLIC_PROFILE_REQUEST',
	PUBLIC_PROFILE_SUCCESS: 'PUBLIC_PROFILE_SUCCESS',
	PUBLIC_PROFILE_FAILURE: 'PUBLIC_PROFILE_FAILURE',
	PUBLIC_PROFILE_PROCESSING: 'PUBLIC_PROFILE_PROCESSING',
	PUBLIC_PROFILE_RESET: 'PUBLIC_PROFILE_RESET'
};
