import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { colors, StatusCircle, FontIcon } from 'fs-toolkit-react';
import { withRouter } from 'react-router';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import withResize from 'components/HOC/WithResize';
import {
	fetchTask,
	submitTask,
	resetTask,
	markTaskSubmitted
} from 'actions/taskActions';
import {
	fetchUnfilteredTasks,
	resetUnfilteredTasks
} from 'actions/unfilteredTasksActions';
import { Container, FooterContainer } from 'components/layout';
import {
	getCardIcon,
	isIncomplete,
	getTaskTemplatePath
} from 'components/views/tasks/utils/tasksUtils';
import {
	isDigitalFormTask,
	isDocusignSigned
} from 'components/views/tasks/utils/digitalFormUtils';
import TaskMessage from './components/TaskMessage';
import TaskFooter from './components/TaskFooter';
import customTaskTemplateMap from 'components/views/tasks/task/templates/custom/customTaskTemplateMap';
import { isLastTaskOnCurrentList } from 'components/views/tasks/utils/tasksUtils';
import { layoutUtils } from 'utils/layoutUtils';

function TaskDetail({ history, location, match, isMobile }) {
	const { id } = match.params;
	const { search } = location;
	const [nextTask, setNextTask] = useState(null);
	const [TaskTemplate, setTaskTemplate] = useState(null);
	const [submittedTaskCode, setSubmittedTaskCode] = useState(null);
	const dispatch = useDispatch();

	const profile = useSelector(state => state.getIn(['profile', 'data']));
	const unfilteredTasksItem = useSelector(state =>
		state.getIn(['unfilteredTasks', 'item'])
	);
	const unfilteredTasks = useSelector(state =>
		state.getIn(['unfilteredTasks', 'tasks'])
	);
	const unfilteredTasksError = useSelector(state =>
		state.getIn(['unfilteredTasks', 'error'])
	);
	const processingUnfilteredTasks = useSelector(state =>
		state.getIn(['unfilteredTasks', 'processing'])
	);
	const tasksItem = useSelector(state => state.getIn(['tasks', 'item']));
	const taskItem = useSelector(state => state.getIn(['task', 'item']));
	const processingTask = useSelector(state =>
		state.getIn(['task', 'processing'])
	);
	const error = useSelector(state => state.getIn(['task', 'error']));

	const status = taskItem.get('status');
	const code = taskItem.getIn(['task', 'code']);
	const type = taskItem.getIn(['task', 'type']);
	const instuctional = taskItem.getIn(['task', 'instructional']);
	const taskId = taskItem.get('id');
	const enrollmentId = taskItem.getIn(['enrollment', 'id']);
	const incomplete = isIncomplete(status, code);
	const isEsign = code => customTaskTemplateMap[code] === 'Esign';

	// guarantees a fresh list of unfiltered tasks
	// in case user filters by status (applying/completed, etc)
	// so that the footer navigates the right list
	const enrollmentIdHasChanged = useCallback(() => {
		const enrollmentIdFromUnfilteredTasks =
			!unfilteredTasks.isEmpty() &&
			unfilteredTasks.getIn([0, 'enrollment', 'id']);
		return (
			enrollmentIdFromUnfilteredTasks &&
			enrollmentId &&
			enrollmentId !== enrollmentIdFromUnfilteredTasks
		);
	}, [unfilteredTasks, enrollmentId]);

	useEffect(() => {
		if (
			(process.env.REACT_APP_ENV === 'production' ||
			process.env.NODE_ENV === 'production') && window.utag
		) {
			// track the user for tealium
			window.utag.view({ fsid: profile.get('fsOneId') });
		}
	}, [profile]);

	useEffect(() => {
		if (enrollmentIdHasChanged()) {
			dispatch(resetUnfilteredTasks());
		}
	}, [enrollmentIdHasChanged, dispatch]);

	useEffect(() => {
		if (taskId && isDigitalFormTask(type) && isDocusignSigned(search)) {
			dispatch(markTaskSubmitted(taskId));
		}
	}, [dispatch, taskId, type, search]);

	useEffect(() => {
		dispatch(fetchTask(id, history));
		setTaskTemplate(null); // prevent page flashing previous task template
		setSubmittedTaskCode(null);

		return () => dispatch(resetTask());
	}, [dispatch, id, history]);

	useEffect(() => {
		const templatePath = getTaskTemplatePath(taskItem);

		if (templatePath) {
			(async () => {
				try {
					// webpack dynamic import doesn't work with variables (i.e import(path))
					const TaskTemplate = await import(
						`components/views/tasks/task/templates/${templatePath}`
					);
					setTaskTemplate(TaskTemplate);
				} catch (error) {
					throw new Error(error);
				}
			})();
		}
	}, [taskItem]);

	useEffect(() => {
		const currentTaskIndex = unfilteredTasks.findIndex(
			item => item.get('id') === parseInt(id)
		);

		setNextTask(unfilteredTasks.get(currentTaskIndex + 1));
	}, [unfilteredTasks, id]);

	// fetches unfiltered tasks on page load/refresh
	// if navigating from task list fetch 12
	// if refreshing or task list has fetched more than one page, fetch 200
	useEffect(() => {
		if (
			unfilteredTasksItem.isEmpty() &&
			!processingUnfilteredTasks &&
			enrollmentId
		) {
			const tasksItemIsEmpty = tasksItem.isEmpty();
			const tasksPage = tasksItem.get('number');
			const size = tasksItemIsEmpty || tasksPage > 0 ? 200 : 12;
			const page = tasksItemIsEmpty || tasksPage > 0 ? null : tasksPage;
			dispatch(fetchUnfilteredTasks({ size, enrollmentId, page }));
		}
	}, [
		unfilteredTasksItem,
		tasksItem,
		dispatch,
		processingUnfilteredTasks,
		enrollmentId
	]);

	// fetch next tasks page
	useEffect(() => {
		const enrollmentId = taskItem.getIn(['enrollment', 'id']);
		const fetchNextTasksPageForFooter =
			enrollmentId &&
			nextTask &&
			!unfilteredTasksItem.isEmpty() &&
			!unfilteredTasksItem.get('last') &&
			isLastTaskOnCurrentList(unfilteredTasks, id);

		if (fetchNextTasksPageForFooter) {
			dispatch(
				fetchUnfilteredTasks({
					page: unfilteredTasksItem.get('number') + 1,
					enrollmentId
				})
			);
		}
	}, [unfilteredTasksItem, unfilteredTasks, id, dispatch, taskItem, nextTask]);

	const handleTasksNavigation = () => {
		history.push('/tasks');
	};

	const handleSubmit = data => {
		setSubmittedTaskCode(code);
		dispatch(submitTask(data, id));
	};

	const handleMarkSubmitted = () => {
		dispatch(markTaskSubmitted(taskId));
	};

	const processing = processingTask || processingUnfilteredTasks;

	const getContent = () => {
		let content = null;
		const isEsignTask = isEsign(code);
		const esignTaskSubmitted = submittedTaskCode && isEsign(submittedTaskCode);
		const displayMessage = !error && !incomplete && !!status;

		const TaskComponent = (
			<TaskTemplate.default
				taskItem={taskItem}
				onSubmit={handleSubmit}
				markSubmitted={handleMarkSubmitted}
				urlProps={{ location, match }}
			/>
		);

		if (isEsignTask) {
			content = esignTaskSubmitted ? (
				<React.Fragment>
					{displayMessage && <TaskMessage status={status} />} {TaskComponent}
				</React.Fragment>
			) : (
				TaskComponent
			);
		} else {
			content = displayMessage ? (
				instuctional ? TaskComponent : <TaskMessage status={status} />
			) : (
				TaskComponent
			);
		}

		return content;
	};

	return (
		<div className="task-detail">
			<Container
				isLoading={processing}
				loaderError={taskItem.isEmpty() && Boolean(error)}
				size="full"
				modifier="onboard"
				className="wrapper--task-detail fs-container--task-detail"
			>
				<div className="task-detail__back-navigation">
					<FontIcon
						icon={faArrowLeft}
						iconColor={colors.orangeDark}
						iconSize="20px"
						onClick={handleTasksNavigation}
					/>
					<span onClick={handleTasksNavigation}>tasks</span>
				</div>
				{!processing && TaskTemplate && (
					<div
						style={{
							maxWidth: layoutUtils.size.m,
							marginLeft: 'auto',
							marginRight: 'auto'
						}}
					>
						<div className="task-detail__header">
							<StatusCircle
								icon={getCardIcon(status)}
								complete={!incomplete}
								background={colors.orangeDark}
								size={isMobile ? 'medium' : 'large'}
							/>
							<h1>{taskItem.getIn(['task', 'displayName'])}</h1>
							<span className="meta">
								{taskItem.getIn(['task', 'category'])}
							</span>
						</div>

						<div className="fs-onboard__content">{getContent()}</div>
					</div>
				)}
			</Container>
			<FooterContainer custom>
				{!processing && TaskTemplate && (
					<TaskFooter taskItem={nextTask} error={unfilteredTasksError} />
				)}
			</FooterContainer>
		</div>
	);
}

TaskDetail.prototypes = {
	unfilteredTasksItem: PropTypes.instanceOf(Map).isRequired,
	unfilteredTasks: PropTypes.instanceOf(List).isRequired,
	unfilteredTasksError: PropTypes.instanceOf(Map),
	processingUnfilteredTasks: PropTypes.bool.isRequired,
	tasksItem: PropTypes.instanceOf(Map).isRequired,
	tasksProcessing: PropTypes.bool.isRequired,
	processingTask: PropTypes.bool.isRequired,
	taskItem: PropTypes.instanceOf(Map).isRequired,
	error: PropTypes.instanceOf(Map)
};

export default withRouter(withResize(TaskDetail));
