import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const eventsProcessing = () => ({
	type: ActionTypes.MY_EVENTS_PROCESSING
});

const eventsSuccess = data => ({
	type: ActionTypes.MY_EVENTS_SUCCESS,
	data
});

const eventsFailure = error => ({
	type: ActionTypes.MY_EVENTS_FAILURE,
	error
});


export const fetchMyEvents = () => {
	return (dispatch) => {
		dispatch(eventsProcessing());

		return APIClient.get(
			`/my/events?page=0&filter=upcoming`
		).then(
			({ data }) => {
				return dispatch(eventsSuccess(data.content))
			},
			(error) => dispatch(eventsFailure(error))
		);
	};
};