import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontIcon } from 'fs-toolkit-react';

export default function SearchFilterItem({ onFilter, item, selectedValue }) {
	const handleClick = () => {
		onFilter(item.value);
	};

	return (
		<div
			onClick={handleClick}
			className={cn(
				'type-filter-item',
				selectedValue === item.value && 'type-filter-item--selected'
			)}
		>
			{item.icon && <FontIcon icon={item.icon} iconSize="14px" />}
			<span>{item.text}</span>
		</div>
	);
}

SearchFilterItem.propTypes = {
	onClick: PropTypes.func,
	item: PropTypes.object.isRequired,
	selectedValue: PropTypes.string.isRequired
};
