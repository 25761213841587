export const fsCardBody = 'fs-card__body';
export const fsDateBox = 'fs-date-box';
export const fsCardCategory = 'fs-card__category';
export const fsCardCustom = 'fs-card__custom';
export const fsCardError = 'fs-card__error';
export const fsCardExcerpt = 'fs-card__excerpt';
export const fsCardFooter = 'fs-card__footer';
export const fsCardHeader = 'fs-card__header';
export const fsCardHeaderTitle = 'fs-card__header-title';
export const fsCardImage = 'fs-card__image';
export const fsCardListGroup = 'fs-card__list';
export const fsCardListItem = 'fs-card__list-item';
export const fsCardLoader = 'fs-card__loader';
export const fsCardMeta = 'fs-card__meta';
export const fsCardSelector = 'fs-card__single';
export const fsCardTitle = 'fs-card__title';

export const fsCardGroup = 'fs-card__group';
