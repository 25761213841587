import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
    data: [],
    page: 0,
    query: '',
    filter: 'all',
    lastPage: false,
    processing: false,
    noContentMessage: '',
    error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TOOLS_GROUPER_LOAD_SEGMENTS_PROCESSING:
        return state.merge({
            processing: true
        });
    case ActionTypes.TOOLS_GROUPER_INCREMENT_PAGE:
        return state.merge({ page: action.page });
    case ActionTypes.TOOLS_GROUPER_LOAD_SEGMENTS_SUCCESS:
        return state.merge({
            processing: false,
            error: {},
            data: (action.payload.pageable.pageNumber === 0 ? 
                action.payload.content : state.get('data').concat(fromJS(action.payload.content))
            ),
            page: action.payload.pageable.pageNumber,
            lastPage: action.payload.last,
            noContentMessage: ( 
                action.payload.totalElements === 0 ?
                    (state.get('filter') !== 'all' || state.get('query').trim() !== '' ? 
                        "Try adjusting your search or filter to find what you are looking for." : 
                        "There are no segments yet." 
                    ) 
                : ''
            )
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_SEGMENTS_FAILURE:
        return state.merge({
            processing: false,
            error: action.payload
        });
    case ActionTypes.TOOLS_GROUPER_CHANGE_SEGMENT_FILTER:
        return state.merge({
            filter: action.filter
        });
    case ActionTypes.TOOLS_GROUPER_CHANGE_SEARCH:
        return state.merge({
            query: action.query
        });
    case ActionTypes.TOOLS_GROUPER_SEGMENTS_RESET:
        return initialState;
    default:
      return state;
  }
}