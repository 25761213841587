import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ContainerFooterLayout from '../containers/ContainerFooterLayout';
import ContainerDefaultLayout from '../containers/ContainerDefaultLayout';
import { LayoutContext } from 'components/layout/LayoutProvider';
import { fsContainer, fsPageFooterPush } from '../helpers/layoutClassNameList';
import { prefixer } from '../helpers/prefixer';
import union from 'lodash/union';
import compact from 'lodash/compact';
import without from 'lodash/without';


function Container(props) {
	const {
		children,
		className = '',
		size = 'l',
		modifier = null,
		hasHeaderDate = false,
		hasHeaderImage = false,
		style = {},
		innerRef,
		...loaderProps
	 } = props;

	const { state, action } = useContext(LayoutContext);
	const { footerActive } = state;
	const { setPageModifier, removePageModifier } = action;

	const ContainerLayout = footerActive ? ContainerFooterLayout : ContainerDefaultLayout;

	const [containerModifier, setContainerModifier] = useState([size]);

	const containerStyles = [
		fsContainer,
			...prefixer(fsContainer, containerModifier),
		className
	];

	const { isLoading } = loaderProps;

	// Normalize modifier to an array
	const modifierList = JSON.stringify(Array.isArray(modifier) ? modifier : [modifier]);

	useEffect(() => {
		const parsedModifierList = JSON.parse(modifierList);

		const detailPageModifier = [
			...parsedModifierList,
			hasHeaderImage
				? 'detail-image'
				: hasHeaderDate ? 'detail-date' : false
		];

		const detailContainerModifier = hasHeaderDate ? 'detail-date' : '';
		// Need to wait until page loading is complete before applying modifier classes
		// page modifiers will incorrectly position the loader if its applied before the content is rendered
		if (!isLoading) {
			setContainerModifier(state =>
				compact(union(state.concat(...parsedModifierList, detailContainerModifier))));
			setPageModifier(detailPageModifier);
		}

		return () => {
			removePageModifier(detailPageModifier);
			setContainerModifier(state => without(state, detailContainerModifier, ...parsedModifierList));
		};
	}, [modifierList, removePageModifier, setPageModifier, hasHeaderDate, hasHeaderImage, isLoading]);

	const containerRenderer = (
		<div className={cn(containerStyles)} style={{ ...style }} ref={!footerActive ? innerRef : null}>
			<ContainerLayout {...loaderProps}>
				{children}
			</ContainerLayout>
		</div>
	);

	return footerActive
		? <div className={fsPageFooterPush} ref={footerActive ? innerRef : null}>{containerRenderer}</div>
		: containerRenderer;
}

const modifiers = [
	'admin',
	'banner', // group banner
	'center', // error, message sent
	'detail',
	'list',
	'onboard'
];

Container.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
	style: PropTypes.object,

	// width sizes defined in layoututils.js and _variables.scss
	// full always = 100%
	size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'full']),

	modifier: PropTypes.oneOfType([
		PropTypes.oneOf(modifiers),
		PropTypes.arrayOf(PropTypes.oneOf(modifiers))
	]),

	// Flags that will apply specific class names based on the type of content
	hasHeaderImage: PropTypes.bool,
	hasHeaderDate: PropTypes.bool
};

// Forward container ref for dom access
const ContainerWithRef = (props, ref) => (
	<Container {...props} innerRef={ref} />
);

export default React.forwardRef(ContainerWithRef);