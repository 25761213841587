import findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import { fromJS, List } from 'immutable';

export function updateReactionProfileId(reactions, type, userId) {
	const reactionIndex = findIndex(reactions, { profileId: null, type });
	const reactionsCopy = [...reactions];
	if (~reactionIndex) {
		reactionsCopy.splice(reactionIndex, 1, {
			...reactions[reactionIndex],
			profileId: userId
		});
	}
	return reactionsCopy;
}

// Shortcut functions to handle both normal js and immutableJS
const isEmpty = (object, immutable) =>
	immutable ? object.isEmpty() : _isEmpty(object)
const has = (object, property, immutable) =>
	immutable ? object.has(property) : _has(object, property);
const get = (path, object, immutable = false) =>
	immutable ? object.getIn(path) : _get(object, path);

export function findParentId(id, child, parent, immutable = false) {
	const pathProp = 'parentId';
	let searchId = id;
	let done = false;

	if (!isEmpty(child, immutable) && !isEmpty(parent, immutable) && searchId) {
		do {
			const parentHasSearchId = has(parent, searchId.toString(), immutable);
			const isSearchIdParentRoot = get([searchId.toString(), pathProp], parent, immutable) === null;
			if (parentHasSearchId && isSearchIdParentRoot) {
				done = true;
			} else if (has(child, searchId.toString(), immutable)) {
				searchId = get([searchId.toString(), pathProp], child, immutable);
			} else {
				done = true;
			}
		} while (!done);
	}

	return done && searchId;
}

export function addComments(state, comments = []) {
	return comments.reduce((newState, comment) => {
		const { id } = comment;
		if (newState.get('allIds').includes(id)) return newState;
		return newState
			.setIn(['byId', id.toString()], fromJS(comment))
			.setIn(['byId', id.toString(), 'replies'], List())
			.updateIn(['allIds'], listOfIds => listOfIds.concat(id));
	}, state);
}

export function addReplies(state, replies) {
	return replies.reduce((newState, reply) => {
		const { id } = reply;
		// only add new replies
		if (newState.get('allIds').includes(id)) return newState;
		return newState
			.setIn(['byId', id.toString()], fromJS(reply))
			.updateIn(['allIds'], listOfIds => listOfIds.concat(id));
	}, state);
}

export function updateCommentReplies(state, replies) {
	return replies.reduce((newState, reply) => {
		const { id, rootParentId } = reply;

		if ( // only concat new replies
			typeof rootParentId !== 'number'  ||
			newState.getIn(['byId', rootParentId.toString(), 'replies']).includes(id)
		) return newState;

		return newState
			.updateIn(['byId', rootParentId.toString(), 'replies'],
				replies => replies.concat(id));
	}, state);
}

export function updateComments(state, comments) {
	return comments.reduce((newState, comment) => {
		const { id } = comment;
		if (!newState.get('allIds').includes(id)) return newState;
		 return newState
			.mergeDeepIn(['byId', id.toString()], fromJS(comment))
		 	.setIn(['byId', id.toString(), 'reactions'], fromJS(comment.reactions));
	}, state);
}

export function commentChunks(comments) {
	return comments.reduce((result, comment) => {
		if (comment.parentId === null)
			return Object.assign({}, result, { comments: result.comments.concat(comment) });
		return Object.assign({}, result, { replies: result.replies.concat(comment) });
	}, { comments: [], replies: [] });
}

export function getRepliesWithRootParent(replies, commentsById, repliesById) {
	return replies.map(reply => {
		const rootParentId = findParentId(reply.parentId, repliesById, commentsById, true);
		return { ...reply, rootParentId };
	});
}