import { useEffect } from 'react';



function useActivePanel(activatePanelCallback, option = {}) {
	const defaultOptions = { negate: false };

	const { negate } = Object.assign({}, defaultOptions, option);

	useEffect(() => {
		activatePanelCallback(!negate);
		return () => activatePanelCallback(negate);
	}, [activatePanelCallback, negate]);
}

export default useActivePanel;