import { fromJS } from 'immutable';
import { makeActionCreator } from '../utils/reducerUtils';
import KnowledgeBaseAPI from 'api/knowledge-base/KnowledgeBaseAPI';
import { capitalize } from 'utils/globalUtils';

// actions
const KB_CATEGORIES_PROCESSING = 'KB_CATEGORIES_PROCESSING';
const KB_CATEGORIES_SUCCESS = 'KB_CATEGORIES_SUCCESS';
const KB_CATEGORIES_FAILURE = 'KB_CATEGORIES_FAILURE';

// reducer
const initialState = fromJS({
	categories: [],
	processing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case KB_CATEGORIES_PROCESSING:
			return state.merge({
				processing: true
			});
		case KB_CATEGORIES_SUCCESS:
			return state.merge({
				processing: false,
				categories: action.payload
			});
		case KB_CATEGORIES_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false
			});
		default:
			return state;
	}
}

const processing = makeActionCreator(KB_CATEGORIES_PROCESSING);
const success = makeActionCreator(KB_CATEGORIES_SUCCESS, 'payload');
const failure = makeActionCreator(KB_CATEGORIES_FAILURE, 'payload');

export const KBCategoriesActions = {
	getCategories(type) {
		return (dispatch, getState) => {
			dispatch(processing());

			const groupId = getState().getIn(['app', 'group', 'id']);
			return KnowledgeBaseAPI.getCategories({
				groupId,
				type: capitalize(type)
			}).then(
				({ data }) => dispatch(success(data)),
				err => dispatch(failure(err))
			);
		};
	}
};
