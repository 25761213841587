import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	item: {},
	tasks: [],
	processing: false,
	error: null
});

const getTasks = (tasks, tasksItem) => {
	const newTasks = tasksItem.content;
	return tasksItem.number > 0 ? tasks.toJS().concat(newTasks) : newTasks;
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.UNFILTERED_TASKS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.UNFILTERED_TASKS_SUCCESS:
			const tasks = getTasks(state.get('tasks'), action.data);
			return state.merge({
				processing: false,
				item: action.data,
				tasks: tasks
			});
		case ActionTypes.UNFILTERED_TASKS_FAILURE:
			return state.merge({
				error: action.error,
				processing: false
			});
		case ActionTypes.RESET_UNFILTERED_TASKS:
			return initialState;
		default:
			return state;
	}
}
