import { colors } from 'fs-toolkit-react';
import { faFlag ,faComment } from '@fortawesome/pro-regular-svg-icons';

export default {
	'ticket updated': {
		icon: 'faTicketAlt',
		iconSize: '2rem',
		umbrella: 'support',
		color: colors.aqua,
		root: '/support',
		path: '/ticket',
		propToPath: ['caseId']
	},
	'event updated': {
		icon: 'faCalendarAlt',
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/events',
		propToPath: ['eventId', 'sequenceId']
	},
	'rsvp canceled': {
		icon: 'faCalendarAlt',
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/events',
		propToPath: ['eventId', 'sequenceId']
  },
  'rsvp removed': {
		icon: 'faCalendarAlt',
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/events',
		propToPath: []
  },
	'event canceled': {
		icon: 'faCalendarAlt',
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/events',
		propToPath: []
  },
  'event rescheduled': {
		icon: 'faCalendarAlt',
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/events',
		propToPath: ['eventId', 'sequenceId']
  },
  'event occurrence updated': {
		icon: 'faCalendarAlt',
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/events',
		propToPath: ['eventId', 'sequenceId']
	},
	'comment reported': {
		icon: faFlag,
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/topics',
		propToPath: ['topicId', 'commentId']
	},
	'comment moderated': {
		icon: faFlag,
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/topics',
		propToPath: ['topicId', 'commentId']
	},
	'replied to comment': {
		icon: faComment,
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/topics',
		propToPath: ['topicId', 'commentId']
	},
	'new comment': {
		icon: faComment,
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/topics',
		propToPath: ['topicId', 'commentId']
	},
	'comment cleared': {
		icon: faFlag,
		iconSize: '1.75rem',
		umbrella: 'connect',
		color: colors.lime,
		root: '/connect',
		path: '/topics',
		propToPath: ['topicId', 'commentId']
	}
}