import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	HeadingDropdown,
	DropdownItem,
	ButtonRefactor as Button,
	Card
} from 'fs-toolkit-react';
import { Container } from 'components/layout';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import SearchInput from 'components/shared/searchInput/SearchInput';
import InfiniteScroller from 'components/shared/InfiniteScroller';
import Error from 'components/views/error/Error';
import ActionTypes from 'actions/actionTypes';
import { loadSegments } from 'actions/grouperActions';
import { List, Map } from 'immutable';

class Segments extends React.Component {
	componentDidMount() {
		this._fetch(0);
	}

	componentWillUnmount() {
		const { dispatch } = this.props;
		dispatch({ type: ActionTypes.TOOLS_GROUPER_SEGMENTS_RESET });
	}

	handleSearch = () => {
		this._fetch(0);
	};

	handleKeyup = evt => {
		if (evt.key === 'Enter') {
			this.handleSearch();
		}
	};

	handleSearchChange = evt => {
		const { dispatch } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_SEARCH,
			query: evt.currentTarget.value
		});
	};

	handleFilterChange = val => {
		const { dispatch } = this.props;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_CHANGE_SEGMENT_FILTER,
			filter: val
		});
		setTimeout(() => this._fetch(0), 100);
	};

	fetchMoreSegments = () => {
		const { page, dispatch } = this.props;
		const nextPage = page + 1;
		dispatch({
			type: ActionTypes.TOOLS_GROUPER_INCREMENT_PAGE,
			page: nextPage
		});
		setTimeout(() => this._fetch(nextPage), 100);
	};

	_fetch = page => {
		const { dispatch, query, filter } = this.props;
		dispatch(loadSegments({ page, query: query.trim(), filter }));
	};

	render() {
		const {
			history,
			data,
			query,
			filter,
			page,
			processing,
			lastPage,
			error,
			noContentMessage
		} = this.props;

		return (
			<Container>
				<React.Fragment>
					<div className="list-header">
						<HeadingDropdown
							gutter
							defaultHeading="All Segments"
							headingLevel="h2"
							onChange={this.handleFilterChange}
						>
							{({ onClick, selected }) => (
								<React.Fragment>
									<DropdownItem
										value="all"
										onClick={onClick}
										selected={filter === 'all'}
									>
										All Segments
									</DropdownItem>
									<DropdownItem
										value="rules"
										onClick={onClick}
										selected={filter === 'rules'}
									>
										Without Rules
									</DropdownItem>
								</React.Fragment>
							)}
						</HeadingDropdown>
						<Button onClick={() => history.push('/tools/grouper/segments/new')}
										style={{ flex: '1 0 auto'}}
						>New Segment</Button>
					</div>
					<SearchInput
						placeholder="Search"
						onChange={this.handleSearchChange}
						onIconClick={this.handleSearch}
						value={query}
						onKeyUp={this.handleKeyup}
					/>
					<LoaderWrapper
						loaderError={!error.isEmpty()}
						isLoading={processing && page === 0}
						errorComponent={
							<Error
								icon="faFrownLight"
								title="Error"
								message="Sorry, an error occurred while loading the segments."
							/>
						}
					>
						{!data.isEmpty() ? (
							<InfiniteScroller
								next={this.fetchMoreSegments}
								hasMore={!lastPage}
								processing={processing}
							>
								<React.Fragment>
									{data.map(segment => {
										return (
											<Card
												key={segment.get('id')}
												onClick={() => {
													this.props.history.push(
														`/tools/grouper/segments/${segment.get('id')}`
													);
												}}
												modifier={['full-width']}
											>
												<Card.Body>
													<Card.Title lines={1}>
														{segment.get('title')}
													</Card.Title>
													<Card.Meta lines={{ mobile: 1, desktop: 2 }}>
														{[
															`${segment.get('profileCount')} ${
																segment.get('profileCount') === 1
																	? 'Person'
																	: 'People'
															}`,
															`${segment.get('ruleCount')} ${
																segment.get('ruleCount') === 1
																	? 'Rule'
																	: 'Rules'
															}`,
															segment.get('description')
														]
															.filter(
																element => element !== '' && element !== null
															)
															.join(' ᛫ ')}
													</Card.Meta>
												</Card.Body>
											</Card>
										);
									})}
								</React.Fragment>
							</InfiniteScroller>
						) : (
							<Error
								icon="faFrownLight"
								title="No Segments found"
								message={noContentMessage}
							/>
						)}
					</LoaderWrapper>
				</React.Fragment>
			</Container>
		);
	}
}

Segments.propTypes = {
	data: PropTypes.instanceOf(List),
	page: PropTypes.number,
	query: PropTypes.string,
	filter: PropTypes.string,
	lastPage: PropTypes.bool,
	noContentMessage: PropTypes.string,
	processing: PropTypes.bool,
	error: PropTypes.instanceOf(Map)
};

function mapStateToProps(state) {
	return {
		data: state.getIn(['segments', 'data']),
		page: state.getIn(['segments', 'page']),
		query: state.getIn(['segments', 'query']),
		filter: state.getIn(['segments', 'filter']),
		lastPage: state.getIn(['segments', 'lastPage']),
		noContentMessage: state.getIn(['segments', 'noContentMessage']),
		processing: state.getIn(['segments', 'processing']),
		error: state.getIn(['segments', 'error'])
	};
}

export default connect(mapStateToProps)(Segments);
