import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ContainerLite from './ContainerLite';

function FeaturedWrapper({ children, className, size }) {
	return (
		<ContainerLite className={cn('fs-featured-wrapper', className)} size={size}>
			{children}
		</ContainerLite>
	)
}

FeaturedWrapper.propTypes = {
	// width sizes defined in layoututils.js and _variables.scss
	// full always = 100%
	size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'full']),
};

export default FeaturedWrapper;