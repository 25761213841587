import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { formatStringForUrl } from 'utils/urlUtils';
import { Card } from 'components/patterns/card';
import { getBasePath } from 'utils/globalUtils';
import { formatDate } from 'utils/dateTimeUtils';
import moment from 'moment';

class ArticleGridItem extends React.Component {
	static propTypes = {
		article: PropTypes.instanceOf(Map).isRequired,
		group: PropTypes.instanceOf(Map),
		groupAvatarUrl: PropTypes.string,
		groupTitle: PropTypes.string,
		timezone: PropTypes.string,
		type: PropTypes.oneOf(['event', 'story']).isRequired
	};

	static defaultProps = {
		group: Map()
	};

	getUrl = () => {
		const basePath = getBasePath(this.props.group, '/connect');
		let url = '';
		if (this.props.type === 'story') {
			const title = formatStringForUrl(this.props.article.get('title'));
			url = `${basePath}/stories/${this.props.article.get('id')}/${title}`;
		} else if (this.props.type === 'event') {
			const title = formatStringForUrl(this.props.article.get('title'));
			url = `${basePath}/events/${this.props.article.get(
				'eventId'
			)}/${this.props.article.get('sequenceId')}/${title}`;
		}
		return url;
	};

	render = () => {
		const { article, groupAvatarUrl, groupTitle, timezone, type } = this.props;

		const endsAt = article.get('endsAt');
		const eventDate = article.get('startsAt');
		const eventDateFormat = 'ddd MMM D @ h:mm a z';
		const oneDayEvent = moment(eventDate).isSame(endsAt, 'day');
		const image = article.get('image');
		const title = article.get('title');
		const storyDate = article.get('publishedAt');
		const articleGroup = article.get('group');
		const storyDateFormat = 'ddd MMM D';
		const summary = article.get('summary');
		const isStory = type === 'story';
		const isEvent = type === 'event';
		const getImage = () => (image ? image.get('url') : null);
		const getDate = () => {
			return formatDate({
				date: isEvent ? eventDate : storyDate,
				format: isEvent ? eventDateFormat : storyDateFormat,
				timezone: isEvent ? timezone : undefined
			}).tz();
		};

		return (
			<Card RouteComponent={Link} RouteProps={{ to: this.getUrl() }}>
				{groupTitle && (
					<Card.Header>
						<Card.Avatar name={groupTitle} url={groupAvatarUrl} />
						<Card.Title lines={1}>{title}</Card.Title>
					</Card.Header>
				)}
				{isEvent ? (
					<Card.Image backgroundImgUrl={getImage()} position="top">
						<Card.Date
							date={eventDate}
							endDate={!oneDayEvent && endsAt ? endsAt : null}
						/>
					</Card.Image>
				) : (
					<Card.Image backgroundImgUrl={getImage()} position="top" />
				)}
				<Card.Body>
					<Card.Title lines={2}>{title}</Card.Title>
					<Card.Meta>{getDate()}</Card.Meta>
					<Card.Excerpt lines={3}>{summary}</Card.Excerpt>
				</Card.Body>
				<Card.Footer>
					{articleGroup.get('id') !== 1 && (
						<Card.Category label={articleGroup.get('name')} hideOnMobile />
					)}
					{isStory && !article.get('published') && (
						<Card.Badge label="Draft" slim />
					)}
				</Card.Footer>
			</Card>
		);
	};
}

export default withRouter(ArticleGridItem);
