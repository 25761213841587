import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  processing: false,
  submitted: false,
  error: null,
  processingMoreCases: false
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.CASE_RESET_ERROR:
      return state.merge({
        error: null
      });
    case ActionTypes.CASE_PAGE_PROCESSING:
      return state.merge({
        processing: false,
        submitted: false,
        processingMoreCases: true
      });
    case ActionTypes.CASE_PROCESSING:
      return state.merge({
        processing: true,
        submitted: false,
        processingMoreCases: false
      });
    case ActionTypes.CASE_SUCCESS:
      return state.merge({
        processing: false,
        submitted: false,
        processingMoreCases: false,
        data: action.data,
        error: false
      });
    case ActionTypes.CASE_FAILURE:
      return state.merge({
        processing: false,
        submitted: false,
        processingMoreCases: false,
        error: action.error.data
      });
    case ActionTypes.CASE_CREATE:
      return state.merge({
        data: action.data,
        error: null
      });
    case ActionTypes.CASE_SUBMITTED:
      return state.merge({
        data: action.data,
        submitted: true,
        processing: false,
        processingMoreCases: false
      });
    default:
      return state;
  }
}
