import { fromJS } from 'immutable';
import * as TopicsAPI from 'api/topics/TopicsAPI';
import { growl, types as growlTypes, getErrorMessage } from 'utils/errorUtils';
import { makeActionCreator } from '../utils/reducerUtils';
import { createLoaderSelector } from './loader';
import { createErrorSelector } from './error';
import * as AdminTopicAPI from 'api/admin/AdminTopicsAPI';

// Types

// Data management
const UPDATE_TOPIC = 'UPDATE_TOPIC';
const RESET_TOPIC = 'RESET_TOPIC';

const FOLLOW_TOPIC = 'FOLLOW_TOPIC';
const FOLLOW_TOPIC_PROCESSING = 'FOLLOW_TOPIC_PROCESSING';
const FOLLOW_TOPIC_SUCCESS = 'FOLLOW_TOPIC_SUCCESS';
const FOLLOW_TOPIC_FAILURE = 'FOLLOW_TOPIC_FAILURE';

const UNFOLLOW_TOPIC = 'UNFOLLOW_TOPIC';
const UNFOLLOW_TOPIC_PROCESSING = 'UNFOLLOW_TOPIC_PROCESSING';
const UNFOLLOW_TOPIC_SUCCESS = 'UNFOLLOW_TOPIC_SUCCESS';
const UNFOLLOW_TOPIC_FAILURE = 'UNFOLLOW_TOPIC_FAILURE';

const CLOSE_COMMENTING = 'CLOSE_COMMENTING';
const CLOSE_COMMENTING_PROCESSING = 'CLOSE_COMMENTING_PROCESSING';
const CLOSE_COMMENTING_SUCCESS = 'CLOSE_COMMENTING_SUCCESS';
const CLOSE_COMMENTING_FAILURE = 'CLOSE_COMMENTING_FAILURE';

const OPEN_COMMENTING = 'OPEN_COMMENTING';
const OPEN_COMMENTING_PROCESSING = 'OPEN_COMMENTING_PROCESSING';
const OPEN_COMMENTING_SUCCESS = 'OPEN_COMMENTING_SUCCESS';
const OPEN_COMMENTING_FAILURE = 'OPEN_COMMENTING_FAILURE';

const FETCH_TOPIC = 'FETCH_TOPIC';
const FETCH_TOPIC_PROCESSING = 'FETCH_TOPIC_PROCESSING';
const FETCH_TOPIC_SUCCESS = 'FETCH_TOPIC_SUCCESS';
const FETCH_TOPIC_FAILURE = 'FETCH_TOPIC_FAILURE';

const CREATE_TOPIC = 'CREATE_TOPIC';
const CREATE_TOPIC_PROCESSING = 'CREATE_TOPIC_PROCESSING';
const CREATE_TOPIC_SUCCESS = 'CREATE_TOPIC_SUCCESS';
const CREATE_TOPIC_FAILURE = 'CREATE_TOPIC_FAILURE';

const EDIT_TOPIC = 'EDIT_TOPIC';
const EDIT_TOPIC_PROCESSING = 'EDIT_TOPIC_PROCESSING';
const EDIT_TOPIC_SUCCESS = 'EDIT_TOPIC_SUCCESS';
const EDIT_TOPIC_FAILURE = 'EDIT_TOPIC_FAILURE';

const DELETE_TOPIC = 'DELETE_TOPIC';
const DELETE_TOPIC_PROCESSING = 'DELETE_TOPIC_PROCESSING';
const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
const DELETE_TOPIC_FAILURE = 'DELETE_TOPIC_FAILURE';


// Reducer
const topicInitialState = fromJS({
	data: {},
	metrics: {}
});

export default function topicReducer(state = topicInitialState, action) {
	switch (action.type) {
		case UPDATE_TOPIC:
			return state.mergeDeep({
				data: fromJS(action.payload)
			});
		case RESET_TOPIC:
			return topicInitialState;
		default:
			return state;
	}
}


export const topicActions = {
	// action creators
	updateTopic: makeActionCreator(UPDATE_TOPIC, 'payload'),
	resetTopic: makeActionCreator(RESET_TOPIC),

	followTopicProcessing: makeActionCreator(FOLLOW_TOPIC_PROCESSING),
	followTopicSuccess: makeActionCreator(FOLLOW_TOPIC_SUCCESS),
	followTopicFailure: makeActionCreator(FOLLOW_TOPIC_FAILURE, 'payload'),

	unfollowTopicProcessing: makeActionCreator(UNFOLLOW_TOPIC_PROCESSING),
	unfollowTopicSuccess: makeActionCreator(UNFOLLOW_TOPIC_SUCCESS),
	unfollowTopicFailure: makeActionCreator(UNFOLLOW_TOPIC_FAILURE, 'payload'),

	closeCommentingProcessing: makeActionCreator(CLOSE_COMMENTING_PROCESSING),
	closeCommentingSuccess: makeActionCreator(CLOSE_COMMENTING_SUCCESS),
	closeCommentingFailure: makeActionCreator(CLOSE_COMMENTING_FAILURE, 'payload'),

	openCommentingProcessing: makeActionCreator(OPEN_COMMENTING_PROCESSING),
	openCommentingSuccess: makeActionCreator(OPEN_COMMENTING_SUCCESS),
	openCommentingFailure: makeActionCreator(OPEN_COMMENTING_FAILURE, 'payload'),

	fetchTopicProcessing: makeActionCreator(FETCH_TOPIC_PROCESSING),
	fetchTopicSuccess: makeActionCreator(FETCH_TOPIC_SUCCESS),
	fetchTopicFailure: makeActionCreator(FETCH_TOPIC_FAILURE, 'payload'),

	createTopicProcessing: makeActionCreator(CREATE_TOPIC_PROCESSING),
	createTopicSuccess: makeActionCreator(CREATE_TOPIC_SUCCESS),
	createTopicFailure: makeActionCreator(CREATE_TOPIC_FAILURE, 'payload'),

	editTopicProcessing: makeActionCreator(EDIT_TOPIC_PROCESSING),
	editTopicSuccess: makeActionCreator(EDIT_TOPIC_SUCCESS),
	editTopicFailure: makeActionCreator(EDIT_TOPIC_FAILURE, 'payload'),

	deleteTopicProcessing: makeActionCreator(DELETE_TOPIC_PROCESSING),
	deleteTopicSuccess: makeActionCreator(DELETE_TOPIC_SUCCESS),
	deleteTopicFailure: makeActionCreator(DELETE_TOPIC_FAILURE, 'payload'),

	// actions with side effects
	getTopic(params, onDone) {
		return (dispatch) => {
			dispatch(topicActions.fetchTopicProcessing());
			return TopicsAPI.getTopic(params)
				.then(({ data }) => {
					dispatch(topicActions.fetchTopicSuccess());
					dispatch(topicActions.updateTopic(data));
					onDone && onDone();
				}, error => {
					dispatch(topicActions.fetchTopicFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		};
	},

	createTopic(params, onDone) {
		return (dispatch) => {
			dispatch(topicActions.createTopicProcessing());
			return AdminTopicAPI.createTopic(params)
				.then(({ data }) => {
					dispatch(topicActions.createTopicSuccess());
					dispatch(topicActions.updateTopic(data));
					onDone && onDone(data);
				}, error => {
					dispatch(topicActions.createTopicFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		};
	},

	deleteTopic(params, onDone) {
		return dispatch => {
			dispatch(topicActions.deleteTopicProcessing());
			return AdminTopicAPI.deleteTopic(params)
				.then(({ data }) => {
					dispatch(topicActions.deleteTopicSuccess());
					onDone && onDone();
				}, error => {
					dispatch(topicActions.deleteTopicFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		};
	},

	editTopic(params, onDone) {
		return dispatch => {
			dispatch(topicActions.editTopicProcessing());
			return AdminTopicAPI.updateTopic(params)
				.then(({ data }) => {
					dispatch(topicActions.editTopicSuccess());
					dispatch(topicActions.updateTopic(data));
					onDone && onDone(data);
				}, error => {
					dispatch(topicActions.editTopicFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		};
	},

	followTopic(params) {
		return dispatch => {
			dispatch(topicActions.followTopicProcessing());
			return TopicsAPI.followTopic(params)
				.then(response => response.data)
				.then(data => {
					dispatch(topicActions.updateTopic(data));
					dispatch(topicActions.followTopicSuccess());
				}, error => {
					dispatch(topicActions.followTopicFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		}
	},

	unfollowTopic(params) {
		return dispatch => {
			dispatch(topicActions.unfollowTopicProcessing());
			return TopicsAPI.unfollowTopic(params)
				.then(response => response.data)
				.then(data => {
					dispatch(topicActions.updateTopic(data));
					dispatch(topicActions.unfollowTopicSuccess());
				}, error => {
					dispatch(topicActions.unfollowTopicFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		}
	},

	closeCommenting(params, onDone) {
		return dispatch => {
			dispatch(topicActions.closeCommentingProcessing());
			return AdminTopicAPI.closeCommenting(params)
				.then(response => response.data)
				.then(data => {
					dispatch(topicActions.updateTopic(data));
					dispatch(topicActions.closeCommentingSuccess());
					onDone && onDone();
				}, error => {
					dispatch(topicActions.closeCommentingFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				})
		}
	},

	openCommenting(params, onDone) {
		return dispatch => {
			dispatch(topicActions.openCommentingProcessing());
			return AdminTopicAPI.openCommenting(params)
				.then(response => response.data)
				.then(data => {
					dispatch(topicActions.updateTopic(data));
					dispatch(topicActions.openCommentingSuccess());
					onDone && onDone();
				}, error => {
					dispatch(topicActions.openCommentingFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				})
		}
	},

};

// Selectors
export const topicSelectors = {
	getFollowTopicLoading: createLoaderSelector([FOLLOW_TOPIC]),
	getFollowTopicError: createErrorSelector([FOLLOW_TOPIC]),

	getUnfollowTopicLoading: createLoaderSelector([UNFOLLOW_TOPIC]),
	getUnfollowTopicError: createErrorSelector([UNFOLLOW_TOPIC]),

	getCloseCommentingLoading: createLoaderSelector([CLOSE_COMMENTING]),
	getCloseCommentingError: createErrorSelector([CLOSE_COMMENTING]),

	getOpenCommentingLoading: createLoaderSelector([OPEN_COMMENTING]),
	getOpenCommentingError: createErrorSelector([OPEN_COMMENTING]),

	getTopicLoading: createLoaderSelector([FETCH_TOPIC]),
	getTopicError: createErrorSelector([FETCH_TOPIC]),

	getCreateTopicLoading: createLoaderSelector([CREATE_TOPIC]),
	getCreateTopicError: createErrorSelector([CREATE_TOPIC]),

	getEditTopicLoading: createLoaderSelector([EDIT_TOPIC]),
	getEditTopicError: createErrorSelector([EDIT_TOPIC]),

	getDeleteTopicLoading: createLoaderSelector([DELETE_TOPIC]),
	getDeleteTopicError: createErrorSelector([DELETE_TOPIC]),

	getTopic: state => state.getIn(['topic', 'data'])
};