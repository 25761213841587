import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'fs-toolkit-react';
import cn from 'classnames';

class LayoutMini extends React.Component {
	render() {
		const {
			children,
			classes,
			icon,
			iconColor,
			iconSize,
			message,
			subtext,
			title,
			type } = this.props;

		return (
			<div className={ cn('width--mini flex flex-column flex-align-center content--mini', classes ) }>
				{ icon ?
					<FontIcon icon={ icon } iconColor={ iconColor ? iconColor : '#838383' } iconSize={ iconSize ? iconSize : '8rem' } />
				: null }

				{ title &&
					type === 'error' ?
						<h3 className='content--mini__title'>{ title }</h3>
					: <h2 className='content--mini__title'>{ title }</h2>
				}
				{ message && <p className='large content--mini__message' dangerouslySetInnerHTML={{__html: message  }} /> }
				{ subtext && <p className='content--mini__subtext'>{ subtext }</p> }

				{ children }
			</div>
		);
	}
}

LayoutMini.propTypes = {
	classes: PropTypes.oneOfType([
	  PropTypes.array,
    PropTypes.string
  ]),
	icon: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string
	]),
	iconColor: PropTypes.string,
	iconSize: PropTypes.string,
	title: PropTypes.string,
	message: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	]),
	subtext: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string
	]),
	type: PropTypes.string
};

export default LayoutMini;