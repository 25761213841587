import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	processing: false,
	error: {}
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.CLASS_GRADES_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.CLASS_GRADES_SUCCESS:
			return state.merge({
				processing: false,
				data: action.payload,
				error: {}
			});
		case ActionTypes.CLASS_GRADES_FAILURE:
			return state.merge({
				processing: false,
				error: action.payload
			});
		default:
			return state;
	}
}