import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const taskCategoriesProcessing = () => ({
  type: ActionTypes.TASK_CATEGORIES_PROCESSING
});

const taskCategoriesSuccess = data => ({
  type: ActionTypes.TASK_CATEGORIES_SUCCESS,
  data
});

const taskCategoriesFailure = error => ({
  type: ActionTypes.TASK_CATEGORIES_FAILURE,
  error
});

export const fetchTaskCategories = (params) => {
  return (dispatch, getState) => {
    dispatch(taskCategoriesProcessing());

    return APIClient.get('/task_categories').then(
      ({ data }) => dispatch(taskCategoriesSuccess(data)),
      ({ response: { data } }) => dispatch(taskCategoriesFailure(data))
    );
  };
};