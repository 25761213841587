import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Error from 'components/views/error/Error';
import { Container } from 'components/layout';
import {
	Avatar,
	ButtonRefactor as Button, FontIcon, Typography, colors, IconCircle
} from 'fs-toolkit-react';
import {
	isGlobalAdmin,
} from 'utils/profileUtils';
import withResize from 'components/HOC/WithResize';
import { Map, List } from 'immutable';
import {
	faFacebookF,
	faInstagram,
	faLinkedinIn,
	faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { fetchPublicProfile, resetPublicProfile } from 'actions/publicProfileActions';
import moment from 'moment';
import { formatDate } from '../../../../utils/globalUtils';
import { faUserCog } from '@fortawesome/pro-solid-svg-icons';
import PeopleDetailAdmin from './PeopleDetailAdmin';

class PeopleDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isAdminPanelOpen: false
		}
	}

	componentDidMount() {
		const { dispatch, match } = this.props;
		const id = match.params.fsOneId;

		dispatch(fetchPublicProfile(id));
	}

	componentWillUnmount() {
		this.props.dispatch(resetPublicProfile());
	}

	getIcon(platform) {
		switch (platform) {
			case 'Facebook':
				return faFacebookF;
			case 'Instagram':
				return faInstagram;
			case 'Twitter':
				return faTwitter;
			case 'LinkedIn':
				return faLinkedinIn;
			default:
				return faGlobe;
		}
	}

	getIconSize(platform) {
		switch (platform) {
			case 'Facebook':
				return '1.25rem';
			case 'Twitter':
				return '2rem';
			case 'Other':
				return '1.939rem';
			default:
				return '1.75rem';
		}
	}

	handleNonAppRedirect = url => () => {
		window.open(url, '_blank');
	}

	toggleAdminPanel = () => {
		this.setState({ isAdminPanelOpen: !this.state.isAdminPanelOpen });
	}

	render() {
		const {
			currentProfile,
			isMobile,
			publicProfile,
			error,
			processing,
			socialIdentifiers,
		} = this.props;

		const isAdmin =
			isGlobalAdmin({ profile: currentProfile, adminType: 'profileViewer' }) ||
			isGlobalAdmin({ profile: currentProfile, adminType: 'profileAdmin' }) ||
			isGlobalAdmin({
				profile: currentProfile,
				adminType: 'impersonation'
			});

		const canEditPublicProfile = (currentProfile.get('fsOneId') === publicProfile.get('fsOneId'));

		const alternateEmail = publicProfile.get('alternateEmail');
		const universityEmail = publicProfile.get('universityEmail');
		const fullName = `${publicProfile.get('firstName')} ${publicProfile.get('lastName')}`;

		return (
			<Fragment>
				<Container
					isLoading={processing}
					className="wrapper--people-profile"
					size={isAdmin && this.state.isAdminPanelOpen ? 's' : 'l'}
					modifier='center'
					loaderError={error}
					errorComponent={
						<Error
							icon="faSadTearLight"
							title="Profile Error"
							message="An error occurred while loading the profile. Please try again."
						/>
					}
				>
					{this.state.isAdminPanelOpen ? <PeopleDetailAdmin {...this.props}/> :
						<div className='people-public-profile'>
							<Avatar
								name={fullName}
								size={isMobile ? 'medium' : 'large'}
								url={publicProfile.get('avatarUrl')}
								className='public-avatar'
							/>
							<Typography component='h1' className='public-name'>{fullName}</Typography>
							{publicProfile.get('pronouns') &&
							<Typography modifier='meta' className='public-pronouns'>{publicProfile.get('pronouns')}</Typography>}

							{socialIdentifiers && socialIdentifiers.size > 0 && <div className='public-social-icons'>
								{socialIdentifiers.map((platformItem, i) => {
									return (
										<FontIcon key={i}
															iconSize={this.getIconSize(platformItem.get('platform'))}
															icon={this.getIcon(platformItem.get('platform'))}
															iconColor={colors.gray}
															onClick={() => window.open(platformItem.get('identifier'))}/>
									)
								})}
							</div>
							}

							{canEditPublicProfile &&
							<Button modifier={['secondary', isMobile ? 'full' : 'large']}
											className='profile-edit-button'
											onClick={this.handleNonAppRedirect(process.env.REACT_APP_PROFILE_URL)}>Edit Profile</Button>
							}

							<div className='public-details'>
								{publicProfile.get('aboutMe') &&
								<Typography modifier='large' className='public-about'>{publicProfile.get('aboutMe')}</Typography>}

								{(universityEmail || alternateEmail) &&
									<div className='public-email-list'>
										<Typography modifier='label'>Email</Typography>

										<ul className='fs-reset--list'>
											{universityEmail &&
												<li>
													<a
														className='public-email'
														href={`mailto: ${universityEmail}`}
														target="_blank"
														rel="noopener noreferrer"
													>{universityEmail}</a>
												</li>
											}

											{alternateEmail &&
												<li>
													<a
														className='public-email'
														href={`mailto: ${alternateEmail}`}
														target="_blank"
														rel="noopener noreferrer"
													>{alternateEmail}</a>
												</li>
											}
										</ul>
									</div>
								}

								{publicProfile.get('enrollments') && publicProfile.get('enrollments').size > 0 &&
								<div className='public-degree-list'>
									<Typography modifier='label'>Degree Program</Typography>

									<ul className='fs-reset--list'>
										{publicProfile.get('enrollments').map((enrollment, i) => {
											const isAlumni = !(moment(enrollment.get('graduationDate')).isAfter(moment(new Date())));

											return (
												<li key={i}>
													<Typography modifier='large'>{enrollment.get('program')}</Typography>
													<Typography
														modifier={['meta', 'medium']}>{isAlumni ? 'Graduated' : 'Graduating'} {formatDate(enrollment.get('graduationDate'), 'MMMM YYYY')}</Typography>
												</li>
											)
										})}
									</ul>
								</div>
								}
							</div>
						</div>
					}

					{isAdmin ?
						!!this.state.isAdminPanelOpen ?
							<IconCircle
								className="admin-circle"
								icon='faTimesLight'
								color={colors.grayDarkest}
								background={colors.white}
								onClick={this.toggleAdminPanel}
							/>
							:
							<IconCircle
								className="admin-circle"
								icon={faUserCog}
								onClick={this.toggleAdminPanel}
							/>
						: null
					}

				</Container>
			</Fragment>
		);
	}
}

PeopleDetail.propTypes = {
	currentProfile: PropTypes.instanceOf(Map).isRequired,
	roles: PropTypes.instanceOf(List).isRequired,
	publicProfile: PropTypes.instanceOf(Map),
	error: PropTypes.any,
	processing: PropTypes.bool,
	socialIdentifiers: PropTypes.instanceOf(List),
};

function mapStateToProps(state) {
	return {
		currentProfile: state.getIn(['profile', 'data']),
		roles: state.getIn(['peopleDetail', 'roles']),
		publicProfile: state.getIn(['publicProfile', 'data']),
		error: state.getIn(['publicProfile', 'error']),
		processing: state.getIn(['publicProfile', 'processing']),
		socialIdentifiers: state.getIn(['publicProfile', 'data', 'socialIdentifiers']),
	};
}

export default connect(mapStateToProps)(withResize(withRouter(PeopleDetail)));
