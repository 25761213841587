import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Segments from './Segments';
import SegmentForm from './SegmentForm';
import Rules from './Rules';
import RuleForm from './RuleForm';
import { isSuperAdmin } from 'utils/profileUtils';

class Grouper extends React.Component {
    componentDidMount() {
        const { history, profile } =  this.props;

        if(!isSuperAdmin(profile)){
            history.replace("/401");
        }
    }

    render() {
        return (
            <div className="grouper">
                <Switch>
                    <Route exact path="/tools/grouper/segments/new" component={SegmentForm} />
                    <Route exact path="/tools/grouper/segments/:id" component={SegmentForm} />
                    <Route path="/tools/grouper/segments" component={Segments} />
                    <Route exact path="/tools/grouper/rules/new" component={RuleForm} />
                    <Route exact path="/tools/grouper/rules/:id" component={RuleForm} />
                    <Route path="/tools/grouper/rules" component={Rules} />
                    <Redirect to="/tools/grouper/segments" />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => ({
	profile: state.getIn(['profile', 'data'])
});

export default connect(mapStateToProps)(Grouper);