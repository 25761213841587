import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  event: {},
  eventProcessing: false,
  eventError: {},
  rsvps: [],
  page: 0,
  lastPage: true,
  search: '',
  filter: 'all',
  rsvpsProcessing: false,
  rsvpsError: {},
  manualCheckInProfile: null,
  checkInProcessing: false
});

const processUpdates = (currentRsvps, updatedRsvp, search, filter) => {
  let updatedRsvps;
  const index =  currentRsvps.findIndex((rsvp) => rsvp.get('id') === updatedRsvp.get('id'));
  if(index !== -1){
      updatedRsvps = currentRsvps.set(index, updatedRsvp);
  } else {
      updatedRsvps = currentRsvps.push(updatedRsvp);
  }

  if (filter === 'checkin') {
    updatedRsvps = updatedRsvps.filter(rsvp => {
      return rsvp.get('checkedIn');
    });
  }

  if (filter === 'notCheckin') {
    updatedRsvps = updatedRsvps.filter(rsvp => {
      return !rsvp.get('checkedIn');
    });
  }

  if (search.trim().length > 0) {
    const tokens = search.trim().toLowerCase().split(' ');
    updatedRsvps = updatedRsvps.filter(rsvp => {
  
      let matches = tokens.map(token => {
        return (
          rsvp
            .getIn(['profile', 'firstName'])
            .match(new RegExp(`^${token}`, 'gi')) ||
          rsvp
            .getIn(['profile', 'lastName'])
            .match(new RegExp(`^${token}`, 'gi')) ||
          (rsvp.getIn(['profile', 'universityEmail','address']) &&
            rsvp
              .getIn(['profile', 'universityEmail','address'])
              .match(new RegExp(`^${token}`, 'gi')))
        );
      });
      return (
        matches.filter(match => {
          return match;
        }).length > 0
      );
    });
  }
  // dedup and resort
  return updatedRsvps.toSet().toList().sortBy((rsvp) => `${rsvp.getIn(['profile','lastName'])} ${rsvp.getIn(['profile','firstName'])}`);
}

const processCancelations = (currentRsvps, canceledRsvp) => {
  return currentRsvps.filter((rsvp) => rsvp.get('id') !== canceledRsvp.get('id'));
}

export default function(state = initialState, action) {
  let rsvpData, data;
  switch (action.type) {
    case ActionTypes.SCANNER_EVENT_SUCCESS: 
      return state.merge({ event: fromJS(action.data), eventError: fromJS({}), eventProcessing: false });
    case ActionTypes.SCANNER_EVENT_PROCESSING: 
      return state.merge({ event: fromJS({}), eventProcessing: true });
    case ActionTypes.SCANNER_EVENT_FAILURE:
      return state.merge({ eventError: fromJS(action.error), eventProcessing: false });
    case ActionTypes.SCANNER_EVENT_RSVPS_SUCCESS: 
      rsvpData = state.get('rsvps');
      data = fromJS(action.data.content);
      if(action.data.number !== 0){
        rsvpData = rsvpData.concat(data);
      } else {
        rsvpData = data;
      }
      return state.merge({ 
        page: action.data.number, 
        lastPage: action.data.last, 
        // with updates coming in potentially before more pages are fetch need to deduplicate the list
        rsvps: rsvpData, 
        rsvpsError: fromJS({}), 
        rsvpsProcessing: false
      });
    case ActionTypes.SCANNER_EVENT_RSVPS_PROCESSING: 
      return state.merge({ rsvpsProcessing: true });
    case ActionTypes.SCANNER_EVENT_RSVPS_FAILURE: 
      return state.merge({ rsvpsError: fromJS(action.error), rsvpsProcessing: false });
    case ActionTypes.SCANNER_SET_MANUAL_CHECKIN_PROFILE:
      return state.merge({ manualCheckInProfile: action.profile, checkInProcessing: false });
    case ActionTypes.SCANNER_REMOVE_MANUAL_CHECKIN_PROFILE:
      return state.merge({ manualCheckInProfile: null });
    case ActionTypes.SCANNER_CHECKIN_SUCCESS: 
      return state.merge({ rsvps: processUpdates(state.get('rsvps'), fromJS(action.data), state.get('search'), state.get('filter')), checkInProcessing: false, manualCheckInProfile: null });
    case ActionTypes.SCANNER_CHECKIN_PROCESSING: 
      return state.merge({ checkInProcessing: true });
    case ActionTypes.SCANNER_CHECKIN_FAILURE:
      return state.merge({ checkInProcessing: false });
    case ActionTypes.EVENT_RSVP_UPDATES:
      // if nothing was updated, just return the current state
      if(action.data.length){
        rsvpData = state.get('rsvps');
        data = fromJS(action.data);
        data.forEach(element => {
            rsvpData = processUpdates(rsvpData, element, state.get('search'), state.get('filter'));
        });
        return state.set('rsvps', rsvpData);
      } else {
          return state;
      }
    case ActionTypes.EVENT_RSVP_CANCELATIONS:
      // if nothing was updated, just return the current state
      if(action.data.length){
        let rsvpData = state.get('rsvps');
        let data = fromJS(action.data);
        data.forEach(element => {
            rsvpData = processCancelations(rsvpData, element, state.get('search'), state.get('filter'));
        });
        return state.set('rsvps', rsvpData);
      } else {
          return state;
      }
    case ActionTypes.SCANNER_UPDATE_SEARCH:
      return state.merge({ search: action.search, page: 0, lastPage: true });
    case ActionTypes.SCANNER_UPDATE_FILTER:
      return state.merge({ filter: action.filter, page: 0, lastPage: true });
    case ActionTypes.SCANNER_INCREMENT_PAGE:
      return state.merge({ page: action.page });
    case ActionTypes.SCANNER_RESET:
      return initialState;
    default:
      return state;
  }
}