import React, { Fragment } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionTypes from 'actions/actionTypes';
import { withRouter } from 'react-router';
import WithResize from 'components/HOC/WithResize';
import { capitalize } from 'utils/globalUtils';
import TermsOfUse from 'components/views/components/TermsOfUse';
import { generalFeedbackFormURL } from 'utils/contentUtils';
import { getSearchDropdownItems } from 'utils/globalSearchUtils';
import { faUniversalAccess } from '@fortawesome/fontawesome-pro';
import {
	NotificationList,
	NotificationWrapper,
	NotificationPanel
} from '../components/notification-panel';
import {
	fetchNotification,
	getUnreadCount,
	setAcknowledgedNotification,
	buildRedirectUrl,
	notificationUrlReset
} from 'actions/notificationActions';
import { globalSearch } from 'actions/globalSearchActions';
import {
	SubNavigationDropdown
} from 'components/layout';

import {
	ButtonRefactor as Button,
	Divider,
	FontIcon,
	Modal,
	AccountMenu,
	Avatar,
	brandVariables,
	colors,
	Image,
	Navigation,
	SearchLookupDropdown
} from 'fs-toolkit-react';
import {
	endImpersonation,
	isSuperAdmin
	// isGlobalAdmin
} from 'utils/profileUtils';

import { LayoutContext } from 'components/layout/LayoutProvider';
import DangerBar from 'components/layout/components/DangerBar';
import { AlertBar } from 'components/layout';
import MyTicketCode from 'components/shared/MyTicketCode';

// umbrella type
const umbrellaTypes = {
	connect: 'connect',
	learn: 'learn',
	support: 'support'
};

class Header extends React.PureComponent {
	static contextType = LayoutContext;

	constructor(props) {
		super(props);
		this.state = {
			accountMenuOpen: false,
			checkInModalOpen: false,
			modalOpen: false,
			umbrella: '',
			openNotificationPanel: false,
			notificationPath: '',
			openTermsModal: false,
			searchExpanded: false,
			searchPlaceholder: props.isMobile ? '' : 'Search',
			searchInputValue: '',
			borderColor: ''
		};

		this.wrapperNavigationRef = React.createRef();
	}

	static defaultProps = {
		hideSubnavigation: false,
		navigationType: 'user',
		isPublicPage: false
	};

	static propTypes = {
		hideSubnavigation: PropTypes.bool,
		navigationType: PropTypes.oneOf(['global', 'user', 'plain']),
		profile: PropTypes.instanceOf(Map),
		subnavigationTitle: PropTypes.string,
		children: PropTypes.object,
		globalSearchData: PropTypes.object,
		globalSearchProcessing: PropTypes.bool,
		globalSearchError: PropTypes.object,
		networkErrorStatus: PropTypes.string,
		isPublicPage: PropTypes.bool
	};

	static searchPlaceholderStates = {
		desktop: {
			default: 'Search',
			focused: 'Search stories, events, articles, and more'
		},
		mobile: {
			default: '',
			focused: 'Search'
		}
	};

	getPlaceholderState = () => {
		const screen = this.props.isMobile ? 'mobile' : 'desktop';
		return Header.searchPlaceholderStates[screen];
	};

	handleSearchItemClick = dataSet => {
		this.props.history.push(dataSet.value);
	};

	handleSearchIconClick = () => this.navigateToResults();

	handleKeyup = e => {
		e.currentTarget.name === 'searchLookupInput' &&
			e.key === 'Enter' &&
			this.navigateToResults();
	};

	navigateToResults = () => {
		const { searchInputValue } = this.state;
		searchInputValue &&
			this.props.history.push(`/search?query=${searchInputValue}`);
	};

	handleBlur = () =>
		this.setState({
			searchPlaceholder: this.getPlaceholderState().default
		});

	handleFocus = () =>
		this.setState({
			searchPlaceholder: this.getPlaceholderState().focused
		});

	getValueImmediately = searchInputValue => this.setState({ searchInputValue });

	handleSearch = val => {
		const value = val.trim();

		if (value) {
			this.props.dispatch(
				globalSearch({
					query: value,
					limit: 10
				})
			);
		}
	};

	getAccountMenu = () => {
		const {
			children,
			hideSubnavigation,
			isMobile,
			profile,
			subnavigationTitle
		} = this.props;
		const navHeight =
			this.wrapperNavigationRef &&
			this.wrapperNavigationRef.current &&
			`${this.wrapperNavigationRef.current.getBoundingClientRect().bottom}`;
		const withSubnav =
			isMobile &&
			!hideSubnavigation &&
			(subnavigationTitle || children || this.state.umbrella)
				? true
				: false;

		const username = `${profile.get('firstName')} ${profile.get('lastName')}`;
		const isEmployee = profile.get('isStaff');
		const isStudent = profile.get('isStudent');

		return (
			<AccountMenu
				width={!isMobile && '375px'}
				style={{
					maxHeight: `calc(100vh - ${
						withSubnav ? navHeight - 60 : navHeight
					}px)`,
					height:
						isMobile &&
						`calc(100vh - ${withSubnav ? navHeight - 60 : navHeight}px)`,
					top: isMobile && `${withSubnav ? navHeight - 60 : navHeight}px`
				}}
				border={false}
				onClose={!isMobile && this.hideAccountMenu}
				version={process.env.REACT_APP_RELEASE_VERSION}
			>
				<AccountMenu.Header
					name={username}
					onClick={this.handleNonAppRedirect(process.env.REACT_APP_PROFILE_URL)}
					linkTitle="Manage Profile"
					avatarUrl={profile.get('avatarUrl')}
				/>
				<AccountMenu.Link
					title="Check-In Code"
					onClick={this.toggleCheckIn}
					icon="faQrcode"
					borderBottom
				/>
				<AccountMenu.Link
					title="Tasks"
					onClick={this.handleNavigation('/tasks')}
					icon="faCheckSquare"
				/>
				<Divider small />
				<div className="tool-links">
					{isSuperAdmin(profile) && (
						<React.Fragment>
							<AccountMenu.Link
								title="Segments"
								onClick={this.handleNavigation('/tools/grouper/segments')}
							/>
							<AccountMenu.Link
								title="Rules"
								onClick={this.handleNavigation('/tools/grouper/rules')}
							/>
						</React.Fragment>
					)}

					<AccountMenu.Link
						title="Student Manual"
						onClick={this.handleNonAppRedirect('https://www.fullsail.edu/resources/brochure-file/student-manual.pdf')}
					/>
				</div>

				<Divider small />

				{isEmployee && (
					<AccountMenu.Link
						title="Email"
						onClick={this.handleNonAppRedirect(
							'http://outlook.com/owa/fullsail.com'
						)}
						externalLinkIcon
					/>
				)}
				{!isEmployee && isStudent && (
					<AccountMenu.Link
						title="Email"
						onClick={this.handleNonAppRedirect('http://mail.fullsail.edu/')}
						externalLinkIcon
					/>
				)}
				{isEmployee && (
					<AccountMenu.Link
						title="Workday"
						onClick={this.handleNonAppRedirect(
							'https://www.myworkday.com/fullsail/'
						)}
						externalLinkIcon
					/>
				)}
				<AccountMenu.Link
					title="Send Feedback"
					onClick={this.handleNonAppRedirect(generalFeedbackFormURL())}
					externalLinkIcon
				/>
				{isStudent && (
					<AccountMenu.Link
						title="Campus Portal"
						onClick={this.handleNonAppRedirect(
							'https://campusportal.fullsail.com'
						)}
						externalLinkIcon
					/>
				)}
				{window.UserWay && (
					<AccountMenu.Link
						title="Accessibility"
						onClick={() => {
							window.UserWay.widgetOpen();
							this.hideAccountMenu();
						}}
						icon={faUniversalAccess}
					/>
				)}
				{window.localStorage.getItem('impersonation') && (
					<AccountMenu.Link
						title="End Impersonation"
						onClick={() => {
							endImpersonation();
						}}
						icon="faUserShield"
					/>
				)}
				<AccountMenu.Link
					title="Terms and Privacy"
					onClick={this.toggleModal}
				/>
				<AccountMenu.Link title="Logout" onClick={this.handleLogout} />
			</AccountMenu>
		);
	};

	getSearch = () => (
		<SearchLookupDropdown
			onSearch={this.handleSearch}
			options={getSearchDropdownItems(this.props.globalSearchData)}
			onIconMouseDown={this.handleSearchIconClick}
			onKeyUp={this.handleKeyup}
			onMouseDown={this.handleSearchItemClick}
			onTouchStart={this.handleSearchItemClick}
			isSearchProcessing={this.props.globalSearchProcessing}
			onFocus={this.handleFocus}
			onBlur={this.handleBlur}
			placeholder={this.state.searchPlaceholder}
			inputIconPosition="left"
			inputStyles="highlight"
			searchError={this.props.globalSearchError}
			getValueImmediately={this.getValueImmediately}
			expanded={
				(this.props.isMobile && this.state.searchExpanded) ||
				!this.props.isMobile
			}
		/>
	);

	getUmbrellas = () => (
		<Fragment>
			<Navigation.Link
				active={this.isUmbrellaActive(umbrellaTypes.learn)}
				activeColor={this.state.borderColor}
				onClick={this.handleNavigation('/learn/classes')}
			>
				Learn
			</Navigation.Link>
			<Navigation.Link
				active={this.isUmbrellaActive(umbrellaTypes.connect)}
				activeColor={this.state.borderColor}
				onClick={this.handleNavigation('/connect')}
			>
				Connect
			</Navigation.Link>
			<Navigation.Link
				active={this.isUmbrellaActive(umbrellaTypes.support)}
				activeColor={this.state.borderColor}
				onClick={this.handleNavigation('/support')}
			>
				Support
			</Navigation.Link>
		</Fragment>
	);

	handleLogout = () => {
		this.props.history.push('/logout');
	};

	onCardClick = ({ id, readAt, linkParams, type }) => () => {
		if (!readAt) {
			this.props.dispatch(setAcknowledgedNotification(id));
		}
		this.props.dispatch(
			buildRedirectUrl({
				id,
				readAt,
				linkParams,
				type,
				appGroup: this.props.appGroup
			})
		);
	};

	onScrollToBottom = () => {
		if (!this.props.lastPage && !this.props.notificationProcessing) {
			this.props.dispatch(fetchNotification(this.props.page + 1));
		}
	};

	toggleCheckIn = () =>
		this.setState(state => ({
			checkInModalOpen: !state.checkInModalOpen,
			accountMenuOpen: false
		}));

	toggleModal = () =>
		this.setState(state => ({
			modalOpen: !state.modalOpen,
			accountMenuOpen: false
		}));

	hideAccountMenu = () =>
		this.setState(state => ({
			accountMenuOpen: false
		}));

	hideMobileSearch = () =>
		this.setState(state => ({
			searchExpanded: false
		}));

	showAccountMenu = () =>
		this.setState(state => ({
			accountMenuOpen: true
		}));

	showMobileSearch = () =>
		this.setState(state => ({
			searchExpanded: true
		}));

	handleNonAppRedirect = url => () => window.open(url, '_blank');

	handleAppRedirect = path => this.props.history.push(path);

	handleNavigation = path => () => {
		this.hideAccountMenu();
		this.props.navigationType !== 'plain' && this.handleAppRedirect(path);
	};

	openNotificationPanel = status => {
		this.setState({ openNotificationPanel: status }, () => {
			// when the panel open, reset the notifications
			if (status) {
				this.props.dispatch(fetchNotification(this.props.page));
			} else {
				this.props.dispatch({ type: ActionTypes.RESET_NOTIFICATIONS_PANEL });
			}
		});
	};

	isUmbrellaActive = umbrellaType => this.state.umbrella === umbrellaType;

	getUmbrellaState = (pathname = '') => {
		const umbrella = this.getUmbrellaFromPath(pathname);
		const umbrellaToState = {
			connect: { borderColor: colors.lime },
			learn: { borderColor: colors.blue },
			support: { borderColor: colors.aqua }
		};
		return {
			borderColor: '',
			...umbrellaToState[umbrella]
		};
	};

	getUmbrellaFromPath = (pathname = '') => {
		const [, umbrella] = pathname.split('/');
		return umbrella;
	};

	componentDidMount() {
		this.setState({
			...this.getUmbrellaState(this.props.location.pathname),
			umbrella: this.getUmbrellaFromPath(this.props.location.pathname)
		});
		!this.props.isPublicPage && this.props.dispatch(getUnreadCount());
		this.context.action.setNavigationRef(this.wrapperNavigationRef);
	}

	componentDidUpdate(prevProps, prevState) {
		const navRect = this.wrapperNavigationRef.current.getBoundingClientRect();

		if (navRect.height !== this.context.state.refHeights.navigation) {
			this.context.action.setNavigationRef(this.wrapperNavigationRef);
		}
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({
				...this.getUmbrellaState(this.props.location.pathname),
				umbrella: this.getUmbrellaFromPath(this.props.location.pathname)
			});
		}

		if (
			this.props.notificationUrl &&
			!this.props.markedAcknowledgedProcessing
		) {
			this.handleAppRedirect(this.props.notificationUrl);
			this.setState({ openNotificationPanel: false });
			if (
				!this.props.networkErrorStatus ||
				(this.props.networkErrorStatus !== '0' && !this.props.isPublicPage)
			) {
				this.props.dispatch(notificationUrlReset());
			}
		}

		if (prevProps.isMobile !== this.props.isMobile) {
			this.setState({
				searchPlaceholder: this.getPlaceholderState().default
			});
		}
	}

	componentWillUnmount() {
		this.setState({ notificationPath: '', openNotificationPanel: false });
	}

	render() {
		const {
			children,
			hideSubnavigation,
			isMobile,
			navigationType,
			profile,
			subnavigationTitle
		} = this.props;
		const authenticated = !profile.isEmpty();
		const username = `${profile.get('firstName')} ${profile.get('lastName')}`;
		let navigationComponent = null;
		let mobileSubNav = this.context.state.subNavigation;

		if (
			navigationType === 'user' &&
			authenticated &&
			!!profile &&
			!!profile.size
		) {
			if (isMobile) {
				navigationComponent = (
					<Fragment>
						<Navigation borderColor={this.state.borderColor}>
							{this.state.accountMenuOpen ? (
								this.state.searchExpanded ? (
									<Navigation.Section>
										<div
											className="fs-flex-justify-end fs-flex-align-center"
											style={{ width: '100%' }}
										>
											{this.getSearch()}
											<FontIcon
												icon="faTimesLight"
												onClick={this.hideMobileSearch}
											/>
										</div>
									</Navigation.Section>
								) : (
									<Fragment>
										<Navigation.Section>
											{this.getUmbrellas()}
										</Navigation.Section>
										<Navigation.Section style={{ flex: '1' }}>
											<FontIcon
												icon="faSearch"
												onClick={this.showMobileSearch}
											/>
											<FontIcon
												icon="faTimesLight"
												onClick={this.hideAccountMenu}
											/>
										</Navigation.Section>
									</Fragment>
								)
							) : (
								<Fragment>
									<Navigation.Section>
										<Image
											imageUrl={brandVariables.logo.icon.url}
											imageWidth={brandVariables.logo.icon.width}
											onImageClick={this.handleNavigation('/')}
										/>
									</Navigation.Section>
									<Navigation.Section>
										<FontIcon
											icon="faBell"
											notificationCount={this.props.unreadNotificationCount}
											onClick={this.openNotificationPanel.bind(this, true)}
										/>
										<FontIcon
											icon="faBarsLight"
											onClick={this.showAccountMenu}
										/>
									</Navigation.Section>
								</Fragment>
							)}
						</Navigation>

						{/* Subnav */}
						{!hideSubnavigation && mobileSubNav.component ? (
							React.createElement(mobileSubNav.component, mobileSubNav.props, mobileSubNav.children)
						) : // default for pages
						subnavigationTitle || this.state.umbrella ? (
							<Navigation type="sub">
								<h3>
									{subnavigationTitle
										? capitalize(subnavigationTitle)
										: capitalize(this.state.umbrella)}
								</h3>
							</Navigation>
						) : null}
					</Fragment>
				);
			} else {
				navigationComponent = (
					<Fragment>
						<Navigation borderColor={this.state.borderColor}>
							{/* Left */}
							<Navigation.Section>
								<Image
									imageUrl={brandVariables.logo.icon.url}
									imageWidth={brandVariables.logo.icon.width}
									onImageClick={this.handleNavigation('/')}
								/>
								<Divider
									direction="vertical"
									style={{
										height: '32px',
										marginLeft: '20px',
										marginRight: '15px'
									}}
								/>
								{this.getSearch()}
							</Navigation.Section>

							{/* Center */}
							<Navigation.Section>{this.getUmbrellas()}</Navigation.Section>

							{/*	Right */}
							<Navigation.Section>
								<FontIcon
									icon="faBell"
									notificationCount={this.props.unreadNotificationCount}
									onClick={this.openNotificationPanel.bind(this, true)}
								/>
								<Avatar
									size="x-small"
									name={username}
									url={profile.get('avatarUrl')}
									onClick={this.showAccountMenu}
								/>

								{this.state.accountMenuOpen && this.getAccountMenu()}
							</Navigation.Section>
						</Navigation>
					</Fragment>
				);
			}
		} else {
			navigationComponent = (
				<Navigation>
					<Navigation.Section>
						{isMobile ? (
							<Image
								imageUrl={brandVariables.logo.icon.url}
								imageWidth="32px"
								onImageClick={this.handleNavigation('/')}
							/>
						) : (
							<Image
								imageUrl={brandVariables.logo.full.url}
								imageWidth="262px"
								onImageClick={this.handleNavigation('/')}
							/>
						)}
					</Navigation.Section>

					{authenticated && (
						<Navigation.Section>
							<Navigation.Link onClick={this.handleLogout}>
								Logout
							</Navigation.Link>
						</Navigation.Section>
					)}
				</Navigation>
			);
		}

		return (
			<Fragment>
				{this.state.modalOpen && (
					<Modal ref={this.modal} onClose={this.toggleModal}>
						<Modal.Header title={'Terms of Use'} />
						<TermsOfUse />
						<Modal.Footer>
							<Button modifier="secondary" onClick={this.toggleModal}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				)}

				{this.state.checkInModalOpen && (
					<Modal
						onClose={this.toggleCheckIn}
						height={!isMobile && '500px'}
						width={!isMobile && '500px'}
					>
						<Modal.Header title={'Check-in Code'} />
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<MyTicketCode
								isMobile={isMobile}
								code={profile.get('digitalTicket')}
							/>
						</div>
						<Modal.Footer>
							<Button modifier="secondary" onClick={this.toggleCheckIn}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				)}

				<NotificationPanel
					isOpen={this.state.openNotificationPanel}
					label="Notifications"
					onDismiss={this.openNotificationPanel.bind(this, false)}
				>
					<NotificationWrapper
						showLoader={this.props.notificationProcessing}
						showLoaderOverlay={
							this.props.markedAcknowledgedProcessing ||
							this.props.notificationUrlProcessing
						}
						onScrollToBottom={this.onScrollToBottom}
					>
						<NotificationList
							defaultText="There are no notifications yet."
							content={this.props.notifications}
							onCardClick={this.onCardClick}
						/>
					</NotificationWrapper>
				</NotificationPanel>

				<div className="wrapper__header" ref={this.wrapperNavigationRef}>
					<DangerBar />

					{window.localStorage.getItem('impersonation') && (
						<AlertBar onClose={endImpersonation} align="center">
							<p className="fs-text--base fs-label" style={{ margin: 0 }}>
								{`Impersonating - ${window.localStorage.getItem(
									'impersonationName'
								)}`}
							</p>
						</AlertBar>
					)}

					{navigationComponent}
					{isMobile && this.state.accountMenuOpen && this.getAccountMenu()}
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		profile: state.getIn(['profile', 'data']),
		unreadNotificationCount: state.getIn(['notifications', 'unreadCount']),
		notifications: state.getIn(['notifications', 'data']),
		page: state.getIn(['notifications', 'page']),
		lastPage: state.getIn(['notifications', 'lastPage']),
		notificationProcessing: state.getIn(['notifications', 'processing']),
		markedAcknowledgedProcessing: state.getIn([
			'notifications',
			'markedNotificationProcessing'
		]),
		appGroup: state.getIn(['app', 'group']),
		notificationUrl: state.getIn(['notifications', 'url']),
		notificationUrlProcessing: state.getIn(['notifications', 'urlProcessing']),
		notificationUrlError: state.getIn(['notifications', 'urlFailure']),
		globalSearchData: state.getIn(['globalSearch', 'data']),
		globalSearchProcessing: state.getIn(['globalSearch', 'processing']),
		globalSearchError: state.getIn(['globalSearch', 'error'])
	};
};

export default withRouter(connect(mapStateToProps)(WithResize(Header)));
