import { fromJS } from 'immutable';
import * as GroupAPI from 'api/groups/GroupsAPI';
import { getErrorMessage, growl, types as growlTypes } from 'utils/errorUtils';
import { makeActionCreator } from '../utils/reducerUtils';
import { createLoaderSelector } from './loader';
import { createErrorSelector } from './error';

// Types

const RESET_CATEGORIES = 'RESET_CATEGORIES';
const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const FETCH_CATEGORIES_PROCESSING = 'FETCH_CATEGORIES_PROCESSING';
const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

// Reducer
const categoriesInitialState = fromJS({
	data: []
});

export default function categoriesReducer(state = categoriesInitialState, action) {
	switch (action.type) {
		case UPDATE_CATEGORIES:
			return state.merge({
				data: action.payload
			});
		case RESET_CATEGORIES:
			return categoriesInitialState;
		default:
			return state;
	}
}

export const categoriesActions = {
	resetCategories: makeActionCreator(RESET_CATEGORIES),
	updateCategories: makeActionCreator(UPDATE_CATEGORIES, 'payload'),
	fetchCategoriesProcessing: makeActionCreator(FETCH_CATEGORIES_PROCESSING),
	fetchCategoriesSuccess: makeActionCreator(FETCH_CATEGORIES_SUCCESS, ),
	fetchCategoriesFailure: makeActionCreator(FETCH_CATEGORIES_FAILURE, 'payload'),

	getTopicCategories(params) {
		return (dispatch) => {
			dispatch(categoriesActions.fetchCategoriesSuccess());
			return GroupAPI.getTopicCategories(params)
				.then(({ data }) => {
					dispatch(categoriesActions.updateCategories(data));
					dispatch(categoriesActions.fetchCategoriesSuccess());
				}, error => {
					dispatch(categoriesActions.fetchCategoriesFailure(error));
					growl(growlTypes.error, getErrorMessage(error));
				});
		};
	}
};

export const categoriesSelectors = {
	getFetchCategoriesLoading: createLoaderSelector([FETCH_CATEGORIES]),
	getFetchCategoriesError: createErrorSelector([FETCH_CATEGORIES]),
	getCategories: state => state.getIn(['categories', 'data'])
};