// Roles
// —————————————
// Stories - STORIES_ADMIN
//  * Create, Edit, Delete news articles
//  * Can see drafts
// Events - EVENTS_ADMIN
//  * Create, Edit, Delete events
//  * Check-in for events
//  * Request promotion if not FS group
// Knowledge Base - KB_ADMIN
//  * Create, Edit, Delete KB articles
//  * Can see drafts
// Events Check-in - EVENTS_ATTENDANCE
//  * Can check-in for events
// About Page - GROUP_INFO
//  * Can edit group about page data
//  * Cannot update group owners
// Audiences - MEMBERSHIP_AUDIENCE_ADMIN
//  * Can change user audiences
//  * Cannot change group owner audiences
//  * Cannot change their own audiences
// Permissions - MEMBERSHIP_ROLE_ADMIN
//  * Can change user roles
//  * Cannot change group owner roles
//  * Cannot change their own roles
// Profile Admin - PROFILE_ADMIN
//  * Can view all profile details
//  * Update global roles
//  * Disable access to the app
// Profile Viewer - PROFILE_VIEWER
// * Can view all profile details
// Impersonation - IMPERSONATION
//  * Can login as a user
// Super Admin - SUPER_ADMIN
//  * Acts as all roles
//  * Global SUPER_ADMIN can create groups
//  * Global or group SUPER_ADMIN can update group owners on the group about edit page
//  * Cannot be added directly
//  * Users get role with the association to the group as an owner and lose it with the removal of the association
//  * Users get the role across all groups via DB insert

const SUPER_ADMIN = 'SUPER_ADMIN';

const isAdmin = ({
	profile,
	groupId = 1,
	adminType,
	considerPromotion = false
}) => {
	const mapTypeToAdminValue = {
		calendarAdmin: 'EVENT_ADMIN',
		KBAdmin: 'KB_ADMIN',
		newsArticleAdmin: 'STORIES_ADMIN',
		groupInfoAdmin: 'GROUP_INFO_ADMIN',
		membershipAdmin: 'MEMBERSHIP_ADMIN',
		checkInAdmin: 'EVENT_ATTENDANCE',
		moderationAdmin: 'MODERATION_ADMIN',
		topicsAdmin: 'TOPIC_ADMIN'
	};

	const adminValue = mapTypeToAdminValue[adminType];

	let isAdmin = false;

	// does the user have role across all groups or are a global super admin
	if (
		profile
			.get('roles')
			.findIndex(
				role =>
					role.get('value') === adminValue || role.get('value') === SUPER_ADMIN
			) !== -1
	) {
		isAdmin = true;
	}

	// if not a global admin, are they an admin for the group
	if (!isAdmin) {
		const membership = profile
			.get('memberships')
			.find(membership => membership.get('groupId') === parseInt(groupId));

		// do they have a membership for the group
		if (membership) {
			if (
				membership
					.get('roles')
					.findIndex(
						role =>
							role.get('value') === adminValue ||
							role.get('value') === SUPER_ADMIN
					) !== -1
			) {
				isAdmin = true;
			}
		}
		// if not a group admin and should consider FS cause of promotion
		if (!isAdmin && considerPromotion) {
			const fsMembership = profile
				.get('memberships')
				.find(
					membership =>
						parseInt(membership.get('id')) ===
						parseInt(process.env.REACT_APP_GROUP_CONFIG_ID)
				);
			if (fsMembership) {
				if (
					fsMembership
						.get('roles')
						.findIndex(
							role =>
								role.get('value') === adminValue ||
								role.get('value') === SUPER_ADMIN
						) !== -1
				) {
					isAdmin = true;
				}
			}
		}
	}

	return isAdmin;
};

const isSuperAdmin = profile => {
	return (
		profile
			.get('roles')
			.findIndex(role => role.get('value') === SUPER_ADMIN) !== -1
	);
};

const isGroupOwner = membership => {
	return (
		membership
			.get('roles')
			.findIndex(role => role.get('value') === SUPER_ADMIN) !== -1
	);
};

const isGlobalAdmin = ({ profile, adminType }) => {
	const mapTypeToAdminValue = {
		calendarAdmin: 'EVENT_ADMIN',
		KBAdmin: 'KB_ADMIN',
		newsArticleAdmin: 'STORIES_ADMIN',
		groupInfoAdmin: 'GROUP_INFO_ADMIN',
		membershipAdmin: 'MEMBERSHIP_ADMIN',
		checkInAdmin: 'EVENT_ATTENDANCE',
		impersonation: 'IMPERSONATION',
		profileAdmin: 'PROFILE_ADMIN',
		profileViewer: 'PROFILE_VIEWER'
	};

	const adminValue = mapTypeToAdminValue[adminType];

	let isAdmin = false;

	// does the user have role across all groups or are a global super admin
	if (
		profile
			.get('roles')
			.findIndex(
				role =>
					role.get('value') === adminValue || role.get('value') === SUPER_ADMIN
			) !== -1
	) {
		isAdmin = true;
	}

	return isAdmin;
};

const getRoleOptions = () => {
	return [
		{
			title: 'Super Admin',
			description: 'Can manage profiles, content and groups.',
			value: 'SUPER_ADMIN'
		},
		{
			title: 'Profile Admin',
			description: 'Can manage profiles.',
			value: 'PROFILE_ADMIN'
		},
		{
			title: 'Profile Viewer',
			description: 'Can view profile details.',
			value: 'PROFILE_VIEWER'
		},
		{
			title: 'Impersonation',
			description: 'Can assume the identity of another user.',
			value: 'IMPERSONATION'
		},
		{
			title: 'Audiences',
			description: 'Can manage audiences of users in groups.',
			value: 'MEMBERSHIP_ADMIN'
		},
		{
			title: 'Memberships',
			description: 'Can manage group access and permissions for a user.',
			value: 'MEMBERSHIP_ADMIN'
		},
		{
			title: 'About Pages',
			description: 'Can update about page(s).',
			value: 'GROUP_INFO_ADMIN'
		},
		{
			title: 'Stories',
			description: 'Can manage stories.',
			value: 'STORIES_ADMIN'
		},
		{
			title: 'Events',
			description: 'Can manage events.',
			value: 'EVENT_ADMIN'
		},
		{
			title: 'Event Check-in',
			description: ' Can manage event attendance.',
			value: 'EVENT_ATTENDANCE'
		},
		{
			title: 'Articles',
			description: 'Can manage articles.',
			value: 'KB_ADMIN'
		},
		{
			title: 'Topics',
			description: 'Can manage topics.',
			value: 'TOPIC_ADMIN'
		},
		{
			title: 'Moderation',
			description: 'Can moderate comments.',
			value: 'MODERATION_ADMIN'
		}
	];
};

const impersonate = profile => {
	window.localStorage.setItem('impersonation', profile.get('fsOneId'));
	window.localStorage.setItem(
		'impersonationName',
		`${profile.get('firstName')} ${profile.get('lastName')}`
	);
	window.location = '/';
};

const endImpersonation = () => {
	const fsOneId = window.localStorage.getItem('impersonation');
	window.localStorage.removeItem('impersonation');
	window.localStorage.removeItem('impersonationName');
	window.location = `/people/${fsOneId}`;
};

const isImpersonating = () => {
	return !!window.localStorage.getItem('impersonation');
};

const getPeopleProfileURL = (fsOneId, params) => {
	let peoplePath;
	params && params.groupType && params.groupName && params.groupId ?
		peoplePath = `/people/${params.groupType}/${params.groupName}/${params.groupId}/${fsOneId}`
		: peoplePath = `/people/${fsOneId}`;

	return peoplePath;
}

export {
	isAdmin,
	isSuperAdmin,
	isGroupOwner,
	isGlobalAdmin,
	getRoleOptions,
	impersonate,
	endImpersonation,
	isImpersonating,
	getPeopleProfileURL
};
