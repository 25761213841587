
export const createActions = types => Object.keys(types).reduce((result, typeName) => {
	return { ...result, [typeName]: args => ({ type: types[typeName], payload: args }) };
}, {});

// helper
const buildTypeStructure = (...typeStructure) => typeStructure
	.filter(item => !!item)
	.map(string => string.toUpperCase())
	.join('_');

export const createTypes = ({ types = [], prefix = '', reducer = '', app = 'fsone' }) => {
	return (type = '') => types.reduce((result, typeName) => {
		const typePrefix = reducer ? `${app}/${reducer}` : app;
		const typeActionName = buildTypeStructure(prefix, type, typeName);
		return {
			...result,
			[typeName]: `${typePrefix}/${typeActionName}`
		};
	}, {});
}

export function makeActionCreator(type, ...argNames) {
	return function (...args) {
		const action = { type }
		argNames.forEach((arg, index) => {
			action[argNames[index]] = args[index]
		})
		return action
	}
}

