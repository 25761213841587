import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toast } from 'react-toastify';
import { getRsvps } from 'api/events/RsvpAPI';

const eventProcessing = () => ({
	type: ActionTypes.SCANNER_EVENT_PROCESSING
});

const eventSuccess = data => ({
	type: ActionTypes.SCANNER_EVENT_SUCCESS,
	data
});

const eventFailure = error => ({
	type: ActionTypes.SCANNER_EVENT_FAILURE,
	error
});

export const fetchEvent = (eventId, sequenceId) => {
	return dispatch => {
		dispatch(eventProcessing());

		return APIClient.get(
			`/admin/attendance_tracking/events/${eventId}/occurrences/${sequenceId}`
		).then(
			({ data }) => dispatch(eventSuccess(data)),
			({ response: { data } }) => dispatch(eventFailure(data))
		);
	};
};

const rsvpsProcessing = () => ({
	type: ActionTypes.SCANNER_EVENT_RSVPS_PROCESSING
});

const rsvpsSuccess = data => ({
	type: ActionTypes.SCANNER_EVENT_RSVPS_SUCCESS,
	data
});

const rsvpsFailure = error => ({
	type: ActionTypes.SCANNER_EVENT_RSVPS_FAILURE,
	error
});

export const fetchRsvps = (eventId, sequenceId, { query, isScanned, page }) => {
	return (dispatch, getState) => {
		dispatch(rsvpsProcessing());

		return getRsvps(eventId, sequenceId, { query, isScanned, page }).then(
			({ data }) => dispatch(rsvpsSuccess(data)),
			({ response: { data } }) => dispatch(rsvpsFailure(data))
		);
	};
};

const checkInProcessing = () => ({
	type: ActionTypes.SCANNER_CHECKIN_PROCESSING
});

const checkInSuccess = data => {
	toast.success('Successfully processed check-in.', {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--success'
	});
	return { type: ActionTypes.SCANNER_CHECKIN_SUCCESS, data };
};

const checkInFailure = error => {
	toast.error(error.message, {
		position: toast.POSITION.BOTTOM_RIGHT,
		className: 'toast-override toast-override--error'
	});
	return { type: ActionTypes.SCANNER_CHECKIN_FAILURE, error };
};

export const checkIn = (eventId, sequenceId, payload) => {
	return (dispatch, getState) => {
		dispatch(checkInProcessing());

		return APIClient.post(
			`/admin/attendance_tracking/events/${eventId}/occurrences/${sequenceId}/scans`,
			{ data: payload }
		).then(
			({ data }) => dispatch(checkInSuccess(data)),
			({ response: { data } }) => dispatch(checkInFailure(data))
		);
	};
};
