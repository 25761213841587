import APIClient from '../APIClient';
import { buildUrl } from 'utils/globalUtils';

export const getEventOccurrence = (eventId, sequenceId) => {
	return APIClient.get(buildUrl(`/events/${eventId}/occurrences/${sequenceId}`, {}));
}

export const getEvent = (params) => {
	const { eventId } = params;
	return APIClient.get(`/events/${eventId}`);
};