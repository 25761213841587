import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { colors, FontIcon } from 'fs-toolkit-react';

class AlertBar extends React.PureComponent {
	static propTypes = {
		align: PropTypes.oneOf(['center', 'left', 'right']),
		background: PropTypes.string,
		color: PropTypes.string,
		className: PropTypes.string,
		onClick: PropTypes.func,
		onClose: PropTypes.func,
		style: PropTypes.object,
		url: PropTypes.string
	}

	render() {
		const { align, background, children, className, color, onClick, onClose, style, url } = this.props;
		const Tag = url ? 'a' : 'div';
		const classes = { className: cn(
				'fs-alertbar', className
			)};
		const defaultStyles = {
			color: colors.grayDarkest,
			cursor: onClick && 'pointer',
			background: background ? background : colors.warning,
			margin: align ==='center' && '0 auto',
			textAlign: (align || (align && onClose)) ? align : onClose ? 'left' : 'center'
		}

		const attrs = url ?
			Object.assign(
				{},
				{ href: url },
				classes,
				{target: '_blank'},
				{rel: 'noopener noreferrer'},
				{style: {...defaultStyles, ...style}})
			: Object.assign(
				{},
				classes,
				{onClick},
				{style: {...defaultStyles, ...style}}
			);

		return (
			<Tag {...attrs}>
				{/* adds empty div for flex centering of message if close is present*/}
				{ onClose && align === 'center' && <div></div> }

				<div className='fs-alertbar__message' style={{ margin: align === 'center' && '0 auto'}}>{ children }</div>

				{ onClose && <div className='fs-alertbar__close'><FontIcon icon='faTimesLight' iconColor={color} onClick={ onClose } iconSize='14px' /></div> }
			</Tag>
		);
	}
}

export default AlertBar;