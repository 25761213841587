import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	content: [],
	error: null,
	processing: false
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.CALENDAR_EVENTS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.CALENDAR_EVENTS_SUCCESS:
			return state.merge({
				processing: false,
				content: action.payload.content,
				error: null
			});
		case ActionTypes.CALENDAR_EVENTS_FAILURE:
			return state.merge({
				processing: false,
				error: action.payload
			});
		default:
			return state;
	}
}
