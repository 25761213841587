import React from 'react';

/**
 * Component is wrapped with reacts forward ref
 * @param Component
 * @param refPropName name of the property assigned to the component ref
 * @returns {<Component>}
 */
const forwardRefWrapper = (Component, refPropName) =>
	React.forwardRef((props, ref) => (
		<Component {...{ [refPropName]: ref }} {...props} />
	));

export { forwardRefWrapper };
