import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
    title: '',
    description: '',
    segmentIds: ["0"], // no segment will have an id of zero, so 0 will represent a placeholder field
    executors: [{}],
    options: {},
    processing: false, // used for the loading of the options or the segment for edits
    saving: false,
    saved: false,
    deleting: false,
    deleted: false,
    isDirty: false,
    validationErrors: { title: "", description: "", conditions: "" },
    error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TOOLS_GROUPER_LOAD_RULE_FORM_PROCESSING:
        return state.merge({
            processing: true
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_RULE_FORM_SUCCESS:
        return state.merge({
            processing: false,
            error: {},
            title: (Object.keys(action.payload).length !== 0 ? action.payload.title : '' ),
            description: (Object.keys(action.payload).length !== 0 ? action.payload.description : '' ),
            segmentIds: (Object.keys(action.payload).length !== 0 ? action.payload.segments.map((segment) => `${segment.id}`) : fromJS(["0"])),
            executors: (Object.keys(action.payload).length !== 0 ? fromJS(action.payload.executors.map((executor) => { return { groupId: `${executor.group.id}`, member: executor.makeMember } })) : fromJS([{}])),
            options: fromJS(action.options)
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_RULE_FORM_FAILURE:
        return state.merge({
            processing: false,
            error: action.payload
        });
    case ActionTypes.TOOLS_GROUPER_CHANGE_RULE_FORMFIELD:
        // clear out the validation errors for this field
        return state.set(action.field, action.value).setIn(['validationErrors', action.field], "").set('isDirty',true);
    case ActionTypes.TOOLS_GROUPER_VALIDATE_RULE_FORM:
        return state.set('validationErrors', fromJS(action.validationErrors));
    case ActionTypes.TOOLS_GROUPER_CLEAR_RULE_FORM_VALIDATIONS:
        return state.set('validationErrors', initialState.get('validationErrors'));
    case ActionTypes.TOOLS_GROUPER_CREATE_RULE_PROCESSING:
    case ActionTypes.TOOLS_GROUPER_UPDATE_RULE_PROCESSING:
        return state.set('saving', true);
    case ActionTypes.TOOLS_GROUPER_CREATE_RULE_FAILURE:
    case ActionTypes.TOOLS_GROUPER_UPDATE_RULE_FAILURE:
        return state.set('saving', false);
    case ActionTypes.TOOLS_GROUPER_CREATE_RULE_SUCCESS:
    case ActionTypes.TOOLS_GROUPER_UPDATE_RULE_SUCCESS:
        return state.merge({saving: false, saved: true });
    case ActionTypes.TOOLS_GROUPER_DELETE_RULE_PROCESSING:
        return state.set('deleting', true);
    case ActionTypes.TOOLS_GROUPER_DELETE_RULE_FAILURE:
        return state.set('deleting', false);
    case ActionTypes.TOOLS_GROUPER_DELETE_RULE_SUCCESS:
        return state.merge({deleting: false, deleted: true });
    case ActionTypes.TOOLS_GROUPER_RULE_FORM_RESET:
        return initialState;
    default:
      return state;
  }
}