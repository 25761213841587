import React from 'react';
import PropTypes from 'prop-types';
import { fsCardError } from './helpers/cardClassNameList';
import CardIcon from './CardIcon';
import { colors, Typography } from 'fs-toolkit-react';

class CardError extends React.Component {
	static propTypes = {
		flip: PropTypes.oneOf(['both', 'horizontal', 'vertical']),
		icon: PropTypes.string,
		iconColor: PropTypes.string,
		iconHoverColor: PropTypes.string,
		iconSize: PropTypes.string,
		title: PropTypes.string,
		message: PropTypes.string,
		position: PropTypes.string
	};

	static defaultProps = {
		icon: 'faFrownLight'
	};

	render() {
		return (
			<div className={fsCardError}>
				<CardIcon
					icon={this.props.icon}
					iconColor={this.props.iconColor ? this.props.iconColor : colors.gray}
					iconHoverColor={this.props.iconHoverColor}
					iconSize={this.props.iconSize}
					flip={this.props.flip}
					position="js-ignore"
				/>
				<div className="fs-card__error-body">
					<Typography className="fs-card__error-title">
						{this.props.title}
					</Typography>
					<Typography className="fs-card__error-message">
						{this.props.message}
					</Typography>
				</div>
			</div>
		);
	}
}

export default CardError;
