import { cnWrapperHeader } from 'constants/UIConstants';

const scrolledToBottom = (selector, additionalScrollHeight = null) => {
	const element = document.documentElement;
	const navBar = document.querySelector(cnWrapperHeader);
	const wrapperElement =
		typeof selector === 'string' ? document.querySelector(selector) : selector;
	const navBarHeight = Math.round(navBar.getBoundingClientRect().bottom);

	return (
		element.clientHeight + wrapperElement.scrollTop >=
		wrapperElement.scrollHeight +
			navBarHeight
	);
};

const pageHasVerticalScrollBar = selector => {
	const { scrollHeight, clientHeight, currentStyle } = selector;
	const hasVerticalScroll = scrollHeight > clientHeight;

	// Computed style of the body element
	const computedStyle = currentStyle || window.getComputedStyle(selector, '');

	// Check the overflow and overflowY properties for "auto" and "visible" values
	return (
		scrollHeight > clientHeight ||
		computedStyle.overflow === 'visible' ||
		computedStyle.overflowY === 'visible' ||
		(hasVerticalScroll && computedStyle.overflow === 'auto') ||
		(hasVerticalScroll && computedStyle.overflowY === 'auto')
	);
};

export { scrolledToBottom, pageHasVerticalScrollBar };
