import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Loader } from 'fs-toolkit-react';

const PAGE_TRIGGER_OFFSET = 200;

class InfiniteScroller extends React.Component {
	constructor(props) {
		super(props);

		this.state = { processing: false };
	}

	componentDidMount = () => {
		this.page = document.querySelector(this.props.containerClass);
		this.page.addEventListener('scroll', this.handleScroll);
		this.page.addEventListener('mousewheel', this.mouseWheelHandler);
	};

	componentWillUnmount = () => {
		this.page.removeEventListener('scroll', this.handleScroll);
		this.page.removeEventListener('mousewheel', this.mouseWheelHandler);
	};

	handleScroll = debounce(e => {
		const { processing, hasMore } = this.props;
		if (!processing && hasMore && this.scrolledToBottom(this.page)) {
			this.props.next();
		}
	}, 200);

	mouseWheelHandler = e => {
		if (!this.pageHasVerticalScrollBar(this.page) && e.deltaY > 20) {
			this.handleScroll();
		}
	};

	scrolledToBottom = (selector, additionalScrollHeight = null) => {
		const element = document.documentElement;
		const navBar = document.querySelector('.fs-navigation');
		const dangerBarEl = document.querySelector('.danger-bar');
		const impersonationBannerHeight =
			(localStorage.getItem('impersonation') &&
				document.querySelector('.fs-alertbar ').clientHeight) ||
			0;
		const dangerBarHeight = (dangerBarEl && dangerBarEl.clientHeight) || 0;
		const wrapperElement =
			typeof selector === 'string'
				? document.querySelector(selector)
				: selector;
		const navBarHeight =
			typeof additionalScrollHeight === 'number'
				? additionalScrollHeight
				: navBar.clientHeight;

		return (
			element.clientHeight + wrapperElement.scrollTop + PAGE_TRIGGER_OFFSET >=
			wrapperElement.scrollHeight +
				navBarHeight +
				impersonationBannerHeight +
				dangerBarHeight
		);
	};

	pageHasVerticalScrollBar = selector => {
		const { scrollHeight, clientHeight, currentStyle } = selector;
		const hasVerticalScroll = scrollHeight > clientHeight;

		// Computed style of the body element
		const computedStyle = currentStyle || window.getComputedStyle(selector, '');

		// Check the overflow and overflowY properties for "auto" and "visible" values
		return (
			scrollHeight > clientHeight ||
			computedStyle.overflow === 'visible' ||
			computedStyle.overflowY === 'visible' ||
			(hasVerticalScroll && computedStyle.overflow === 'auto') ||
			(hasVerticalScroll && computedStyle.overflowY === 'auto')
		);
	};

	render() {
		const { children, processing } = this.props;
		return (
			<Fragment>
				{children}
				{processing ? <Loader /> : null}
			</Fragment>
		);
	}
}

InfiniteScroller.defaultProps = {
	processing: false,
	containerClass: '.fs-container'
};

InfiniteScroller.propTypes = {
	next: PropTypes.func.isRequired,
	hasMore: PropTypes.bool.isRequired,
	processing: PropTypes.bool,
	containerClass: PropTypes.string
};

export default InfiniteScroller;
