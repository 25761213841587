import APIClient from '../APIClient';
import { buildUrl } from 'utils/globalUtils';

const basePath = '/my';

export const getTopics = (params = {}) => {
	return APIClient.get(buildUrl(`${basePath}/topics`, params));
}

export const getMyProfile = () => {
	return APIClient.get(buildUrl(`${basePath}/profile`, {}));
}
