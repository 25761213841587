import moment from 'moment';

const formatDate = ({
	date,
	format = 'ddd MMM YY',
	timeZone = 'America/New_York'
}) => {
	return {
		toUTC() {
			return moment(date)
				.utc()
				.toISOString();
		},
		default() {
			return moment(date).format(format);
		},
		tz() {
			return moment(date)
				.tz(timeZone)
				.format(format);
		}
	};
};

const getRelativePastTime = dateTime => {
	const moreThanThreeDaysAgo = moment(dateTime).isBefore(
		moment(new Date()).subtract(3, 'd')
	);
	return (
		dateTime &&
		(moreThanThreeDaysAgo
			? moment(dateTime).format('MMM DD')
			: moment(dateTime)
					.fromNow()
					.replace(' ago', ''))
	);
};

export { formatDate, getRelativePastTime };
