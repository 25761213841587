import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
  url: '',
  error: null
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.DANGER_BAR_SUCCESS:
      return state.merge({
        data: action.data,
        error: null
      });
    case ActionTypes.DANGER_BAR_FAILURE:
      return state.merge({
        error: action.error,
        data: {}
      });
    case ActionTypes.DANGER_BAR_SET_URL_LOCALLY:
      return state.merge({
        url: action.data
      });

    default:
      return state;
  }
}