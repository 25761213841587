import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	processing: true,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.PUBLIC_PROFILE_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.PUBLIC_PROFILE_SUCCESS:
			return state.merge({
				processing: false,
				data: action.data
			});
		case ActionTypes.PUBLIC_PROFILE_FAILURE:
			return state.merge({
				processing: false,
				error: action.error
			});
		case ActionTypes.PUBLIC_PROFILE_RESET:
			return initialState;
		default:
			return state;
	}
}
