import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { formatDate } from 'utils/globalUtils';
import { FontIcon } from 'fs-toolkit-react';
import { Card } from 'components/patterns/card';

class Class extends React.Component {
	static propTypes = {
		lmsClass: PropTypes.instanceOf(Map).isRequired,
		hasDate: PropTypes.bool
	};

	handleClick = () => {
		window.open(
			`${process.env.REACT_APP_LMS_URL}class_sections/${this.props.lmsClass.get(
				'id'
			)}/dashboard`,
			'_blank'
		);
	};

	render = () => {
		const { lmsClass, hasDate } = this.props;
		const embedded = lmsClass.get('_embedded');
		const id = lmsClass.get('id');
		const title = embedded.getIn(['course', 'title'])
			? embedded.getIn(['course', 'title'])
			: '';
		const instructor = embedded.getIn(['primary_instructor', 'full_name'])
			? embedded.getIn(['primary_instructor', 'full_name'])
			: '';
		const startedAt = hasDate && lmsClass.get('started_at');
		const startedAtFormat = 'll';

		return (
			<Card modifier="full-width" key={id} onClick={this.handleClick}>
				<Card.Body>
					<Card.Title lines={1}>{title}</Card.Title>
					{(instructor || startedAt) && (
						<Card.Meta lines={2}>
							{instructor && startedAt
								? `${instructor} · Started ${formatDate(
										startedAt,
										startedAtFormat
								  )}`
								: instructor
								? instructor
								: startedAt
								? `Started ${formatDate(startedAt, startedAtFormat)}`
								: null}
						</Card.Meta>
					)}
				</Card.Body>
				<Card.Footer>
					<FontIcon icon="faExternalLinkAlt" />
				</Card.Footer>
			</Card>
		);
	};
}

export default withRouter(Class);
