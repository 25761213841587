import { useState, useCallback } from 'react';

function useRefCallback(defaultValue = null) {
	const [ref, setRef] = useState(defaultValue);
	// renders and update when ref is set
	const onRefChange = useCallback(node => setRef(node), []);

	return [ref, onRefChange];
}

export default useRefCallback;