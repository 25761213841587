import React from 'react';
import PropTypes from 'prop-types';

class ScrollBottom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.scrollContainer = React.createRef();
  }

  handlePanelScroll = event => {
    const { target: element } = event;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight)
      this.props.onScrollBottom();
  };

  componentDidMount() {
    this.scrollContainer.current.addEventListener('scroll', this.handlePanelScroll, false);
  }

  componentWillUnmount() {
    this.scrollContainer.current.removeEventListener('scroll', this.handlePanelScroll, false);
  }

  render() {
    return (
      <div ref={this.scrollContainer} className={this.props.customClass}>
        {this.props.children}
      </div>
    )
  }
}

ScrollBottom.defaultProps = {
  customClass: ''
};

ScrollBottom.propTypes = {
  onScrollBottom: PropTypes.func.isRequired,
  customClass: PropTypes.string
};

export default ScrollBottom;