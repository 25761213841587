import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchMyEvents } from 'actions/myEventsActions';
import { fetchLearnClasses } from 'actions/learnActions';
import { fetchPublishedMyNewsArticles } from 'actions/myNewsActions';
import { fetchTasksForCta, resetTasks } from 'actions/tasksActions';
import { getGreeting } from 'utils/homeUtils';
import ClassList from 'components/shared/currentClasses/ClassList.js';
import ArticleGrid from 'components/shared/latestArticles/ArticleGrid';
import TasksCTA from 'components/views/tasks/cta/TasksCTA';
import { Container } from 'components/layout';
import { Typography } from 'fs-toolkit-react';
import { isImpersonating } from 'utils/profileUtils';
import {
	learnStatusCodeErrors,
	learnEmptyClassError,
	impersonationError
} from 'components/views/error/errorTypes';
import withResize from 'components/HOC/WithResize';
import { getErrorStatus } from 'utils/errorUtils';

class Home extends React.Component {
	static propTypes = {
		classes: PropTypes.instanceOf(List).isRequired,
		classesProcessing: PropTypes.bool.isRequired,
		events: PropTypes.instanceOf(List).isRequired,
		eventsProcessing: PropTypes.bool.isRequired,
		stories: PropTypes.instanceOf(List).isRequired,
		storiesProcessing: PropTypes.bool.isRequired,
		profile: PropTypes.instanceOf(Map).isRequired,
		tasksProcessing: PropTypes.bool.isRequired,
		tasksItem: PropTypes.instanceOf(Map).isRequired
	};

	componentDidMount() {
		this.props.dispatch(fetchPublishedMyNewsArticles());
		this.props.dispatch(fetchMyEvents());
		this.props.dispatch(fetchTasksForCta({}));

		if (!isImpersonating()) {
			this.props.dispatch(fetchLearnClasses());
		}
	}

	componentWillUnmount() {
		this.props.dispatch(resetTasks());
	}

	getContingencyMessage() {
		const { classesError } = this.props;
		let message = learnEmptyClassError.message;

		if (classesError) {
			const status = getErrorStatus(classesError);

			let contingency =
				learnStatusCodeErrors[status] || learnStatusCodeErrors[404];

			message = contingency.message;
		}

		return message;
	}

	getClassesContent() {
		const { classes } = this.props;
		let content = null;

		if (isImpersonating()) {
			content = <Typography>{impersonationError.message}</Typography>;
		} else if (!classes.isEmpty()) {
			content = <ClassList classes={classes} />;
		} else {
			content = (
				<Typography>
					<span
						dangerouslySetInnerHTML={{
							__html: this.getContingencyMessage()
						}}
					/>
				</Typography>
			);
		}
		return (
			<section
				className={cn('section section--slashed', {
					'section--no-data': classes.size < 1
				})}
			>
				<Typography component="h4" modifier="slashed">
					Classes
				</Typography>
				{content}
			</section>
		);
	}

	getGreeting() {
		let greeting = getGreeting();

		return (
			<Typography component="h4" key={greeting.id}>
				Good {greeting.partOfTheDay}, {this.props.profile.get('firstName')}!{' '}
				<span
					role="img"
					aria-label={greeting.label}
					dangerouslySetInnerHTML={{ __html: greeting.emoji }}
				/>
			</Typography>
		);
	}

	render() {
		const {
			classes,
			classesProcessing,
			events,
			eventsProcessing,
			profile,
			stories,
			storiesProcessing,
			tasksItem,
			tasksProcessing
		} = this.props;

		const displayClassesContent =
			(profile.get('isStaff') && classes.size > 1) || !profile.get('isStaff');

		/* TASK: this needs to be addressed in the layout refactor
		shouldn't need to add styles when all we need is a full width
		div.
		*/

		return (
			<Container
				isLoading={
					storiesProcessing ||
					eventsProcessing ||
					classesProcessing ||
					tasksProcessing
				}
			>
				<div>
					{this.getGreeting()}
					<React.Fragment>
						{tasksItem.get('totalElements') ? (
							<TasksCTA tasksCount={tasksItem.get('totalElements')} />
						) : null}
					</React.Fragment>
					<React.Fragment>
						{displayClassesContent ? this.getClassesContent() : null}
					</React.Fragment>
					<ArticleGrid
						articles={stories}
						type="story"
						profile={profile}
						withGroup
						limit={3}
					/>
					<ArticleGrid
						articles={events}
						type="event"
						profile={profile}
						withGroup
						limit={3}
					/>
				</div>
			</Container>
		);
	}
}

const mapStateToProps = state => ({
	classes: state.getIn(['learnClasses', 'content']),
	classesProcessing: state.getIn(['learnClasses', 'processing']),
	classesError: state.getIn(['learnClasses', 'error']),
	events: state.getIn(['myEvents', 'events']),
	eventsProcessing: state.getIn(['events', 'processing']),
	stories: state.getIn(['myNewsArticles', 'articles']),
	storiesProcessing: state.getIn(['myNewsArticles', 'processing']),
	tasksItem: state.getIn(['tasks', 'item']),
	tasksProcessing: state.getIn(['tasks', 'processing']),
	profile: state.getIn(['profile', 'data'])
});

export default connect(mapStateToProps)(withRouter(withResize(Home)));
