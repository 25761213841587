import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { CardGroup } from 'components/patterns/card';
import SearchItem from './SearchItem';

class SearchList extends React.Component {
	static propTypes = {
		articles: PropTypes.instanceOf(List).isRequired,
		superCategory: PropTypes.string.isRequired,
		isGlobalSearch: PropTypes.bool
	};
	render = () => (
		<CardGroup>
			{this.props.articles.map(article => (
				<SearchItem
					article={article}
					isGlobalSearch={this.props.isGlobalSearch}
					superCategory={this.props.superCategory}
					key={article.get('id')}
				/>
			))}
		</CardGroup>
	);
}

export default SearchList;
