import axios from 'axios';
import { BASE_URL } from 'constants/APIConstants';
import { HTTPMethods } from 'constants/APIConstants';
import AxiosConfig from './config/AxiosConfig';

const { GET, POST, PUT, PATCH, DELETE } = HTTPMethods;

AxiosConfig();

// axios config options: https://github.com/axios/axios#request-config
const sendAPIRequest = ({
	baseURL,
	urlPath,
	method,
	params,
	headers,
	data,
	timeout = 0
}) => {
	const defaultRequest = {
		url: urlPath,
		baseURL: baseURL || BASE_URL, //default to fs one
		method,
		headers
	};

	const request = Object.assign({}, defaultRequest, params, data, timeout);

	return axios(request);
};

export default {
	get({ baseURL, urlPath, headers, params, timeout = 0 }) {
		return sendAPIRequest({
			baseURL,
			urlPath,
			method: GET,
			headers,
			params,
			timeout
		});
	},
	post({ baseURL, urlPath, headers, data }) {
		return sendAPIRequest({ baseURL, urlPath, method: POST, headers, data });
	},
	put({ baseURL, urlPath, headers, data }) {
		return sendAPIRequest({ baseURL, urlPath, method: PUT, headers, data });
	},
	patch({ baseURL, urlPath, headers, data }) {
		return sendAPIRequest({ baseURL, urlPath, method: PATCH, headers, data });
	},
	delete({ baseURL, urlPath, headers }) {
		return sendAPIRequest({ baseURL, urlPath, method: DELETE, headers });
	},
	formPost({ baseURL, urlPath, headers, data }) {
		// there's a bug with axios and form data - passing form data in the data config attribute
		// removes the content type header; however, called like below, axios recognizes the data is
		// a FormData object and sets the correct header ¯\_(ツ)_/¯
		return axios.post(`${baseURL || BASE_URL}${urlPath}`, data, { headers });
	}
};
