import React from 'react';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Typography } from 'fs-toolkit-react';
import { CardGroup } from 'components/patterns/card';
import ArticleGridItem from './ArticleGridItem';
import { getBasePath } from 'utils/globalUtils';
import WithResize from 'components/HOC/WithResize';

class ArticleGrid extends React.Component {
	static propTypes = {
		articles: PropTypes.instanceOf(List).isRequired,
		group: PropTypes.instanceOf(Map),
		profile: PropTypes.instanceOf(Map).isRequired,
		type: PropTypes.oneOf(['event', 'story']).isRequired,
		withGroup: PropTypes.bool,
		hide: PropTypes.bool
	};

	static defaultProps = {
		group: Map(),
		withGroup: false
	};

	viewMoreLink = basePath => {
		if (this.props.type === 'story') {
			let path = basePath ? `${basePath}/stories` : 'connect/stories';

			return (
				<p className="link__view-more">
					<Link to={{ pathname: path }}>View all Stories</Link>
				</p>
			);
		} else if (this.props.type === 'event') {
			let path = basePath ? `${basePath}/events` : 'connect/events';

			return (
				<p className="link__view-more">
					<Link to={{ pathname: path }}>View all Events</Link>
				</p>
			);
		}
	};

	getCardItems = article => {
		const { group } = this.props;
		const items = {
			groupTitle: article.getIn(['group', 'name']),
			groupAvatar: article.getIn(['group', 'icon'])
		};

		if (!group.isEmpty()) {
			items.groupTitle = group.get('name');
			items.groupAvatar = group.getIn(['iconImage', 'url']);
		}

		return items;
	};

	getGridItem = () => {
		const { articles, withGroup, group, type, profile } = this.props;
		const tz = profile.get('timeZone');

		return articles.slice(0, this.props.limit).map((article, i) => {
			if (withGroup) {
				const { groupTitle, groupAvatar } = this.getCardItems(article);

				return (
					<ArticleGridItem
						article={article}
						group={group}
						groupAvatarUrl={groupAvatar}
						groupTitle={groupTitle}
						key={i}
						timezone={tz || null}
						type={type}
					/>
				);
			} else {
				return (
					<ArticleGridItem
						article={article}
						group={group}
						key={i}
						timezone={tz || null}
						type={type}
					/>
				);
			}
		});
	};

	getContingencyMessage = () => {
		const messages = {
			story: 'Looks like there are no stories at this time.',
			event: 'Looks like there are no events at this time.'
		};

		return messages[this.props.type];
	};

	render = () => {
		const { articles, group, isMobile, type, hide } = this.props;

		const basePath = group ? getBasePath(group, '/connect') : null;
		return (
			<React.Fragment>
				{!hide ? (
					<section className="article-grid">
						<Typography modifier="slashed">
							{type === 'story' ? 'Latest Stories' : 'Upcoming Events'}
						</Typography>

						{!articles.isEmpty() ? (
							<React.Fragment>
								<CardGroup
									modifier={['grid', 'scroll']}
									gutter={isMobile ? 10 : 0}
								>
									{this.getGridItem()}
								</CardGroup>

								{this.viewMoreLink(basePath)}
							</React.Fragment>
						) : (
							<p>{this.getContingencyMessage()}</p>
						)}
					</section>
				) : null}
			</React.Fragment>
		);
	};
}

ArticleGrid.propType = {
	limit: PropTypes.number
};

export default WithResize(ArticleGrid);
