import React from 'react';
import { connect } from 'react-redux';

export const withPolling = (polling, interval = 60000) => 
  ComposedComponent => {
    class Polling extends React.Component {
      componentDidMount() {
        this.props.polling();
        this.timer = setInterval(
          () => this.props.polling(),
        interval);
      };

      componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
      };

      render = () =>  <ComposedComponent {...this.props}/>;
    };
    const mapStateToProps = () => ({});
    const mapDispatchToProps = { polling };
    return connect(mapStateToProps, mapDispatchToProps)(Polling);
  };