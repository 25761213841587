import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toParams } from 'utils/globalUtils';

const searchProcessing = () => ({
	type: ActionTypes.SEARCH_RESULTS_PROCESSING
});

const searchSuccess = data => ({
	type: ActionTypes.SEARCH_RESULTS_SUCCESS,
	data
});

const searchFailure = error => ({
	type: ActionTypes.SEARCH_RESULTS_FAILURE,
	error
});

export const searchReset = () => ({
	type: ActionTypes.SEARCH_RESULTS_RESET
});

export const search = (inputParams = { page: 0 }, globalSearch = false) => {
	return (dispatch, getState) => {
		dispatch(searchProcessing());
		const groupId = getState().getIn(['app', 'group', 'id']);
		const endpoint = globalSearch
			? `/search?${toParams(inputParams)}`
			: `/groups/${groupId}/support_articles?${toParams(inputParams)}`;

		return APIClient.get(endpoint).then(
			({ data }) => dispatch(searchSuccess(data)),
			err => dispatch(searchFailure(err))
		);
	};
};
