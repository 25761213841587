import { fromJS } from 'immutable';
import { makeActionCreator } from '../utils/reducerUtils';
import * as NewsArticlesAPI from 'api/newsArticles/NewsArticlesAPI';

// actions
const PROCESSING = 'PROCESSING';
const PROCESSING_SCROLL = 'PROCESSING_SCROLL';
const PROCESSING_FILTER = 'PROCESSING_FILTER';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

// reducer
const initialState = fromJS({
	data: {},
	articles: [],
	processing: false,
	componentInitialized: false,
	processingFilter: false,
	processingScroll: false,
	error: null
});

const getArticles = (articles, articlesItem) => {
	const newArticles = articlesItem.content;

	return articlesItem.number > 0
		? articles.toJS().concat(newArticles)
		: newArticles;
};

export default function(state = initialState, action) {
	switch (action.type) {
		case PROCESSING:
			return state.merge({
				processing: true
			});
		case PROCESSING_FILTER:
			return state.merge({
				processingFilter: true
			});
		case PROCESSING_SCROLL:
			return state.merge({
				processingScroll: true
			});
		case SUCCESS:
			const articles = getArticles(state.get('articles'), action.payload);

			return state.merge({
				processing: false,
				processingScroll: false,
				processingFilter: false,
				componentInitialized: true,
				data: action.payload,
				articles
			});
		case FAILURE:
			return state.merge({
				error: action.payload,
				processing: false,
				processingScroll: false,
				processingFilter: false
			});
		default:
			return state;
	}
}
const processing = makeActionCreator(PROCESSING);
const processingScroll = makeActionCreator(PROCESSING_SCROLL);
const processingFilter = makeActionCreator(PROCESSING_FILTER);
const success = makeActionCreator(SUCCESS, 'payload');
const failure = makeActionCreator(FAILURE, 'payload');

export function fetchNewsArticles({
	params = {},
	processingType = 'processing'
}) {
	return dispatch => {
		dispatchProcessingAction(processingType, dispatch);

		return NewsArticlesAPI.getArticles(params).then(
			({ data }) => dispatch(success(data)),
			err => dispatch(failure(err))
		);
	};
}

const dispatchProcessingAction = (type, dispatch) => {
	const types = {
		processing,
		processingScroll,
		processingFilter
	};

	dispatch(types[type]());
};
