import React from 'react';
import cn from 'classnames';

function FeaturedBlock({ children, className }) {
	return (
		<div className={cn('fs-featured', className)}>
			{children}
		</div>
	);
}

export default FeaturedBlock;