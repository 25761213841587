import { useRouteMatch } from 'react-router';

// umbrella types
const connect = 'connect';
const learn = 'learn';
const support = 'support';

function useUmbrella() {
	const match = useRouteMatch([`/${connect}`, `/${learn}`, `/${support}`]);

	return [
		// current umbrella
		!!match && `${match.path.substring(1)}`,
		// umbrella types
		{ connect, learn, support }
	];
}

export default useUmbrella;
