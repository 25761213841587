import React from 'react';
import loadable from '@loadable/component';
import AsyncImporter from 'components/handlers/AsyncImporter';
import Auth0Callback from 'components/handlers/Auth0Callback';
import ErrorBoundary from 'components/handlers/ErrorBoundary';

import withTracker from 'components/HOC/WithTracker';

import ReactGA from 'react-ga4';
import { LastLocationProvider } from 'react-router-last-location';
// import EmbeddedChat from 'components/ui/EmbeddedChat';

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';

import Maintenance from './components/handlers/Maintenance';
import TokenExpired from './components/handlers/TokenExpired';
import NetworkError from './components/handlers/NetworkError';
import AccountError from './components/handlers/AccountError';
import FourZeroThree from './components/handlers/403';
import FourZeroOne from './components/handlers/401';
import FiveHundred from './components/handlers/500';
import FourZeroFour from './components/handlers/404';

import AuthenticatedWrapper from 'components/handlers/AuthenticatedWrapper';
import LayoutProvider from 'components/layout/LayoutProvider';

const KBPublicArticle = loadable(
	() => import('components/views/support/knowledge-base/publicArticle/KBPublicArticle'),
	{ fallback: <AsyncImporter /> }
);

const PublicUploader = loadable(
	() => import('components/views/tasks/public-uploader/PublicUploader'),
	{ fallback: <AsyncImporter /> }
);

const Login = loadable(
	() => import('components/views/Login'), {
	fallback: <AsyncImporter />
});

const Logout = loadable(
	() => import('components/views/Logout'), {
	fallback: <AsyncImporter />
});

// initialize google analytics
if (process.env.REACT_APP_GA_ID) {
	ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

class FSOne extends React.PureComponent {
	render() {
		return (
			<ErrorBoundary>
				{/* <EmbeddedChat /> */}
				<Router>
						<LastLocationProvider>
							<LayoutProvider>
								<Switch>
									<Route exact path="/login" component={Login} />
									<Route exact path="/logout" component={Logout} />

									<Route exact path="/callback" component={Auth0Callback} />

									<Route exact path="/500" component={FiveHundred} />
									<Route exact path="/401" component={FourZeroOne} />
									<Route exact path="/403" component={FourZeroThree} />
									<Route exact path="/404" component={FourZeroFour} />

									<Route exact path="/maintenance" component={Maintenance} />
									<Route exact path="/account_error" component={AccountError} />
									<Route exact path="/network-error" component={NetworkError} />
									<Route exact path="/token-expired" component={TokenExpired} />

									<Route exact path="/public/tasks/uploader" component={withTracker(PublicUploader)} />
									<Route exact path="/public/todos/uploader" component={withTracker(PublicUploader)} />
									<Route
										exact
										path={[
											// TODO: Create backward compatibility for now since there isn't any reference to a public article in codebase
											'/public/knowledge_base_articles/:id',
											'/public/knowledge_base_articles/:id/:title'
										]}
										component={withTracker(KBPublicArticle)}
									/>
									<Route path="*" component={AuthenticatedWrapper}/>
								</Switch>
							</LayoutProvider>
						</LastLocationProvider>	
				</Router>
			</ErrorBoundary>
		);
	}
}

export default FSOne;