import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import rootPaths from './rootPaths';
import { Map, fromJS } from 'immutable';
import { getGroup, getGroupReset } from 'actions/groupActions';
import { formatStringForUrl } from 'utils/urlUtils';
import isEmpty from 'lodash/isEmpty';

/**
 * The PathFinder component figures out the basePath for dynamic routes. These paths are built from custom
 * methods that are mapped to specific root paths.
 */
class PathFinder extends React.Component {
	static propTypes = {
		// The root path should only be strings used from rootPaths.js. When a new route needs to be dynamically built
		// then add the new root path to the rootPath.js file
		rootPath: PropTypes.oneOf(Object.values(rootPaths)).isRequired,

		// When children are provided then the component should use a function as children and will receive data from the
		// the parameter else if no children then the component will return null
		children: PropTypes.func,

		// Provide the component with a group to use to generate a base path
		useGroup: PropTypes.instanceOf(Map),

		// This prop provides a way to get path information outside of the render function.
		// Use this component within the render method with no children and it'll be a null element. Then hookup a callback
		// function to handle whenever there's an update.
		// example: <PathFinder rootPath="/connect" onPathUpdate={(data) => { ... }} />
		onPathUpdate: PropTypes.func,

		immutable: PropTypes.bool
	};

	static defaultProps = {
		useGroup: Map(),
		children: () => null,
		onPathUpdate: (data) => {},
		immutable: false
	};


	state = {
		// Will be updated with the group the use for building dynamic paths for certain rootPaths
		group: Map(),
		processing: false,
		error: Map(),
	};

	/**
	 * Builds the base path for the root path passed into this method
	 * @param pathConfig - configurations to build dynamic paths
	 * @param pathConfig.root {string} - root path to get the base path for
	 * @param pathConfig.group {Map} - specific group
	 * @param pathConfig.appGroup {Map} - application group
	 * @param [pathConfig.groupPath] {boolean} - generate path using the group. Only use when it is certain that the group isn't the application group
	 * @return string
	 */
	static getBasePath(pathConfig) {
		const { root } = pathConfig;
		switch (root) {
			case rootPaths.connect:
				// connect related config
				const {
					group = Map(),
					appGroup = Map({ id: parseInt(process.env.REACT_APP_GROUP_CONFIG_ID) }),
					groupPath = false,
					fromParams = {}
				}  = pathConfig;

				if (
					(!group.isEmpty() && group.get('id') !== appGroup.get('id')) ||
					(!group.isEmpty() && groupPath) ||
					!isEmpty(fromParams)
				) {
					const groupName = fromParams.groupName || formatStringForUrl(group.get('name'));
					// Manually inserting an "s" for building the proper path. Will need a better solution if group types need an "ies" vs "s"
					const groupType = fromParams.groupType || `${formatStringForUrl(group.get('type'))}s`;
					const groupId = fromParams.groupId || group.get('id');

					if (groupId && groupName && groupType) {
						return `${root}/${groupType}/${groupName}/${groupId}`;
					}
				}
				return root;
			default:
				return root;
		}
	};

	/**
	 * A lookup function for retrieving the data needed to build a path for a particular root path
	 * @param rootPath
	 * @returns {*}
	 */
	getConfigForPath = (rootPath) => {
		const data = {
			[rootPaths.connect]: {
				root: rootPath,
				group: this.state.group,
				appGroup: this.props.appGroup
			}
		};
		return data[rootPath];
	};

	getPathData = () => {
		if (this.props.rootPath === rootPaths.connect) {
			const wrapper = this.props.immutable ? fromJS : (data) => data;
			return wrapper({
				basePath: PathFinder.getBasePath({ ...this.getConfigForPath(this.props.rootPath) }),
				isAppGroup: this.props.appGroup.get('id') === this.state.group.get('id'),
				appGroup: this.props.appGroup,
				group: this.state.group,
				groupProcessing: this.state.processing,
				groupError: this.state.error
			});
		}
	};

	/**
	 * This method is called whenever there's a state update for the group.
	 * the onPathUpdate method is also triggered here with updated data
 	 */
	onGroupUpdate = () => this.props.onPathUpdate(this.getPathData());

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.useGroup.isEmpty()) {
			if (
				prevProps.pageGroup.size !== this.props.pageGroup.size ||
				prevProps.pageGroupProcessing !== this.props.pageGroupProcessing ||
				prevProps.pageGroupError !== this.props.pageGroupError
			) {
				this.setState({
					group: this.props.pageGroup.isEmpty() ? this.state.group : this.props.pageGroup,
					processing: this.props.pageGroupProcessing,
					error: this.props.pageGroupError
				}, this.onGroupUpdate);
			}
		}
	}

	componentDidMount() {
		let {
			match,
			rootPath,
			dispatch,
			appGroup,
			useGroup
		} = this.props;

		const {
			groupId
		} = match.params;


		if (rootPath === rootPaths.connect) {
			if (!useGroup.isEmpty()) {
				this.setState({ group: useGroup }, this.onGroupUpdate);
			} else if (groupId) {
				const currentGroupId = this.props.pageGroup.get('id') && Number(this.props.pageGroup.get('id'));
				const paramGroupId = Number(groupId);

				// Reset group only if the current group stored in redux empty or if the group id is not the same
				if (currentGroupId) {
					if (currentGroupId !== paramGroupId) dispatch(getGroupReset())
				} else {
					dispatch(getGroupReset())
				}

				dispatch(getGroup(groupId));
			} else {
				this.setState({ group: appGroup }, this.onGroupUpdate);
			}
		}
	}

	render() {
		return this.props.children(this.getPathData());
	}
}

const mapStateToProps = (state) => ({
	// group populated if there's a groupId in the url
	pageGroup: state.getIn(['group', 'group']),
	pageGroupProcessing: state.getIn(['group', 'processing']),
	pageGroupError: state.getIn(['group', 'error']),

	// group that is auto populated on application startup and is the main group for the app
	appGroup: state.getIn(['app', 'group'])
});

export default connect(mapStateToProps)(withRouter(PathFinder));
