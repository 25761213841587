import { formatStringForUrl, formatGroupTypeForUrl } from 'utils/urlUtils';

const BASE_APP_URL = process.env.REACT_APP_PUBLIC_URL;

const generalFeedbackFormURL = () => {
	return process.env.REACT_APP_FORMS_GENERAL_FEEDBACK;
};

const kbFeedbackFormURL = (profile, article) => {
	const title = article.get('title');
	const type = article
		.get('categories')
		.first()
		.get('type');
	const categories = article
		.get('categories')
		.map(category => category.get('title'))
		.join(', ');
	const firstName = profile.get('firstName');
	const lastName = profile.get('lastName');
	const email = profile.getIn(['universityEmail', 'address']) || '';
	const url = `${BASE_APP_URL}/support/knowledge_base_articles/${type.toLowerCase()}/${article.get(
		'id'
	)}/${formatStringForUrl(title)}`;

	return process.env.REACT_APP_FORMS_SUPPORT_FEEDBACK.replace(
		'%title%',
		encodeURIComponent(title)
	)
		.replace('%firstName%', encodeURIComponent(firstName))
		.replace('%lastName%', encodeURIComponent(lastName))
		.replace('%email%', email)
		.replace('%url%', url)
		.replace('%type%', encodeURIComponent(type))
		.replace('%categories%', encodeURIComponent(categories));
};

const eventPromotionFormURL = (profile, eventOccurrence) => {
	const profileId = profile.get('id');
	const firstName = profile.get('firstName');
	const lastName = profile.get('lastName');
	const email = profile.getIn(['universityEmail', 'address']) || '';
	const eventId = eventOccurrence.get('eventId');
	const title = eventOccurrence.get('title');
	// need the first group from a visibility to be able to build the url to the event occurrence details
	const group = eventOccurrence.get('group');
	const url = `${BASE_APP_URL}/connect/${formatGroupTypeForUrl(
		group.get('type')
	)}/${formatStringForUrl(group.get('name'))}/${group.get(
		'id'
	)}/events/${eventOccurrence.get('eventId')}/${eventOccurrence.get(
		'sequenceId'
	)}/${formatStringForUrl(title)}`;

	return process.env.REACT_APP_FORMS_EVENT_PROMOTION.replace(
		'%firstName%',
		encodeURIComponent(firstName)
	)
		.replace('%lastName%', encodeURIComponent(lastName))
		.replace('%profileId%', profileId)
		.replace('%email%', email)
		.replace('%title%', encodeURIComponent(title))
		.replace('%eventId%', eventId)
		.replace('%url%', url);
};

const eventRegistrationFormURL = ({
	profile,
	eventOccurrence,
	registrationUrl
}) => {
	const profileId = profile.get('id');
	const name = `${profile.get('firstName')} ${profile.get('lastName')}`;
	const email = profile.getIn(['universityEmail', 'address']) || '';
	const eventId = eventOccurrence.get('eventId');
	const sequenceId = eventOccurrence.get('sequenceId');

	return `${registrationUrl}?name=%name%&email=%email%&profile_id=%profile_id%&event_id=%event_id%&sequence_id=%sequence_id%`
		.replace('%name%', encodeURIComponent(name))
		.replace('%email%', encodeURIComponent(email))
		.replace('%profile_id%', encodeURIComponent(profileId))
		.replace('%event_id%', encodeURIComponent(eventId))
		.replace('%sequence_id%', encodeURIComponent(sequenceId));
};

const isContentViewable = (profile, content) => {
	let segmentIds = content.get('segments').map(a => {
		return a.get('id');
	});
	const found = profile.get('segments').filter(segment => {
		return segmentIds.includes(segment.get('id'));
	});
	return !found.isEmpty();
};

export {
	generalFeedbackFormURL,
	kbFeedbackFormURL,
	eventPromotionFormURL,
	isContentViewable,
	eventRegistrationFormURL
};
