import { getAccessToken } from './authentication/Auth';
import HTTPClient from './HTTPClient';
import { LMS_URL, PLATINUM_CMS_BASE_URL, PROFILE_APP_URL } from 'constants/APIConstants';

const getHeaders = ({
	token,
	acceptType = 'application/json',
	contentType = 'application/json'
}) => {
	let headers = {
		Accept: acceptType,
		'Content-Type': contentType,
		Authorization: `Bearer ${token}`
	};

	if (window.localStorage.getItem('impersonation')) {
		headers['X-Impersonation-ID'] = window.localStorage.getItem(
			'impersonation'
		);
	}

	return headers;
};

const getLMSHeaders = ({
	token,
	acceptType = 'application/json',
	contentType = 'application/json'
}) => {
	let headers = {
		Accept: acceptType,
		'Content-Type': contentType,
		Authorization: `Bearer ${token}`,
		'X-Api-Version': 1
	};

	return headers;
};

const getPlatinumCMSHeaders = ({
	acceptType = 'application/json',
	contentType = 'application/json'
}) => {
	let headers = {
		Accept: acceptType,
		'Content-Type': contentType
	};

	return headers;
};

const getPublicUploaderHeaders = ({
	contentType = 'multipart/form-data',
	signature
}) => {
	let headers = {
		'Content-Type': contentType,
		Authorization: signature
	};

	return headers;
};

// API
export default {
	get(urlPath) {
		return getAccessToken().then(token =>
			HTTPClient.get({ urlPath, headers: getHeaders({ token }) })
		);
	},
	getBinary(urlPath) {
		return getAccessToken().then(token =>
			HTTPClient.get({
				urlPath,
				headers: getHeaders({
					token,
					acceptType: 'application/octet-stream',
					contentType: 'application/octet-stream'
				})
			})
		);
	},
	getPublic(urlPath) {
		return HTTPClient.get({ urlPath });
	},
	getFSO(url) {
		return getAccessToken().then(token => {
			return HTTPClient.get({
				baseURL: LMS_URL,
				urlPath: url,
				timeout: 5000,
				headers: getLMSHeaders({ token })
			});
		});
	},
	getPlatinumCMS(url) {
		return HTTPClient.get({
			baseURL: PLATINUM_CMS_BASE_URL,
			urlPath: url,
			headers: getPlatinumCMSHeaders({})
		});
	},
	getPublicProfile(urlPath) {
		// return HTTPClient.get({
		// 	baseURL: PROFILE_APP_URL,
		// 	urlPath: url,
		// })
		return getAccessToken().then(token =>
			HTTPClient.get({ baseURL: PROFILE_APP_URL, urlPath, headers: getHeaders({ token }) })
		);
	},
	put(urlPath, data) {
		return getAccessToken().then(token =>
			HTTPClient.put({
				urlPath,
				headers: getHeaders({ token }),
				data
			})
		);
	},
	post(urlPath, data) {
		return getAccessToken().then(token =>
			HTTPClient.post({
				urlPath,
				headers: getHeaders({ token }),
				data
			})
		);
	},
	postFormData(urlPath, data) {
		return getAccessToken().then(token => {
			return HTTPClient.formPost({
				urlPath,
				headers: getHeaders({ token, contentType: 'multipart/form-data' }),
				data
			});
		});
	},
	postPublicUploaderData(urlPath, data, signature) {
		return HTTPClient.formPost({
			urlPath,
			headers: getPublicUploaderHeaders({ signature }),
			data
		});
	},
	delete(urlPath) {
		return getAccessToken().then(token =>
			HTTPClient.delete({
				urlPath,
				headers: getHeaders({ token })
			})
		);
	}
};
