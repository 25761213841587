import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsCardHeader } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';


class CardHeader extends React.Component {
	static propTypes = {
		url: PropTypes.string,
		className: PropTypes.string
	};

	static defaultProps = {
		url: '',
		className: '',
	};

	render() {
		const { url, className } = this.props;
		const Tag = url ? 'a' : 'div';
		const cardHeaderProps = url ? { href: url } : {};
		return (
			<div className={cn(fsCardHeader, className)}>
				<Tag {...cardHeaderProps}>
					{this.props.children}
				</Tag>
			</div>
		);
	}
}

export default withHide(CardHeader);
