import React from 'react';
import PropTypes from 'prop-types';
import { fsContainerCenter, fsContainerContent } from '../helpers/layoutClassNameList';
import LoaderWrapper from 'components/ui/LoaderWrapper';

function ContainerDefaultLayout(props) {
	const {
		children,
		...loaderProps
	} = props;

	return (
		<div className={fsContainerCenter}>
			<div className={fsContainerContent}>
				<LoaderWrapper {...loaderProps}>{children}</LoaderWrapper>
			</div>
		</div>
	);
}

ContainerDefaultLayout.propTypes = {
	children: PropTypes.any
};

export default ContainerDefaultLayout;