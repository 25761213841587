const greetings = [
	{
		id: 1,
		emoji: '😴',
		hours: {
			start: '0',
			end: '4'
		},
		partOfTheDay: 'night',
		label: 'sleep'
	},
	{
		id: 2,
		emoji: '☕',
		hours: {
			start: '5',
			end: '11'
		},
		partOfTheDay: 'morning',
		label: 'coffee'
	},
	{
		id: 3,
		emoji: '😎',
		hours: {
			start: '12',
			end: '16'
		},
		partOfTheDay: 'afternoon',
		label: 'sunglasses'
	},
	{
		id: 4,
		emoji: '🌛',
		hours: {
			start: '17',
			end: '23'
		},
		partOfTheDay: 'evening',
		label: 'moon'
	}
];

let currentHour = new Date().getHours();

const isGreetingForTimeOfTheDay = greeting => {
	return (
		currentHour >= greeting.hours.start && currentHour <= greeting.hours.end
	);
};

const getGreeting = () => greetings.find(isGreetingForTimeOfTheDay);

export { getGreeting };
