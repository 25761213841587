import { useEffect, useState } from 'react';

function useHover(ref) {
	const [inHover, setInHover] = useState(false);

	useEffect(function handleMouseEvents() {
		const onEnter = () => setInHover(true);
		const onLeave = () => setInHover(false);
		if (ref) {
			const node = ref.current || ref;
			node.addEventListener('mouseenter', onEnter);
			node.addEventListener('mouseleave', onLeave);
			return () => {
				node.removeEventListener('mouseenter', onEnter);
				node.removeEventListener('mouseleave', onLeave);
			}
		}
	}, [ref]);

	return inHover;
}

export default useHover;