import PropTypes from 'prop-types';
import React from 'react';
import { ButtonRefactor as Button, FontIcon } from 'fs-toolkit-react';
import { CardContext } from './CardContext';
import { withHide } from './helpers/withHide';
import { withResponsiveProp } from './helpers/withResponsiveProp';

/**
 * Button components wrapped with logic for props to be placed on the button
 * if the current device is mobile or desktop
 */
class CardButton extends React.Component {
	static contextType = CardContext;

	static propTypes = {
		icon: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				mobile: PropTypes.string,
				desktop: PropTypes.string
			})
		]),
		iconSize: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				mobile: PropTypes.string,
				desktop: PropTypes.string
			})
		]),
		label: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				mobile: PropTypes.string,
				desktop: PropTypes.string
			})
		]),
		type: PropTypes.string,
		onClick: PropTypes.func.isRequired
	};

	static defaultProps = {
		modifier: 'secondary'
	};

	render() {
		const {
			icon,
			iconSize,
			label,
			modifier,
			onClick,
			type,
			...rest
		} = this.props;

		return (
			<Button modifier={type || modifier} onClick={onClick} {...rest}>
				{icon && <FontIcon icon={icon} iconSize={iconSize} />}
				{label}
			</Button>
		);
	}
}

const responsivePropConfig = [
	{ propName: 'iconSize', mobile: '20px', desktop: '' },
	{ propName: 'label', mobile: '', desktop: '' },
	{ propName: 'icon', mobile: '', desktop: '' }
];

export default withResponsiveProp(withHide(CardButton))(responsivePropConfig);
