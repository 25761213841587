import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	page: 0,
	query: '',
	filter: '',
	lastPage: false,
	processing: false,
});

export default function (state = initialState, action) {
	switch (action.type) {
		case ActionTypes.PAGINATION_LAST_PAGE:
			return state.merge({
				lastPage: action.payload,
				processing: false,
			});
		case ActionTypes.PAGINATION_PROCESSING:
			return state.merge({
				processing: true,
			});
		case ActionTypes.PAGINATION_INCREMENT:
			return state.merge({
				page: state.get('page') + 1,
				processing: false,
			});
		case ActionTypes.PAGINATION_RESET:
			return state.merge({
				page: 0,
				query: '',
				filter: '',
				lastPage: false,
				processing: false,
			});
		case ActionTypes.PAGINATION_UPDATE_QUERY:
			return state.merge({
				query: action.payload,
				processing: false,
			});
		case ActionTypes.PAGINATION_UPDATE_FILTER:
			return state.merge({
				filter: action.payload,
				processing: false,
			});
		default:
			return state;
	}
}