import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  data: [],
  page: 0,
  lastPage: true,
  error: null,
  processing: false,
  markedNotificationProcessing: false,
  url: '',
  urlProcessing: false,
  urlFailure: null,
  unreadCount: 0
});

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.NOTIFICATION_MARKED_PROCESSING:
      return state.merge({
        processing: false,
        markedNotificationProcessing: true
      });
    case ActionTypes.NOTIFICATION_MARKED_SUCCESS:
      let index =  state.get('data').findIndex((notification) => notification.get('id') === action.data.id);
      // find the notification in the data. it should be in the list, but if not(really should be there), just return the current list
      if(index !== -1){
        return state.setIn(['data', index], fromJS(action.data)).merge({
          processing: false,
          markedNotificationProcessing: false,
          error: null,
          unreadCount: (state.get('unreadCount') - 1) < 0 ? 0 : (state.get('unreadCount') - 1)
        });
      } else {
        return state.merge({
          processing: false,
          markedNotificationProcessing: false,
          error: null,
          unreadCount: (state.get('unreadCount') - 1) < 0 ? 0 : (state.get('unreadCount') - 1)
        });
      }
    case ActionTypes.NOTIFICATION_SUCCESS:
      return state.merge({
        data: action.data.number === 0 ? fromJS(action.data.content) : state.get('data').concat(fromJS(action.data.content)),
        page: action.data.number,
        lastPage: action.data.last,
        processing: false,
        error: null
      });
    case ActionTypes.NOTIFICATION_PROCESSING:
      return state.merge({
        processing: true
      });
    case ActionTypes.NOTIFICATION_FAILURE:
      return state.merge({
        processing: false,
        error: action.error
      });
    case ActionTypes.NOTIFICATION_URL_RESET:
      return state.merge({
        url: '',
        urlProcessing: false
      });
    case ActionTypes.NOTIFICATION_URL_PROCESSING:
      return state.merge({
        urlProcessing: true
      });
    case ActionTypes.NOTIFICATION_URL_SUCCESS:
      return state.merge({
        urlProcessing: false,
        url: action.payload,
        urlFailure: null
      });
    case ActionTypes.NOTIFICATION_URL_FAILURE:
      return state.merge({
        urlProcessing: false,
        urlFailure: action.payload
      });
    case ActionTypes.SET_NOTIFICATION_COUNT:
      return state.merge({
        unreadCount: action.data.unacknowledgedCount
      });
    case ActionTypes.RESET_NOTIFICATIONS_PANEL:
      return state.merge({
        data: fromJS([]),
        page: 0,
        lastPage: true
      });
    default:
      return state;
  }
}