import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleRedirectCallback } from 'api/authentication/Auth';
import { Loader } from 'fs-toolkit-react';

class Auth0Callback extends React.Component {
  state = {
    error: false,
    initialized: false,
    redirectTo: "/",
  };

  componentDidMount() {
    const handleCallback = async () => {
      try {
        await handleRedirectCallback();
        const redirectTo = localStorage.getItem('redirectTo');
        // clear localstorage key before redirecting
        localStorage.removeItem('redirectTo');
        this.setState({
          redirectTo: (redirectTo && redirectTo.length ? redirectTo : "/"),
          initialized: true
        });
      } catch(err) {
        this.setState({ error: true });
      }
    };

    handleCallback();
   
  }

  render() {
    const { error, initialized, redirectTo } = this.state;
    return (
      <>
        <Loader />
        {error && <Redirect to="/500" />}
        {initialized && <Redirect to={redirectTo} />}
      </>
    );
  }
}

export default withRouter(connect()(Auth0Callback));
