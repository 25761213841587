import _ from 'lodash';
import React from 'react';
import Class from './Class';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { CardGroup } from 'components/patterns/card';

const getDuplicates = (a, n, word) => {
	if (n > 1) a.push(word);
	return a;
};

class ClassList extends React.Component {
	static propTypes = {
		classes: PropTypes.instanceOf(List).isRequired
	};

	render = () => {
		const { classes } = this.props;
		let classTitles = [];

		classes.map(
			lmsClass =>
				lmsClass &&
				classTitles.push(lmsClass.getIn(['_embedded', 'course', 'title']))
		);

		const duplicates = _(classTitles)
			.chain()
			.countBy()
			.reduce(getDuplicates, [])
			.value();

		return (
			<CardGroup>
				{classes.map(lmsClass => {
					const classTitle = lmsClass.getIn(['_embedded', 'course', 'title']);

					return (
						<Class
							lmsClass={lmsClass}
							key={lmsClass.get('id')}
							hasDate={duplicates.indexOf(classTitle) !== -1}
						/>
					);
				})}
			</CardGroup>
		);
	};
}

export default ClassList;
