import { sortImmutableItems } from 'utils/globalUtils';
import customTaskTemplateMap from 'components/views/tasks/task/templates/custom/customTaskTemplateMap';
import { OrderedMap, List } from 'immutable';

const statuses = {
	approved: 'Approved',
	incomplete: 'Incomplete',
	incompleteReopened: 'Incomplete - Reopened',
	pending: 'Pending'
};

const isIncomplete = (status, taskCode) => {
	if (!status || !taskCode) {
		return undefined;
	}

	let incomplete =
		status === statuses.incomplete || status === statuses.incompleteReopened;

	// esign task with "Pending" status is considered incomplete
	if (taskCode === 'AMESIGNA') {
		incomplete = status === statuses.pending ? true : incomplete;
	}

	return incomplete;
};

const maskInput = ({ type, value, separator }) => {
	const types = {
		phone: {
			regex: /(\d{0,3})(\d{0,3})(\d{0,4})/,
			separator: '-'
		},
		date: {
			regex: /(\d{0,2})(\d{0,2})(\d{0,4})/,
			separator: '/'
		}
	};

	const maskType = types[type];
	separator = separator || maskType.separator;

	value = value.replace(/\D/g, '').match(maskType.regex);

	const maskedInput = !value[2]
		? value[1]
		: `${value[1]}${separator}${value[2]}${
				value[3] ? separator + value[3] : ''
		  }`;

	return maskedInput;
};

const getDefaultLaunchEnrollment = ({ launchEnrmts }) => {
	if (launchEnrmts.size === 1) {
		return launchEnrmts.get(0);
	}

	let enrmtsByStatus = OrderedMap({
		Applying: List(),
		Current: List(),
		Completed: List()
	});

	launchEnrmts.forEach(e => {
		const status = e.get('status');

		if (enrmtsByStatus.has(status)) {
			enrmtsByStatus = enrmtsByStatus.update(status, s => s.push(e));
		}
	});

	/* eslint-disable no-unused-vars */
	for (let [, enrmts] of enrmtsByStatus) {
		if (enrmts.size) {
			return sortImmutableItems({
				items: enrmts,
				comparator: 'startDate',
				comparatorIsDate: true
			}).get(0);
		}
	}

	return launchEnrmts.get(0);
};

const isLastTaskOnCurrentList = (immutableTaskList, taskId) => {
	const currentTaskIndex = immutableTaskList.findIndex(
		item => item.get('id') === parseInt(taskId)
	);

	return currentTaskIndex === immutableTaskList.size - 1;
};

const getCardIcon = status => {
	const mapStatusToIcon = {
		[status]: 'faCheck',
		Pending: 'faHourglass'
	};

	return mapStatusToIcon[status];
};

const getTaskTemplatePath = immutableTask => {
	if (immutableTask.isEmpty()) return;

	const taskTypes = {
		cms: {
			path: 'cms',
			component: 'CMS'
		},
		custom: {
			path: 'custom',
			component: customTaskTemplateMap[immutableTask.getIn(['task', 'code'])]
		},
		digitalForm: {
			path: 'digitalForm',
			component: 'DigitalForm'
		}
	};

	const taskType = taskTypes[immutableTask.getIn(['task', 'type'])];

	return `${taskType.path}/${taskType.component}`;
};

export {
	getCardIcon,
	isIncomplete,
	maskInput,
	getDefaultLaunchEnrollment,
	getTaskTemplatePath,
	isLastTaskOnCurrentList
};
