import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const enrollmentsProcessing = () => ({
  type: ActionTypes.ENROLLMENTS_PANEL_PROCESSING
});

const enrollmentsSuccess = ({ data }) => ({
  type: ActionTypes.ENROLLMENTS_PANEL_SUCCESS,
  payload: data
});

const enrollmentsFailure = (error) => ({
  type: ActionTypes.ENROLLMENTS_PANEL_FAILURE,
  payload: error.response ? error.response.data : {}
});

export const setCurrentEnrollmentForPanel = (enrollment) => ({
  type: ActionTypes.ENROLLMENTS_PANEL_SET_CURRENT_ENROLLMENT,
  payload: enrollment
});

export const resetPanelEnrollments = () => ({
  type: ActionTypes.ENROLLMENTS_PANEL_RESET_CURRENT_ENROLLMENT
});

export const getPanelEnrollments = () => {
  return dispatch => {
    dispatch(enrollmentsProcessing());

    return APIClient.get('/my/enrollments')
      .then((response) => dispatch(enrollmentsSuccess(response)))
      .catch((error) => dispatch(enrollmentsFailure(error)));
  };
};

