import React from 'react';
import ErrorPage from 'components/views/error/ErrorPage';
import { Root } from 'components/layout';

function FiveHundred(props) {
	return (
		<Root>
			<ErrorPage
				{...props}
				icon="faBanLight"
				title="Application Load Error"
				message={'There is an error with loading the application. Please <a href="mailto:technicalsupport@fullsail.edu?Subject=FS%20One%20Account%20Error">contact support</a> to report the problem.'}
			/>
		</Root>
	)
}

export default FiveHundred;