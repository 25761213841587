import React from 'react';
import PropTypes from 'prop-types';
import { IconMessage } from 'components/layout';
import { globalErrors } from './errorTypes';

class Error extends React.Component {
	static propTypes = {
		icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		title: PropTypes.string,
		message: PropTypes.string,
		errorStatus: PropTypes.number
	};

	static defaultProps = {
		icon: 'faSadTearLight',
		message:
			"You didn't do anything wrong. We may have moved the page you're looking for somewhere else.",
		title: "Sorry, that page isn't here"
	};

	render() {
		const { errorStatus } = this.props;
		const props = errorStatus
			? Object.assign({}, this.props, globalErrors[errorStatus])
			: this.props;

		return <IconMessage {...props} />;
	}
}

export default Error;
