import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ActionTypes from 'actions/actionTypes';
import Error from 'components/views/error/Error';
import { fetchEvent, fetchRsvps, checkIn } from 'actions/scannerActions';
import { lookupRsvpFromScan } from 'api/events/RsvpAPI';
import WithResize from 'components/HOC/WithResize';
import { Container } from 'components/layout';
import EventOverviewItem from 'components/views/connect/events/components/EventOverviewItem';
import EventOverview from 'components/views/connect/events/components/EventOverview';
import { getDateboxProps, eventExpireSameDay } from 'utils/eventUtils';
import { formatDate } from 'utils/globalUtils';
import QrReader from 'react-qr-reader'
import {
	colors,
	ButtonRefactor as Button,
	DateBox,
	Input,
	Modal,
	Loader,
	Card,
	Banner,
	TabMenu,
	CardGroup
} from 'fs-toolkit-react';
import { isAdmin } from 'utils/profileUtils';
import { debounce } from 'lodash';
import InfiniteScroller from 'components/shared/InfiniteScroller';
import LoaderWrapper from 'components/ui/LoaderWrapper';
import { toast } from 'react-toastify';
import { SocksContext } from 'components/shared/sockets/SocksProvider';

const MENUS = [
	{ label: 'Scan', value: 'scan' },
	{ label: 'Attendees', value: 'attendees' }
];

class EventTicketScanner extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			action: 'scan',
			isCameraActive: false,
			showNonRsvpCheckinConfirmation: false,
			nonRsvpCheckinData: null,
			lookingUpRsvpProcessing: false
		};
	}

	static contextType = SocksContext;

	componentDidMount() {
		const { dispatch, match, page } = this.props;
		dispatch(fetchEvent(match.params.eventId, match.params.sequenceId));
		this._fetchRsvps(page);
	}

	componentDidUpdate(prevProps, prevState) {
		const { eventProcessing, event, profile, history, match, filter, search, page } = this.props;

		if (!eventProcessing && !event.isEmpty() && prevProps.event.isEmpty()) {
			const checkInAdmin = isAdmin({
				profile: profile,
				adminType: 'checkInAdmin',
				groupId: event.getIn(['group', 'id'])
			});
			const eventAdmin = isAdmin({
				profile: profile,
				adminType: 'calendarAdmin',
				groupId: event.getIn(['group', 'id'])
			});

			if (!checkInAdmin && !eventAdmin) {
				history.push('/401');
			} else {
				this.context.subscribe(`/topic/fsone/admin/events/${match.params.eventId}/occurrences/${match.params.sequenceId}`);
			}
		}

		if(prevProps.search !== search || prevProps.filter !== filter){
			this._fetchRsvps(page);
		}
	}

	componentWillUnmount() {
		const { dispatch, match } = this.props;
		this.context.unsubscribe(`/topic/fsone/admin/events/${match.params.eventId}/occurrences/${match.params.sequenceId}`);
		dispatch({ type: ActionTypes.SCANNER_RESET });
	}

	_fetchRsvps = (page) => {
		const { dispatch, match, search, filter } = this.props;
		const query = search !== '' ? search : null;
		const isScanned = filter === 'all' ? null : (filter === 'checkin');

		dispatch(fetchRsvps(match.params.eventId, match.params.sequenceId, { page, query, isScanned }));
	}

	fetchNextPage = () => {
		const { page, lastPage, processing, dispatch } = this.props;
		if(!lastPage && !processing){
			const nextPage = page + 1;
			dispatch({ type: ActionTypes.SCANNER_INCREMENT_PAGE, page: nextPage })
			this._fetchRsvps(nextPage);
		}
	}

	getDatebox = ({ startsAt, endsAt }, showRange = false) => {
		return showRange ? (
			<DateBox
				large={!this.props.isMobile}
				{...getDateboxProps({ startsAt, endsAt })}
			/>
		) : (
			<DateBox
				large={!this.props.isMobile}
				{...getDateboxProps({ startsAt })}
			/>
		);
	};

	getFormattedDateTime = ({ startTime, endTime, timezone }) => ({
		startDate: formatDate(new Date(startTime), 'ddd MMM DD, YYYY', timezone),
		startTime: formatDate(new Date(startTime), 'h:mm A', timezone),
		endDate: formatDate(new Date(endTime), 'ddd MMM DD, YYYY', timezone),
		endTime: formatDate(new Date(endTime), 'h:mm A z', timezone)
	});

	formatEventDate = dateConfig => {
		const { range = false } = dateConfig;
		const {
			startDate,
			startTime,
			endDate,
			endTime
		} = this.getFormattedDateTime(dateConfig);

		return range
			? `${startDate} from ${startTime} - ${endDate} ${endTime}`
			: `${startDate} @ ${startTime} - ${endTime}`;
	};

	openManualCheckIn = profile => {
		const { dispatch } = this.props;
		dispatch({ type: ActionTypes.SCANNER_SET_MANUAL_CHECKIN_PROFILE, profile });
	};

	closeManualCheckIn = () => {
		const { dispatch } = this.props;
		dispatch({ type: ActionTypes.SCANNER_REMOVE_MANUAL_CHECKIN_PROFILE });
	};

	toggleCamera = () => {
		this.setState({ isCameraActive: !this.state.isCameraActive });
	};

	manualCheckIn = () => {
		const {
			dispatch,
			match,
			manualCheckInProfile,
			checkInProcessing
		} = this.props;
		if (!checkInProcessing) {
			dispatch(
				checkIn(match.params.eventId, match.params.sequenceId, {
					profileId: manualCheckInProfile.get('id')
				})
			);
		}
	};

	handleScan = data => {
		const { match } = this.props;
		if (data) {
			this.setState({ lookingUpRsvpProcessing: true }, () => {
				lookupRsvpFromScan(match.params.eventId, match.params.sequenceId, { encodedData: data }).then((response) => {
					const rsvp = response.data;
					if(rsvp.id !== null){
						this._checkinUser(match.params.eventId, match.params.sequenceId, data);
					} else {
						this.setState({
							showNonRsvpCheckinConfirmation: true,
							nonRsvpCheckinData: data
						});
					}
					this.setState({ lookingUpRsvpProcessing: false });
				}).catch((err) => {
					toast.error("An error occurred looking up the RSVP for this user.", {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					this.setState({ lookingUpRsvpProcessing: false });
				});
			});
		}
	};

	acceptNonRsvpCheckIn = () => {
		const data = this.state.nonRsvpCheckinData;
		this.setState(
			{
				showNonRsvpCheckinConfirmation: false,
				nonRsvpCheckinData: null
			},
			() => {
				const { match } = this.props;
				this._checkinUser(match.params.eventId, match.params.sequenceId, data);
			}
		);
	};

	closeAcceptNonRsvpCheckIn = () => {
		this.setState({
			showNonRsvpCheckinConfirmation: false,
			nonRsvpCheckinData: null
		});
	};

	_checkinUser = (eventId, sequenceId, data) => {
		const { dispatch } = this.props;
		dispatch(checkIn(eventId, sequenceId, { encodedData: data }));
	};

	handleError = err => {};

	handleFilterChange = filter => {
		this.props.dispatch({ type: ActionTypes.SCANNER_UPDATE_FILTER, filter });
	};

	handleSearchChange = (evt) => {
		this.updateSearch(evt.currentTarget.value.trim());
	};

	updateSearch = debounce((search) => {
		this.props.dispatch({ type: ActionTypes.SCANNER_UPDATE_SEARCH, search });
	}, 1000);

	render() {
		const {
			profile,
			event,
			eventProcessing,
			rsvpsProcessing,
			eventError,
			rsvps,
			page,
			lastPage,
			search,
			filter,
			rsvpsError,
			isMobile,
			manualCheckInProfile,
			checkInProcessing
		} = this.props;
		const {
			action,
			isCameraActive,
			showNonRsvpCheckinConfirmation,
			nonRsvpCheckinData,
			lookingUpRsvpProcessing
		} = this.state;

		const formattedEventDateTime = this.formatEventDate({
			startTime: event.get('startsAt'),
			endTime: event.get('endsAt'),
			timezone: profile.get('timeZone'),
			range: !eventExpireSameDay(event.get('startsAt'), event.get('endsAt'))
		});

		const dateboxOptions = {
			startsAt: event.get('startsAt'),
			endsAt: event.get('endsAt'),
			expired: moment(new Date()).isAfter(new Date(event.get('endsAt')))
		};

		const peopleCount = event.get('rsvpCount');
		const peopleAttendingText = !dateboxOptions.expired
			? `${peopleCount}${
					event.get('rsvpLimit') ? `/${event.get('rsvpLimit')}` : ''
			  } going`
			: `${peopleCount}${
					event.get('rsvpLimit') ? `/${event.get('rsvpLimit')}` : ''
			  } attended`;

		const checkInCount = event.get('rsvpProcessed');

		let errorMessage = null;
		if (!!rsvpsError || !!eventError) {
			if (eventError) {
				errorMessage = eventError.get('message');
			} else if (rsvpsError) {
				errorMessage = rsvpsError.get('message');
			} else {
				errorMessage = 'An error has occurred while initializing the scanner.';
			}
		}
		if (!event.isEmpty() && !(event.getIn(['event', 'trackAttendance']) || event.get('tracksAttendance'))) {
			errorMessage = 'This event does not have check-ins configured.';
		}

		const canStillProcessCheckIns =
			moment.duration(moment().diff(moment(event.get('endsAt')))).asMinutes() <=
			60;

		return (
			<Fragment>
				<div className="modal--confirm-mini">
					{manualCheckInProfile && (
						<Modal onClose={this.closeManualCheckIn}>
							<Modal.Header title="Manual Check-in" />
							<div
								dangerouslySetInnerHTML={{
									__html: `<p>Are you sure that you want to manually check in ${manualCheckInProfile.get(
										'firstName'
									)} ${manualCheckInProfile.get(
										'lastName'
									)} for this event?</p>`
								}}
							/>
							<Modal.Footer
								onPrimaryButtonClick={this.manualCheckIn}
								primaryButtonText="Yes"
							>
								<Button
									modifier="secondary"
									onClick={this.closeManualCheckIn}
								>No</Button>
							</Modal.Footer>
						</Modal>
					)}
					{showNonRsvpCheckinConfirmation && (
						<Modal onClose={this.closeManualCheckIn}>
							<Modal.Header title="Manual Check-in" />
							<div
								dangerouslySetInnerHTML={{
									__html: `<p>${
										nonRsvpCheckinData.split(':')[0].split('_')[0]
									} has not RSVP’d. Are you sure you want to check-in this user? It could affect your event counts</p>`
								}}
							/>
							<Modal.Footer
								onPrimaryButtonClick={this.acceptNonRsvpCheckIn}
								primaryButtonText="Yes"
							>
								<Button
									modifier="secondary"
									onClick={this.closeAcceptNonRsvpCheckIn}
								>No</Button>
							</Modal.Footer>
						</Modal>
					)}
				</div>
				{/*<Header hideSubnavigation navigationType='global'/>*/}

				<Container
					isLoading={eventProcessing}
					className="wrapper--ticket-scanner"
					loaderError={errorMessage}
					errorComponent={
						<Error
							icon="faQrcode"
							title="Scanner Initialization"
							message={errorMessage}
						/>
					}
				>
					{isMobile ? (
						<div className="ticket-scanner-heading">
							<div className="event-header">
								{this.getDatebox(dateboxOptions)}

								<h1>{event.getIn(['event', 'title'])}</h1>
							</div>

							<EventOverview>
								<EventOverviewItem
									icon="faCalendarAlt"
									label={formattedEventDateTime}
								/>
								<EventOverviewItem
									icon="faMapMarkerAlt"
									label={
										event.getIn(['event', 'room'])
										? `${event.getIn(['event', 'location'])} · ${event.getIn(['event', 'room'])}`
										: event.getIn(['event', 'location'])
									}
								/>
								<EventOverviewItem
									icon="faUserFriends"
									label={`${peopleAttendingText} ${
										event.get('rsvpLimit')
											? ` · ${
													event.get('rsvpLimit') - peopleCount < 0
														? 0
														: event.get('rsvpLimit') - peopleCount
											  } spots left`
											: ''
									} · ${checkInCount} checked-in`}
								/>
							</EventOverview>
						</div>
					) : (
						<div className="ticket-scanner-heading">
							{this.getDatebox(dateboxOptions)}

							<div>
								<h1>{event.getIn(['event', 'title'])}</h1>

								<EventOverview>
									<EventOverviewItem
										icon="faCalendarAlt"
										label={formattedEventDateTime}
									/>
									<EventOverviewItem
										icon="faMapMarkerAlt"
										label={
											event.getIn(['event', 'room'])
												? `${event.getIn(['event', 'location'])} · ${event.getIn(['event', 'room'])}`
												: event.getIn(['event', 'location'])
										}
									/>
									<EventOverviewItem
										icon="faUserFriends"
										label={`${peopleAttendingText} ${
											event.get('rsvpLimit')
												? ` · ${
														event.get('rsvpLimit') - peopleCount < 0
															? 0
															: event.get('rsvpLimit') - peopleCount
												  } spots left`
												: ''
										} · ${checkInCount} checked-in`}
									/>
								</EventOverview>
							</div>
						</div>
					)}

					<TabMenu
						items={MENUS}
						color="lime"
						selectedTab={this.state.action}
						onChange={action => {
							this.setState({ action: action, isCameraActive: false });
						}}
					/>

					{!canStillProcessCheckIns ? (
						<React.Fragment>
							<Banner
								type="warning"
								title="The event has ended. Check-in function is disabled."
							/>
							<br />
						</React.Fragment>
					) : null}

					{action === 'scan' ? (
						<React.Fragment>
							<Button
								active={isCameraActive}
								onClick={this.toggleCamera}
								disabled={!canStillProcessCheckIns}
								width={!isMobile ? '160px' : '100%'}
							>{isCameraActive ? 'Stop Scanning' : 'Start Scanning'}</Button>
							{/iPad|iPhone|iPod/.test(navigator.userAgent) &&
							!window.MSStream ? (
								<p className="meta">
									This features requires Safari 12+ (iOS 12+).
								</p>
							) : null}
							{isCameraActive && !manualCheckInProfile ? (
								<div className="camera-container">
									{checkInProcessing || lookingUpRsvpProcessing ? (
										<div className="camera-processing">
											<Loader />
										</div>
									) : null}
									<QrReader
										delay={2000}
										onError={this.handleError}
										onScan={this.handleScan}
										className="camera-preview"
									/>
								</div>
							) : null}
						</React.Fragment>
					) : (
						<React.Fragment>
							<div>
								<div className="flex flex-space scanner-actionbar">
									<div className="fs-button__toggle">
										<Button
											modifier="secondary"
											active={filter === 'all'}
											onClick={this.handleFilterChange.bind(this, 'all')}
										>All</Button>
										<Button
											modifier="secondary"
											active={filter === 'checkin'}
											onClick={this.handleFilterChange.bind(this, 'checkin')}
										>Checked-in</Button>
										<Button
											modifier="secondary"
											active={filter === 'notCheckin'}
											onClick={this.handleFilterChange.bind(
												this,
												'notCheckin'
											)}
										>Not Checked-in</Button>
									</div>
								</div>
								<Input
									placeholder="Search"
									name="rsvp-search"
									onChange={this.handleSearchChange}
									faIcon="faSearch"
									onIconClick={() => {}}
								/>
								<LoaderWrapper loaderError={!rsvpsError.isEmpty()} isLoading={rsvpsProcessing && page === 0}
									errorComponent={
										<Error
											icon="faFrownLight"
											title="Error"
											message="Sorry, an error occurred while loading the RSVPs."
										/>
									}>
									{ !rsvps.isEmpty() ?
										<InfiniteScroller next={this.fetchNextPage} hasMore={!lastPage} processing={rsvpsProcessing && page !== 0}>
											<CardGroup modifier={isMobile ? 'list' : 'grid'}>
												{ rsvps.map((rsvp) => {
													const profile = rsvp.get('profile');
													const name = `${profile.get('firstName')} ${profile.get('lastName')}`;
													const avatar = profile.get('avatarUrl');
													const email = profile.getIn(['universityEmail','address']);
													const modifier = rsvp.get('checkedIn') ? 'checked-in' : 'not-checked-in';

													return (
														<Card modifier={[ isMobile ? 'full-width' : 'slim', modifier]} key={profile.get('id')}>
															<Card.Avatar
																name={name}
																url={avatar}
																position={isMobile ? 'left' : 'top'}
															/>
															<Card.Body>
																<Card.Title lines={1}>{name}</Card.Title>
																<Card.Meta lines={1}>{email}</Card.Meta>
															</Card.Body>
															<Card.Footer>
																<Card.Button
																	icon={{ mobile: 'faCheck' }}
																	label={{
																		desktop: `${
																			rsvp.get('checkedIn')
																				? 'Checked-In'
																				: 'Check-In'
																		}`
																	}}
																	onClick={this.openManualCheckIn.bind(
																		this,
																		profile
																	)}
																	modifier={isMobile ? 'text' : (rsvp.get('checkedIn') ? 'positive' : 'secondary')}
																	style={isMobile ? {
																		background : 'transparent',
																		color: rsvp.get('checkedIn') ? colors.success
																			: (isCameraActive || !canStillProcessCheckIns) ? colors.gray
																			: colors.grayDarkest
																	} : null}
																	disabled={
																		rsvp.get('checkedIn') ||
																		isCameraActive ||
																		!canStillProcessCheckIns
																	}
																/>
															</Card.Footer>
														</Card>
													);
												}) }
											</CardGroup>
										</InfiniteScroller> :
										<Error
											icon="faFrownLight"
											title={"No RSVPs"}
											message={search.trim().length === 0 && filter === "all" ? "There have not been any RSVPs." : "Your filter returned no results." }
										/>
									}
								</LoaderWrapper>
							</div>
						</React.Fragment>
					)}
				</Container>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		event: state.getIn(['scanner', 'event']),
		eventError: state.getIn(['scanner', 'eventError']),
		eventProcessing: state.getIn(['scanner', 'eventProcessing']),
		rsvps: state.getIn(['scanner', 'rsvps']),
		page: state.getIn(['scanner', 'page']),
		lastPage: state.getIn(['scanner', 'lastPage']),
		search: state.getIn(['scanner', 'search']),
		filter: state.getIn(['scanner', 'filter']),
		rsvpsError: state.getIn(['scanner', 'rsvpsError']),
		rsvpsProcessing: state.getIn(['scanner', 'rsvpsProcessing']),
		manualCheckInProfile: state.getIn(['scanner', 'manualCheckInProfile']),
		checkInProcessing: state.getIn(['scanner', 'checkInProcessing']),
		profile: state.getIn(['profile', 'data'])
	};
}

export default connect(mapStateToProps)(WithResize(EventTicketScanner));
