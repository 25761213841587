import React from 'react';
import PropTypes from 'prop-types';
import { ButtonRefactor as Button, ButtonDropdown, DropdownItem, FontIcon } from 'fs-toolkit-react';
import Condition from './Condition';
import { Map, fromJS } from 'immutable';
import cn from 'classnames';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

class ConditionGroup extends React.Component {
    handleJoinChange = (type) => {
        const { map, onChange } = this.props;
        if(onChange){
            onChange(map.update('joinType', () => type));
        }
    }

    handleNodeAdd = (type) => {
        const { map, onChange } = this.props;

        let node;
        if(type === "group"){
            node = fromJS({ 
                id: new Date().getTime(), 
                joinType: "and", 
                conditions: [] 
            });
        } else {
            node = fromJS({ 
                id: new Date().getTime(), 
                attribute: "", 
                value: "",
                eval: "eq"
            });
        }

        // propagate tree from this node back to the parent
        if(onChange){
            onChange(map.update('conditions', (val) => val.push(node)));
        }
    }

    handleNodeUpdate = (node) => {
        const { map, onChange } = this.props;
        // find node in tree and replace it
        const index = map.get('conditions').findIndex((n) => n.get('id') === node.get('id'));
        if(index !== -1){
            const conditions = map.get('conditions').set(index, node);
            if(onChange){
                // update the node in the conditions list with the updated node
                onChange(map.set('conditions', conditions));
            }
        }
    }

    handleNodeRemove = (node) => {
        const { map, onChange } = this.props;
        // find node in tree and delete it
        const index = map.get('conditions').findIndex((n) => n.get('id') === node.get('id'));
        if(index !== -1){
            const conditions = map.get('conditions').delete(index);
            if(onChange){
                // update the node in the conditions list with the updated node
                onChange(map.set('conditions', conditions));
            }
        }
    }

    render(){
        const { rootNode, map, options, onRemove, disabled } = this.props;

        return (
            <div className={cn("condition-group condition-node", { "root-node": rootNode })}>
                <div className="controls">
                    <div className="controls__join-toggle">
                        <Button modifier="secondary" onClick={this.handleJoinChange.bind(this, "and")} active={map.get('joinType') === "and"} disabled={disabled}>And</Button>
                        <Button modifier="secondary" onClick={this.handleJoinChange.bind(this, "or")} active={map.get('joinType') === "or"} disabled={disabled}>Or</Button>
                    </div>
                    <ButtonDropdown icon='faPlus' buttonType="secondary" onChange={this.handleNodeAdd} disabled={disabled}>
                        {({ onClick, selected }) => (
                            <React.Fragment>
                                <DropdownItem value="group" onClick={onClick}>Add Condition Group</DropdownItem>
                                <DropdownItem value="condition" onClick={onClick}>Add Condition</DropdownItem>
                            </React.Fragment>
                        )}
                    </ButtonDropdown>
                    { rootNode ? null : <Button modifier="secondary" onClick={onRemove.bind(this, map)} disabled={disabled}>
                        <FontIcon icon={faTrashAlt}/>
                    </Button> }
                </div>
                <div className="nodes">
                    <div className="nodes__condition-list">
                        { map.get('conditions').map((condition) => {
                            if(condition.has("conditions")){
                                return (
                                    <div key={condition.get('id')} className="group-row">
                                        <ConditionGroup map={condition} options={options} rootNode={false} onChange={this.handleNodeUpdate} onRemove={this.handleNodeRemove} disabled={disabled} />
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={condition.get('id')} className="condition-row">
                                        <Condition map={condition} options={options} onChange={this.handleNodeUpdate} onRemove={this.handleNodeRemove} disabled={disabled}/>
                                    </div>
                                );
                                
                            }
                          }) 
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ConditionGroup.defaultProps = {
    rootNode: false,
    disabled: false,
    map: fromJS({ 
        id: new Date().getTime(), 
        joinType: "and", 
        conditions: [] 
    }),
    options: fromJS({ audience: [], degree: []})
}

ConditionGroup.propTypes = {
    map: PropTypes.instanceOf(Map),
    options: PropTypes.instanceOf(Map),
    disabled: PropTypes.bool,
    rootNode: PropTypes.bool,
    onChange: PropTypes.func,
    onRemove: PropTypes.func
}

export default ConditionGroup;