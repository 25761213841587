import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import AsyncImporter from 'components/handlers/AsyncImporter';
import ErrorPage from 'components/views/error/ErrorPage';

import AppRoute from 'components/layout/AppRoute';
import SidePanelLayout from 'components/layout/containers/SidePanelLayout';


function LearnRoutes(props) {
	const { authenticated } = props;
	const { path } = useRouteMatch();
	return (
		<Switch>
			<AppRoute exact path={`${path}/classes`} component={Classes} layout={SidePanelLayout} />
			<AppRoute exact path={`${path}/gps`} component={GPS} layout={SidePanelLayout} />
			<AppRoute exact path={`${path}/schedules`} component={LearnSchedules} layout={SidePanelLayout} />
			<AppRoute exact path={`${path}/attendance`} component={Attendance} layout={SidePanelLayout} />
			<AppRoute exact path={`${path}/attendance/:enrollmentId/:attendanceId`} component={AttendanceDetail} layout={SidePanelLayout} />
			<AppRoute exact path={`${path}/grades`} component={Grades} layout={SidePanelLayout} />
			<AppRoute exact path={`${path}/grades/:enrollmentId/:classGradeId`} component={GradesDetail} layout={SidePanelLayout} />

			{/* Handle 404s */}
			<Route path="*">
				{authenticated
					? <ErrorPage useEmbeddedError={true} />
					: <Redirect to="/404" />
				}
			</Route>
		</Switch>
	);
}

const Classes = loadable(
	() => import('components/views/learn/Classes'),
	{ fallback: <AsyncImporter /> }
);

const GPS = loadable(
	() => import('components/views/learn/GPS'),
	{ fallback: <AsyncImporter /> }
);

const Grades = loadable(
	() => import('components/views/learn/grades/Grades'),
	{ fallback: <AsyncImporter /> }
);

const GradesDetail = loadable(
	() => import('components/views/learn/grades/GradesDetail'),
	{ fallback: <AsyncImporter /> }
);

const LearnSchedules = loadable(
	() => import('components/views/learn/schedules/Schedules'),
	{ fallback: <AsyncImporter /> }
);

const Attendance = loadable(
	() => import('components/views/learn/attendance/Attendance'),
	{ fallback: <AsyncImporter /> }
);

const AttendanceDetail = loadable(
	() => import('components/views/learn/attendance/AttendanceDetail'),
	{ fallback: <AsyncImporter /> }
);

export default LearnRoutes;