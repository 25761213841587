const mobileBreakPoint = 960;
const cnWrapperHeader = '.wrapper__header';
const cnNavigationContainer = '.fs-navigation_container';
const cnWrapperAlertbar = '.fs-alertbar';
const cnWrapperMain = '.fs-container';
const cnDangerBar = '.danger-bar';
const cnRedactorToolbarWrapper = '.redactor-toolbar-wrapper';
const cnRedactorToolbar = '.redactor-toolbar';
const cnRedactorBox = '.redactor-box';

export {
	mobileBreakPoint,
	cnWrapperAlertbar,
	cnWrapperHeader,
	cnNavigationContainer,
	cnWrapperMain,
	cnDangerBar,
	cnRedactorToolbarWrapper,
	cnRedactorToolbar,
	cnRedactorBox
};
