import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const intialProcessingTypes = {
	processing: false,
	searchProcessing: false,
	scrollProcessing: false,
	labFilterProcessing: false
};
const immutableProcessingTypes = fromJS(intialProcessingTypes);
const initialState = fromJS({
	data: {},
	...intialProcessingTypes,
	error: null,
	schedules: []
});

const getSchedules = (schedules, schedulesItem) => {
	const newSchedules = schedulesItem.content;

	return schedulesItem.number > 0
		? schedules.toJS().concat(newSchedules)
		: newSchedules;
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.CLASS_SCHEDULES_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.CLASS_SCHEDULES_SEARCH_PROCESSING:
			return state.merge(immutableProcessingTypes).merge({
				searchProcessing: true
			});
		case ActionTypes.CLASS_SCHEDULES_SCROLL_PROCESSING:
			return state.merge(immutableProcessingTypes).merge({
				scrollProcessing: true
			});
		case ActionTypes.CLASS_SCHEDULES_LAB_FILTER_PROCESSING:
			return state.merge({
				labFilterProcessing: true
			});
		case ActionTypes.CLASS_SCHEDULES_SUCCESS:
			const schedules = getSchedules(state.get('schedules'), action.data);

			return state.merge(immutableProcessingTypes).merge({
				data: action.data,
				schedules
			});
		case ActionTypes.CLASS_SCHEDULES_FAILURE:
			return state.merge(immutableProcessingTypes).merge({
				error: action.error
			});
		default:
			return state;
	}
}
