import React from 'react';
import { CardContext } from '../CardContext';
import PropTypes from 'prop-types';
import { clamp, getClampConfig } from 'utils/clamp';

const withClamp = WrappedComponent => {
	return config => {
		class Component extends React.Component {
			static contextType = CardContext;

			static propTypes = {
				lines: PropTypes.oneOfType([
					PropTypes.number,
					PropTypes.shape({
						mobile: PropTypes.number,
						desktop: PropTypes.number
					})
				])
			};

			withClampRef = React.createRef();

			componentDidMount() {
				const linesToClamp = this.props.lines || config;
				if (linesToClamp && this.withClampRef.current) {
					const clampConfig = getClampConfig(
						linesToClamp,
						this.context.isMobile
					);
					setTimeout(() => {
						clamp(this.withClampRef.current, clampConfig);
					}, 100);
				}
			}

			render() {
				return <WrappedComponent ref={this.withClampRef} {...this.props} />;
			}
		}
		return Component;
	};
};

export { withClamp };
