import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';
import { toParams } from 'utils/globalUtils';

const processing = () => ({
	type: ActionTypes.UNFILTERED_TASKS_PROCESSING
});

export const unfilteredTasksSuccess = data => ({
	type: ActionTypes.UNFILTERED_TASKS_SUCCESS,
	data
});

const unfilteredTasksFailure = error => ({
	type: ActionTypes.UNFILTERED_TASKS_FAILURE,
	error
});

export const resetUnfilteredTasks = () => ({
	type: ActionTypes.RESET_UNFILTERED_TASKS
});

// technically filtered by id and possibly size
// need to keep this state separate from tasks
// to use on task footer
export const fetchUnfilteredTasks = (params = {}) => {
	return dispatch => {
		dispatch(processing());

		return APIClient.get(
			`/launch/my/task_assignments?${toParams(params)}`
		).then(
			({ data }) => dispatch(unfilteredTasksSuccess(data)),
			({ response: { data } }) => dispatch(unfilteredTasksFailure(data))
		);
	};
};
