import { fromJS } from 'immutable';
import { makeActionCreator } from '../utils/reducerUtils';
import KnowledgeBaseAPI from 'api/knowledge-base/KnowledgeBaseAPI';

const KB_TOP_ARTICLES_PROCESSING = 'KB_TOP_ARTICLES_PROCESSING';
const KB_TOP_ARTICLES_SUCCESS = 'KB_TOP_ARTICLES_SUCCESS';
const KB_TOP_ARTICLES_FAILURE = 'KB_TOP_ARTICLES_FAILURE';

const initialState = fromJS({
	articles: [],
	processing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case KB_TOP_ARTICLES_PROCESSING:
			return state.merge({
				processing: true
			});
		case KB_TOP_ARTICLES_SUCCESS:
			return state.merge({
				processing: false,
				articles: action.payload.content
			});
		case KB_TOP_ARTICLES_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false
			});
		default:
			return state;
	}
}

const processing = makeActionCreator(KB_TOP_ARTICLES_PROCESSING);
const success = makeActionCreator(KB_TOP_ARTICLES_SUCCESS, 'payload');
const failure = makeActionCreator(KB_TOP_ARTICLES_FAILURE, 'payload');

export const getTopKBArticlesForCategory = (params = { page: 0 }) => {
	return (dispatch, getState) => {
		dispatch(processing());

		const groupId = getState().getIn(['app', 'group', 'id']);
		return KnowledgeBaseAPI.getTopArticles(groupId, params).then(
			({ data }) => dispatch(success(data)),
			err => dispatch(failure(err))
		);
	};
};
