import { useContext, useEffect } from 'react';
import { LayoutContext } from 'components/layout/LayoutProvider';
import useActivePanel from '../hooks/useActivePanel';

function SidePanelLayout({ children }) {
	const { action, state } = useContext(LayoutContext);
	const { setPanelActive, resetPanel, resetSubNavigation } = action;
	const { isMobile } = state;

	// toggles active state panel when mounted/unmounted
	useActivePanel(setPanelActive);

	// clear panel state for when unmounted to prevent stale data flicking
	// before new data is loaded
	useEffect(() => {
		return () => {
			if (isMobile) {
				resetSubNavigation();
			} else {
				resetPanel();
			}
		};
	}, [resetPanel, resetSubNavigation, isMobile]);

	return children;
}

export default SidePanelLayout;
