import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	comments: {},
	processing: false,
	commentSubmitted: false,
	commentCreateProcessing: false,
	error: null
});

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.CASE_COMMENTS_CREATE_PROCESSING:
			return state.merge({
				commentCreateProcessing: true,
				commentSubmitted: false,
				processing: false
			});
		case ActionTypes.CASE_COMMENTS_SUBMITTED:
			return state.merge({
				commentSubmitted: true,
				commentCreateProcessing: false,
				processing: false
			});
		case ActionTypes.CASE_COMMENTS_PROCESSING:
			return state.merge({
				processing: true,
				commentSubmitted: false,
				commentCreateProcessing: false
			});
		case ActionTypes.CASE_COMMENTS_SUCCESS:
			return state.merge({
				processing: false,
				commentSubmitted: false,
				commentCreateProcessing: false,
				comments: action.data,
				error: null
			});
		case ActionTypes.CASE_COMMENTS_FAILURE:
			return state.merge({
				processing: false,
				commentSubmitted: false,
				commentCreateProcessing: false,
				error: action.error.response && action.error.response.data
			});
		case ActionTypes.CASE_COMMENTS_RESET_ERROR:
			return state.merge({
				error: null
			});
		case ActionTypes.CASE_COMMENTS_RESET:
			return state.merge(initialState);
		case ActionTypes.CASE_COMMENTS_CREATE:
			return state.merge({
				processing: false,
				commentSubmitted: false,
				commentCreateProcessing: false,
				comments: action.data
			});
		default:
			return state;
	}
}
