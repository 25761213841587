import { combineReducers } from 'redux-immutable';
import app from 'reducers/app';
import profile from 'reducers/profile';
import memberships from 'reducers/memberships';
import membership from 'reducers/membership';
import myEvents from 'reducers/myEvents';
import myNewsArticles from 'reducers/myNewsArticles';
import notifications from 'reducers/notifications';
import cases from 'reducers/cases';
import caseDetails from 'reducers/caseDetails';
import caseComments from 'reducers/caseComments';
import learnClasses from 'reducers/learnClasses';
import group from 'reducers/group';
import groupAbout from 'reducers/groupAbout';
import groupCreate from 'reducers/groupCreate';
import groupEdit from 'reducers/groupEdit';
import groups from 'reducers/groups';
import enrollments from 'reducers/enrollments';
import enrollmentsPanel from 'reducers/enrollmentsPanel';
import events from 'reducers/events';
import eventsCalendar from 'reducers/eventsCalendar';
import eventDetail from 'reducers/eventDetail';
import eventCreate from 'reducers/eventCreate';
import eventCategories from 'reducers/eventCategories';
import eventUpdate from 'reducers/eventUpdate';
import eventCancel from 'reducers/eventCancel';
import tags from 'reducers/tags';
import roles from 'reducers/roles';
import scanner from 'reducers/scanner';
import peopleDetail from 'reducers/peopleDetail';
import classSchedules from 'reducers/classSchedules';
import gps from 'reducers/gps';
import attendance from 'reducers/attendance';
import attendanceDetail from 'reducers/attendanceDetail';
import pagination from 'reducers/pagination';
import grades from 'reducers/grades';
import gradesDetail from 'reducers/gradesDetail';
import dangerBar from 'reducers/dangerBar';
import globalSearch from 'reducers/globalSearch';
import search from 'reducers/search';
import rsvpRegistration from 'reducers/rsvpRegistration';
import tasks from 'reducers/tasks';
import unfilteredTasks from 'reducers/unfilteredTasks';
import task from 'reducers/task';
import taskCategories from 'reducers/taskCategories';
import taskStatuses from 'reducers/taskStatuses';
import launchEnrollments from 'reducers/launchEnrollments';
import docusign from 'reducers/docusign';
import publicUploader from 'reducers/publicUploader';
import classLabOptions from 'reducers/classLabOptions';
import listSearch from 'reducers/listSearch';
import segments from 'reducers/segments';
import segment from 'reducers/segment';
import rules from 'reducers/rules';
import rule from 'reducers/rule';
import bypassPageLoader from 'reducers/bypassPageLoader';
import publicProfile from 'reducers/publicProfile';

// Implementing the import of "duck reducers" here for now until the rest of the application
// can be refactored to use the re-ducks modular architecture
// https://github.com/erikras/ducks-modular-redux
import * as reducers from 'state/ducks';

const appReducers = {
	...reducers,
	app,
	profile,
	myNewsArticles,
	cases,
	caseDetails,
	caseComments,
	classSchedules,
	learnClasses,
	globalSearch,
	group,
	groupAbout,
	groupCreate,
	groupEdit,
	groups,
	enrollments,
	enrollmentsPanel,
	events,
	eventsCalendar,
	notifications,
	memberships,
	membership,
	myEvents,
	eventDetail,
	eventCreate,
	eventCategories,
	eventUpdate,
	eventCancel,
	tags,
	roles,
	scanner,
	peopleDetail,
	gps,
	attendance,
	attendanceDetail,
	pagination,
	grades,
	gradesDetail,
	dangerBar,
	search,
	rsvpRegistration,
	tasks,
	unfilteredTasks,
	task,
	taskCategories,
	taskStatuses,
	launchEnrollments,
	docusign,
	publicUploader,
	classLabOptions,
	listSearch,
	segments,
	segment,
	rules,
	rule,
	bypassPageLoader,
	publicProfile
};

const reducer = combineReducers(appReducers);

export default reducer;
