import { fromJS, List } from 'immutable';
import { toast } from 'react-toastify';
import { makeActionCreator } from '../utils/reducerUtils';
import NewsArticleAPI from 'api/newsArticle/NewsArticleAPI';
import { formatDate } from 'utils/dateTimeUtils';
import {
	isDirty,
	setInitialFormData,
	getFilteredEditorContent,
	setInitialFormItem
} from 'state/utils/formUtils';

// actions
const NEWS_ARTICLE_PROCESSING = 'NEWS_ARTICLE_PROCESSING';
const NEWS_ARTICLE_SUCCESS = 'NEWS_ARTICLE_SUCCESS';
const NEWS_ARTICLE_FAILURE = 'FAILURE';
const NEWS_ARTICLE_RESET = 'NEWS_ARTICLE_RESET';

const NEWS_ARTICLE_CREATE_PROCESSING = 'NEWS_ARTICLE_CREATE_PROCESSING';
const NEWS_ARTICLE_CREATE_SUCCESS = 'NEWS_ARTICLE_CREATE_SUCCESS';
const NEWS_ARTICLE_CREATE_FAILURE = 'NEWS_ARTICLE_CREATE_FAILURE';

const NEWS_ARTICLE_UPDATE_PROCESSING = 'NEWS_ARTICLE_UPDATE_PROCESSING';
const NEWS_ARTICLE_UPDATE_SUCCESS = 'NEWS_ARTICLE_UPDATE_SUCCESS';
const NEWS_ARTICLE_UPDATE_FAILURE = 'NEWS_ARTICLE_UPDATE_FAILURE';

const NEWS_ARTICLE_DELETE_PROCESSING = 'NEWS_ARTICLE_DELETE_PROCESSING';
const NEWS_ARTICLE_DELETE_SUCCESS = 'NEWS_ARTICLE_DELETE_SUCCESS';
const NEWS_ARTICLE_DELETE_FAILURE = 'NEWS_ARTICLE_DELETE_FAILURE';

const NEWS_ARTICLE_CATEGORIES_PROCESSING = 'NEWS_ARTICLE_CATEGORIES_PROCESSING';
const NEWS_ARTICLE_CATEGORIES_SUCCESS = 'NEWS_ARTICLE_CATEGORIES_SUCCESS';
const NEWS_ARTICLE_CATEGORIES_FAILURE = 'NEWS_ARTICLE_CATEGORIES_FAILURE';

// form create/edit
const NEWS_ARTICLE_SET_INITIAL_CONTENT = 'NEWS_ARTICLE_SET_INITIAL_CONTENT';
const NEWS_ARTICLE_CHANGE_TITLE = 'NEWS_ARTICLE_CHANGE_TITLE';
const NEWS_ARTICLE_CHANGE_SUMMARY = 'NEWS_ARTICLE_CHANGE_SUMMARY';
const NEWS_ARTICLE_CHANGE_CONTENT = 'NEWS_ARTICLE_CHANGE_CONTENT';
const NEWS_ARTICLE_CHANGE_TAGS = 'NEWS_ARTICLE_CHANGE_TAGS';
const NEWS_ARTICLE_CHANGE_SEGMENTS = 'NEWS_ARTICLE_CHANGE_SEGMENTS';
const NEWS_ARTICLE_CHANGE_CATEGORIES = 'NEWS_ARTICLE_CHANGE_CATEGORIES';
const NEWS_ARTICLE_CHANGE_IMAGE = 'NEWS_ARTICLE_CHANGE_IMAGE';
const NEWS_ARTICLE_CHANGE_DATE = 'NEWS_ARTICLE_CHANGE_DATE';

const initialState = fromJS({
	article: {},
	processing: false,
	error: null,
	createProcessing: false,
	createError: null,
	created: false,
	processingUpdate: false,
	updateError: null,
	updated: false,
	processingDelete: false,
	deleteError: null,
	deleted: false,

	// admin form
	formData: {},
	initialContentSet: false,
	image: null,
	isDirty: false,

	// categories
	categories: [],
	categoriesProcessing: false,
	categoriesError: null
});

const processFormData = data => {
	return {
		content: data.content,
		publishedAt: data.publishedAt,
		summary: data.summary,
		title: data.title,
		categoryIds: data.categoryIds || data.categories.map(c => `${c.id}`),
		segmentIds: data.segmentIds || data.segments.map(s => `${s.id}`),
		tagIds: data.tagIds || data.tags.map(t => `${t.id}`),
		imageId: data.image ? data.image.id : undefined,
		groupId: data.groupId // create only
	};
};

export default function(state = initialState, action) {
	switch (action.type) {
		case NEWS_ARTICLE_PROCESSING:
			return state.merge({
				processing: true
			});
		case NEWS_ARTICLE_SUCCESS:
			const data = processFormData(action.payload);
			setInitialFormData(data);

			return state.merge({
				processing: false,
				article: action.payload,
				formData: data,
				image: action.payload.image
			});
		case NEWS_ARTICLE_FAILURE:
			return state.merge({
				processing: false,
				error: action.payload
			});

		// create
		case NEWS_ARTICLE_CREATE_PROCESSING:
			return state.merge({
				createProcessing: true
			});
		case NEWS_ARTICLE_CREATE_SUCCESS:
			return state.merge({
				createProcessing: false,
				article: action.payload,
				created: true
			});
		case NEWS_ARTICLE_CREATE_FAILURE:
			return state.merge({
				createError: action.payload,
				createProcessing: false
			});

		// edit
		case NEWS_ARTICLE_UPDATE_PROCESSING:
			return state.merge({
				processingUpdate: true
			});
		case NEWS_ARTICLE_UPDATE_SUCCESS:
			return state.merge({
				processingUpdate: false,
				updated: true
			});
		case NEWS_ARTICLE_UPDATE_FAILURE:
			return state.merge({
				updateError: action.payload,
				processingUpdate: false
			});

		// delete
		case NEWS_ARTICLE_DELETE_PROCESSING:
			return state.merge({
				processingDelete: true
			});
		case NEWS_ARTICLE_DELETE_SUCCESS:
			return state.merge({
				processingDelete: false,
				deleted: true
			});
		case NEWS_ARTICLE_DELETE_FAILURE:
			return state.merge({
				deleteError: action.payload,
				processingDelete: false
			});

		// categories
		case NEWS_ARTICLE_CATEGORIES_PROCESSING:
			return state.merge({
				categoriesProcessing: true
			});
		case NEWS_ARTICLE_CATEGORIES_SUCCESS:
			return state.merge({
				categoriesProcessing: false,
				categories: action.payload
			});
		case NEWS_ARTICLE_CATEGORIES_FAILURE:
			return state.merge({
				categoriesError: action.payload,
				categoriesProcessing: false
			});

		// form state
		case NEWS_ARTICLE_SET_INITIAL_CONTENT:
			setInitialFormItem('content', action.payload);

			return state.merge({
				formData: state.get('formData').set('content', action.payload),
				initialContentSet: true
			});
		case NEWS_ARTICLE_CHANGE_TITLE:
			return state.merge({
				formData: state.get('formData').set('title', action.payload),
				isDirty: isDirty('title', action.payload)
			});
		case NEWS_ARTICLE_CHANGE_SUMMARY:
			return state.merge({
				formData: state.get('formData').set('summary', action.payload),
				isDirty: isDirty('summary', action.payload)
			});
		case NEWS_ARTICLE_CHANGE_CONTENT:
			// need to filter content because redactor adds extra tags when content is deleted
			const filteredContent = getFilteredEditorContent(action.payload);
			const initialContentSet = state.get('initialContentSet');

			// set initial content individually (sent from redactor, not the api) because redactor adds values to tag attributes and changes html entities into characters
			if (!initialContentSet) {
				setInitialFormItem('content', filteredContent);
			}

			return state.merge({
				formData: state.get('formData').set('content', filteredContent),
				isDirty: isDirty('content', filteredContent),
				initialContentSet: true
			});
		case NEWS_ARTICLE_CHANGE_TAGS:
			return state.merge({
				formData: state.get('formData').set('tagIds', List(action.payload)),
				isDirty: isDirty('tagIds', action.payload)
			});
		case NEWS_ARTICLE_CHANGE_SEGMENTS:
			return state.merge({
				formData: state.get('formData').set('segmentIds', List(action.payload)),
				isDirty: isDirty('segmentIds', action.payload)
			});
		case NEWS_ARTICLE_CHANGE_CATEGORIES:
			return state.merge({
				formData: state
					.get('formData')
					.set('categoryIds', List(action.payload)),
				isDirty: isDirty('categoryIds', action.payload)
			});
		case NEWS_ARTICLE_CHANGE_IMAGE:
			const id = action.payload ? action.payload.id : undefined;

			return state.merge({
				formData: state.get('formData').set('imageId', id),
				isDirty: isDirty('imageId', id),
				image: action.payload
			});
		case NEWS_ARTICLE_CHANGE_DATE:
			return state.merge({
				formData: state.get('formData').set('publishedAt', action.payload),
				isDirty: isDirty('publishedAt', action.payload)
			});
		case NEWS_ARTICLE_RESET:
			return state.merge(initialState);
		default:
			return state;
	}
}

// get
const processing = makeActionCreator(NEWS_ARTICLE_PROCESSING);
const success = makeActionCreator(NEWS_ARTICLE_SUCCESS, 'payload');
const failure = makeActionCreator(NEWS_ARTICLE_FAILURE, 'payload');

// get categories
const categoriesProcessing = makeActionCreator(
	NEWS_ARTICLE_CATEGORIES_PROCESSING
);
const categoriesSuccess = makeActionCreator(
	NEWS_ARTICLE_CATEGORIES_SUCCESS,
	'payload'
);
const categoriesFailure = makeActionCreator(
	NEWS_ARTICLE_CATEGORIES_FAILURE,
	'payload'
);

// create
const createProcessing = makeActionCreator(NEWS_ARTICLE_CREATE_PROCESSING);
const createSuccess = makeActionCreator(NEWS_ARTICLE_CREATE_SUCCESS, 'payload');
const createFailure = makeActionCreator(NEWS_ARTICLE_CREATE_FAILURE, 'payload');

// update
const updateProcessing = makeActionCreator(NEWS_ARTICLE_UPDATE_PROCESSING);
const updateSuccess = makeActionCreator(NEWS_ARTICLE_UPDATE_SUCCESS, 'payload');
const updateFailure = makeActionCreator(NEWS_ARTICLE_UPDATE_FAILURE, 'payload');

// delete
const deleteProcessing = makeActionCreator(NEWS_ARTICLE_DELETE_PROCESSING);
const deleteSuccess = makeActionCreator(NEWS_ARTICLE_DELETE_SUCCESS, 'payload');
const deleteFailure = makeActionCreator(NEWS_ARTICLE_DELETE_FAILURE, 'payload');

// form data
const setInitialContent = makeActionCreator(
	NEWS_ARTICLE_SET_INITIAL_CONTENT,
	'payload'
);
const changeTitle = makeActionCreator(NEWS_ARTICLE_CHANGE_TITLE, 'payload');
const changeSummary = makeActionCreator(NEWS_ARTICLE_CHANGE_SUMMARY, 'payload');
const changeTags = makeActionCreator(NEWS_ARTICLE_CHANGE_TAGS, 'payload');
const changeSegments = makeActionCreator(
	NEWS_ARTICLE_CHANGE_SEGMENTS,
	'payload'
);
const changeCategories = makeActionCreator(
	NEWS_ARTICLE_CHANGE_CATEGORIES,
	'payload'
);
const changeContent = makeActionCreator(NEWS_ARTICLE_CHANGE_CONTENT, 'payload');
const changeImage = makeActionCreator(NEWS_ARTICLE_CHANGE_IMAGE, 'payload');
const changeDate = makeActionCreator(NEWS_ARTICLE_CHANGE_DATE, 'payload');

const formatData = (data, published) => {
	return data.merge({
		published: published,
		publishedAt: formatDate({ date: data.get('publishedAt') }).toUTC()
	});
};

export const newsArticleActions = {
	get(articleId) {
		return dispatch => {
			dispatch(processing());

			return NewsArticleAPI.get(articleId).then(
				({ data }) => dispatch(success(data)),
				err => dispatch(failure(err))
			);
		};
	},
	post(published) {
		return (dispatch, getState) => {
			dispatch(createProcessing());

			const data = formatData(
				getState().getIn(['newsArticle', 'formData']),
				published
			).toJS();

			return NewsArticleAPI.post(data).then(
				({ data }) => dispatch(createSuccess(data)),
				err => {
					toast.error('There was an error creating the story.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(createFailure(err));
				}
			);
		};
	},
	update({ id, published }) {
		return (dispatch, getState) => {
			dispatch(updateProcessing());

			const data = formatData(
				getState().getIn(['newsArticle', 'formData']),
				published
			).toJS();

			return NewsArticleAPI.update(id, data).then(
				({ data }) => dispatch(updateSuccess(data)),
				err => {
					toast.error('There was an error updating the story.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(updateFailure(err));
				}
			);
		};
	},
	delete(articleId) {
		return dispatch => {
			dispatch(deleteProcessing());

			return NewsArticleAPI.delete(articleId).then(
				({ data }) => dispatch(deleteSuccess(data)),
				err => {
					toast.error('There was an error deleting the story.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(deleteFailure(err));
				}
			);
		};
	},
	getCategories(groupId) {
		return (dispatch, getState) => {
			dispatch(categoriesProcessing());

			groupId = groupId || getState().getIn(['app', 'group', 'id']);

			return NewsArticleAPI.getCategories(groupId).then(
				({ data }) => dispatch(categoriesSuccess(data)),
				({ response: { data } }) => dispatch(categoriesFailure(data))
			);
		};
	},
	reset: makeActionCreator(NEWS_ARTICLE_RESET),

	// form data
	success: success,
	setInitialContent: setInitialContent,
	changeTitle: changeTitle,
	changeSummary: changeSummary,
	changeContent: changeContent,
	changeTags: changeTags,
	changeSegments: changeSegments,
	changeCategories: changeCategories,
	changeImage: changeImage,
	changeDate: changeDate
};
