import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = {
	articles: {},
	processing: false,
	error: null
};

export default function(state = fromJS(initialState), action) {
	switch (action.type) {
		case ActionTypes.SEARCH_RESULTS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.SEARCH_RESULTS_SUCCESS:
			return state.merge({
				processing: false,
				articles: action.data,
				error: null
			});
		case ActionTypes.SEARCH_RESULTS_FAILURE:
			return state.merge({
				processing: false,
				error: action.error
			});
		case ActionTypes.SEARCH_RESULTS_RESET:
			return state.merge(initialState);
		default:
			return state;
	}
}
