export default [
	{
		title: 'Home',
		icon: 'faHome',
		activeState: 'home',
		path: '',
		showOnGroupHome: true,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'Stories',
		icon: 'faNewspaper',
		activeState: 'stories',
		path: '/stories',
		showOnGroupHome: true,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'Events',
		icon: 'faCalendarAlt',
		activeState: 'events',
		path: '/events',
		showOnGroupHome: true,
		showOnConnectHome: true,
		size: '1.75rem'
	},
	{
		title: 'Topics',
		icon: 'faComments',
		activeState: 'topics',
		path: '/topics',
		showOnGroupHome: true,
		showOnConnectHome: false,
		size: '1.75rem'
	},
	{
		title: 'People',
		icon: 'faUserFriends',
		activeState: 'people',
		path: '/people',
		showOnGroupHome: true,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'Programs',
		icon: 'faGraduationCap',
		activeState: 'programs',
		path: '/programs',
		showOnGroupHome: false,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'Departments',
		icon: 'faLandmark',
		activeState: 'departments',
		path: '/departments',
		showOnGroupHome: false,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'Clubs',
		icon: 'faGamepad',
		activeState: 'clubs',
		path: '/clubs',
		showOnGroupHome: false,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'Spaces',
		icon: 'faRocketLight',
		activeState: 'spaces',
		path: '/spaces',
		showOnGroupHome: false,
		showOnConnectHome: true,
		size: '2rem'
	},
	{
		title: 'About',
		icon: 'faInfoCircle',
		activeState: 'about',
		path: '/about',
		showOnGroupHome: true,
		showOnConnectHome: false,
		size: '2rem'
	}
];
