import ActionTypes from 'actions/actionTypes';
import APIClient from 'api/APIClient';

const enrollmentsProcessing = () => ({
	type: ActionTypes.LAUNCH_ENROLLMENTS_PROCESSING
});

const enrollmentsSuccess = data => ({
	type: ActionTypes.LAUNCH_ENROLLMENTS_SUCCESS,
	payload: data
});

const enrollmentsFailure = error => ({
	type: ActionTypes.LAUNCH_ENROLLMENTS_FAILURE,
	payload: error
});

export const resetLaunchEnrollments = () => ({
	type: ActionTypes.LAUNCH_ENROLLMENTS_RESET
});

export const getLaunchEnrollments = () => {
	return dispatch => {
		dispatch(enrollmentsProcessing());

		return APIClient.get('/launch/my/enrollments').then(
			({ data }) => dispatch(enrollmentsSuccess(data)),
			({ response: { data } }) => dispatch(enrollmentsFailure(data))
		);
	};
};
