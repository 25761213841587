import { fromJS } from 'immutable';
import { makeActionCreator } from '../utils/reducerUtils';
import { createLoaderSelector } from './loader';
import { createErrorSelector } from './error';
import * as LearnAPI from '../../api/learn/LearnAPI';
import { toast } from 'react-toastify';

// Types
const FETCH_LEARNING_STYLE_LINK = 'FETCH_LEARNING_STYLE_LINK';
const FETCH_LEARNING_STYLE_LINK_PROCESSING = 'FETCH_LEARNING_STYLE_LINK_PROCESSING';
const FETCH_LEARNING_STYLE_LINK_SUCCESS = 'FETCH_LEARNING_STYLE_LINK_SUCCESS';
const FETCH_LEARNING_STYLE_LINK_FAILURE = 'FETCH_LEARNING_STYLE_LINK_FAILURE';

const FETCH_LEARNING_STYLE_STATUS = 'FETCH_LEARNING_STYLE_STATUS';
const FETCH_LEARNING_STYLE_STATUS_PROCESSING = 'FETCH_LEARNING_STYLE_STATUS_PROCESSING';
const FETCH_LEARNING_STYLE_STATUS_SUCCESS = 'FETCH_LEARNING_STYLE_STATUS_SUCCESS';
const FETCH_LEARNING_STYLE_STATUS_FAILURE = 'FETCH_LEARNING_STYLE_STATUS_FAILURE';

const RESET_LEARNING_STYLES ='RESET_LEARNING_STYLES';

// Reducer
const learningStyleInitialState = fromJS({
	status: null,
	url: null,
});

export default function learningStylesReducer(state = learningStyleInitialState, action) {
	switch (action.type) {
		case FETCH_LEARNING_STYLE_STATUS_SUCCESS:
			return state.merge({
				status: action.payload.status
			});
		case FETCH_LEARNING_STYLE_LINK_SUCCESS:
			return state.merge({
				url: action.payload.url
			});
		case RESET_LEARNING_STYLES:
			return learningStyleInitialState;
		default:
			return state;
	}
}

const fetchLearningStatusProcessing = makeActionCreator(FETCH_LEARNING_STYLE_STATUS_PROCESSING);
const fetchLearningStatusSuccess = makeActionCreator(FETCH_LEARNING_STYLE_STATUS_SUCCESS, 'payload');
const fetchLearningStatusFailure = makeActionCreator(FETCH_LEARNING_STYLE_STATUS_FAILURE);

const fetchLearningLinkProcessing = makeActionCreator(FETCH_LEARNING_STYLE_LINK_PROCESSING);
const fetchLearningLinkSuccess = makeActionCreator(FETCH_LEARNING_STYLE_LINK_SUCCESS, 'payload');
const fetchLearningLinkFailure = makeActionCreator(FETCH_LEARNING_STYLE_LINK_FAILURE);

export const learningActions = {
	// actions creators
	getLearningStyleStatus() {
		return (dispatch) => {
			dispatch(fetchLearningStatusProcessing());
			return LearnAPI.getLearnStyleStatus()
				.then(({ data }) => {
					dispatch(fetchLearningStatusSuccess(data));
				}, error => {
					dispatch(fetchLearningStatusFailure(error));
				});
		};
	},

	getLearningStyleURL() {
		return (dispatch) => {
			dispatch(fetchLearningLinkProcessing());
			return LearnAPI.getLearnStyleLink()
				.then(({ data }) => {
					dispatch(fetchLearningLinkSuccess(data));
				}, error => {
					toast.error('Sorry, an error occurred retrieving your learning styles results. Please try again.', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'toast-override toast-override--error'
					});
					dispatch(fetchLearningLinkFailure(error));
				});
		};
	}
}

export const learningSelectors = {
	getLearningStatusLoading: createLoaderSelector([FETCH_LEARNING_STYLE_STATUS]),
	getLearningStatusError: createErrorSelector([FETCH_LEARNING_STYLE_STATUS]),
	getLearningStatus: state => state.getIn(['learn', 'status']),

	getLearningLinkLoading: createLoaderSelector([FETCH_LEARNING_STYLE_LINK]),
	getLearningLinkError: createErrorSelector([FETCH_LEARNING_STYLE_LINK]),
	getLearningLink: state => state.getIn(['learn', 'url'])
};

