import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsFooter, fsFooterContent, fsFooterFixed } from '../helpers/layoutClassNameList';
import { Footer } from 'fs-toolkit-react';
import { LayoutContext } from 'components/layout/LayoutProvider';
import { useRefCallback } from '../../hooks';

function FooterContainer(props) {
	const { state, action } = useContext(LayoutContext);
	const { isMobile } = state;
	const { setFooterRef, setFooterActive, setPageModifier, removePageModifier } = action;

	const {
		fixed = false,
		className = '',
		background = '',
		style = {},
		custom = false,
		children,
		...footerProps
	} = props;

	const footerStyles = [
		fsFooter,
		fixed && fsFooterFixed,
		className
	];

	const footerInlineStyles = {
		background,
		...style
	};


	useEffect(() => {
		const footerModifiers = ['footer', fixed && 'footer-fixed'];
		setPageModifier(footerModifiers);

		return () => removePageModifier(footerModifiers);
	}, [fixed, removePageModifier, setFooterActive, setPageModifier]);

	const [footerRef, footerRefCb] = useRefCallback();

	useEffect(() => {
		if (footerRef) {
			setFooterRef(footerRef);
			return () => {
				setFooterRef(null);
			}
		}
	}, [footerRef, setFooterRef]);

	return (
		<div className={cn(footerStyles)} style={footerInlineStyles} ref={footerRefCb}>
			<div className={fsFooterContent}>
				{custom
					? children
				 	: <Footer displayMobile={isMobile} {...footerProps}>
							{children}
						</Footer>
				}
			</div>
		</div>
	);
}

FooterContainer.propTypes = {
	fixed: PropTypes.bool,
	className: PropTypes.string,
	background: PropTypes.string,
	children: PropTypes.any,
	style: PropTypes.object,
	custom: PropTypes.bool
};

export default FooterContainer;