import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
    title: '',
    description: '',
    conditions: {
        id: new Date().getTime(),
        joinType: "and",
        conditions: []
    },
    ruleCount: 0,
    systemControlled: false,
    options: {},
    processing: false, // used for the loading of the options or the segment for edits
    saving: false,
    saved: false,
    deleting: false,
    deleted: false,
    isDirty: false,
    validationErrors: { title: "", description: "", conditions: "" },
    error: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.TOOLS_GROUPER_LOAD_SEGMENT_FORM_PROCESSING:
        return state.merge({
            processing: true
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_SEGMENT_FORM_SUCCESS:
        return state.merge({
            processing: false,
            error: {},
            title: (Object.keys(action.payload).length !== 0 ? action.payload.title : '' ),
            description: (Object.keys(action.payload).length !== 0 ? action.payload.description : '' ),
            ruleCount: (Object.keys(action.payload).length !== 0 ? action.payload.ruleCount : 0 ),
            systemControlled: action.payload.systemControlled,
            conditions: (Object.keys(action.payload).length !== 0 ? action.payload.conditions : {
                id: new Date().getTime(),
                joinType: "and",
                conditions: []
            }),
            options: action.options
        });
    case ActionTypes.TOOLS_GROUPER_LOAD_SEGMENT_FORM_FAILURE:
        return state.merge({
            processing: false,
            error: action.payload
        });
    case ActionTypes.TOOLS_GROUPER_CHANGE_SEGMENT_FORMFIELD:
        // clear out the validation errors for this field
        return state.set(action.field, action.value).setIn(['validationErrors', action.field], "").set('isDirty',true);
    case ActionTypes.TOOLS_GROUPER_VALIDATE_SEGMENT_FORM:
        return state.set('validationErrors', fromJS(action.validationErrors));
    case ActionTypes.TOOLS_GROUPER_CLEAR_SEGMENT_FORM_VALIDATIONS:
        return state.set('validationErrors', initialState.get('validationErrors'));
    case ActionTypes.TOOLS_GROUPER_CREATE_SEGMENT_PROCESSING:
    case ActionTypes.TOOLS_GROUPER_UPDATE_SEGMENT_PROCESSING:
        return state.set('saving', true);
    case ActionTypes.TOOLS_GROUPER_CREATE_SEGMENT_FAILURE:
    case ActionTypes.TOOLS_GROUPER_UPDATE_SEGMENT_FAILURE:
        return state.set('saving', false);
    case ActionTypes.TOOLS_GROUPER_CREATE_SEGMENT_SUCCESS:
    case ActionTypes.TOOLS_GROUPER_UPDATE_SEGMENT_SUCCESS:
        return state.merge({saving: false, saved: true });
    case ActionTypes.TOOLS_GROUPER_DELETE_SEGMENT_PROCESSING:
        return state.set('deleting', true);
    case ActionTypes.TOOLS_GROUPER_DELETE_SEGMENT_FAILURE:
        return state.set('deleting', false);
    case ActionTypes.TOOLS_GROUPER_DELETE_SEGMENT_SUCCESS:
        return state.merge({deleting: false, deleted: true });
    case ActionTypes.TOOLS_GROUPER_SEGMENT_FORM_RESET:
        return initialState;
    default:
      return state;
  }
}