import React from 'react';
import SidePanelLayout from './SidePanelLayout';
import FooterLayout from './FooterLayout';

function SidePanelFooterLayout({ children }) {
	return (
		<SidePanelLayout>
			<FooterLayout>
				{children}
			</FooterLayout>
		</SidePanelLayout>
	);
}

export default SidePanelFooterLayout;