import get from 'lodash/get';

export const globalErrors = {
	401: {
		title: 'Unauthorized',
		message: 'You do not have permission to access this.',
		icon: 'faLockAltLight'
	},
	403: {
		title: 'No Access',
		message: "Oops! Looks like you don't have access to this page.",
		icon: 'faLockAltLight'
	},
	404: {
		title: 'Sorry, that page isn’t here',
		message:
			'You didn’t do anything wrong. We may have moved the page you’re looking for somewhere else.',
		icon: 'faSadTearLight'
	}
};

export const impersonationError = {
	title: 'Unavailable',
	message:
		'Sorry, this feature is not available while impersonation is active.',
	icon: 'faBanLight'
};

// classes
const learnServerError = {
	title: 'Nothing to show',
	message:
		'Your list of classes is not available at this time. Please log into <a href="https://online.fullsail.edu" target="_blank">online.fullsail.edu</a> to access your classes.',
	icon: 'faFrownLight'
};

export const learnEmptyClassError = {
	title: 'Nothing to show',
	message: "Looks like you don't have any classes at this time.",
	icon: 'faSmileWinkLight'
};

export const learnStatusCodeErrors = {
	401: {
		title: 'Nothing to show',
		message: 'Sorry, you do not have access to take classes at this time.',
		icon: 'faFrownLight'
	},
	403: {
		title: 'Unauthorized',
		message: 'You do not have permission to access this.',
		icon: 'faLockAltLight'
	},
	404: globalErrors[404],
	422: learnServerError,
	500: learnServerError,
	503: learnServerError
};

export function customizeError(errorObject, errorPath, customErrorObject) {
	return Object.assign({}, get(errorObject, errorPath), customErrorObject);
}
