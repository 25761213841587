import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

function AppRoute({ component: Component, layout: LayoutComponent, ...routeProps }) {
	return (
		<Route
			{...routeProps}
			render={props => (
				<LayoutComponent>
					<Component {...props} />
				</LayoutComponent>
			)}
		/>
	);
}

AppRoute.propTypes = {
	component: PropTypes.elementType.isRequired,
	layout: PropTypes.elementType.isRequired
};

export default AppRoute;
