import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { fsCardMeta } from './helpers/cardClassNameList';
import { CardContext } from './CardContext';
import { withHide } from './helpers/withHide';
import { withClamp } from './helpers/withClamp';
import { forwardRefWrapper } from 'utils/forwardRefWrapper';
import { Typography } from 'fs-toolkit-react';

const CardMeta = ({ className, children, modifier, style, innerRef }) => {
	const props = { ref: innerRef };

	return (
		<Typography
			className={cn(fsCardMeta, className)}
			modifier={modifier ? modifier : ['meta', 'medium']}
			style={style}
			{...props}
		>
			{children}
		</Typography>
	);
};

CardMeta.context = CardContext;

CardMeta.propTypes = {
	className: PropTypes.string,
	lines: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			mobile: PropTypes.number,
			desktop: PropTypes.number
		})
	]),
	style: PropTypes.object
};

CardMeta.defaultProps = {
	className: ''
};

export default withClamp(forwardRefWrapper(withHide(CardMeta), 'innerRef'))(1);
