import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { formatDate } from 'utils/dateTimeUtils';

class AdminAccountPanel extends React.Component {
  render(){
    const { currentProfile, profile } = this.props;
    let alternateEmail = profile.get('emailAddresses').find((emailAddress) => emailAddress.get('type') === 'Alternate');
    return (
      <React.Fragment>
        <div className='fs-slashed-header'>Profile</div>
        <div className="table account-table">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{ profile.get('id') }</td>
              </tr>
              <tr>
                <td>Full Sail One ID</td>
                <td>{ profile.get('fsOneId') }</td>
              </tr>
              <tr>
                <td>Time Zone</td>
                <td>{ profile.get('timeZone') }</td>
              </tr>
              <tr>
                <td>Setup Completed At</td>
                <td>{ profile.get('setupCompletedAt') ? formatDate({ date: profile.get('setupCompletedAt'), format: "ddd, MMM DD, YYYY @ h:mm a", timeZone: currentProfile.get('timeZone') }).default() : "" }</td>
              </tr>
              <tr>
                <td>Personal Pronouns</td>
                <td>{ profile.get('pronouns') }</td>
              </tr>
              <tr>
                <td>Alternate Email</td>
                <td>{ alternateEmail ? alternateEmail.get('address') : "" }</td>
              </tr>
              <tr>
                <td>Tags</td>
                <td>{ profile.get('tags').map((tag) => { return tag.get('title') }).join(", ") }</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='fs-slashed-header'>Audiences</div>
        <div className="table account-table">
          <table>
            <tbody>
              { profile.get('audiences').count() === 0 ?
                <tr>
                  <td colSpan="2">No audiences</td>
                </tr> :
                profile.get('audiences').sortBy((audience) => audience.get('displayTitle')).map((audience) => {
                  return (
                    <tr key={audience.get('id')}>
                      <td>{audience.get('integrationControlled') ? audience.get('displayTitle') : audience.get('title')}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className='fs-slashed-header'>Enrollments</div>
        <div className="table account-table">
          <table>
            <thead>
              <tr>
                <th>Degree</th>
                <th>Graduation Date</th>
              </tr>
            </thead>
            <tbody>
              { profile.get('enrollments').count() === 0 ?
                <tr>
                  <td colSpan="2">No enrollments</td>
                </tr> :
                profile.get('enrollments').map((enrollment) => {
                  return (
                    <tr key={enrollment.get('id')}>
                      <td>{enrollment.get('program')}</td>
                      <td>{enrollment.get('graduationDate')}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className='fs-slashed-header'>Identity Lookups</div>
        <div className="table account-table">
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {
                profile.get('identityLinks').map((identityLink) => {
                  return (
                    <tr key={identityLink.get('id')}>
                      <td>{identityLink.getIn(['identityLookup', 'name'])}</td>
                      <td>{identityLink.get('value')}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}

AdminAccountPanel.propTypes = {
  currentProfile: PropTypes.instanceOf(Map).isRequired,
  profile: PropTypes.instanceOf(Map).isRequired
}

export default AdminAccountPanel;
