import moment from 'moment-timezone';
import React from 'react';
import { formatStringForUrl } from './urlUtils';
import isEmpty from 'lodash/isEmpty';
import { Map } from 'immutable';

const capitalize = string => {
	return string
		.split(' ')
		.map(s => s.charAt(0).toUpperCase() + s.substring(1, s.length))
		.join(' ');
};

const camelCase = (...strings) => {
	return strings
		.map(string => string.toLowerCase())
		.map((string, index) => (index === 0 ? string : capitalize(string)))
		.join('');
};

const getFrienlyStringFromUrlPath = (
	path,
	splitChar = '-',
	shouldCapitalize = true
) => {
	return path
		.split(splitChar)
		.map(p => (shouldCapitalize ? capitalize(p) : p))
		.join(' ');
};

const formatDate = (date, format, timeZone) => {
	date = new Date(date);
	format = format ? format : 'ddd MMM YY';

	const newDate = timeZone
		? moment(date)
				.tz(timeZone)
				.format(format)
		: moment(date).format(format);

	return newDate;
};

const bytesToSize = bytes => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const isInViewport = elem => {
	if (!elem) return;
	let bounding = elem.getBoundingClientRect();
	return (
		bounding.top >= -bounding.height &&
		bounding.left >= -bounding.width &&
		bounding.top <=
			(window.innerHeight || document.documentElement.clientHeight)
	);
};

const toParams = params =>
	Object.keys(params)
		.filter(
			key =>
				(typeof params[key] === 'string' && !!params[key]) ||
				typeof params[key] === 'number' ||
				typeof params[key] === 'boolean'
		)
		.map(key => `${key}=${params[key]}`)
		.join('&');

const buildUrl = (rootPath, params) =>
	isEmpty(params) ? rootPath : `${rootPath}?${toParams(params)}`;

const pluralize = (count, singular, plural) =>
	count === 1 ? singular : plural;

const sortImmutableItems = ({
	items,
	comparator,
	reverse = false,
	comparatorIsDate = false,
	nestedProperties = []
}) => {
	// i.e. items (memberships), comparator (firstName)
	const getComparator = item => {
		return nestedProperties.length
			? item.getIn([...nestedProperties, comparator]) // if the comparator is a nested property of the item (i.e. membership.getIn(['profile', 'firstName']))
			: item.get(comparator);
	};

	if (comparatorIsDate) {
		return !reverse
			? items.sort(
					(a, b) => new Date(getComparator(b)) - new Date(getComparator(a))
			  )
			: items.sort(
					(a, b) => new Date(getComparator(a)) - new Date(getComparator(b))
			  );
	}

	return !reverse
		? items.sort((a, b) => getComparator(a).localeCompare(getComparator(b)))
		: items.sort((a, b) => getComparator(b).localeCompare(getComparator(a)));
};

const traverseChildren = (children, callback, nestedLevels = 1) => {
	callback(children);
	let count = React.Children.count(children);
	for (let i = 0; i < count; i++) {
		let child = children[i]
			? children[i]
			: children.props && children.props.children;
		nestedLevels && traverseChildren(child, callback, --nestedLevels);
	}
};

const truncate = (string, limit) => {
	const stringPart = string.split('').slice(0, limit);
	return string.length <= limit ? string : `${stringPart.join('')}...`;
};

const getBasePath = (group, basePath = '') => {
	if (group.size) {
		const groupName = formatStringForUrl(group.get('name'));
		const groupType = formatStringForUrl(group.get('type'));
		basePath = `${basePath}/${groupType}s/${groupName}/${group.get('id')}`;
	}
	return basePath;
};

const mapTextToProcess = (defaultText, options) =>
	Object.keys(options).filter(loaderText => options[loaderText])[0] ||
	defaultText;

const getUsername = user =>
	Map.isMap(user) && !user.isEmpty()
		? `${user.get('firstName')} ${user.get('lastName')}`
		: '';

// use with immutable list
// lodash compact returns and array if compacted list is empty
function compact(list) {
	const invalidValues = [false, null, 0, '', undefined, NaN];
	return list.filter(item => !invalidValues.includes(item));
}

/**
 *
 * @param source
 * @param propToTransformMapping
 * @return {Object}
 */
const transformProps = (source = {}, propToTransformMapping = {}) => {
	return Object.keys(propToTransformMapping).reduce(
		(result, prop) => {
			result[prop] = propToTransformMapping[prop](source[prop]);
			return result;
		},
		{ ...source }
	);
};

export {
	capitalize,
	formatDate,
	bytesToSize,
	isInViewport,
	toParams,
	pluralize,
	sortImmutableItems,
	traverseChildren,
	truncate,
	getBasePath,
	mapTextToProcess,
	camelCase,
	buildUrl,
	getUsername,
	compact,
	getFrienlyStringFromUrlPath,
	transformProps
};
