export const layoutUtils = {
	// breakpoints in px
	breakpoint: {
		hd: 1920, // change to 1630 or below to test on laptop
		desktop: 1032,
		tablet: 808,
		mobile: 375
	},

	// layout widths in px
	size: {
		xs: '',
		s: 680,
		m: 856,
		l: 1032,
		xl: 1384,
		full: '100%'
	},

	// padding in px
	spacing: {
		size: {
			xxs: 4,
			xs: 8,
			s: 16,
			sm: 20,
			m: 24,
			l: 32,
			xl: 48,
			xxl: 64,
			xxxl: 96
		}
	}
};
