import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
	data: {},
	groups: [],
	processing: false,
	processingScroll: false,
	processingFilter: false,
	componentInitialized: false,
	error: null,
	tab: 'all'
});

const getGroups = (groups, groupsItem, reset) => {
	const newGroups = groupsItem.content;

	return reset || groupsItem.number === 0
		? newGroups
		: groups.toJS().concat(newGroups);
};

export default function(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.GROUPS_PROCESSING:
			return state.merge({
				processing: true
			});
		case ActionTypes.GROUPS_PROCESSING_SCROLL:
			return state.merge({
				processingScroll: true
			});
		case ActionTypes.GROUPS_PROCESSING_FILTER:
			return state.merge({
				processingFilter: true
			});
		case ActionTypes.GROUPS_SUCCESS:
			const groups = getGroups(
				state.get('groups'),
				action.payload,
				action.reset
			);

			return state.merge({
				processing: false,
				processingScroll: false,
				processingFilter: false,
				componentInitialized: true,
				data: action.payload,
				groups
			});
		case ActionTypes.GROUPS_FAILURE:
			return state.merge({
				error: action.payload,
				processing: false,
				processingScroll: false,
				processingFilter: false
			});
		case ActionTypes.GROUPS_TAB:
			return state.merge({
				tab: action.payload
			});
		case ActionTypes.GROUPS_RESET:
			return state.merge(initialState);
		default:
			return state;
	}
}
