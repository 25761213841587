import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import { TooltipRefactor as Tooltip, FontIcon } from 'fs-toolkit-react';
import { Card } from 'components/patterns/card';
import WithResize from 'components/HOC/WithResize';
import {
	getSearchItemMetadata,
	getSearchItemUrl
} from 'utils/globalSearchUtils';
import { sortImmutableItems } from 'utils/globalUtils';
import { formatStringForUrl } from 'utils/urlUtils';
import { isAdmin } from 'utils/profileUtils';
import { getIcon } from './searchFilter/searchUtils/iconUtils';

class SearchItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isKbResults: this.props.location.pathname.split('/')[1] === 'support',
			isKBAdmin: isAdmin({
				profile: this.props.profile,
				adminType: 'KBAdmin'
			}),
			showTooltip: false
		};
	}

	static propTypes = {
		article: PropTypes.instanceOf(Map).isRequired,
		superCategory: PropTypes.string,
		isGlobalSearch: PropTypes.bool
	};

	onClick = e => {
		// prevent navigation if click is on tooltip icon
		if (e.target.classList.value === 'fs-icon_container') return;

		const { article, superCategory, isGlobalSearch, history } = this.props;
		// select the first category as default if there isn't a superCategory prop value (main support page)
		const category = superCategory || article.getIn(['categories', 0, 'type']);

		const url = isGlobalSearch
			? getSearchItemUrl(article)
			: `/support/knowledge_base_articles/${category.toLowerCase()}/${article.get(
					'id'
			  )}/${formatStringForUrl(article.get('title'))}`;

		history.push(url);
	};

	getTitle = () => {
		const { article } = this.props;
		const title = this.props.isGlobalSearch
			? article.getIn(['searchable', 'title']) ||
			  article.getIn(['searchable', 'name'])
			: article.get('title');

		return <p className="reset title large">{title}</p>;
	};

	getSummary = () => {
		const { article } = this.props;
		const summary = this.props.isGlobalSearch
			? article.getIn(['searchable', 'summary'])
			: article.get('summary');

		return <span style={{ display: 'inline-block' }}>{summary}</span>;
	};

	toggleTooltip = () => {
		this.setState({ showTooltip: !this.state.showTooltip });
	};

	render = () => {
		const { isKbResults, isKBAdmin, showTooltip } = this.state;
		const { article, isMobile, isGlobalSearch } = this.props;
		const segments = article.get('segments');

		return (
			<Card onClick={this.onClick} modifier={['full-width']}>
				{isGlobalSearch && !isMobile && (
					<Card.Icon
						icon={getIcon(article)}
						position="left"
						iconSize={{ desktop: '20px' }}
					/>
				)}
				<Card.Body>
					{isGlobalSearch && (
						<Card.Meta style={{ color: '#505050' }}>
							{getSearchItemMetadata(article)}
						</Card.Meta>
					)}
					<Card.Title lines={{ mobile: 1, desktop: 2 }}>
						{this.getTitle()}
					</Card.Title>
					<Card.Excerpt>{this.getSummary()}</Card.Excerpt>
					{!article.get('published') && !isGlobalSearch && (
						<Card.Badge slim label="Draft" />
					)}
				</Card.Body>
				<Card.Footer>
					{isKbResults && isKBAdmin && segments && (
						<Tooltip
							heading={'Who can see this?'}
							position={isMobile ? 'left' : 'top'}
							size="medium"
							alwaysVisible={isMobile && showTooltip}
							disableHover={isMobile}
							items={sortImmutableItems({
								items: segments,
								comparator: 'title'
							}).map(s => s.get('title'))}
						>
							<FontIcon
								icon="faEyeFree"
								iconSize="2rem"
								onClick={this.toggleTooltip}
								style={{ display: 'block' }}
							/>
						</Tooltip>
					)}
				</Card.Footer>
			</Card>
		);
	};
}

const mapStateToProps = state => ({
	profile: state.getIn(['profile', 'data'])
});

export default withRouter(connect(mapStateToProps)(WithResize(SearchItem)));
