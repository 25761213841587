import APIClient from '../APIClient';
import { buildUrl } from 'utils/globalUtils';

const basePath = '/groups';

export const getTopicCategories = (params) => {
	const { groupId } = params;
	return APIClient.get(`${basePath}/${groupId}/topic_categories`);
};

export const getTopics = (params = {}) => {
	const { groupId, ...urlParams } = params;
	return APIClient.get(buildUrl(`${basePath}/${groupId}/topics`, urlParams || {}));
}

export const getGroup = (params) => {
	const { groupId } = params;
	return APIClient.get(`${basePath}/${groupId}`);
}