import React from 'react';
import { Loader } from 'fs-toolkit-react';
import PropTypes from 'prop-types';
import Error from 'components/views/error/Error';

class LoaderWrapper extends React.PureComponent {
	render() {
		const {
			children,
			loaderError,
			errorComponent,
			isLoading,
			...rest
		} = this.props;

		if (isLoading) {
			return <Loader {...this.props} />;
		} else if (loaderError) {
			return errorComponent || <Error {...rest} />;
		} else {
			return children;
		}
	}
}

LoaderWrapper.propTypes = {
	errorComponent: PropTypes.any,
	isLoading: PropTypes.bool,
	loaderError: PropTypes.any,
	loaderText: PropTypes.string
};

export default LoaderWrapper;
