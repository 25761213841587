import React from 'react';
import PropTypes from 'prop-types';
import { CardContext } from './CardContext';
import { Avatar } from 'fs-toolkit-react';
import { fsCardHeader } from './helpers/cardClassNameList';
import { withHide } from './helpers/withHide';
import { withResponsiveProp } from './helpers/withResponsiveProp';
import { findParentComponent } from 'utils/findParentComponent';

/**
 * Avatar component wrapped with card functionality.
 * The feature added was the ability to send data from this component up to
 * the parent Card component through the context api
 */
class CardAvatar extends React.Component {
	static contextType = CardContext;

	static propTypes = {
		position: PropTypes.oneOf(['top', 'left']),
		name: PropTypes.string.isRequired,
		url: PropTypes.string,
		size: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				mobile: PropTypes.string,
				desktop: PropTypes.string
			})
		])
	};

	static defaultProps = {
		url: ''
	};

	state = {
		avatarProps: {}
	};

	componentDidMount() {
		if (this.props.position) {
			this.context.fromChild({ modifier: `avatar-${this.props.position}` });
		}

		findParentComponent(fsCardHeader, this.avatarRef) &&
			this.setState({
				avatarProps: Object.assign(this.state.avatarProps, {
					size: 'x-small',
					type: 'group'
				})
			});
	}

	avatarRef = React.createRef();

	render() {
		return (
			<Avatar
				ref={this.avatarRef}
				{...Object.assign({}, this.props, this.state.avatarProps)}
			>
				{this.props.children}
			</Avatar>
		);
	}
}

const responsivePropConfig = [
	{ propName: 'size', mobile: 'small', desktop: 'medium' }
];

export default withResponsiveProp(withHide(CardAvatar))(responsivePropConfig);
